const Coffee = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_497_896)">
                <path
                    opacity="0.2"
                    d="M6.50754 16.8753C5.31144 16.3262 4.29803 15.4455 3.58765 14.3375C2.87728 13.2296 2.49979 11.9411 2.5 10.625V6.875H16.25V10.625C16.2502 11.9411 15.8727 13.2296 15.1623 14.3376C14.452 15.4455 13.4385 16.3263 12.2424 16.8753L6.50754 16.8753Z"
                    fill="#303030"
                />
                <path
                    d="M6.875 1.875V4.375"
                    stroke="#303030"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.375 1.875V4.375"
                    stroke="#303030"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.875 1.875V4.375"
                    stroke="#303030"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.5 16.875H16.25"
                    stroke="#303030"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.50754 16.8753C5.31144 16.3262 4.29803 15.4455 3.58765 14.3375C2.87728 13.2296 2.49979 11.9411 2.5 10.625V6.875H16.25V10.625C16.2502 11.9411 15.8727 13.2296 15.1623 14.3376C14.4519 15.4455 13.4385 16.3263 12.2424 16.8753"
                    stroke="#303030"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.25 6.875C16.913 6.875 17.5489 7.13839 18.0177 7.60723C18.4866 8.07607 18.75 8.71196 18.75 9.375V10C18.75 10.663 18.4866 11.2989 18.0177 11.7678C17.5489 12.2366 16.913 12.5 16.25 12.5H15.986"
                    stroke="#303030"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_497_896">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Coffee;
