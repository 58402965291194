import { RootState } from './index';
import { AvailableIntegration, EnabledIntegration, UserIntegrations, WorkspaceIntegration } from 'utils/types';

interface IntegrationsReducerState {
    userIntegrations: UserIntegrations;
    availableIntegrations: AvailableIntegration[];
    workspaceIntegrations: WorkspaceIntegration[];
    workspaceIntegrationLoading: boolean;
}

export const ACTION_SET_USER_INTEGRATIONS = 'setUserIntegrations';
export const ACTION_ADD_USER_INTEGRATION = 'addUserIntegration';
export const ACTION_SET_AVAILABLE_INTEGRATIONS = 'setAvailableIntegrations';
export const ACTION_GET_WORKSPACE_INTEGRATIONS = 'getWorkspaceIntegrations';
export const ACTION_SET_WORKSPACE_INTEGRATIONS = 'setWorkspaceIntegrations';
export const ACTION_ADD_WORKSPACE_INTEGRATION = 'addWorkspaceIntegration';
export const ACTION_DELETE_WORKSPACE_INTEGRATIONS = 'deleteWorkspaceIntegration';

const initialState: IntegrationsReducerState = {
    userIntegrations: [],
    availableIntegrations: [],
    workspaceIntegrations: [],
    workspaceIntegrationLoading: false,
}

const integrations = (state: IntegrationsReducerState = initialState, action: {type: string, payload: IntegrationsReducerState | EnabledIntegration | EnabledIntegration[] | AvailableIntegration[] | WorkspaceIntegration | WorkspaceIntegration[] | string}) => {
    switch (action.type) {
    case ACTION_SET_USER_INTEGRATIONS:
        return { ...state, userIntegrations: action.payload as EnabledIntegration[] };
    case ACTION_ADD_USER_INTEGRATION:
        return { ...state, userIntegrations:[...state.userIntegrations, action.payload as EnabledIntegration] }
    case ACTION_SET_AVAILABLE_INTEGRATIONS:
        return { ...state, availableIntegrations: action.payload as AvailableIntegration[] }
    case ACTION_GET_WORKSPACE_INTEGRATIONS:
        return { ...state, workspaceIntegrations: [], workspaceIntegrationLoading: true }
    case ACTION_SET_WORKSPACE_INTEGRATIONS:
        return { ...state, workspaceIntegrations: action.payload as WorkspaceIntegration[], workspaceIntegrationLoading: false }
    case ACTION_ADD_WORKSPACE_INTEGRATION:
        return { ...state, workspaceIntegrations:[...state.workspaceIntegrations, action.payload as WorkspaceIntegration] }
    case ACTION_DELETE_WORKSPACE_INTEGRATIONS:
        return { ...state, workspaceIntegrations: state.workspaceIntegrations.filter(workspaceIntegration => workspaceIntegration.name !== (action.payload as string)) }
    default:
        return state
    }
}
export default integrations;


export const userIntegrationsSelector = (store: RootState) => store.integrations.userIntegrations;
export const availableIntegrationsSelector = (store: RootState) => store.integrations.availableIntegrations;
export const workspaceIntegrationSelector = (store: RootState) => store.integrations.workspaceIntegrations;
export const workspaceIntegrationLoadingSelector = (store: RootState) => store.integrations.workspaceIntegrationLoading;
