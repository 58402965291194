import { Document, Page, Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer';
import { INITIATIVE_TAB_TO_TITLE_MAP } from 'utils/constants';
import { Initiative, OKR, Story, isAttachmentBlock, isLinkBlock } from 'utils/types';
import Html from 'react-pdf-html';

interface InitiaitvePdfProps{
    initiative: Initiative;
    stories: Story[];
    mockupLinks: string[];
    affectedOkr: OKR[]
}

const styles = StyleSheet.create({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    titleLg: {
        fontSize: 22,
        lineHeight: 1.2,
        textAlign: 'center',
        marginBottom: 30,
    },
    titleMd: {
        padding: 5,
        backgroundColor: '#025B62',
        fontSize: 16,
        lineHeight: 1.2,
        color: '#ffffff',
        marginBottom: 20,
        marginTop: 10,
    },
    twoText: {
        fontSize: 12,
        lineHeight: 1.3,
        fontWeight: 700,
        paddingRight: 5,
        marginBottom: 5,
    },
    smallTitle:{
        fontSize: 12,
        fontWeight: 700,
        color: '#025B62',
        lineHeight: 1.2,
        marginTop: 10,
    },
    editorText: {
        fontSize: 12,
        lineHeight: 1.4,
        marginBottom: 5,
        color: '#000000'
    },
    storyItem: {
        marginTop: 10,
        marginBottom: 10,
    },
    line: {
        borderBottom: '1px solid #e0e0e0',
        marginBottom: 10,
        marginTop: 10,
    },
    mapText: {
        fontSize: 12,
        lineHeight: 1.2,
        padding: 4,
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: '#f6f6f6'
    },
    link: {
        fontSize: 12,
        lineHeight: 1.4,
        textDecoration: 'underline',
        display: 'flex',
        marginBottom: 5,
    },
    imageContainer: {
        width: 260,
    },
    imageContainerLarge: {
        width: 460,
    },
    image: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        marginVertical: 10,
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});

const stylesheetHTML = {
    table: {
        borderCollapse: 'collapse',
        width: '100%'
    },
    th: {
        backgroundColor: '#025B62',
        color: 'white',
        padding: 5,
    },
    td: {
        padding: 5,
        backgroundColor: '#fafafa',
        border: '1px solid #c2c2c2',
    },
  };


const InitiaitvePdf = ({ initiative, stories, mockupLinks, affectedOkr }: InitiaitvePdfProps) => {

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap={true}>
                <Text style={styles.titleLg}>{initiative.title}</Text>

                <Text style={styles.titleMd}>{INITIATIVE_TAB_TO_TITLE_MAP.overview}</Text>
                <Text style={styles.twoText}>Type: {initiative.type}</Text>
                <Text style={styles.twoText}>Priority: {initiative.priority}</Text>
                <Text style={styles.twoText}>Size: {initiative.size}</Text>
                <Text style={styles.smallTitle}>Problem</Text>
                <Html resetStyles stylesheet={stylesheetHTML} style={styles.editorText}>
                    {initiative.problem || '-'}
                </Html>
                <Text style={styles.smallTitle}>Validation</Text>
                <Html resetStyles stylesheet={stylesheetHTML} style={styles.editorText}>
                    {initiative.validation || '-'}
                </Html>
                <Text style={styles.smallTitle}>High level solution</Text>
                <Html resetStyles stylesheet={stylesheetHTML} style={styles.editorText}>
                    {initiative.solution || '-'}
                </Html>

                <Text style={styles.titleMd}>{INITIATIVE_TAB_TO_TITLE_MAP.plan}</Text>
                <Text style={styles.twoText}>Owner: {initiative.owner.fullName}</Text>
                <Text style={styles.twoText}>
                    Product:
                    {initiative.products.length ? initiative.products.map((product, index: number) => (
                        <Text key={index} style={styles.mapText}>
                            {product.title + ', '}
                        </Text>
                    )) : '-'}
                </Text>
                <Text style={styles.twoText}>
                    Teams:
                    {initiative.teams.length ? initiative.teams.map((team, index: number) => (
                        <Text key={index} style={styles.mapText}>
                            {team.name + ', '}
                        </Text>
                    )) : '-'}
                </Text>
                <Text style={styles.twoText}>
                    Affected products:
                    {initiative.affectedProducts.length ? initiative.affectedProducts.map((affectedProduct, index: number) => (
                        <Text key={index} style={styles.mapText}>
                            {affectedProduct.title + ', '}
                        </Text>
                    )) : '-'}
                </Text>
                <Text style={styles.twoText}>
                    Development Timeline:
                    {initiative.startDate
                        ? new Date(initiative.startDate).toLocaleDateString() + ''
                        : ''}
                    -
                    {initiative.endDate
                        ? new Date(initiative.endDate).toLocaleDateString() + ''
                        : ''}
                </Text>
                <Text style={styles.twoText}>
                    Live date:
                    {initiative.releaseDate
                        ? new Date(initiative.releaseDate).toLocaleDateString() + ''
                        : ''}
                </Text>
                <View style={styles.line} />

                <Text style={styles.titleMd}>{INITIATIVE_TAB_TO_TITLE_MAP.requirements}</Text>
                {stories.length ? stories.map((story, index: number) => (
                    <View style={styles.storyItem} key={index}>
                        <Text style={styles.twoText}>Title: {story.title || '-'}</Text>
                        <Text style={styles.smallTitle}>
                            Description:
                            <Html resetStyles stylesheet={stylesheetHTML} style={styles.editorText}>
                                {story.description || '-'}
                            </Html>
                        </Text>
                        <View style={styles.line} />
                    </View>
                )) : <Text style={styles.editorText}>-</Text>}
                <Text style={styles.titleMd}>{INITIATIVE_TAB_TO_TITLE_MAP.mockups}</Text>
                {mockupLinks.length ? mockupLinks.map((mockupImg, index: number) => {
                    return (
                        <View style={styles.imageContainerLarge} key={index}>
                            <Image src={mockupImg} style={styles.image} />
                        </View>
                    );
                }) : <Text style={styles.editorText}>-</Text>}
                <Text style={styles.titleMd}>{INITIATIVE_TAB_TO_TITLE_MAP.impact}</Text>
                <Text style={styles.smallTitle}>
                    Success definition:
                    <Html resetStyles stylesheet={stylesheetHTML} style={styles.editorText}>
                        {initiative?.successDefinition || '-'}
                    </Html>
                </Text>
                <Text style={styles.twoText}>
                    Affected OKR:
                    {affectedOkr.length ? affectedOkr.map((okr, index: number) => (
                        <Text key={index} style={styles.mapText}>
                            {okr.objective + ', '}
                        </Text>
                    )) : '-'}
                </Text>
                <View style={styles.line} />

                <Text style={styles.titleMd}>{INITIATIVE_TAB_TO_TITLE_MAP.resources}</Text>
                <View>
                    {initiative.blocks?.length ? initiative.blocks.map((block, index: number) => {
                        if (isLinkBlock(block)) {
                            return (
                                <Link src={block.metadata.url} style={styles.link} key={index}>
                                    {block.metadata?.title || block.metadata.url}
                                </Link>
                            );
                        } else if (isAttachmentBlock(block)) {
                            return (
                                <View style={styles.imageContainer}>
                                    <Image src={block.metadata.signedUrl} style={styles.image} />
                                </View>
                            );
                        }
                    }) : <Text style={styles.editorText}>-</Text>}
                </View>
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </Page>
        </Document>
    );
};

export default InitiaitvePdf