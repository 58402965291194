import { Button, ConfirmationDialog, FilterButton, Flexbox, Loader, SearchField } from 'components'
import { EmptyInitiativeIcon, PlusIcon } from 'components/icons'
import { getProducts } from 'pages/Products/products.api'
import { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { productsSelector } from 'store/products'
import { Actions, hasPermission } from 'utils/permissions'
import classNames from 'classnames/bind';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import styles from './ideas.module.scss';
import { deleteIdea, getIdeas } from './ideas.api'
import { ideasSelector } from 'store/ideas'
import { Idea, InitiativePriority, PreferenceValues, Preferences, PreferencesKeys } from 'utils/types'
import { useDebounce, useWorkspaceId } from 'utils/hooks'
import EditDialog from './components/editDialog'
import { FilterOption } from 'components/FilterButton'
import { FilterValuesReducerAction } from 'pages/Products/productsList'
import { useSearchParams } from 'react-router-dom'
import { getEnumKeyByEnumValue } from 'utils'
import { getPreferences, updatePreferences } from 'common/preferences/index.api'
import { userSelector } from 'store/user'
import { ButtonItem } from 'components/ActionsMenu'
import AgGridTable, { AgColumn, ColumnTypes, GridStatePreferences } from 'components/AgGridTable'
import { GridApi, GridReadyEvent, SortChangedEvent } from 'ag-grid-community'
import ExportButton from 'components/AgGridTable/components/ExportButton'
import EmptyState from 'common/emptyState';
import * as timeago from 'timeago.js';
import { activeUsersSelector } from 'store/users-slice'
import { useLazyGetUsersQuery } from 'store/users-api'

const classes = classNames.bind(styles);
const classesInfo = classNames.bind(stylesInfo);


interface IdeaTableHeaders extends Idea {
    actions?: string;
}

export enum FilterKeys {
    owner = 'owner',
    product = 'product',
    priority = 'priority',
    query = 'query',
    orderBy = 'orderBy',
    order = 'order',
    ideaGridLayout = 'ideaGridLayout'
}

const defaultFilterState = {
    [FilterKeys.owner]: [],
    [FilterKeys.product]: [],
    [FilterKeys.priority]: [],
}

const filterValuesReducer = (state: {[key: string]: FilterOption[]}, action: FilterValuesReducerAction) => {
    switch(action.type) {
    case 'update':
        return { ...state, [action.key] : action.payload }
    case 'reset':
        return defaultFilterState;
    default:
        return state;
    }
}

export default () => {
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState<keyof IdeaTableHeaders | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc' | undefined>();
    const [searchValue, searchDebounceValue, setSearchValue] = useDebounce('');
    const [userOptions, setUserOptions] = useState<FilterOption[]>([]);
    const [productOptions, setProductOptions] = useState<FilterOption[]>([]);
    const [priorityOptions, setPriorityOptions] = useState<FilterOption[]>(Object.keys(InitiativePriority).map((key, index) => ({ id: index, title: key })));
    const [data, setData] = useState<Idea[]>([]);
    const [filterValues, setFilterValues] = useReducer(filterValuesReducer, defaultFilterState);
    const [canUpdatePreferences, setCanUpdatePreferences] = useState(false);

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [ideaToDelete, setIdeaToDelete] = useState<number | null>(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editingIdea, setEditingIdea] = useState<Idea | null | undefined>();

    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();

    const [getUsers] = useLazyGetUsersQuery()

    const [searchParams, setSearchParams] = useSearchParams();
    const [gridStatePreferences, setGridStatePreferences] = useState<GridStatePreferences | undefined>({})

    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null)

    const ideas = useSelector(ideasSelector);
    const users = useSelector(activeUsersSelector);
    const products = useSelector(productsSelector);
    const user = useSelector(userSelector);

    const columns: AgColumn[] = useMemo(() => [
        {
            headerName: 'Idea',
            field: 'title',
            minWidth: 150,
            sortable: true,
            defaultValue: 'Untitled',
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell',
        },
        {
            colType: ColumnTypes.TextEditor,
            headerName: 'Description',
            field: 'description',
            minWidth: 200,
            sortable: true,
        },
        {
            colType: ColumnTypes.SimpleSelect,
            headerName: 'Owner',
            field: 'owner',
            minWidth: 200,
            sortable: true,
            valueGetter: (params) => {
                return params.data ? params.data.owner?.fullName : '';
            },
            valueFormatter: (params) => {
                return params?.value?.fullName;
            },
        },
        {
            headerName: 'Product',
            field: 'product',
            minWidth: 150,
            sortable: true,
            valueFormatter: params => params.value?.title,
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell',
        },
        {
            colType: ColumnTypes.Priority,
            headerName: 'Priority',
            field: 'priority',
            sortable: true,
        },
        {
            colType: ColumnTypes.TimeAgo,
            headerName: 'Last Updated',
            field: 'lastModifiedDate',
            minWidth: 130,
            sortable: true,
        },
        {
            colType: ColumnTypes.Action,
            field: 'actions',
            headerName: '',
            actions: params =>  {
                const buttons: ButtonItem[] = [];
                if(hasPermission(Actions.edit, params.data)){
                    buttons.push({ label: 'Edit', action: () => onEditIdea(params.data) })
                }
                if(hasPermission(Actions.delete, params.data)){
                    buttons.push({ label: 'Delete', action: () => showDeleteConfirmation(params.data.id), type: 'red' })
                }
                return buttons
            },
        },
    ], [])

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                dispatch(getIdeas()),
                getUsers({ workspaceId }),
                dispatch(getProducts()),
                loadPreferences()
            ])
            setLoading(false)
        }

        setLoading(true);
        fetchData();
    }, []);

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.idea))) as unknown as Preferences<FilterKeys>[];
        if(preferences && preferences.length) {
            const { ideaGridLayout, order, orderBy, ...filters } = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;

            ideaGridLayout && setGridStatePreferences(ideaGridLayout as GridStatePreferences);
            setOrder(order);
            setOrderBy(orderBy)

            if(searchParams.toString().length === 0) {
                setSearchParams(filters, { replace: true })
            }
        }
    }

    useEffect(() => {
        const options: FilterOption[]  = users.map(u => ({ id: u.id, title: `${u.fullName}${u.id === user.id ? ' (Me)' : ''}`, tooltip: u.email }))
        const currentUserIndex = options.findIndex((o) => o.id === user.id)
        if(currentUserIndex >= 0) {
            const currentUserOption = options.splice(currentUserIndex, 1)[0]
            options.splice(0, 0, currentUserOption)
        }
        setUserOptions(options)

    }, [users, user])

    useEffect(() => {
        setProductOptions(products.map(product => ({ id: product.id, title: product.title || '' })))
    }, [products])

    useEffect(() => {
        if(canUpdatePreferences) {
            const ownerIds = filterValues[FilterKeys.owner].map(filter => filter.id);
            const priorities = filterValues[FilterKeys.priority].map(filter => filter.title);
            const productIds = filterValues[FilterKeys.product].map(filter => filter.id);

            const filterKeys: PreferenceValues<FilterKeys> = {};

            if(ownerIds.length) {
                filterKeys[FilterKeys.owner] = ownerIds.join(',');
            }

            if(productIds.length) {
                filterKeys[FilterKeys.product] = productIds.join(',');
            }

            if(priorities.length) {
                filterKeys[FilterKeys.priority] = priorities.join(',');
            }

            if(searchDebounceValue.length) {
                filterKeys[FilterKeys.query] = searchDebounceValue;
            }
            if(order && orderBy) {
                filterKeys[FilterKeys.order] = order;
                filterKeys[FilterKeys.orderBy] = orderBy;
            }

            dispatch(updatePreferences(filterKeys, PreferencesKeys.idea));

            setSearchParams(filterKeys, { replace: true });
        }
    }, [filterValues, searchDebounceValue, order, orderBy]);

    useEffect(() => {

        if(!loading) {
            const ownerIdsString = searchParams.get(FilterKeys.owner);
            if(ownerIdsString) {
                const ownerIds = ownerIdsString.split(',').map(id => parseInt(id));
                setFilterValues({ type: 'update', key: FilterKeys.owner, payload:  userOptions.filter(option => ownerIds.includes(option.id)) })
            }

            const productIdsString = searchParams.get(FilterKeys.product);
            if(productIdsString) {
                const productIds = productIdsString.split(',').map(id => parseInt(id));
                setFilterValues({ type: 'update', key: FilterKeys.product, payload:  productOptions.filter(option => productIds.includes(option.id)) })
            }

            const prioritiesString = searchParams.get(FilterKeys.priority);
            if(prioritiesString) {
                const priorities = prioritiesString.split(',');
                setFilterValues({ type: 'update', key: FilterKeys.priority, payload:  priorityOptions.filter(option => priorities.includes(option.title)) })
            }

            const queryString = searchParams.get(FilterKeys.query);
            if(queryString) {
                setSearchValue(queryString);
            }
        }
    }, [loading])

    useEffect(() => {
        let data = [...ideas];

        if(searchDebounceValue) {
            data = data.filter(idea => (
                idea.title && idea.title.toLowerCase().includes(searchDebounceValue.toLowerCase()) ||
                idea.description && idea.description.toLowerCase().includes(searchDebounceValue.toLowerCase())
            ))
        }

        data = data.filter(idea => {
            if(!filterValues[FilterKeys.owner].length || filterValues[FilterKeys.owner].some(filter => filter.id === idea.owner.id)) {
                return true;
            }
            return false;
        })

        data = data.filter(idea => {
            const ideaPriority = getEnumKeyByEnumValue(InitiativePriority, idea.priority);
            if(!filterValues[FilterKeys.priority].length || filterValues[FilterKeys.priority].some(filter => filter.title === ideaPriority)) {
                return true;
            }
            return false
        })

        data = data.filter(idea => {
            if(!filterValues[FilterKeys.product].length || filterValues[FilterKeys.product].some(filter => filter.id === idea.product?.id)) {
                return true;
            }
            return false;
        })

        setData(data)
    }, [ideas, searchDebounceValue, filterValues]);

    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setCanUpdatePreferences(true);
    }

    const onSearchClear = () => {
        setSearchValue('');
        setCanUpdatePreferences(true);
    }

    const onFilterValueChange = (targetName: string, value: FilterOption[]) => {
        setFilterValues({ type: 'update', key: targetName, payload: value });
        setCanUpdatePreferences(true)
    }

    const resetAllFilter = () => {
        setSearchValue('');
        setOrder(undefined);
        setOrderBy(undefined);
        setFilterValues({ type: 'reset' });
        setCanUpdatePreferences(true)
    }

    const onFilterReset = (filterName: string) => {
        setFilterValues({ type: 'update', key: filterName, payload: [] });
        setCanUpdatePreferences(true)
    }

    const openEditDialog = () => {
        setEditDialogOpen(true);
    }

    const closeEditDialog = () => {
        setEditDialogOpen(false)
        setEditingIdea(null);
    }

    const showDeleteConfirmation = (ideaId: number) => {
        setIdeaToDelete(ideaId)
        setOpenConfirmation(true)
    }

    const onCancelDelete = () => {
        setOpenConfirmation(false)
    }

    const deleteIdeaAction = async () => {
        if(ideaToDelete){
            dispatch(deleteIdea(ideaToDelete));
            onCancelDelete();
        }
    }

    const buttonItemsEmptyState = useMemo(() => {
        const buttons = []

        if(hasPermission(Actions.create)){
            buttons.push({
                onClick: openEditDialog,
                text: 'Create',
            },)
        }

        return buttons
    },[])

    const onEditIdea = (idea: Idea) => {
        setEditingIdea(idea);
        openEditDialog();
    }

    const onSortChanged = (e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if(value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        if(order !== value?.sort || orderBy !== value?.colId) {
            modifiedSearchParamsObject.ideaGridLayout = gridStatePreferences
            dispatch(updatePreferences(modifiedSearchParamsObject, PreferencesKeys.idea));
        }
    }

    const onGridStateChanged = (data: GridStatePreferences) => {
        setGridStatePreferences(data)
        const updatedPreferences: any = {};

        searchParams.forEach((value, key) => {
            updatedPreferences[key] = value;
        });

        updatedPreferences.ideaGridLayout = data
        dispatch(updatePreferences(updatedPreferences, PreferencesKeys.idea));
    }

    const onGridReady = useCallback ((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    return (
        <Flexbox vertical fullWidth className={classes('mainContainer')}>
            <Flexbox vertical className={classesInfo('headerContainer')}>
                <Flexbox className={classesInfo('headerInfoTop')}>
                    <Flexbox className={classesInfo('headerTitle')}>Ideas</Flexbox>
                    {ideas.length > 0 &&
                        <Flexbox>
                            { gridApi && (
                                <ExportButton
                                    api={gridApi}
                                    formatExportedCellValue={
                                        (colId: string, value: any, formattedValue: any) => {
                                            if (colId === 'lastModifiedDate') {
                                                return value ? timeago.format(value) : '';
                                            } else if(colId === 'description') {
                                                return value.replace(/<\/?[^>]+(>|$)/g, '');
                                            } else {
                                                return formattedValue
                                            }
                                        }
                                    }
                                />
                            ) }
                            <Button variant='contained' onClick={openEditDialog} className={classesInfo('createButton')}>Create<PlusIcon/></Button>
                        </Flexbox>
                    }
                </Flexbox>
                {ideas.length > 0 && <Flexbox className={classesInfo('headerInfo')}>
                    <Flexbox>
                        <SearchField
                            value={searchValue}
                            onChange={onSearchValueChange}
                            onClear={onSearchClear}
                            placeholder='Search Idea'
                            className={classesInfo('searchInput')}
                        />
                        <FilterButton
                            options={userOptions}
                            value={filterValues[FilterKeys.owner]}
                            onChange={(value) => onFilterValueChange(FilterKeys.owner, value)}
                            onFilterReset={() => onFilterReset(FilterKeys.owner)}
                            multiple
                            label={'Owner'}
                            className={classesInfo('filterButton')}
                            keepFirstOption
                        />

                        <FilterButton
                            options={priorityOptions}
                            value={filterValues[FilterKeys.priority]}
                            onChange={(value) => onFilterValueChange(FilterKeys.priority, value)}
                            onFilterReset={() => onFilterReset(FilterKeys.priority)}
                            multiple
                            sortAlphabetically={false}
                            label={'Priority'}
                            className={classesInfo('filterButton')}
                        />
                        <FilterButton
                            options={productOptions}
                            value={filterValues[FilterKeys.product]}
                            onChange={(value) => onFilterValueChange(FilterKeys.product, value)}
                            onFilterReset={() => onFilterReset(FilterKeys.product)}
                            multiple
                            label={'Products'}
                            className={classesInfo('filterButton')}
                        />
                        {Object.values(filterValues).some(v => v.length !== 0) &&
                                <Flexbox className={classesInfo('resetButtonContainer')}>
                                    <Button className={classesInfo('resetButton')} onClick={resetAllFilter}>
                                    Reset
                                    </Button>
                                </Flexbox>
                        }
                    </Flexbox>

                </Flexbox>}
            </Flexbox>
            <Flexbox className={classes('tableContainer')}>
                {loading ? (
                    <Flexbox fullWidth fullHeight align justify><Loader disableShrink/></Flexbox>
                ) : ideas.length > 0 ? (
                    <AgGridTable
                        data={data}
                        columns={columns}
                        order={order}
                        orderBy={orderBy}
                        onSortChanged={onSortChanged}
                        onGridStateChanged={onGridStateChanged}
                        gridStatePreferences={gridStatePreferences}
                        onGridReady={onGridReady}
                        exportFileName='Ideas'
                    />
                ) : (
                    <EmptyState
                        icon={<EmptyInitiativeIcon />}
                        title='There are no Ideas yet'
                        titleSmall={buttonItemsEmptyState.length ? 'Write down your first idea' : ''}
                        buttonItems={buttonItemsEmptyState}
                    />
                )}
            </Flexbox>

            <ConfirmationDialog
                open={openConfirmation}
                onClose={onCancelDelete}
                onConfirm={deleteIdeaAction}
                confirmButtonStyle='danger'
                title='Delete this idea?'
            >
                <Flexbox>
                    You're about to permanently delete this Idea.
                </Flexbox>
            </ConfirmationDialog>
            <EditDialog isOpen={editDialogOpen} onClose={closeEditDialog} idea={editingIdea} />
        </Flexbox>
    )
}