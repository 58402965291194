export const compareEnumValues = <T extends string>(d1: T, d2: T, data: Record<string, T>)  => {
    const dataArray = Object.values(data)

    return dataArray.indexOf(d1) > dataArray.indexOf(d2) ? 1 : dataArray.indexOf(d1) < dataArray.indexOf(d2) ? -1 : 0
}

export const sortByValue = <T extends string>(d1: T, d2: T, order: 'asc' | 'desc', data: Record<string, T>)  => {
    const dataArray = Object.values(data)
    const comparison = dataArray.indexOf(d1) - dataArray.indexOf(d2);

    if(comparison > 0) {
        return order === 'asc' ? 1 : -1
    } else {
        return order === 'asc' ? -1 : 1
    }
}