import { Flexbox } from 'components'
import Table, { TableHeader, TableRowProps } from 'components/Table';
import { useEffect, useState } from 'react';
import { getEnumKeyByEnumValue } from 'utils';
import { ReportInitiative, InitiativeStatus } from 'utils/types';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
const classes = classNames.bind(styles);

const initiativesHeader: TableHeader<ReportInitiative>[] = [
    {
        id: 'title',
        text: 'Title',
        sortable: true,
    },
    {
        id: 'daysInStatus',
        text: 'Days in Status',
        sortable: true,
    },
    {
        id: 'status',
        text: 'Status',
        sortable: true,
        width: '180px',
    },
    {
        id: 'startDate',
        text: 'Start Date',
        sortable: true,
    },
    {
        id: 'releaseDate',
        text: 'Live Date',
        sortable: true,
    },
];

interface InitiativeReportTableProps{
    initiatives: ReportInitiative[]
}

const InitiativeReportTable = ({ initiatives }: InitiativeReportTableProps) => {
    const [orderBy, setOrderBy] = useState<keyof ReportInitiative | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc' | undefined>();

    const [data, setData] = useState<ReportInitiative[]>([])

    useEffect(() => {
        setData(data)
    }, [initiatives])

    useEffect(() => {
        let data = [...initiatives]

        if(order && orderBy) {
            data.sort((d1, d2) => {
                const value1 = d1[orderBy];
                const value2 = d2[orderBy]
                if(!!value1  && !!value2 && value1 > value2) {
                    return order === 'asc' ? 1 : -1
                } else {
                    return order === 'asc' ? -1 : 1
                }
            })
        }
        setData(data);

    }, [initiatives, order, orderBy])

    const sortHandler = (header: keyof ReportInitiative) => {
        setOrderBy(header);
        setOrder(oldOrder => {
            return oldOrder === 'asc' ? 'desc' : 'asc'
        })
    }

    return(
        <Flexbox vertical className={classes('tableContainer')}>
            <Flexbox className={classes('title')}>
                Initiatives
            </Flexbox>
            <Table
                header={initiativesHeader}
                stickyHeader={false}
                data={data.map(initiative => {
                    const startDate = initiative.startDate ? new Date(initiative.startDate) : null;
                    const releaseDate = initiative.releaseDate ? new Date(initiative.releaseDate) : null;

                    const row: TableRowProps = {
                        url: `/initiatives/initiative/${initiative.id}`,
                        data: [
                            <Flexbox>{initiative.title || 'Untitled'}</Flexbox>,
                            <Flexbox>
                                {initiative.daysInStatus}
                            </Flexbox>,
                            <Flexbox>{getEnumKeyByEnumValue(InitiativeStatus, initiative.status)}</Flexbox>,
                            <Flexbox>{startDate?.toLocaleDateString()}</Flexbox>,
                            <Flexbox>{releaseDate?.toLocaleDateString()}</Flexbox>,
                        ],
                    }
                    return row
                })}
                orderBy={orderBy}
                order={order}
                sortHandler={sortHandler}
                emptyText={data.length === 0 ? 'There are no Initiatives yet' : undefined}
            />
        </Flexbox>
    )
}

export default InitiativeReportTable