const JiraSmall = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M14.5468 1.71378H7.66736C7.66736 2.5374 7.99454 3.32729 8.57693 3.90968C9.15932 4.49207 9.94921 4.81926 10.7728 4.81926H12.0401V6.04283C12.0412 7.75638 13.43 9.14523 15.1436 9.14635V2.31057C15.1436 1.98106 14.8765 1.71378 14.5468 1.71378Z" fill="currentColor" />
            <path d="M11.1423 5.14172H4.26294C4.264 6.85527 5.6528 8.24413 7.36641 8.24525H8.63368V9.47273C8.63586 11.1863 10.0256 12.5742 11.7392 12.5742V5.73863C11.7392 5.40901 11.4719 5.14172 11.1423 5.14172Z" fill="currentColor" />
            <path d="M7.73612 8.5675H0.856689C0.856689 10.2826 2.24711 11.673 3.96217 11.673H5.23341V12.8965C5.23453 14.6085 6.62092 15.9967 8.33296 16V9.16435C8.33296 8.83473 8.06573 8.5675 7.73612 8.5675Z" fill="currentColor" />
        </svg>
    )
}

export default JiraSmall