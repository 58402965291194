import { PreferenceValues } from 'utils/types';
import { RootState } from './index';


export enum MainPreferenceKeys {
    sidebarCollapsed = 'sidebarCollapsed',
}
interface PreferencesReducerState {
    loading: boolean;
    preferences: PreferenceValues<MainPreferenceKeys>;
}

const initialState: PreferencesReducerState = {
    loading: true,
    preferences: {},
}

export const ACTION_GET_PREFERENCES = 'getPreferences';
export const ACTION_SET_PREFERENCES = 'setPreferences';
export const ACTION_UPDATE_PREFERENCES = 'updatePreferences';

const mainPreferences = (state: PreferencesReducerState = initialState, action: {type: string, payload: any}): PreferencesReducerState => {
    switch (action.type) {
    case ACTION_GET_PREFERENCES:
        return { ...state, preferences: {}, loading: true }
    case ACTION_SET_PREFERENCES:
        return { ...state, loading: false, preferences: { ...action.payload } }
    case ACTION_UPDATE_PREFERENCES:
        return { ...state, preferences: { ...state.preferences, ...action.payload } }
    default:
        return state
    }
}
export default mainPreferences;

export const preferencesSelector = (store: RootState) => store.mainPreferences.preferences;
export const preferencesLoadingSelector = (store: RootState) => store.mainPreferences.loading;
