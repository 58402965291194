import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { logout, refreshToken } from 'pages/Home/home.api';
import store, { RootState } from 'store';
import { BASE_URL } from './constants';
import { Mutex } from 'async-mutex';
import { ACTION_SET_GLOBAL_ERROR, ACTION_UPDATE_NOT_FOUND } from 'store/globalError';

/** This will prevent multiple requests to refresh token when multiple requests fail from 401 */
const mutex = new Mutex();

export const rtkBaseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState;
        const token = state.user.token;
        if (token) {
            headers.set('Authorization', `Bearer ${state.user.token}`);
        }
        return headers;
    },
});

/** https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery */
export const rtkBaseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await rtkBaseQuery(args, api, extraOptions);

    const error = result.error;

    if (error && error.data && typeof error.data === 'object' && 'errorMessage' in error.data && typeof error.data.errorMessage === 'string' && error.data.errorMessage.toLowerCase().includes('network error')) {
        store.dispatch({ type: ACTION_SET_GLOBAL_ERROR, payload: error.data.errorMessage });
    }

    if (error && error.data && typeof error.data === 'object' &&
        (
            ('code' in error.data && (error.data.code === 'access_denied' || error.data.code === 'entity_not_found')) ||
            (error.status === 403)
        )
    ) {
        store.dispatch({ type: ACTION_UPDATE_NOT_FOUND, payload: true });
    }

    if (result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const { data } = await refreshToken();
                if (data) {
                    // retry the initial query
                    result = await rtkBaseQuery(args, api, extraOptions);
                } else {
                    await logout();
                }
            } finally {
                // release must be called once the mutex should be released again.
                release();
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            result = await rtkBaseQuery(args, api, extraOptions);
        }
    }
    return result;
};
