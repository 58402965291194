function blobToBase64(blob: Blob) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            } else {
                reject(new Error('Failed to read blob as base64'));
            }
        };
        reader.onerror = () => {
            reject(new Error('Failed to read blob as base64'));
        };
        reader.readAsDataURL(blob);
    });
}

export default blobToBase64;