import request from 'utils/request';
import { Block, LinkMetadata, TextMetadata } from 'utils/types';
import { Dispatch, GetState } from 'store';


export const editAction = (blockId: number, linkText: LinkMetadata, baseUrl: string) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();

    const url = `/workspaces/${storeState.user.workspace.id}/${baseUrl}/${blockId}`.replaceAll('//', '/')
    const res = await request.put(url, linkText);
    return res.data
}

export const deleteAction = (blockId: number, baseUrl: string) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();

    const url = `/workspaces/${storeState.user.workspace.id}/${baseUrl}/${blockId}`.replaceAll('//', '/')
    const res = await request.delete(url);

    return res.data
}

export const createAction = (linkText: LinkMetadata, baseUrl: string) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();

    const url = `/workspaces/${storeState.user.workspace.id}/${baseUrl}/link`.replaceAll('//', '/')
    const res = await request.post(url, linkText);

    return res.data as Block
}

export const createText = (content: TextMetadata, baseUrl: string) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();

    const url = `/workspaces/${storeState.user.workspace.id}/${baseUrl}/text`.replaceAll('//', '/')
    const res = await request.post(url, content);

    return res.data as Block
}

export const updateText = (blockId: number, content: TextMetadata, baseUrl: string) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();

    const url = `/workspaces/${storeState.user.workspace.id}/${baseUrl}/${blockId}`.replaceAll('//', '/')
    const res = await request.put(url, content);
    return res.data as Block
}