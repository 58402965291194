const TrafficSign = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_763_8385)">
                <path opacity="0.2" d="M9.55807 1.37943L1.37944 9.55806C1.13536 9.80214 1.13536 10.1979 1.37944 10.4419L9.55807 18.6206C9.80215 18.8646 10.1979 18.8646 10.442 18.6206L18.6206 10.4419C18.8647 10.1979 18.8647 9.80214 18.6206 9.55806L10.442 1.37943C10.1979 1.13535 9.80215 1.13535 9.55807 1.37943Z" fill="#303030" />
                <path d="M11.25 11.25L13.125 9.375L11.25 7.5" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.55807 1.37943L1.37944 9.55806C1.13536 9.80214 1.13536 10.1979 1.37944 10.4419L9.55807 18.6206C9.80215 18.8646 10.1979 18.8646 10.442 18.6206L18.6206 10.4419C18.8647 10.1979 18.8647 9.80214 18.6206 9.55806L10.442 1.37943C10.1979 1.13535 9.80215 1.13535 9.55807 1.37943Z" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.875 11.875V11.25C6.875 10.7527 7.07254 10.2758 7.42417 9.92417C7.77581 9.57254 8.25272 9.375 8.75 9.375H13.125" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_763_8385">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default TrafficSign