const FileText = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_763_7434)">
                <path opacity="0.2" d="M11.875 2.5V6.875H16.2506L11.875 2.5Z" fill="#303030" />
                <path d="M15.625 17.5H4.37439C4.20863 17.5 4.04966 17.4342 3.93245 17.3169C3.81524 17.1997 3.74939 17.0408 3.74939 16.875V3.125C3.74939 2.95924 3.81524 2.80027 3.93245 2.68306C4.04966 2.56585 4.20863 2.5 4.37439 2.5H11.875L16.25 6.875V16.875C16.25 16.9571 16.2338 17.0383 16.2024 17.1142C16.171 17.19 16.125 17.2589 16.0669 17.3169C16.0089 17.375 15.94 17.421 15.8642 17.4524C15.7883 17.4838 15.7071 17.5 15.625 17.5Z" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.875 2.5V6.875H16.2506" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.5 10.625H12.5" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.5 13.125H12.5" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_763_7434">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FileText