import { ConfirmationDialog, CustomSnackbar, CustomTyphography, Dialog, Flexbox, IconButton, Link, Tooltip } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { FC, useState } from 'react';
import { DeleteIcon, JiraSmallIcon } from 'components/icons';
import { useWorkspaceId } from 'utils/hooks';
import { useParams } from 'react-router-dom';
import { useRemoveLinkFromNodeMutation } from 'store/views-api';
import { LinkNode } from 'utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { setUnlinkTaskError, unlinkTaskErrorSelector } from 'store/views-slice';

const classes = classNames.bind(styles);

interface LinkedNodesModalProps {
    open: boolean;
    handleClosePopup: () => void;
    linkNodes: LinkNode[];
    nodeId: number;
    nodeTitle: string;
}

const LinkedNodesModal: FC<LinkedNodesModalProps> = ({ open, handleClosePopup, linkNodes, nodeId, nodeTitle }) => {
    const workspaceId = useWorkspaceId();
    const params = useParams();
    const dispatch = useDispatch()

    const viewId = params.id

    const [removeLinkFromNode, { isLoading: removeLinkLoading }] = useRemoveLinkFromNodeMutation()
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [currentLinkId, setCurrentLinkId] = useState<number | null>(null)

    const unlinkTaskError = useSelector(unlinkTaskErrorSelector)

    const onClosePopup = () => {
        handleClosePopup()
        setCurrentLinkId(null)
        dispatch(setUnlinkTaskError(''))
    }

    const showDeleteConfirmation = (id: number) => {
        setOpenDeleteConfirmation(true)
        setCurrentLinkId(id)
    }

    const onCancelDelete = () => {
        setOpenDeleteConfirmation(false)
    }

    const handleRemoveLinkFromNode = async () => {
        try {
            await removeLinkFromNode({
                workspaceId,
                viewId,
                nodeId,
                linkedNodeId: currentLinkId
            }).unwrap();

            onClosePopup()
        } catch (error) {
            console.error('Failed to unlink', error);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClosePopup}
            title={'Linked Nodes'}
            description={nodeTitle}
            className={classes('linkingNodesContainer')}
        >
            <Flexbox vertical className={classes('pt-5')}>
                <CustomTyphography className={classes('mb-5')}>Linked Nodes ({linkNodes.length})</CustomTyphography>
                <Flexbox vertical className={classes('gap-2')}>
                    {linkNodes.map((node) => (
                        <Flexbox align justifyBetween className={classes('linkedNode')} key={node.id}>
                            <Link to={node.source} target='_blank'>
                                <Flexbox vertical className={classes('gap-1')}>
                                    <Flexbox align className={classes('gap-2')}>
                                        <JiraSmallIcon />
                                        <CustomTyphography>{node.key}</CustomTyphography>
                                    </Flexbox>
                                    <CustomTyphography>{node.title}</CustomTyphography>
                                </Flexbox>
                            </Link>
                            <Tooltip title='Unlink'>
                                <IconButton
                                    className={classes('deleteIcon')}
                                    onClick={() => showDeleteConfirmation(node.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Flexbox>
                    ))}
                </Flexbox>
            </Flexbox>
            <ConfirmationDialog
                open={openDeleteConfirmation}
                onClose={onCancelDelete}
                onConfirm={handleRemoveLinkFromNode}
                confirmButtonStyle='danger'
                title='Delete this link?'
                confirmButtonLoading={removeLinkLoading}
            >
                <Flexbox>
                    Are you sure you want to delete this link?
                </Flexbox>
            </ConfirmationDialog>

            <CustomSnackbar open={!!unlinkTaskError} type='error' title={unlinkTaskError} />
        </Dialog>
    );
};

export default LinkedNodesModal;
