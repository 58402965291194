import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Metric } from 'utils/types'
import store, { RootState } from 'store';
import { SaveStatus } from 'common/savingStatus';
import { metricsApi } from './metrics-api';

interface MetricsState {
    metrics: Metric[],
    savingStatus: SaveStatus
}

const initialState: MetricsState = {
    metrics: [],
    savingStatus: SaveStatus.UNSET
}

const metricsSlice = createSlice({
    name: 'metrics',
    initialState,
    reducers: {
        setMetricsData: (state, action: PayloadAction<Metric[]>) => {
            state.metrics = action.payload
        },
        updateMetricSavingStatus: (state, action: PayloadAction<SaveStatus>) => {
            state.savingStatus = action.payload
        },
        deleteMetricAction: (state, action: PayloadAction<number>) => {
            state.metrics = state.metrics.filter(m => m.id !== action.payload)
        },
        updateMetricAction: (state, action: PayloadAction<Metric>) => {
            state.metrics = state.metrics.map(metric => {
                if(metric.id === action.payload.id) {
                    return action.payload
                } else {
                    return metric
                }
            })
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            metricsApi.endpoints.getMetrics.matchFulfilled,
            (state, { payload }) => {
                state.metrics = payload
            },
        ),
        builder.addMatcher(
            metricsApi.endpoints.editMetric.matchFulfilled,
            (state, { payload }) => {
                state.savingStatus = SaveStatus.SAVED

                setTimeout(() => {
                    store.dispatch(updateMetricSavingStatus(SaveStatus.UNSET));
                }, 2000);
            },
        ),
        builder.addMatcher(
            metricsApi.endpoints.pushMetricData.matchFulfilled,
            (state, { payload }) => {
                state.savingStatus = SaveStatus.SAVED

                setTimeout(() => {
                    store.dispatch(updateMetricSavingStatus(SaveStatus.UNSET));
                }, 2000);
            },
        ),
        builder.addMatcher(
            metricsApi.endpoints.createMetric.matchFulfilled,
            (state, { payload }) => {
                state.metrics = [payload, ...state.metrics]
            },
        )
    },
})

export const metricsSelector = (store: RootState) => store.metrics.metrics;
export const metricsSavingStatusSelector = (store: RootState) => store.metrics.savingStatus;

export const {
    setMetricsData,
    updateMetricSavingStatus,
    deleteMetricAction,
    updateMetricAction
} = metricsSlice.actions;

export default metricsSlice;
