import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Flexbox, Loader } from 'components';
import { Preferences, PreferencesKeys, Role, User, UserStatuses } from 'utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { Permissions } from 'utils/permissions';
import { jobTitles } from './constants/jobTItles';
import AgGridTable, { AgColumn, ColumnTypes, GridStatePreferences } from 'components/AgGridTable';
import { CellEditRequestEvent, GetRowIdFunc, GetRowIdParams, GridApi, GridReadyEvent, SortChangedEvent } from 'ag-grid-community';
import SelectEditor from 'components/AgGridTable/components/SelectEditor';
import { useSearchParams } from 'react-router-dom';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import { setUsersData, updateUserStatus, usersSelector } from 'store/users-slice';
import UsersHeader from './components/UsersHeader';
import { useDeactivateUserMutation, useEditUserMutation, useLazyGetUsersQuery, useResendInviteUsersMutation } from 'store/users-api';
import { useWorkspaceId } from 'utils/hooks';
import styles from './users.module.scss';

const classes = classNames.bind(styles);

export enum FilterKeys {
    query = 'query',
    order = 'order',
    orderBy = 'orderBy',
    userGridLayout = 'userGridLayout'
}

interface IPreferencesData {
    query?: string,
    order?: 'asc' | 'desc',
    orderBy?: keyof User,
    userGridLayout?: any
}

interface IPreferencesParams extends Omit<IPreferencesData, 'userGridLayout'> { }

let timeoutId: NodeJS.Timeout;

const modifyUpdatedData = (event: CellEditRequestEvent) => {
    if (event.colDef.field === 'roles') {
        let newValue
        for (const key in Permissions) {
            if (Permissions[key as keyof typeof Permissions] === event.newValue) {
                newValue = key
            }
        }

        return [{ roleId: event.oldValue.roleId, name: newValue, authority: newValue }]
    } else {
        return event.newValue
    }
}

const Users = () => {
    const columns: AgColumn[] = useMemo(() => [
        {
            headerName: 'Full name',
            field: 'fullName',
            minWidth: 220,
            sortable: true,
            editable: true,
        },
        {
            headerName: 'Job title',
            field: 'jobTitle',
            minWidth: 200,
            sortable: true,
            editable: true,
            cellEditorPopup: true,
            cellEditor: SelectEditor,
            cellEditorParams: {
                options: jobTitles,
                placeholder: 'Select job title',
                defaultValue: null
            },
        },
        {
            headerName: 'E-mail',
            field: 'email',
            minWidth: 200,
            sortable: true,
        },
        {
            colType: ColumnTypes.SimpleSelect,
            headerName: 'Role',
            field: 'roles',
            minWidth: 150,
            sortable: true,
            editable: true,
            cellEditorParams: {
                values: Object.values(Permissions)
            },
            valueGetter: (params: { data: { roles: { name: keyof typeof Permissions }[] } }) => {
                const roleName = params.data?.roles[0]?.name;
                return roleName && Permissions[roleName];
            },
        },
        {
            colType: ColumnTypes.Circle,
            headerName: 'Teams',
            field: 'teams',
            sortable: true,
        },
        {
            colType: ColumnTypes.SimpleSelect,
            headerName: 'Status',
            field: 'status',
            minWidth: 100,
            sortable: true,
        },
        {
            colType: ColumnTypes.Action,
            field: 'actions',
            headerName: '',
            actions: params => {
                return params.data?.status === UserStatuses.INVITED ? [
                    {
                        label: 'Resend invite',
                        action: () => {
                            onResendInvite({ email: params.data.email, role: params.data.roles[0].name })
                        }
                    }
                ] : params.data?.status === UserStatuses.ACTIVE ? [
                    {
                        label: 'Deactivate',
                        type: 'red',
                        action: () => { deactivateUserHandler(params.data.id) }
                    }
                ] : []
            },
        },
    ], []);

    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();

    const preferencesRef = useRef<IPreferencesData>({});

    const [getUsers, { data: usersData }] = useLazyGetUsersQuery();
    const [editUser] = useEditUserMutation();
    const [resendInviteUsers] = useResendInviteUsersMutation();
    const [deactivateUser] = useDeactivateUserMutation();

    const [searchParams, setSearchParams] = useSearchParams();

    const users = useSelector(usersSelector);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);
    const [searchValue, setSearchValue] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState<null | string>(null);

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.userGridLayout))) as unknown as Preferences<FilterKeys>[];

        if (preferences && preferences.length) {
            const { userGridLayout, order, orderBy, query } = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;

            preferencesRef.current = {
                query,
                order,
                orderBy,
                userGridLayout
            }

            if (searchParams.toString().length === 0) {
                let queryFilters: IPreferencesParams = {}

                queryFilters = {
                    ...queryFilters,
                    ...(order && orderBy ? { order, orderBy } : {}),
                    ...(query ? { query } : {})
                };

                setSearchParams(queryFilters, { replace: true })
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                getUsers({ workspaceId }),
                loadPreferences()
            ])
            setIsLoading(false)
        }
        setIsLoading(true);
        fetchData();
    }, [])

    useEffect(() => {
        if (usersData) {
            const filteredData = usersData.filter((user: User) => user.fullName?.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase()) || user.email.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase()))
            dispatch(setUsersData(filteredData))
        }
    }, [searchValue, usersData])

    useEffect(() => {
        if (!isLoading) {
            const queryString = searchParams.get(FilterKeys.query);
            if (queryString) {
                setSearchValue(queryString);
            }
        }
    }, [isLoading])

    const onSortChanged = useCallback((e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        if (preferencesRef.current.order !== value?.sort || preferencesRef.current.orderBy !== value?.colId) {
            preferencesRef.current = { userGridLayout: preferencesRef.current.userGridLayout, ...modifiedSearchParamsObject }
            dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.userGridLayout));
        }
    }, [preferencesRef, searchParams])

    const onGridStateChanged = useCallback((data: GridStatePreferences) => {
        preferencesRef.current.userGridLayout = data

        dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.userGridLayout));
    }, [preferencesRef]);

    const updateQueryPreference = useCallback((value: string) => {
        setSearchValue(value);
        preferencesRef.current.query = value;

        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setDebouncedQuery(value);
        }, 500);

    }, [preferencesRef])

    useEffect(() => {
        if (debouncedQuery !== null) {
            dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.userGridLayout));
        }

        return () => clearTimeout(timeoutId);
    }, [debouncedQuery])

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    const deactivateUserHandler = (userId: number) => {
        deactivateUser({ workspaceId, userId }).then(() => dispatch(updateUserStatus({ userId, status: UserStatuses.DEACTIVATED })));
    }

    const updateUser = useCallback(async (user: User) => {
        const res: any = await editUser({
            workspaceId,
            userId: user.id,
            data: {
                fullName: user.fullName,
                jobTitle: user.jobTitle,
                role: user.roles[0].name,
            }
        })

        return !res.error
    }, [])

    const onResendInvite = (data: { email: string, role: Role }) => {
        resendInviteUsers({ workspaceId, data: [data] })
    }

    const getRowId = useMemo<GetRowIdFunc>(() => {
        return (params: GetRowIdParams) => params.data.id;
    }, []);

    return (isLoading ? <Flexbox fullWidth align justify><Loader /></Flexbox> :
        <Flexbox vertical fullWidth className={classes('mainContainer')}>
            <UsersHeader
                gridApi={gridApi}
                updateQueryPreference={updateQueryPreference}
                searchValue={searchValue}
            />
            <Flexbox className={classes('tableContainer')}>
                <AgGridTable
                    data={users}
                    columns={columns}
                    onGridReady={onGridReady}
                    order={preferencesRef.current.order}
                    orderBy={preferencesRef.current.orderBy}
                    gridStatePreferences={preferencesRef.current.userGridLayout}
                    onGridStateChanged={onGridStateChanged}
                    onSortChanged={onSortChanged}
                    exportFileName='Users'
                    readOnlyEdit={true}
                    updateRowCallback={updateUser}
                    modifyUpdatedData={modifyUpdatedData}
                    getRowId={getRowId}
                />
            </Flexbox>
        </Flexbox>
    )
}

export default Users;