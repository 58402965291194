import { CustomTyphography, Flexbox } from 'components'
import { FC } from 'react'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ErrorAlert } from 'components/icons';

const classes = classNames.bind(styles);

interface AlertsBoxProps {
    value: number
}

const AlertsBox: FC<AlertsBoxProps> = ({ value }) => {
    return (
        <Flexbox align className={classes('alertsBox', 'accentCaution')}>
            <ErrorAlert />
            <CustomTyphography>{value} Alerts</CustomTyphography>
        </Flexbox>
    )
}

export default AlertsBox