import { Collapse, Flexbox, Input, Select, Table, Tooltip } from 'components';
import classNames from 'classnames/bind';
import styles from '../../../initiative.module.scss';
import { ChangeEvent, useState } from 'react';
import { ArrowCollapsedIcon, ArrowExpendedIcon, InfoOutlinedIcon } from 'components/icons';
import { TableHeader, TableRowProps } from 'components/Table';
import { Contribution, InitiativeStatus } from 'utils/types';
const classes = classNames.bind(styles);


interface KrTableProps {
    title: string;
    impact: string;
    actualImpact: string;
    uom: string;
}

const KrTableHeaders: TableHeader<KrTableProps>[] = [
    {
        id: 'title',
        text: 'Metric',
        width: '320px',
    },
    {
        id: 'impact',
        text: 'Impact level',
        width: '160px',
    },
    {
        id: 'actualImpact',
        text: 'Actual impact',
        width: '160px',
        endIcon:  <Tooltip title='Fill after live'><InfoOutlinedIcon className={classes('iconTableHeader')} /></Tooltip>,
    },
]

interface selectedOkrProps {
    okrId: number;
    contributions: Contribution[];
    onImpactSelect: (value: string, krId: number, okrId: number) => void;
    onActualImpactValue: (value: number, krId: number, okrId: number) => void;
    status: InitiativeStatus;
    isEditable?: boolean;
    objective?: string;
}

const ImpactValues = ['No Impact', 'Low', 'Medium', 'High'];


const SelectedOkr = ({
    okrId,
    onImpactSelect,
    onActualImpactValue,
    status,
    isEditable = true,
    objective,
    contributions
} : selectedOkrProps) => {

    const [expand, setExpand] = useState(true)

    const handleExpand = () => {
        setExpand(!expand)
    }

    return(
        <Flexbox vertical className={classes('selectedOkrsBox')}>
            <Flexbox align className={classes('selectedOkrHead')}>
                <Flexbox align className={classes('selectedOkrHeadLeft')}>
                    <Flexbox
                        className={classes('okrExpandBtn')}
                        key={okrId}
                        onClick={handleExpand}
                    >
                        {expand ? <ArrowExpendedIcon /> : <ArrowCollapsedIcon /> }
                    </Flexbox>
                    <Flexbox className={classes('okrExpandTitle')}>
                        {objective}
                    </Flexbox>
                </Flexbox>
            </Flexbox>
            <Collapse
                in={expand}
                timeout="auto" unmountOnExit>
                <Flexbox className={classes('selectedOkrContainer')} vertical>
                    <Table
                        nested
                        stickyHeader={false}
                        className={classes('okrTableContainer')}
                        header={KrTableHeaders}
                        data={contributions.map(kr => {
                            const impactValue = kr.impact

                            const row: TableRowProps = {
                                data: [
                                    <Flexbox className={classes('metricName', { noImpact: !impactValue })}>
                                        {kr.keyResult.metric.name + (kr.keyResult.metric.uom && ` (${kr.keyResult.metric.uom})`)}
                                    </Flexbox>,
                                    <Flexbox>
                                        <Select
                                            value={impactValue || ImpactValues[0]}
                                            fullWidth
                                            onChange={(e: ChangeEvent<{}>, value: string | null) => {
                                                onImpactSelect(value === ImpactValues[0] || value === null ? '' : value, kr.keyResult.id, okrId)
                                            }}
                                            options={ImpactValues}
                                            disableClearable
                                            disabled={!isEditable}
                                            variant='filled'
                                            className={classes('impactSelect',
                                            // {
                                            //     blue: impactValue === ImpactValues[1],
                                            //     yellow: impactValue === ImpactValues[2],
                                            //     red: impactValue === ImpactValues[3],
                                            // }
                                                { noImpact: !impactValue }
                                            )}
                                        />
                                    </Flexbox>,
                                    <Flexbox>
                                        {status === InitiativeStatus.Live && impactValue &&
                                        <Input
                                            placeholder='Add actual impact'
                                            variant='filled'
                                            value={kr.actualImpact}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                onActualImpactValue(parseInt(e.target.value), kr.keyResult.id, okrId)
                                            }}
                                            alwaysEditable
                                            type='number'
                                            fullWidth
                                            className={classes('actualImpactInput')}
                                        />
                                        }
                                    </Flexbox>,
                                ]
                            }
                            return row;
                        })}
                    />
                </Flexbox>
            </Collapse>
        </Flexbox>
    )
}


export default SelectedOkr