import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flexbox, CustomTyphography, ConfirmationDialog, ActionsMenu, CommonButton, Dialog, } from 'components';
import { DeleteIcon, EditIcon, PlusCircle } from 'components/icons';

import CommonInput from 'components/CommonInput';
import Session from './Session';

import { v4 as uuid } from 'uuid';

import { deleteUserSession, getUserSession, getUserSessions, renameUserSession } from './assistant.api';
import { userSelector } from 'store/user';
import { deleteSession, messagesSelector, renameChat, replaceMessages, sessionIdSelector, sessionsSelector, setSessionId } from 'store/assistant-slice';

import { removeFromLocalStorage, setToLocalStorage } from 'utils/localStorage';
import { PRDMainType } from 'utils/types';

import classNames from 'classnames/bind';
import styles from './assistant.module.scss';

const classes = classNames.bind(styles);

export const returnMainContent = (content: PRDMainType, currentSection?: string) => {
    let newContent = content

    if (content?.title) {
        const { title, ...restContent } = content;
        newContent = restContent
    }

    if (currentSection) {
        newContent = { [currentSection]: content[currentSection] }
    }

    return newContent
}

export default () => {
    const dispatch = useDispatch();

    const [openHistorySidebar, setOpenHistorySidebar] = useState(false);
    const [currentSessionId, setCurrentSessionId] = useState<null | string>(null);
    const [openRenameDialog, setOpenRenameDialog] = useState(false);
    const [chatTitle, setChatTitle] = useState('');
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [isSessionSwitchDisabled, setIsSessionSwitchDisabled] = useState(false)

    const messages = useSelector(messagesSelector);
    const user = useSelector(userSelector);
    const sessions = useSelector(sessionsSelector);
    const sessionId = useSelector(sessionIdSelector);

    const handleStartNewSession = () => {
        const newSessionId = uuid();
        dispatch(setSessionId(newSessionId))
        dispatch(replaceMessages([]))
    }

    const handleSelectSession = (sessionId: string) => {
        try {
            dispatch(getUserSession({ userId: user.id, sessionId: sessionId }))
        } catch (error) {
            console.log(error);
        }
    }

    const showDeleteConfirmation = (sessionId: string) => {
        setCurrentSessionId(sessionId)
        setOpenDeleteConfirmation(true)
    }

    const handleOpenHistorySidebar = () => {
        setOpenHistorySidebar(prev => !prev)
    }

    const handleDeleteChat = async () => {
        if (currentSessionId) {
            try {
                await dispatch(deleteUserSession({ userId: user.id, sessionId: currentSessionId }))
                dispatch(deleteSession(currentSessionId));
                removeFromLocalStorage('sessionId');

                if (sessionId === currentSessionId) {
                    handleStartNewSession()
                }
            } catch (error) {
                console.log(error);
            }
        }
        setOpenDeleteConfirmation(false)
        setCurrentSessionId(null)
    }

    const onCancelDelete = () => {
        setOpenDeleteConfirmation(false)
    }

    const resetRenameDialog = () => {
        setOpenRenameDialog(false)
        setCurrentSessionId(null);
        setChatTitle('')
    }

    const handleRenameChat = async () => {
        if (currentSessionId) {
            try {
                await dispatch(renameUserSession({ userId: user.id, sessionId: currentSessionId, data: { title: chatTitle } }));
                dispatch(renameChat({ id: currentSessionId, title: chatTitle }))
            } catch (err) {
                console.log(err);
            }
        }

        resetRenameDialog()
    }

    useEffect(() => {
        dispatch(getUserSessions(user.id))
    }, [])

    useEffect(() => {
        if (!sessionId) {
            const newSessionId = uuid();
            dispatch(setSessionId(newSessionId))
        }
    }, [sessionId]);

    useEffect(() => {
        // Save Session if user did something in that session
        if (sessionId && (sessions.some(session => session.session_id === sessionId) || messages.length > 0)) {
            setToLocalStorage('sessionId', sessionId);
        }
    }, [sessionId, messages.length])

    useEffect(() => {
        if (sessions.every(session => session.session_id !== sessionId) && messages.length >= 2) {
            dispatch(getUserSessions(user.id))
        }
    }, [messages.length, sessionId, sessions.length])

    return (
        <Flexbox fullWidth className={classes('assistantContainer')}>
            {
                openHistorySidebar && (
                    <Flexbox vertical className={classes('history', 'gap-3')}>
                        <CommonButton
                            buttonType='shadow'
                            variant='text'
                            startIcon={<PlusCircle />}
                            onClick={handleStartNewSession}
                            disabled={isSessionSwitchDisabled}
                        >
                            New Session
                        </CommonButton>
                        <Flexbox vertical className={classes('gap-3', 'sessions')}>
                            {
                                sessions.map(session => {
                                    return (
                                        <Flexbox
                                            justifyBetween
                                            align
                                            key={session.session_id}
                                            className={classes('history-item', { active: session.session_id === sessionId }, { disabled: isSessionSwitchDisabled })}
                                            onClick={() => {
                                                if (!isSessionSwitchDisabled) {
                                                    handleSelectSession(session.session_id)
                                                }
                                            }}
                                        >
                                            <CustomTyphography className={classes('ellipsisText')}>{session.title}</CustomTyphography>
                                            <ActionsMenu
                                                btnClassName={classes('sessionActions-btn')}
                                                activeClassName={classes('session-active')}
                                                disabled={isSessionSwitchDisabled}
                                                buttonItems={[
                                                    {
                                                        label: 'Rename chat',
                                                        action: () => {
                                                            setOpenRenameDialog(true);
                                                            setCurrentSessionId(session.session_id)
                                                            setChatTitle(session.title)
                                                        },
                                                        icon: <EditIcon />
                                                    },
                                                    { label: 'Delete chat', action: () => showDeleteConfirmation(session.session_id), icon: <DeleteIcon />, type: 'red' },
                                                ]}
                                            />
                                        </Flexbox>
                                    )
                                })
                            }
                        </Flexbox>
                    </Flexbox>
                )
            }

            {!!sessionId && <Session
                onOpenHistorySidebar={handleOpenHistorySidebar}
                openHistorySidebar={openHistorySidebar}
                onStartNewSession={handleStartNewSession}
                chatBtnDisabled={!sessions.length}
                sessionId={sessionId}
                messages={messages}
                setIsSessionSwitchDisabled={setIsSessionSwitchDisabled}
            />}

            <ConfirmationDialog
                open={openDeleteConfirmation}
                onClose={onCancelDelete}
                onConfirm={handleDeleteChat}
                confirmButtonStyle='danger'
                title='Delete the history?'
            >
                <Flexbox>
                    Are you sure you want to clear the chat history?
                </Flexbox>
            </ConfirmationDialog>

            <Dialog
                onClose={resetRenameDialog}
                open={openRenameDialog}
                cancelButton
                title="Rename Chat"
                confirmButton
                onConfirm={handleRenameChat}
                disabled={!chatTitle}
                PaperProps={{
                    sx: {
                        width: '450px'
                    }
                }}
            >
                <Flexbox fullWidth>
                    <CommonInput value={chatTitle} onChange={(e) => setChatTitle(e.target.value)} />
                </Flexbox>
            </Dialog>
        </Flexbox>
    );
};
