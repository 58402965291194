import { Flexbox } from 'components';
import RichTextEditor, { EditorRef, RichTextEditorProps } from 'components/RichTextEditor';
import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { useFieldComments } from 'store/comments-api';
import { startCommentCreation } from 'store/comments-slice';
import { Editor } from 'tinymce';
import { createCustomEvent, highlightNewCommentSegment } from 'utils/comments';
import { v4 as uuid } from 'uuid';
import { CommentField, CommentFieldProps } from './CommentField';

interface CommentFieldEditorProps extends CommentFieldProps, RichTextEditorProps {
    flexClassname?: string;
    hideCommentToolbar?: boolean
}

export const CommentFieldEditor = forwardRef<EditorRef, CommentFieldEditorProps>(
    (
        { workspaceId, initiativeId, objectKey, objectId, fieldKey, flexClassname, hideCommentToolbar, ...editorProps },
        ref
    ) => {
        const dispatch = useDispatch();
        const { comments } = useFieldComments(
            { workspaceId, objectKey, objectId },
            fieldKey,
            initiativeId
        );

        const handleCommentCreate = (editor: Editor) => {
            const temporaryId = uuid();
            highlightNewCommentSegment(editor, temporaryId);
            dispatch(
                startCommentCreation({ workspaceId, objectKey, objectId, fieldKey, temporaryId })
            );
            document.dispatchEvent(createCustomEvent('comment-create-start', {}));
        };

        return (
            <CommentField
                workspaceId={workspaceId}
                initiativeId={initiativeId}
                objectKey={objectKey}
                objectId={objectId}
                fieldKey={fieldKey}
                rightGutter
                hideCommentToolbar={hideCommentToolbar}
            >
                <Flexbox className={flexClassname}>
                    <RichTextEditor
                        ref={ref}
                        {...editorProps}
                        comments={comments}
                        onCommentCreate={handleCommentCreate}
                        commentFieldKey={fieldKey}
                        commentObjectId={objectId}
                    />
                </Flexbox>
            </CommentField>
        );
    }
);
