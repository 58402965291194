import { Button, Checkbox, Flexbox, IconButton, Menu, MenuItem, RadioButton, SearchField, Tooltip } from 'components';
import { ArrowExpendedIcon, CloseIcon, SearchIcon } from 'components/icons';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import styles from './index.module.scss';
const classes = classNames.bind(styles);
import classNames from 'classnames/bind';
import { useDebounce } from 'utils/hooks';

export interface FilterOption {
    id: number;
    title: string;
    tooltip?: string
}
export interface FilterGroup {
    title?: string,
    items: FilterOption[],
}
interface FilterButtonBaseProps {
    label: string;
    options: FilterOption[] | FilterGroup[];
    className?: string;
    onFilterReset?: () => void;
    sortAlphabetically?: boolean;
    canSelectAll?: boolean;
    keepFirstOption?: boolean;
}

interface FilterButtonSingleProps extends FilterButtonBaseProps {
    onChange: (option: FilterOption) => void
    value: FilterOption;
    multiple: false
}
interface FilterButtonMultiProps extends FilterButtonBaseProps {
    onChange: (option: FilterOption[]) => void;
    value: FilterOption[];
    multiple: true
}
function isMultiple(props: FilterButtonMultiProps | FilterButtonSingleProps): props is FilterButtonMultiProps {
    return props.multiple;
}

function isOptionGroup(option: FilterOption | FilterGroup): option is FilterGroup {
    return !!(option as FilterGroup)?.items;
}

function getAllOptions(filteredOptions: (FilterOption | FilterGroup)[]) {
    return filteredOptions.flatMap(op => isOptionGroup(op) ? op.items : op)
}

function isAllSelected(filteredOptions: (FilterOption | FilterGroup)[], value: FilterOption[]) {
    const allOptions = getAllOptions(filteredOptions)

    return allOptions.every(op => value.some(el => {
        return el.id === op.id;
    }));
}

const FilterButton = (props: FilterButtonSingleProps | FilterButtonMultiProps) => {
    const { label, options, className, onFilterReset, sortAlphabetically = true, canSelectAll = true, keepFirstOption } = props
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const [searchValue, searchDebounceValue, setSearchValue] = useDebounce('');
    const [filteredOptions, setFilteredOptions] = useState<FilterOption[] | FilterGroup[]>(options)

    const onButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onMenuItemClick = (option: FilterOption) => {

        if (isMultiple(props)) {

            if (props.value.find(o => option.id === o.id)) {
                props.onChange(props.value.filter(o => option.id !== o.id))
            } else {
                props.onChange([...props.value, option])
            }
        } else {
            props.onChange(option);
            handleClose();
        }
    }

    const isSelectedOption = (option: FilterOption) => {
        if (isMultiple(props)) {
            return props.value.some(i => option.id === i.id)
        } else {
            return props.value.id === option.id
        }

    }

    const renderOption = (option: FilterOption) => {
        return (
            <MenuItem
                disableGutters
                className={classes('menuItem')}
                key={option.id}
                selected={isSelectedOption(option)}
                onClick={() => onMenuItemClick(option)}
            >
                {props.multiple ?
                    <Checkbox className={classes('checkbox')} size='small' checked={isSelectedOption(option)} />
                    :
                    <RadioButton className={classes('checkbox')} size='small' checked={isSelectedOption(option)} />}
                <Tooltip title={option.tooltip || option.title}><span className={classes('menuItem-text')}>{option.title}</span></Tooltip>
            </MenuItem>
        )
    }

    const renderElements = () => {
        if (sortAlphabetically) {
            if (keepFirstOption && filteredOptions.length > 0) {
                const firstOption = filteredOptions.shift() as FilterOption & FilterGroup;
                filteredOptions.sort((a, b) => (a.title || '').localeCompare(b.title || '', undefined, { numeric: true }));
                if (firstOption !== undefined) {
                    filteredOptions.unshift(firstOption);
                }
            } else {
                filteredOptions.sort((a, b) => (a.title || '').localeCompare(b.title || '', undefined, { numeric: true }));
            }
        }

        if (filteredOptions.length === 0) {
            return (
                <Flexbox className={classes('empty')} vertical>
                    No matches found
                </Flexbox>
            )
        }

        return filteredOptions.map((option, opIdx) => {
            if (isOptionGroup(option)) {
                sortAlphabetically && option.items.sort((a, b) => (a.title || '').localeCompare(b.title || '', undefined, { numeric: true }));

                return (
                    <Flexbox key={`${option.title}-${opIdx}`} className={classes('filterOption')} vertical>
                        {option.title && option.items.length > 0 &&
                            <Flexbox className={classes('filterGroupTitle')}>
                                {option.title}
                            </Flexbox>
                        }
                        {option.items.map((o) => {
                            return renderOption(o)
                        })}
                    </Flexbox>
                )
            } else {
                return renderOption(option)
            }
        })
    }

    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    }

    const onSearchClear = () => {
        setSearchValue('');
    }

    const onToggleSelectAll = (isSelected: boolean) => {
        if (isSelected && isMultiple(props)) {
            return props.value.filter(val => !filteredOptions.some(op => isOptionGroup(op) ? op.items.some(item => item.id === val.id) : op.id === val.id))
        } else {
            const allOptions = getAllOptions(filteredOptions)
            return [...props.value as FilterOption[], ...allOptions] as FilterOption[]
        }
    }

    useEffect(() => {
        if (Array.isArray(options) && options.length > 0) {
            let filteredArray = options;
            if (isOptionGroup(options[0])) {
                filteredArray = (options as FilterGroup[]).map((group) => ({
                    ...group,
                    items: group.items.filter((subItem) =>
                        subItem.title.toLowerCase().includes(searchDebounceValue.trim().toLowerCase())
                    ),
                }));
            } else {
                filteredArray = (options as FilterOption[]).filter((item) =>
                    item.title.toLowerCase().includes(searchDebounceValue.trim().toLowerCase())
                );
            }

            setFilteredOptions(filteredArray)
        }
    }, [searchDebounceValue, options]);

    return (
        <Flexbox className={classes('container', className)}>
            <Button
                className={classes('button', { 'active': isMultiple(props) && props.value.length })}
                onClick={onButtonClick}
                variant="contained"
                endIcon={<ArrowExpendedIcon />}
            >
                {label}
                {isMultiple(props) && props.value.length ? ` (+${props.value.length})` : null}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes('menu')}
                classes={{
                    list: classes({ menuReset: onFilterReset }),
                    paper: classes('menuPaper')
                }}
                autoFocus={!canSelectAll}
            >
                <SearchField
                    placeholder='Search'
                    startAdornment={null}
                    endAdornment={(
                        <>
                            {searchValue ?
                                <IconButton onClick={onSearchClear}><CloseIcon className={classes('icon')} /></IconButton> :
                                <SearchIcon className={classes('searchIcon')} />
                            }
                        </>
                    )}
                    classes={{
                        root: classes('searchField'),
                        input: classes('searchInput'),
                        focused: classes('focused'),
                    }}
                    value={searchValue}
                    onChange={onSearchValueChange}
                    onKeyDown={e => e.stopPropagation()}
                />

                {
                    canSelectAll && isMultiple(props) && (
                        <MenuItem
                            disableGutters
                            className={classes('menuItem')}
                            key={'select_all'}
                            selected={isAllSelected(filteredOptions, props.value)}
                            onClick={() => props.onChange(onToggleSelectAll(isAllSelected(filteredOptions, props.value)))}
                        >
                            <Checkbox className={classes('checkbox')} size='small' checked={isAllSelected(filteredOptions, props.value)} />
                            Select All
                        </MenuItem>
                    )}

                {renderElements()}

                {onFilterReset &&
                    <Flexbox className={classes('filterResetContainer')}>
                        <Button disabled={!(!!isMultiple(props) && !!props.value.length)} className={classes('filterResetButton')} onClick={onFilterReset}>
                            Clear selection
                        </Button>
                    </Flexbox>
                }
            </Menu>
        </Flexbox>
    )
}

export default FilterButton