import Flexbox from 'components/Flexbox';
import { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { useGetInitiativeTemplatesQuery } from 'store/initiativeTemplates-api';
import { initiativeTemplatesSelector } from 'store/initiativeTemplates-slice';
import { useWorkspaceId } from 'utils/hooks';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import classNames from 'classnames/bind';
import { Box, Loader } from 'components';
import styles from './styles.module.scss'
import AgGridTable, { AgColumn, ColumnTypes } from 'components/AgGridTable';

const classesInfo = classNames.bind(stylesInfo);
const classes = classNames.bind(styles);

const InitiativeTemplates = () => {
    const workspaceId = useWorkspaceId();

    const { isLoading } = useGetInitiativeTemplatesQuery({ workspaceId });
    const initiativeTemplates = useSelector(initiativeTemplatesSelector)

    const columns: AgColumn[] = useMemo(() => [
        {
            colType: ColumnTypes.Linked,
            headerName: 'Name',
            field: 'name',
            minWidth: 200,
            sortable: true,
            suppressMovable: true,
            wrapText: true,
            autoHeight: true,
            link: '/initiativeTemplates/template',
            linkParam: 'id',
        },
    ], []);

    return (
        <Box sx={{
            width: '100%'
        }}>
            <Flexbox vertical className={classesInfo('headerContainer')}>
                <Flexbox wrap className={classesInfo('headerInfoTop')}>
                    <Flexbox className={classesInfo('headerTitle')}>Initiative Templates</Flexbox>
                </Flexbox>
            </Flexbox>
            {isLoading ? <Flexbox justify><Loader /></Flexbox> : (
                <Flexbox className={classes('tableContainer')}>
                    <AgGridTable
                        columns={columns}
                        data={initiativeTemplates}
                        pivotPanelShow={'never'}
                        rowGroupPanelShow='never'
                        sideBar={false}
                        autoSizeStrategy={{ type: 'fitGridWidth' }}
                    />
                </Flexbox>
            )}
        </Box>
    )
}

export default InitiativeTemplates