const EmptyRoadmap = () => {
    return (
        <svg
            width={220}
            height={220}
            viewBox="0 0 220 220"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx={41.6229}
                cy={183.217}
                rx={9.43249}
                ry={9.34232}
                fill="#EBEBEB"
            />
            <ellipse
                cx={173.679}
                cy={89.7642}
                rx={9.4325}
                ry={9.34232}
                fill="#EBEBEB"
            />
            <path
                d="M41.626 183.158H148.965C160.01 183.158 168.965 174.203 168.965 163.158V156.446C168.965 145.4 160.01 136.446 148.965 136.446H113.505C102.459 136.446 93.5047 127.492 93.5047 116.446V109.734C93.5047 98.6887 102.459 89.7344 113.505 89.7344H168.965"
                stroke="#EBEBEB"
                strokeWidth={1.8}
            />
            <path
                d="M66.7791 126.477C66.7791 145.247 42.413 161.336 42.413 161.336C42.413 161.336 18.0469 145.247 18.0469 126.477C18.0469 120.076 20.614 113.938 25.1835 109.412C29.7531 104.886 35.9507 102.344 42.413 102.344C48.8753 102.344 55.0729 104.886 59.6424 109.412C64.212 113.938 66.7791 120.076 66.7791 126.477Z"
                stroke="#EBEBEB"
                strokeWidth={1.8}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M42.417 134.542C46.9026 134.542 50.539 130.94 50.539 126.498C50.539 122.055 46.9026 118.453 42.417 118.453C37.9313 118.453 34.2949 122.055 34.2949 126.498C34.2949 130.94 37.9313 134.542 42.417 134.542Z"
                stroke="#EBEBEB"
                strokeWidth={1.8}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M198.837 33.016C198.837 51.7863 174.471 67.8751 174.471 67.8751C174.471 67.8751 150.104 51.7863 150.104 33.016C150.104 26.6155 152.672 20.4771 157.241 15.9513C161.811 11.4254 168.008 8.88281 174.471 8.88281C180.933 8.88281 187.131 11.4254 191.7 15.9513C196.27 20.4771 198.837 26.6155 198.837 33.016Z"
                stroke="#EBEBEB"
                strokeWidth={1.8}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M174.465 41.0888C178.95 41.0888 182.587 37.4872 182.587 33.0444C182.587 28.6016 178.95 25 174.465 25C169.979 25 166.343 28.6016 166.343 33.0444C166.343 37.4872 169.979 41.0888 174.465 41.0888Z"
                stroke="#EBEBEB"
                strokeWidth={1.8}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default EmptyRoadmap;