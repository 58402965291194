import { Flexbox, IconButton, Link, Tooltip, Image } from 'components';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './sidebar.module.scss';
import { defaultMenuItems } from './menuItems';
import { useEffect, useState } from 'react';
import { PreferenceValues, SidebarMenuItem } from 'utils/types';
import MenuItem from './components/menuItem';
import { ArrowBackIcon, CollapseIcon, ExpandIcon } from 'components/icons';
import FeatureFlagEnabled from 'components/FeatureFlagEnabled';
const classes = classNames.bind(styles);
import { useDispatch, useSelector } from 'react-redux';
import { MainPreferenceKeys, preferencesLoadingSelector, preferencesSelector } from 'store/mainPreferences';
import { updatePreferences } from 'pages/Signin/signin.api';
import SidebarGroup from './components/SidebarGroup';
import logo from 'logo.svg'
import { useFirstAvailableMenuItem } from 'utils/hooks';

const Sidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const firstAvailableMenuItem = useFirstAvailableMenuItem()

    const [nestedMenu, setNestedMenu] = useState<SidebarMenuItem | null>(null);
    const [collapsed, setCollapsed] = useState<boolean>(false)

    const preferences = useSelector(preferencesSelector)
    const preferencesLoading = useSelector(preferencesLoadingSelector)

    useEffect(() => {
        const item = defaultMenuItems.admin.items.find(menuItem => {
            if (menuItem.subMenus) {
                const subMenu = menuItem.subMenus.find(sub => sub.url === location.pathname.replace('\/', ''));
                return !!subMenu
            }
            return false;
        })
        if (item) {
            setNestedMenu(item);
        }
    }, [location.pathname])

    useEffect(() => {
        if (preferences && preferences[MainPreferenceKeys.sidebarCollapsed] !== undefined) {
            setCollapsed(preferences[MainPreferenceKeys.sidebarCollapsed] === 'true')
        }
    }, [preferences]);

    const expandSidebar = async () => {
        const filterKeys: PreferenceValues<MainPreferenceKeys> = { [MainPreferenceKeys.sidebarCollapsed]: !collapsed + '' };
        await dispatch(updatePreferences(filterKeys));
        setCollapsed(!collapsed)
    }

    return !preferencesLoading ? <Flexbox className={classes('mainContainer', { sidebarCollapsed: collapsed })}>
        <Flexbox vertical>
            <Flexbox vertical className='gap-1'>
                <Flexbox align className={classes('logoContainer')}>
                    {!collapsed &&
                        <Link to={`/${firstAvailableMenuItem?.url || ''}`} className={classes('logoLink')}>
                            <Image src={logo} alt='Prodmap.ai' />
                        </Link>
                    }
                    <Tooltip key={collapsed ? 'Expand' : 'Collapse'} placement="right" title={collapsed ? 'Expand' : 'Collapse'}>
                        <IconButton
                            className={classes('collapseButton')}
                            disableRipple
                            size="small"
                            onClick={expandSidebar}
                        >
                            {collapsed ? <ExpandIcon /> : <CollapseIcon />}
                        </IconButton>
                    </Tooltip>
                </Flexbox>

                {
                    !!nestedMenu && (
                        <FeatureFlagEnabled flagKey={nestedMenu.featureFlag}>
                            <Flexbox className={classes('backButtonContainer')} onClick={() => { setNestedMenu(null) }}>

                                <Tooltip placement="right" title='Back'>
                                    <ArrowBackIcon />
                                </Tooltip>
                            </Flexbox>
                        </FeatureFlagEnabled>
                    )
                }
                {
                    !nestedMenu ? (
                        <>
                            {
                                Object.entries(defaultMenuItems).map(([groupName, menu]) => {
                                    // let menuItems: SidebarMenuItem[] = []

                                    // if (groupName === 'views' && menu.getMenuItems) {
                                    //     menuItems = menu.getMenuItems ? menu.getMenuItems(views) : []
                                    // } else {
                                    //     menuItems = menu.items
                                    // }

                                    if (groupName !== 'admin') {
                                        return (
                                            <SidebarGroup key={groupName} groupName={groupName} menu={menu} menuItems={menu.items} collapsed={collapsed} />
                                        )
                                    }
                                })
                            }
                        </>
                    ) : nestedMenu?.subMenus && (
                        <Flexbox className={classes('gap-1')} vertical>
                            {nestedMenu.subMenus.map((item, i) => (
                                <FeatureFlagEnabled key={i} flagKey={item.featureFlag}>
                                    <MenuItem isCollapsed={collapsed} item={item} />
                                </FeatureFlagEnabled>
                            ))}
                        </Flexbox>
                    )
                }
            </Flexbox>

            {/* <Flexbox align justify className={classes('expandButtonContainer', { expandedButton: !collapsed })}>
                <Tooltip key={collapsed ? 'Expand' : 'Collapse'} placement="right" title={collapsed ? 'Expand' : 'Collapse'}>
                    <IconButton
                        className={classes('collapseButton')}
                        disableRipple
                        size="small"
                        onClick={expandSidebar}
                    >
                        {collapsed ? <ArrowFatLinesRight /> : <CollapseIcon />}
                    </IconButton>
                </Tooltip>
            </Flexbox> */}
        </Flexbox>
        <Flexbox className={classes('bottomMenuItemsContainer')} vertical>
            {
                defaultMenuItems.admin.items?.filter(menu => menu.onBottom && !menu.hidden).map((item, i) => (
                    <FeatureFlagEnabled key={i} flagKey={item.featureFlag}>
                        <MenuItem
                            isCollapsed={collapsed}
                            item={item}
                            onClick={() => { setNestedMenu(item) }}
                        />
                    </FeatureFlagEnabled>
                ))
            }
        </Flexbox>
    </Flexbox>
        : (<Flexbox className={classes('mainContainer')}></Flexbox>)
}

export default Sidebar;