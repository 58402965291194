const SquareHalf = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_763_8010)">
                <path opacity="0.2" d="M10 3.4375H15.9375C16.1033 3.4375 16.2622 3.50335 16.3794 3.62056C16.4967 3.73777 16.5625 3.89674 16.5625 4.0625V15.9375C16.5625 16.1033 16.4967 16.2622 16.3794 16.3794C16.2622 16.4967 16.1033 16.5625 15.9375 16.5625H10V3.4375Z" fill="#303030" />
                <path d="M15.9375 3.4375H4.0625C3.71732 3.4375 3.4375 3.71732 3.4375 4.0625V15.9375C3.4375 16.2827 3.71732 16.5625 4.0625 16.5625H15.9375C16.2827 16.5625 16.5625 16.2827 16.5625 15.9375V4.0625C16.5625 3.71732 16.2827 3.4375 15.9375 3.4375Z" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10 3.4375V16.5625" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_763_8010">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SquareHalf