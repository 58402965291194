import { Box, CustomSnackbar, CustomTyphography, Dialog, Flexbox, Grid, IconButton } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Coffee, Disc, JiraSmallIcon, LanguageIcon } from 'components/icons';
import { TextField } from '@mui/material';
import { Datasource, DatasourceType, Source } from 'utils/types';
import { useCreateViewMutation, useEditViewMutation } from 'store/views-api';
import { useWorkspaceId } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { createViewErrorSelector, setCreateViewError, setViewName, updateViewAction } from 'store/views-slice';
import { useNavigate } from 'react-router-dom';
import { isValidHttpsUrl } from 'utils';

const classes = classNames.bind(styles);

interface CreateEditViewDialogProps {
    open: boolean;
    handleClosePopup: () => void;
    title?: string,
    name?: string,
    viewId?: number,
    viewMode?: boolean,
    sources?: Source[]
}

type DatasourceKeys = 'plan' | 'execution';

function makeExcelDownloadable(link: string) {
    const googleSheetsPattern = /https:\/\/docs\.google\.com\/spreadsheets\/d\/([a-zA-Z0-9-_]+)\/edit/;
    const match = link.match(googleSheetsPattern);

    if (match) {
        const spreadsheetId = match[1];

        const urlParams = new URLSearchParams(new URL(link).search);
        const gid = urlParams.get('gid');

        let downloadableLink = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/export?format=xlsx`;
        if (gid) {
            downloadableLink += `&gid=${gid}`;
        }
        return downloadableLink;
    } else {
        return link;
    }
}

const fields = [
    {
        id: 'plan',
        title: 'Plan',
        description: 'Link to your roadmap or plan document',
        icon: Coffee,
        placeholder: 'https://example.com',
        leftContent: <LanguageIcon />
    },
    {
        id: 'execution',
        title: 'Execution',
        description: 'Jira query (JQL)',
        icon: Disc,
        placeholder: 'project = \'PROJ\'',
        leftContent: <JiraSmallIcon />
    },
];

const CreateEditViewDialog: FC<CreateEditViewDialogProps> = ({
    open,
    handleClosePopup,
    title,
    name,
    viewId,
    viewMode,
    sources
}) => {
    const workspaceId = useWorkspaceId();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [createView, { isLoading: createViewLoading }] = useCreateViewMutation();
    const [editView, { isLoading: editViewLoading }] = useEditViewMutation();

    const [datasources, setDatasources] = useState<{ plan: Datasource, execution: Datasource }>({
        plan: {
            input: '',
            type: DatasourceType.plan,
            errorText: '',
        }, execution: {
            input: '',
            type: DatasourceType.execution
        }
    })

    const [entityName, setEntityName] = useState<string>(name || '')
    const createViewError = useSelector(createViewErrorSelector)

    useEffect(() => {
        if (sources) {
            const plan = sources.find(source => source.type === DatasourceType.plan)
            const execution = sources.find(source => source.type === DatasourceType.execution)

            setDatasources(prev => ({
                plan: {
                    ...prev.plan,
                    input: plan?.source || '',
                },
                execution: {
                    ...prev.execution,
                    input: execution?.source || '',
                }
            }))
        }
    }, [sources])


    const handleDataSourceChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDatasources(prev => ({
            ...prev,
            [name]: {
                ...prev[name as DatasourceKeys],
                input: value,
                errorText: name === 'plan' && !isValidHttpsUrl(value) ? 'Should be a valid url' : ''
            }
        }));
    };

    const onClosePopup = () => {
        handleClosePopup()

        setEntityName('');
        setDatasources({
            plan: {
                input: '',
                type: DatasourceType.plan
            }, execution: {
                input: '',
                type: DatasourceType.execution
            }
        })
        dispatch(setCreateViewError(''))
    }

    const handleCreateEditView = async () => {
        if (viewId) {
            try {
                await editView({
                    workspaceId,
                    viewId,
                    data: {
                        name: entityName
                    }
                }).unwrap();


                if (viewMode) {
                    dispatch(setViewName(entityName))
                } {
                    dispatch(updateViewAction({ viewId, name: entityName }))
                }

                onClosePopup()
            } catch (error) {
                console.error('Failed to update view', error);
            }
        } else {
            try {
                const res = await createView({
                    workspaceId,
                    data: {
                        name: entityName,
                        datasourceList: [
                            { integrationId: 1, input: datasources.execution.input, type: 'execution' },
                            { input: makeExcelDownloadable(datasources.plan.input), type: 'plan' },
                        ]
                    }
                }).unwrap();

                onClosePopup()
                navigate(`/views/view/${res.id}`)
            } catch (error) {
                console.error('Failed to create View', error);
            }
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClosePopup}
            title={title || 'Create your View'}
            confirmButton
            confirmButtonLabel={viewId ? 'Save' : 'Continue'}
            onConfirm={handleCreateEditView}
            confirmButtonFullWidth
            description='The project you want AI to analyze'
            confirmButtonLoading={createViewLoading || editViewLoading}
            disabled={viewId ? !entityName : (!entityName || !datasources.plan.input || !datasources.execution.input || !!datasources.plan.errorText)}
        >
            <Grid container className={classes('createViewContainer')}>
                <Grid item xs={12}>
                    <CustomTyphography className={classes('createViewTitle')}>Entity Name</CustomTyphography>
                    <Flexbox className={classes('createViewNameBox')}>
                        <TextField
                            placeholder='Entity Name'
                            name='entityName'
                            value={entityName}
                            className={classes('createViewLinkInput')}
                            onChange={(e) => setEntityName(e.target.value)}
                            disabled={createViewLoading || editViewLoading}
                        />
                    </Flexbox>
                </Grid>
                {
                    fields.map(field => (
                        <Grid item xs={12} key={field.id} className={classes('createViewField')}>
                            <Flexbox className={classes('gap-1')} justifyBetween>
                                <Flexbox align className={classes('gap-1')}>
                                    <IconButton disabled className={classes('createViewFieldIconButton')}>
                                        <field.icon />
                                    </IconButton>
                                    <Box className={classes('createViewFieldcontent')}>
                                        <CustomTyphography className={classes('contentTitle')}>{field.title}</CustomTyphography>
                                        <CustomTyphography className={classes('contentDescription')}>{field.description}</CustomTyphography>
                                    </Box>
                                </Flexbox>

                                {/* <Box>
                                    <IconButton>
                                        <ArrowDropDownIcon />
                                    </IconButton>
                                </Box> */}
                            </Flexbox>
                            <Flexbox vertical fullWidth>
                                <Flexbox className={classes('createViewInputBox', 'w-full', { 'disabled': createViewLoading || !!viewId })}>
                                    <Flexbox align justify className={classes('createViewInputBox__left')}>
                                        {field.leftContent}
                                    </Flexbox>
                                    <Flexbox align justifyBetween fullWidth>
                                        <TextField
                                            name={field.id}
                                            value={datasources[field.id as DatasourceKeys].input}
                                            placeholder={field.placeholder}
                                            className={classes('createViewLinkInput')}
                                            onChange={handleDataSourceChange}
                                            disabled={createViewLoading || !!viewId}
                                        />
                                        {/* <Button variant='contained'>Connect</Button> */}
                                    </Flexbox>
                                </Flexbox>
                                {
                                    datasources[field.id as DatasourceKeys].errorText && <Flexbox className="input-error">
                                        {datasources[field.id as DatasourceKeys].errorText}
                                    </Flexbox>
                                }
                            </Flexbox>
                        </Grid>
                    ))
                }
            </Grid>
            <CustomSnackbar open={!!createViewError} type='error' title={createViewError} />
        </Dialog>
    );
};

export default CreateEditViewDialog;
