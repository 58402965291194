import { Typography, type TypographyProps as MuiTypographyProps } from '@mui/material'
import { FC } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames/bind';

const classes = classNames.bind(styles);

interface CustomTyphographyProps extends MuiTypographyProps {
    type?: 'secondary' | 'primary'
}

const CustomTyphography: FC<CustomTyphographyProps> = ({ className, variant, children, type }) => {
    return (
        <Typography
            className={classes('customTypography', className, type)}
            variant={variant}
        >
            {children}
        </Typography>
    )
}

export default CustomTyphography