import TextArea, { TextAreaProps } from 'components/TextArea'
import React, { KeyboardEvent } from 'react'

const disableNewLine = (e: KeyboardEvent) => {
    if(e.code === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
    }

}

export default ({ onKeyDown, ...props }: TextAreaProps) => {
    return (
        <TextArea onKeyDown={disableNewLine} asInput fullWidth {...props}/>
    )
}