import { Flexbox, Link } from 'components';
import { Permissions, getUserPermissionName } from 'utils/permissions';
import styles from '../index.module.scss';
import classNames from 'classnames/bind';
const classes = classNames.bind(styles);

const EmptyMapping = () => {

    return(
        <Flexbox>
            {getUserPermissionName() !== Permissions.ROLE_ADMIN ?
                <Flexbox>
                    Kindly alert the administrator to initiate the mapping process.
                </Flexbox>
                :
                <Flexbox>
                    Please navigate to the provided link to
                    <Link to={'/integrations/jiramapping'} className={classes('jiraMappingLink')}>
                        Map Prodmap with Jira Projects
                    </Link>
                </Flexbox>
            }
        </Flexbox>
    )
}

export default EmptyMapping