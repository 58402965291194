import { createApi } from '@reduxjs/toolkit/query/react';
import {
    Comment,
    CommentExtended,
    CommentObjectOptions,
    Initiative,
    UserStatuses,
} from '../utils/types';
import { rtkBaseQueryWithReauth } from 'utils/rtk';
import { CommentFieldKey, User } from 'utils/types';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { CommentFilterOption } from 'components/Comments/CommentFilter';
import { searchFilter } from 'utils/search';
import { MentionData } from '@draft-js-plugins/mention';
import { useEffect, useMemo, useRef, useState } from 'react';
import store, { RootState } from 'store';
import {
    createCustomEvent,
    getEditorStateFromMessage,
    getMentionsFromContentState,
    getMessageFromContentState,
    isEditorField,
} from 'utils/comments';
import { EditorState } from 'draft-js';
import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { useWorkspaceId } from 'utils/hooks';

interface CreateCommentOptions extends CommentObjectOptions {
    fieldKey: CommentFieldKey;
}

interface CreateCommentPayload extends CreateCommentOptions {
    message: string;
    parentId?: Comment['id'];
    mentions: User['id'][];
    seen: boolean;
    resolved: boolean;
}

interface UpdateCommentOptions extends CommentObjectOptions {
    fieldKey: CommentFieldKey;
    commentId: Comment['id'];
}

interface UpdateCommentPayload {
    message: string;
    parentId?: Comment['id'];
    mentions: User['id'][];
    seen: boolean;
    resolved: boolean;
}

interface DeleteCommentOptions extends CommentObjectOptions {
    fieldKey: CommentFieldKey;
    commentId: Comment['id'];
    parentId?: Comment['id'];
}

export interface GetCommentsOptions extends CommentObjectOptions { }

interface GetCommentOptions extends CommentObjectOptions {
    commentId: Comment['id'];
}

export const commentsApi = createApi({
    reducerPath: 'comments.api',
    tagTypes: ['comments'],
    baseQuery: rtkBaseQueryWithReauth,
    endpoints: builder => ({
        getComments: builder.query<CommentExtended[], GetCommentsOptions>({
            query: ({ workspaceId, objectKey, objectId }) => ({
                url: `workspaces/${workspaceId}/${objectKey}/${objectId}/comments`,
                params: {
                    includeStories: true,
                },
            }),
            providesTags: ['comments'],
        }),
        getComment: builder.query<Comment, GetCommentOptions>({
            query: ({ workspaceId, objectKey, objectId, commentId }) => ({
                url: `workspaces/${workspaceId}/${objectKey}/${objectId}/comments/${commentId}`,
            }),
        }),
        createComment: builder.mutation<Comment, CreateCommentPayload>({
            query: ({ workspaceId, objectKey, objectId, fieldKey, ...payload }) => ({
                url: `workspaces/${workspaceId}/${objectKey}/${objectId}/${fieldKey}/comments`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['comments'],
            async onQueryStarted(
                { workspaceId, objectId, objectKey, ...rest },
                { dispatch, queryFulfilled }
            ) {
                const {
                    user: author,
                    comments: { options },
                } = store.getState();
                if (!options) {
                    await queryFulfilled;
                    return;
                }
                const comment: CommentExtended = {
                    ...rest,
                    id: -1,
                    objectId,
                    objectKey,
                    author,
                    createdDate: Date.now(),
                    lastModifiedDate: Date.now(),
                    replies: [],
                    edited: false,
                };
                let patchResult: PatchCollection | null = null;
                if (rest.parentId) {
                    // New comment is a reply
                    patchResult = dispatch(
                        commentsApi.util.updateQueryData(
                            'getComments',
                            {
                                workspaceId: options.workspaceId,
                                objectId: options.objectId,
                                objectKey: options.objectKey,
                            },
                            oldData => {
                                const parentComment = oldData.find(c => c.id === rest.parentId);
                                if (!parentComment) {
                                    return;
                                }
                                if (parentComment.replies) {
                                    parentComment.replies.push(comment);
                                } else {
                                    parentComment.replies = [comment];
                                }
                            }
                        )
                    );
                } else {
                    // New comment is a new thread
                    patchResult = dispatch(
                        commentsApi.util.updateQueryData(
                            'getComments',
                            {
                                workspaceId: options.workspaceId,
                                objectId: options.objectId,
                                objectKey: options.objectKey,
                            },
                            oldData => {
                                oldData.unshift(comment);
                            }
                        )
                    );
                }
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        updateComment: builder.mutation<
            Comment,
            UpdateCommentOptions & Partial<UpdateCommentPayload>
        >({
            query: ({ workspaceId, objectKey, objectId, fieldKey, commentId, ...payload }) => ({
                url: `workspaces/${workspaceId}/${objectKey}/${objectId}/${fieldKey}/comments/${commentId}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['comments'],
            async onQueryStarted(arg, api) {
                const options = store.getState().comments.options;
                if (!options) {
                    await api.queryFulfilled;
                    return;
                }
                const { commentId, workspaceId, ...rest } = arg;
                let patchResult: PatchCollection | null = null;
                if (rest.parentId) {
                    // Updating a reply
                    patchResult = api.dispatch(
                        commentsApi.util.updateQueryData(
                            'getComments',
                            {
                                workspaceId: options.workspaceId,
                                objectId: options.objectId,
                                objectKey: options.objectKey,
                            },
                            oldData => {
                                const parentComment = oldData.find(c => c.id === rest.parentId);
                                if (parentComment?.replies) {
                                    const reply = parentComment.replies.find(
                                        c => c.id === commentId
                                    );
                                    if (reply) {
                                        Object.assign(reply, rest);
                                    }
                                }
                            }
                        )
                    );
                } else {
                    // Updating a thread
                    patchResult = api.dispatch(
                        commentsApi.util.updateQueryData(
                            'getComments',
                            {
                                workspaceId: options.workspaceId,
                                objectId: options.objectId,
                                objectKey: options.objectKey,
                            },
                            oldData => {
                                const comment = oldData.find(c => c.id === commentId);
                                if (comment) {
                                    Object.assign(comment, rest);
                                }
                            }
                        )
                    );
                }
                try {
                    await api.queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        deleteComment: builder.mutation<void, DeleteCommentOptions>({
            query: ({ workspaceId, objectKey, objectId, fieldKey, commentId }) => ({
                url: `workspaces/${workspaceId}/${objectKey}/${objectId}/${fieldKey}/comments/${commentId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['comments'],
            async onQueryStarted(arg, api) {
                const options = store.getState().comments.options;
                if (!options) {
                    await api.queryFulfilled;
                    return;
                }
                let patchResult: PatchCollection | null = null;
                if (arg.parentId) {
                    // Deleting a reply
                    patchResult = api.dispatch(
                        commentsApi.util.updateQueryData(
                            'getComments',
                            {
                                workspaceId: options.workspaceId,
                                objectId: options.objectId,
                                objectKey: options.objectKey,
                            },
                            oldData => {
                                const parentComment = oldData.find(c => c.id === arg.parentId);
                                if (parentComment?.replies) {
                                    const replyIndex = parentComment.replies.findIndex(
                                        c => c.id === arg.commentId
                                    );
                                    if (replyIndex !== -1) {
                                        parentComment.replies.splice(replyIndex, 1);
                                    }
                                }
                            }
                        )
                    );
                } else {
                    // Deleting a thread
                    patchResult = api.dispatch(
                        commentsApi.util.updateQueryData(
                            'getComments',
                            {
                                workspaceId: options.workspaceId,
                                objectId: options.objectId,
                                objectKey: options.objectKey,
                            },
                            oldData => {
                                const commentIndex = oldData.findIndex(c => c.id === arg.commentId);
                                if (commentIndex !== -1) {
                                    oldData.splice(commentIndex, 1);
                                }
                            }
                        )
                    );
                }
                try {
                    await api.queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        getWorkspaceUsers: builder.query<User[], { workspaceId: number }>({
            query: ({ workspaceId }) => ({
                url: `workspaces/${workspaceId}/users`,
            }),
        }),
    }),
});

export const {
    useGetCommentsQuery,
    useCreateCommentMutation,
    useDeleteCommentMutation,
    useUpdateCommentMutation,
    useGetWorkspaceUsersQuery,
} = commentsApi;

export const useFieldComments = (
    { objectKey, objectId }: CommentObjectOptions,
    fieldKey: CommentFieldKey,
    initiativeId: Initiative['id']
) => {
    const workspaceId = useWorkspaceId();
    return useSelector(
        createSelector(
            (state: RootState) => state.comments,
            commentsApi.endpoints.getComments.select({
                workspaceId,
                objectKey: 'initiatives',
                objectId: initiativeId,
            }),
            (state, commentsResult) => {
                const unresolvedFieldComments = commentsResult.data?.filter(
                    comment =>
                        !comment.resolved &&
                        !comment.parentId &&
                        comment.objectKey === objectKey &&
                        comment.objectId === objectId &&
                        comment.fieldKey === fieldKey
                );
                const isSelected = unresolvedFieldComments?.some(
                    c => c.id === state.selectedComment?.id
                );
                const isHighlighted = unresolvedFieldComments?.some(
                    c => c.id === state.highlightedComment?.id
                );
                return {
                    comments: unresolvedFieldComments ?? [],
                    isSelected,
                    isHighlighted,
                };
            }
        )
    );
};

export const useGetCommentsState = (options: CommentObjectOptions) => {
    return commentsApi.endpoints.getComments.useQueryState(options);
};

export const useGetCommentsFiltered = (
    options: CommentObjectOptions,
    currentUser: User,
    filter: CommentFilterOption,
    searchTerm: string,
    enabled = true
) => {
    useGetCommentsQuery(options, { skip: !enabled });
    return useSelector(
        createSelector(commentsApi.endpoints.getComments.select(options), result => {
            return {
                ...result,
                comments: result.data
                    ?.filter(comment => {
                        switch (filter) {
                            case 'resolved':
                                return comment.resolved;
                            case 'open':
                                return !comment.resolved;
                            case 'forYou':
                                return [comment, ...(comment.replies ?? [])].some(
                                    c => c.author.id === currentUser?.id
                                );
                            case 'all':
                            default:
                                return true;
                        }
                    })
                    .filter(comment => !comment.parentId)
                    .filter(searchFilter(searchTerm, c => c.message)),
            };
        })
    );
};

export const useGetWorkspaceMentions = () => {
    const workspaceId = useSelector((state: RootState) => state.user.workspace.id);
    const result = useGetWorkspaceUsersQuery(
        { workspaceId },
        {
            skip: !workspaceId,
        }
    );
    const mentions = useMemo(() => {
        const memo = result.data
            ?.filter(u => u.status === UserStatuses.ACTIVE)
            .map(user => {
                return {
                    id: user.id,
                    name: user.fullName,
                } satisfies MentionData;
            });
        return memo;
    }, [result.data]);
    return { mentions, ...result };
};

export const useCommentEdit = <TError = unknown>(
    comment: CommentExtended,
    onError?: (error: TError) => void
) => {
    const [editorState, setEditorState] = useState<EditorState>(() =>
        getEditorStateFromMessage(comment.message)
    );
    const [isEditing, setIsEditing] = useState(false);
    const workspaceId = useWorkspaceId();
    const [updateComment, { isLoading: isUpdatingComment }] = useUpdateCommentMutation();
    const initialContentState = useRef(editorState);

    useEffect(() => {
        if (isEditing) {
            setEditorState(getEditorStateFromMessage(comment.message));
        }
    }, [comment]);

    const handleEdit = () => {
        if (!isEditing) {
            setIsEditing(true);
            initialContentState.current = editorState;
        }
    };

    const handleCancel = () => {
        if (isEditing) {
            setIsEditing(false);
            setEditorState(initialContentState.current);
        }
    };

    const handleSave = async () => {
        if (!isEditing) {
            return;
        }
        try {
            setIsEditing(false);
            await updateComment({
                workspaceId,
                commentId: comment.id,
                parentId: comment.parentId,
                mentions: getMentionsFromContentState(editorState.getCurrentContent()).map(
                    m => m.id
                ),
                objectKey: comment.objectKey,
                objectId: comment.objectId,
                fieldKey: comment.fieldKey,
                seen: comment.seen,
                resolved: comment.resolved,
                message: getMessageFromContentState(editorState.getCurrentContent()),
            });
        } catch (error) {
            console.error('Failed to update comment', error);
            onError?.(error as TError);
        }
    };

    return {
        editorState,
        setEditorState,
        isEditing,
        isUpdatingComment,
        handleCancel,
        handleEdit,
        handleSave,
    };
};

export const useCommentDelete = (comment: CommentExtended) => {
    const workspaceId = useWorkspaceId();
    const [deleteComment, { isLoading: isDeletingComment }] = useDeleteCommentMutation();
    const handleDelete = async () => {
        await deleteComment({
            workspaceId,
            objectKey: comment.objectKey,
            objectId: comment.objectId,
            fieldKey: comment.fieldKey,
            commentId: comment.id,
        });
        if (isEditorField(comment.fieldKey)) {
            document.dispatchEvent(
                createCustomEvent('comment-delete', { detail: { commentId: comment.id } })
            );
        }
    };
    return {
        isDeletingComment,
        handleDelete,
    };
};

export const useCommentCurrentHilghLight = (comment: CommentExtended) => {
    const handleCurrentHilghLight = () => {
        if (isEditorField(comment.fieldKey)) {
            document.dispatchEvent(
                createCustomEvent('comment-current-highlight', { detail: { commentId: comment.id } })
            );
        }
    };
    const handleDeleteCurrentHilghLight = () => {
        if (isEditorField(comment.fieldKey)) {
            document.dispatchEvent(
                createCustomEvent('comment-current-highlight', { detail: { commentId: comment.id, remove: true } })
            );
        }
    };
    return {
        handleCurrentHilghLight,
        handleDeleteCurrentHilghLight
    };
};

export const useCommentEditorHilghLight = (comment: CommentExtended) => {
    const handleEditorHilghLight = () => {
        document.dispatchEvent(
            createCustomEvent('highlight-editor', { detail: { comment } })
        );
    };

    return {
        handleEditorHilghLight,
    };
};

export const useCommentResolve = (comment: CommentExtended) => {
    const workspaceId = useWorkspaceId();
    const [updateComment, { isLoading: isResolvingComment }] = useUpdateCommentMutation();
    const resolved = !comment.resolved;
    const handleResolve = async () => {
        updateComment({
            workspaceId,
            objectKey: comment.objectKey,
            objectId: comment.objectId,
            fieldKey: comment.fieldKey,
            commentId: comment.id,
            message: comment.message,
            parentId: comment.parentId,
            seen: comment.seen,
            resolved,
        });
        if (isEditorField(comment.fieldKey)) {
            document.dispatchEvent(
                createCustomEvent('comment-resolve', {
                    detail: { commentId: comment.id, resolved },
                })
            );
        }
    };
    return {
        isResolvingComment,
        handleResolve,
    };
};
