import { Button, ConfirmationDialog, CustomSnackbar, FilterButton, Flexbox, SearchField } from 'components';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ChangeEvent, FC, useState } from 'react';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import { ExcelCell, GridApi, ProcessRowGroupForExportParams } from 'ag-grid-enterprise';
import ActionsMenu, { ButtonItem } from 'components/ActionsMenu';
import { Actions, hasPermission } from 'utils/permissions';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRoadmapView } from 'store/viewsRoadmap-api';
import { deleteRoadmapViewAction, deleteRoadmapViewLoadingSelector } from 'store/viewsRoadmap-slice';
import { FilterOption } from 'components/FilterButton';
import { FilterKeys } from '.';
import { cell } from 'components/AgGridTable';
const classesInfo = classNames.bind(stylesInfo);
const classes = classNames.bind(styles);

interface HeaderProps {
    gridApi: GridApi<any> | null,
    searchValue: string,
    emptyState: boolean
    totalRows: number
    pendingRows: number
    roadmapViewId: number | null
    ownerOptions: FilterOption[]
    filterValues: { [key: string]: FilterOption[] }
    onSearchValueChange: (e: ChangeEvent<HTMLInputElement>) => void
    onSearchClear: () => void
    onFilterValueChange: (targetName: string, value: FilterOption[]) => void
    onFilterReset: (filterName: string) => void
    resetAllFilter: () => void
    programOptions: FilterOption[]
    speakerOptions: FilterOption[]
    projectLeadOptions: FilterOption[]
    teamOptions: FilterOption[]
}

let getRows = (params: ProcessRowGroupForExportParams) => {
    type DataItem = {
        analysis: {
            title?: string;
            feedback?: string;
            suggestions?: string;
            examples?: string;
            id: number
        },
        original_value?: string;
    };

    const data = (Object.values(params.node.data?.data) as DataItem[])?.filter((item: DataItem) => !!Object.keys(item.analysis).length)

    const rows = [
        {
            outlineLevel: 1,
            cells: [
                cell(''),
                cell(''),
                cell(''),
            ],
        },
    ].concat(
        ...data.map((record: any) => [
            {
                outlineLevel: 1,
                cells: [
                    cell(''),
                    cell(record.analysis.title),
                    cell(record.analysis.feedback),
                ],
            },
        ]),
    );

    return rows;
};

const Header: FC<HeaderProps> = ({
    gridApi,
    searchValue,
    emptyState,
    totalRows,
    pendingRows,
    roadmapViewId,
    ownerOptions,
    filterValues,
    onSearchValueChange,
    onSearchClear,
    onFilterValueChange,
    onFilterReset,
    resetAllFilter,
    programOptions,
    speakerOptions,
    projectLeadOptions,
    teamOptions,
}) => {
    const dispatch = useDispatch();

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('')

    const deleteRoadmapViewLoading = useSelector(deleteRoadmapViewLoadingSelector)

    const showDeleteConfirmation = () => {
        setOpenConfirmation(true);
    };

    const actions: ButtonItem[] = [
        { label: 'Delete', action: showDeleteConfirmation, type: 'red' },
    ];

    const onCancelDelete = () => {
        setOpenConfirmation(false);
    };

    const onDeleteConfirmation = async () => {
        if (roadmapViewId) {
            try {
                await dispatch(deleteRoadmapView(roadmapViewId))
                dispatch(deleteRoadmapViewAction(roadmapViewId))
                setOpenConfirmation(false)

                setSuccessMessage('Analysis deleted successfully.')
                setTimeout(() => setSuccessMessage(''), 1500)
            } catch (err: any) {
                if (err?.data?.errorMessage) {
                    setError(err.data.errorMessage);
                } else {
                    setError('Failed to delete analysis');
                }
            }
        }
    };

    return (
        <Flexbox vertical className={classesInfo('headerContainer')}>
            <Flexbox vertical className={classesInfo('mb-6')}>
                <Flexbox wrap justifyBetween>
                    <Flexbox className={classesInfo('headerTitle')}>Planning Analysis & Action Items</Flexbox>
                    <Flexbox wrap align className={'gap-2'}>
                        {gridApi && (<ExportButton
                            api={gridApi}
                            columnKeysToExport={['title', 'owner', 'program', 'speaker', 'projectLead', 'score', 'feedbackCount']}
                            getRows={getRows}
                        />)}
                        {hasPermission(Actions.delete) && (
                            <ActionsMenu
                                buttonItems={actions}
                            />
                        )}
                    </Flexbox>
                </Flexbox>
                <Flexbox align className={classes('statusBox')}>
                    <Flexbox className={classes('statusBox-info')}><span>{pendingRows}</span>/<span>{totalRows}</span></Flexbox>
                    <Flexbox className={classes('statusBox-description')}>( Pending Action / Total Items )</Flexbox>
                </Flexbox>
            </Flexbox>

            {!emptyState && (
                <Flexbox className={classesInfo('headerInfo')}>
                    <Flexbox wrap className={'row-gap-2'}>
                        <SearchField
                            value={searchValue}
                            onChange={onSearchValueChange}
                            onClear={onSearchClear}
                            placeholder='Search'
                            className={classesInfo('searchInput')}
                        />
                        <FilterButton
                            options={ownerOptions}
                            value={filterValues[FilterKeys.owner]}
                            onChange={(value) => onFilterValueChange(FilterKeys.owner, value)}
                            onFilterReset={() => onFilterReset(FilterKeys.owner)}
                            multiple
                            label={'Owners'}
                            className={classesInfo('filterButton')}
                        />
                        <FilterButton
                            options={programOptions}
                            value={filterValues[FilterKeys.program]}
                            onChange={(value) => onFilterValueChange(FilterKeys.program, value)}
                            onFilterReset={() => onFilterReset(FilterKeys.program)}
                            multiple
                            label={'Program'}
                            className={classesInfo('filterButton')}
                        />
                        <FilterButton
                            options={speakerOptions}
                            value={filterValues[FilterKeys.speaker]}
                            onChange={(value) => onFilterValueChange(FilterKeys.speaker, value)}
                            onFilterReset={() => onFilterReset(FilterKeys.speaker)}
                            multiple
                            label={'Planning Palooza Speaker'}
                            className={classesInfo('filterButton')}
                        />
                        <FilterButton
                            options={projectLeadOptions}
                            value={filterValues[FilterKeys.projectLead]}
                            onChange={(value) => onFilterValueChange(FilterKeys.projectLead, value)}
                            onFilterReset={() => onFilterReset(FilterKeys.projectLead)}
                            multiple
                            label={'TPX Product Lead'}
                            className={classesInfo('filterButton')}
                        />
                        <FilterButton
                            options={teamOptions}
                            value={filterValues[FilterKeys.team]}
                            onChange={(value) => onFilterValueChange(FilterKeys.team, value)}
                            onFilterReset={() => onFilterReset(FilterKeys.team)}
                            multiple
                            label={'Submitting Team'}
                            className={classesInfo('filterButton')}
                        />
                        {Object.values(filterValues).some(v => v.length !== 0) &&
                            <Flexbox className={classesInfo('resetButtonContainer')}>
                                <Button className={classesInfo('resetButton')} onClick={resetAllFilter}>
                                    Reset
                                </Button>
                            </Flexbox>
                        }
                    </Flexbox>
                </Flexbox>
            )}
            <ConfirmationDialog
                open={openConfirmation}
                onClose={onCancelDelete}
                onConfirm={onDeleteConfirmation}
                confirmButtonStyle="danger"
                title="Delete this analysis?"
                confirmButtonLoading={deleteRoadmapViewLoading}
            >
                <Flexbox>
                    Are you sure you want to delete this analysis?
                </Flexbox>
            </ConfirmationDialog>
            <CustomSnackbar open={!!successMessage} type='success' title={successMessage} />
            <CustomSnackbar open={!!error} type='error' title={error} />
        </Flexbox>
    )
}

export default Header