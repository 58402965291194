import AgGridTable, { AgColumn, ColumnTypes, GridStatePreferences, getLinkedColumnContextMenuItems } from 'components/AgGridTable';
import Flexbox from 'components/Flexbox'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteViewMutation, useEvaluateViewRiskMutation, useLazyGetViewsQuery } from 'store/views-api';
import { deleteViewAction, evaluateViewRiskErrorSelector, setEvaluateViewRiskError, setViewsData, updateViewRiskAction, viewsSelector } from 'store/views-slice';
import { useWorkspaceId } from 'utils/hooks';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { GridApi } from 'ag-grid-enterprise';
import { Actions, hasPermission } from 'utils/permissions';
import { ConfirmationDialog, CustomSnackbar, Loader, RiskStatusBox } from 'components';
import EmptyState from 'common/emptyState';
import { DataScience } from 'components/icons';
import { GetRowIdFunc, GetRowIdParams, GridReadyEvent, ICellRendererParams, KeyCreatorParams, SortChangedEvent } from 'ag-grid-community';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CreateEditViewDialog from 'components/CreateEditViewDialog';
import { Preferences, PreferencesKeys, Source, View } from 'utils/types';
import ViewsHeader from './components/ViewsHeader';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import SidePanelDrawer from 'components/SidePanelDrawer';
import AlertsSidePanel from 'components/AlertsSidePanel';

const classes = classNames.bind(styles);

export enum FilterKeys {
    query = 'query',
    order = 'order',
    orderBy = 'orderBy',
    executionViewGridLayout = 'executionViewGridLayout'
}

interface IPreferencesData {
    query?: string,
    order?: 'asc' | 'desc',
    orderBy?: keyof View,
    executionViewGridLayout?: any
}

interface IPreferencesParams extends Omit<IPreferencesData, 'executionViewGridLayout'> { }

let timeoutId: NodeJS.Timeout;

const Views = () => {
    const workspaceId = useWorkspaceId();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const preferencesRef = useRef<IPreferencesData>({});

    const [getViews, { data: viewsData, isLoading: getViewsLoading }] = useLazyGetViewsQuery();
    const [deleteView, { isLoading: isDeleteLoading }] = useDeleteViewMutation()
    const [evaluateViewRisk] = useEvaluateViewRiskMutation();

    const views = useSelector(viewsSelector);
    const evaluateViewRiskError = useSelector(evaluateViewRiskErrorSelector);

    const [searchParams, setSearchParams] = useSearchParams();
    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null)
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [viewId, setViewId] = useState<number | undefined>()
    const [createViewDialog, setCreateViewDialog] = useState({ open: false });
    const [debouncedQuery, setDebouncedQuery] = useState<null | string>(null);
    const [searchValue, setSearchValue] = useState('');
    const [currentView, setCurrentView] = useState<{ id?: number, name: string, sources?: Source[] }>({ name: '' })
    const [deleteViewSuccessMessage, setDeleteViewSuccessMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [evaluatingViews, setEvaluatingViews] = useState<number[]>([]);
    const [evaluateSuccessMessage, setEvaluateSuccessMessage] = useState('');

    const columns: AgColumn[] = useMemo(() => [
        {
            colType: ColumnTypes.Linked,
            headerName: 'Name',
            field: 'name',
            minWidth: 200,
            sortable: true,
            link: '/views/view',
            linkParam: 'id',
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'Risk Level',
            field: 'risk',
            minWidth: 130,
            sortable: true,
            cellRenderer: (params: ICellRendererParams) => {
                if (params.node.group) {
                    if (params.node.field !== 'risk') {
                        return null
                    } else if (params.node.groupData) {
                        const value = params.node.groupData['ag-Grid-AutoColumn']
                        return value ? <Flexbox align className={classes('h-full')}>
                            <RiskStatusBox category={value?.category} withoutAlerts />
                        </Flexbox> : null
                    }
                } else {
                    return (
                        <SidePanelDrawer
                            actionElement={
                                (props: any) => (
                                    <Flexbox align className={classes('h-full')} {...props}>
                                        <RiskStatusBox
                                            category={params.value?.category}
                                            alertsCount={params.value?.reasons?.length}
                                            loading={evaluatingViews.includes(params.data.id)}
                                        />
                                    </Flexbox>
                                )
                            }
                            disabled={evaluatingViews.includes(params.data.id)}
                        >
                            {
                                !!params.value?.reasons?.length && <AlertsSidePanel
                                    title={params.data?.name}
                                    reasons={params.value?.reasons}
                                    format={'markdown'}
                                    lastEvaluationDate={params.value?.lastEvaluationDate}
                                />
                            }
                        </SidePanelDrawer>
                    );
                }
            },
            comparator: (risk1, risk2) => {
                const risk1Value = risk1?.value;
                const risk2Value = risk2?.value;

                const isNullable = (value: any) => value === null || value === undefined;

                if (isNullable(risk1Value) && isNullable(risk2Value)) {
                    return 0;
                }
                if (isNullable(risk1Value)) {
                    return -1;
                }
                if (isNullable(risk2Value)) {
                    return 1;
                }

                if (risk1Value === 0 && risk2Value === 0) {
                    return 0;
                }
                if (risk1Value === 0) {
                    return -1;
                }
                if (risk2Value === 0) {
                    return 1;
                }

                return risk1Value - risk2Value;
            },
            keyCreator: (params: KeyCreatorParams) => {
                return params.value ? params.value?.category : 'N/A'
            }
        },
        {
            colType: ColumnTypes.Date,
            headerName: 'Created Date',
            field: 'createdDate',
            minWidth: 150,
            sortable: true,
        },
        {
            colType: ColumnTypes.TimeAgo,
            headerName: 'Last Updated',
            field: 'lastModifiedDate',
            minWidth: 150,
            sortable: true,
        },
        {
            colType: ColumnTypes.Action,
            field: 'actions',
            headerName: '',
            cellRendererParams: {
                isAddActive: false,
            },
            actions: params => {
                return [
                    {
                        label: 'Open',
                        action: () => {
                            navigate(`/views/view/${params.data?.id}`)
                        }
                    },
                    ...(hasPermission(Actions.edit, params.data) ? [
                        {
                            label: 'Rename', action: () => {
                                if (params.data) {
                                    handleEditView({ name: params.data.name, id: params.data.id, sources: params.data.sources })
                                }
                            }
                        }
                    ] : []),
                    ...(hasPermission(Actions.edit, params.data) ? [
                        {
                            label: 'Evaluate Risk',
                            action: () => {
                                if (params.data) {
                                    handleEvaluateViewRisk(params.data.id)
                                }
                            },
                            disabled: evaluatingViews.includes(params.data.id)
                        }
                    ] : []),
                    ...(hasPermission(Actions.delete, params.data) ? [
                        {
                            action: () => { showDeleteConfirmation(params.data?.id) },
                            label: 'Delete',
                            type: 'red'
                        }
                    ] : []),
                ]
            },
        },
    ], [evaluatingViews, gridApi]);

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.executionViewGridLayout))) as unknown as Preferences<FilterKeys>[];

        if (preferences && preferences.length) {
            const { executionViewGridLayout, order, orderBy, query } = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;

            preferencesRef.current = {
                query,
                order,
                orderBy,
                executionViewGridLayout
            }

            if (searchParams.toString().length === 0) {
                let queryFilters: IPreferencesParams = {}

                queryFilters = {
                    ...queryFilters,
                    ...(order && orderBy ? { order, orderBy } : {}),
                    ...(query ? { query } : {})
                };

                setSearchParams(queryFilters, { replace: true })
            }
        }
    }

    const handleEvaluateViewRisk = async (id: number) => {
        try {
            setEvaluatingViews(prev => ([...prev, id]))
            const res = await evaluateViewRisk({
                workspaceId,
                viewId: id,
            }).unwrap();

            const currentRow = views.find(view => view.id === id)

            const transaction = {
                update: [{
                    ...currentRow,
                    risk: res
                }],
            };

            if (gridApi) {
                gridApi.applyTransactionAsync(transaction);
                dispatch(updateViewRiskAction({ viewId: id, risk: res }))
            }

            setEvaluateSuccessMessage('Risk Evaluated Successfully')
            setTimeout(() => setEvaluateSuccessMessage(''), 3000)
        } catch (error) {
            console.error('Failed to evaluate', error);
            setTimeout(() => dispatch(setEvaluateViewRiskError('')), 3000)
        } finally {
            setEvaluatingViews(prev => prev.filter(el => el !== id))
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                loadPreferences()
            ])
            setIsLoading(false)
        }
        setIsLoading(true);
        fetchData();

        return () => {
            dispatch(setEvaluateViewRiskError(''))
            setEvaluateSuccessMessage('')
            setEvaluatingViews([])
        }
    }, [])

    useEffect(() => {
        if (!isLoading) {
            const queryString = searchParams.get(FilterKeys.query);
            if (queryString) {
                setSearchValue(queryString);
            }
        }
    }, [isLoading])

    const showDeleteConfirmation = (viewId?: number) => {
        setOpenDeleteConfirmation(true)
        setViewId(viewId)
    }

    const handleEditView = ({ name, id, sources }: { name: string, id?: number, sources: Source[] }) => {
        setCreateViewDialog({ open: true })
        setCurrentView({ name, id, sources })
    }

    const handleDeleteView = async () => {
        try {
            const res = await deleteView({ workspaceId, viewId }).unwrap()
            dispatch(deleteViewAction(viewId))
            setOpenDeleteConfirmation(false)
            setViewId(undefined)
            setDeleteViewSuccessMessage('View deleted successfully!');
            setTimeout(() => setDeleteViewSuccessMessage(''), 3000);
        } catch (err) {
            console.log(err);
        }
    }

    const onCancelDelete = () => {
        setOpenDeleteConfirmation(false)
    }

    useEffect(() => {
        if (workspaceId) {
            getViews({ workspaceId })
        }
    }, [workspaceId])

    const onSortChanged = useCallback((e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        if (preferencesRef.current.order !== value?.sort || preferencesRef.current.orderBy !== value?.colId) {
            preferencesRef.current = { executionViewGridLayout: preferencesRef.current.executionViewGridLayout, ...modifiedSearchParamsObject }
            dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.executionViewGridLayout));
        }
    }, [preferencesRef, searchParams])

    const onGridStateChanged = useCallback((data: GridStatePreferences) => {
        preferencesRef.current.executionViewGridLayout = data

        dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.executionViewGridLayout));
    }, [preferencesRef]);

    useEffect(() => {
        if (debouncedQuery !== null) {
            dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.executionViewGridLayout));
        }

        return () => clearTimeout(timeoutId);
    }, [debouncedQuery])

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    const updateQueryPreference = useCallback((value: string) => {
        setSearchValue(value);
        preferencesRef.current.query = value;

        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setDebouncedQuery(value);
        }, 500);

    }, [preferencesRef])

    const handleCreateView = () => {
        setCreateViewDialog({ open: true });
    };

    const handleCloseDialog = () => {
        setCreateViewDialog({ open: false });
        setCurrentView({ name: '' })
    };

    const buttonItemsEmptyState = useMemo(() => {
        const buttons = []

        if (hasPermission(Actions.create)) {
            buttons.push({
                onClick: handleCreateView,
                text: 'Create New View',
            })
        }

        return buttons

    }, [])

    useEffect(() => {
        if (viewsData) {
            const filteredData = viewsData.filter((view: View) => view.name?.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase()))
            dispatch(setViewsData(filteredData))
        }
    }, [searchValue, viewsData])

    const getRowId = useMemo<GetRowIdFunc>(() => {
        return (params: GetRowIdParams) => params.data.id;
    }, []);

    return (isLoading ? <Flexbox fullWidth align justify>< Loader /></Flexbox > :
        <Flexbox fullWidth vertical>
            <ViewsHeader
                gridApi={gridApi}
                updateQueryPreference={updateQueryPreference}
                searchValue={searchValue}
                handleCreateView={handleCreateView}
                emptyViews={Array.isArray(viewsData) && viewsData.length === 0}
            />
            <Flexbox fullHeight>
                {(getViewsLoading || !viewsData) ? <Flexbox fullWidth fullHeight align justify><Loader disableShrink /></Flexbox>
                    :
                    viewsData.length > 0 ?
                        <AgGridTable
                            data={views}
                            columns={columns}
                            getContextMenuItems={params => {
                                if (params.defaultItems) {
                                    const rowData = params?.node?.data;
                                    const url = `/views/view/${rowData.id}`;

                                    const linkItems = getLinkedColumnContextMenuItems(params, url, 'name')
                                    const menuItems = [
                                        ...params.defaultItems,
                                        ...linkItems,
                                    ]

                                    return menuItems
                                } else {
                                    return []
                                }
                            }}
                            order={preferencesRef.current.order}
                            orderBy={preferencesRef.current.orderBy}
                            gridStatePreferences={preferencesRef.current.executionViewGridLayout}
                            onSortChanged={onSortChanged}
                            onGridReady={onGridReady}
                            exportFileName='Execution Views'
                            onGridStateChanged={onGridStateChanged}
                            readOnlyEdit={true}
                            getRowId={getRowId}
                        />
                        :
                        <EmptyState
                            icon={<DataScience />}
                            title='Create Views'
                            titleSmall={'Create AI-generated analysis boards for your projects to detect delays, overlaps, and bottlenecks'}
                            buttonItems={buttonItemsEmptyState}
                        />
                }
            </Flexbox>
            <ConfirmationDialog
                open={openDeleteConfirmation}
                onClose={onCancelDelete}
                onConfirm={() => handleDeleteView()}
                confirmButtonStyle='danger'
                title='Delete this view?'
                confirmButtonLoading={isDeleteLoading}
            >
                <Flexbox>
                    Are you sure you want to delete this view?
                </Flexbox>
            </ConfirmationDialog>
            {createViewDialog.open && (
                <CreateEditViewDialog
                    open={createViewDialog.open}
                    handleClosePopup={handleCloseDialog}
                    name={currentView.name}
                    viewId={currentView.id}
                    title={currentView.id ? 'Edit View' : 'Create your View'}
                    sources={currentView.sources}
                />
            )}
            <CustomSnackbar open={!!deleteViewSuccessMessage} type='success' title={deleteViewSuccessMessage} />
            <CustomSnackbar open={!!evaluateViewRiskError} type='error' title={evaluateViewRiskError} />
            <CustomSnackbar open={!!evaluateSuccessMessage} type='success' title={evaluateSuccessMessage} />
        </Flexbox>
    )
}

export default Views