import React from 'react';
const Logo = React.forwardRef<HTMLDivElement, React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>((props, ref) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <circle cx="12" cy="12" r="12" fill="#025B62"></circle>
            <mask
                id="mask0_16170_86277"
                style={{ maskType: 'alpha' }}
                width="24"
                height="24"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <circle cx="12" cy="12" r="12" fill="#00CC3F"></circle>
            </mask>
            <g mask="url(#mask0_16170_86277)">
                <path
                    fill="#fff"
                    d="M6 7.937a.882.882 0 111.765 0V23.29H6V7.937z"
                ></path>
            </g>
            <path
                fill="#fff"
                d="M10.94 5.301a1.059 1.059 0 012.118 0v13.412a1.059 1.059 0 01-2.118 0V5.3zM16.236 8.296a.882.882 0 011.765 0v6.706a.882.882 0 11-1.765 0V8.296z"
            ></path>
        </svg>
    )
})

export default Logo;