import { MentionData } from '@draft-js-plugins/mention';
import classNames from 'classnames/bind';
import { Button, Stack } from 'components';
import MentionEditor from 'components/MentionEditor/MentionEditor';
import { MentionEditorWithTools } from 'components/MentionEditor/MentionEditorWithTools';
import { FC, ReactNode } from 'react';
import { useCommentDelete, useCommentEdit, useGetWorkspaceMentions } from 'store/comments-api';
import { CommentExtended } from '../../../utils/types';
import { CommentHeader } from '../CommentHeader/CommentHeader';
import styles from './commentItem.module.scss';
const classes = classNames.bind(styles);

const emptyMentions: MentionData[] = [];

export interface CommentItemControlledProps
    extends Omit<ReturnType<typeof useCommentEdit>, 'handleEdit'>,
        Omit<ReturnType<typeof useCommentDelete>, 'handleDelete'> {
    avatar?: boolean;
    comment: CommentExtended;
    actions?: ReactNode;
}

export const CommentItemControlled: FC<CommentItemControlledProps> = ({
    avatar,
    actions,
    comment,
    isEditing,
    isDeletingComment,
    isUpdatingComment,
    editorState,
    setEditorState,
    handleCancel,
    handleSave,
}) => {
    const { mentions = [] } = useGetWorkspaceMentions();

    const isDisabled = isDeletingComment || isUpdatingComment;

    return (
        <div className={classes('comment-item')}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={1.5}
            >
                <CommentHeader
                    avatar={avatar}
                    className={classes('header')}
                    dateCreated={comment.createdDate}
                >
                    {comment.author.fullName}
                </CommentHeader>
                {actions ?? <div />}
            </Stack>
            {isEditing ? (
                <MentionEditorWithTools
                    mentions={mentions}
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    actions={
                        <Stack direction="row" gap={1}>
                            <Button
                                fullWidth
                                disableElevation
                                size="small"
                                disabled={isDisabled}
                                variant="contained"
                                color="inherit"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                fullWidth
                                disableElevation
                                size="small"
                                disabled={isDisabled}
                                variant="contained"
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </Stack>
                    }
                />
            ) : (
                <MentionEditor
                    readonly
                    mentions={emptyMentions}
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                />
            )}
        </div>
    );
};
