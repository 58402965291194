import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Team, TeamGroup } from 'utils/types'
import store, { RootState } from 'store';
import { SaveStatus } from 'common/savingStatus';
import { teamsApi } from './teams-api';

interface TeamsState {
    teams: Team[],
    savingStatus: SaveStatus,
    teamGroups: TeamGroup[]
}

const initialState: TeamsState = {
    teams: [],
    savingStatus: SaveStatus.UNSET,
    teamGroups: []
}

const teamsSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        setTeamsData: (state, action: PayloadAction<Team[]>) => {
            state.teams = action.payload
        },
        updateTeamSavingStatus: (state, action: PayloadAction<SaveStatus>) => {
            state.savingStatus = action.payload
        },
        deleteTeamAction: (state, action: PayloadAction<number>) => {
            state.teams = state.teams.filter(t => t.id !== action.payload)
        },
        updateTeamAction: (state, action: PayloadAction<Team>) => {
            state.teams = state.teams.map(team => {
                if (team.id === action.payload.id) {
                    return action.payload
                } else {
                    return team
                }
            })
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            teamsApi.endpoints.getTeams.matchFulfilled,
            (state, { payload }) => {
                state.teams = payload
            },
        ),
            builder.addMatcher(
                teamsApi.endpoints.editTeam.matchFulfilled,
                (state, { payload }) => {
                    state.savingStatus = SaveStatus.SAVED

                    setTimeout(() => {
                        store.dispatch(updateTeamSavingStatus(SaveStatus.UNSET));
                    }, 2000);
                },
            ),
            builder.addMatcher(
                teamsApi.endpoints.createNewTeam.matchFulfilled,
                (state, { payload }) => {
                    state.teams = [payload, ...state.teams]
                },
            ),
            builder.addMatcher(
                teamsApi.endpoints.getTeamGroups.matchFulfilled,
                (state, { payload }) => {
                    state.teamGroups = payload
                },
            )
    },
})

export const teamsSelector = (store: RootState) => store.teams.teams;
export const teamsSavingStatusSelector = (store: RootState) => store.teams.savingStatus;
export const teamGroupsSelector = (store: RootState) => store.teams.teamGroups;

export const {
    updateTeamSavingStatus,
    setTeamsData,
    deleteTeamAction,
    updateTeamAction
} = teamsSlice.actions;

export default teamsSlice;
