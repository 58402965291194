import { Flexbox, Select } from 'components'
import { FC, SyntheticEvent, useMemo } from 'react'
import classNames from 'classnames/bind';
import styles from '../report.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { currentReportCreatedBySelector, currentReportIdSelector, reportsSelector, setCurrentReport } from 'store/report-slice';
import { Report } from 'utils/types';
const classes = classNames.bind(styles)

const getDateOptinLabel = (option: Report) => new Date(option.createdDate).toLocaleString()

const ReportFilterPanel: FC = () => {
    const dispatch = useDispatch();

    const reports = useSelector(reportsSelector);
    const createdBy = useSelector(currentReportCreatedBySelector);
    const currentReportId = useSelector(currentReportIdSelector);

    const handleCurrentReportChange = (e: SyntheticEvent<Element, Event>, value: Report) => {
        dispatch(setCurrentReport(value));
    }

    const selectedReport = useMemo(() => reports.find(r => r.id === currentReportId), [reports, currentReportId])

    return (
        <Flexbox vertical className={classes('reportContainer')}>
            <Flexbox className={classes('reportControl')}>
                <Select
                    value={createdBy?.fullName}
                    options={[]}
                    label='Created by'
                    disabled
                />
                <Select
                    value={selectedReport}
                    options={reports}
                    onChange={handleCurrentReportChange}
                    getOptionLabel={getDateOptinLabel}
                    disableClearable
                    label='Generated date'
                />
            </Flexbox>
        </Flexbox>
    )
}

export default ReportFilterPanel;