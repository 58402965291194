import { getJiraProjects } from 'common/jira/index.api';
import { ActionsMenu, Button, Flexbox, Loader, Select, Table } from 'components';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { JiraProject, JiraMapping, JiraIssueType, JiraFieldMapping, JiraMappingObjectTypes } from 'utils/types';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import styles from './jiraMapping.module.scss';

import classNames from 'classnames/bind';
import { TableHeader, TableRowProps } from 'components/Table';
import { PlusIcon } from 'components/icons';
import ObjectToIssueType from './components/ObjectToIssueType';
import FieldMappings from './components/FieldMappings';
import { getAvailableIntegrations, getWorkspaceIntegrations, updateJiraMappings } from '../integrations.api';
import { availableIntegrationsSelector, userIntegrationsSelector, workspaceIntegrationSelector } from 'store/integrations';
import { setToLocalStorage } from 'utils/localStorage';
import { JIRA_AUTH_BASE_URL, REDIRECT_URL_KEY } from 'utils/constants';
import { createQueryParamsFromObject } from 'utils/request';
const classesInfo = classNames.bind(stylesInfo);
const classes = classNames.bind(styles);

interface JiraProjectTableHeaders extends JiraProject  {
    actions: string;
}

const jiraProjectsHeader: TableHeader<JiraProjectTableHeaders>[] = [
    {
        id: 'name',
        text: 'Jira project',
        sortable: false,
    },

    {
        id: 'actions',
        text: '',
        width: '30px'
    }
];



export default () => {

    const [allProjects, setAllProjects] = useState<JiraProject[]>([]);
    const [isLoadingProjects, setIsLoadingProjects] = useState(true);
    const [selectedProjects, setSelectedProjects] = useState<(JiraProject | null)[]>([]);
    const [currentMappingProject, setCurrentMappingProject] = useState<JiraProject | null>(null);
    const [currentMappingFields, setCurrentMappingFields] = useState<{object: JiraMappingObjectTypes, issueType: JiraIssueType} | null>(null)
    const [mappings, setMappings] = useState<JiraMapping[]>([]);

    const workspaceIntegrations = useSelector(workspaceIntegrationSelector);
    const userIntegrations = useSelector(userIntegrationsSelector);
    const availableIntegrations = useSelector(availableIntegrationsSelector);



    const dispatch = useDispatch();

    useEffect(() => {
        loadData();
    },[]);
    const loadData = async () => {
        setIsLoadingProjects(true);
        dispatch(getWorkspaceIntegrations())
        dispatch(getAvailableIntegrations());
        const jiraProjects = await dispatch(getJiraProjects()) as unknown as JiraProject[];
        setAllProjects(jiraProjects);
        setIsLoadingProjects(false);
    }

    useEffect(() => {
        const jiraIntegration = workspaceIntegrations.find(integration => integration.name === 'JIRA');
        setMappings(jiraIntegration?.mappings || [])
    }, [workspaceIntegrations])

    useEffect(() => {
        const jiraIntegration = workspaceIntegrations.find(integration => integration.name === 'JIRA');
        if(jiraIntegration && jiraIntegration.mappings && allProjects) {
            setSelectedProjects(() => {
                return allProjects.filter(project => mappings.some(map => map.projectId === project.id));
            })
        }
    }, [workspaceIntegrations, allProjects])

    useEffect(() => {
        if(!isLoadingProjects) {
            dispatch(updateJiraMappings(mappings));
        }
    }, [mappings])

    const onProjectSelect = (project: JiraProject, index: number) => {
        setSelectedProjects(currentValue => {currentValue[index] = project; return [...currentValue]});
        setMappings(oldValue => [...oldValue, { projectId: project.id }])
    }

    const deleteSelectedProject = (index: number) => {
        setSelectedProjects(currentValue => {
            const newValue = [...currentValue];
            const project = newValue.splice(index, 1)[0];
            if(project) {
                setMappings(oldValue => oldValue.filter(mapping => mapping.projectId !== project.id))
            }
            return newValue
        })
    }

    const onMappingObjectSelect = (object: JiraMappingObjectTypes, jiraField: number | undefined | null) => {
        if(currentMappingProject) {
            setMappings(oldValue => {
                const newValue = [...oldValue];
                const index = newValue.findIndex(mapping => mapping.projectId === currentMappingProject.id);
                if(index >= 0) {
                    const key = object === JiraMappingObjectTypes.Initiative ? 'initiativeMapping' : 'requirementMapping'
                    if(jiraField) {

                        newValue[index][key] = {
                            fieldId: jiraField,
                            fields: []
                        };
                    } else {
                        delete newValue[index][key]
                    }
                }
                return newValue;
            })
        }
    }

    const onUnmapObject = (object: JiraMappingObjectTypes) => {
        if(currentMappingProject) {
            setMappings(oldValue => {
                const newValue = [...oldValue];
                const index = newValue.findIndex(mapping => mapping.projectId === currentMappingProject.id);
                if(index >= 0) {
                    const key = object === JiraMappingObjectTypes.Initiative ? 'initiativeMapping' : 'requirementMapping'
                    newValue[index][key] = undefined
                }
                return newValue;
            })
        }
    }

    const onMappingField = (fieldMapping: JiraFieldMapping) => {

        if(currentMappingProject && currentMappingFields && currentMappingFields.object) {
            setMappings(oldValue => {
                const newValue = JSON.parse(JSON.stringify(oldValue)) as JiraMapping[];
                const mapping = newValue.find(mapping => mapping.projectId === currentMappingProject.id);
                if(mapping) {
                    const map = mapping[currentMappingFields.object === JiraMappingObjectTypes.Initiative ? 'initiativeMapping' : 'requirementMapping'];
                    if(map) {
                        map.fields = map.fields.filter(field => field.prodmapField !== fieldMapping.prodmapField);
                        map.fields.push(fieldMapping);

                    }
                }
                return newValue;
            })

        }
    }

    const onUnmapingField = (prodmapField: string) => {
        if(currentMappingProject && currentMappingFields && currentMappingFields.object) {
            setMappings(oldValue => {
                const newValue = [...oldValue];
                const mapping = newValue.find(mapping => mapping.projectId === currentMappingProject.id);
                if(mapping) {
                    const map = mapping[currentMappingFields.object === JiraMappingObjectTypes.Initiative ? 'initiativeMapping' : 'requirementMapping'];
                    if(map) {
                        map.fields = map.fields.filter(field => field.prodmapField !== prodmapField);
                        // map.fields.push(fieldMapping);

                    }
                }
                return newValue;
            })

        }
    }

    const goBackToProjects = () => {
        setCurrentMappingProject(null);
        setCurrentMappingFields(null);
    }

    const goBackToObject = () => {
        setCurrentMappingFields(null);
    }

    const startMappingFields = (object: JiraMappingObjectTypes, issueType: JiraIssueType) => {
        if(currentMappingProject) {

            setCurrentMappingFields({
                object,
                issueType
            })

        }
    }

    const onAddNewProject = () => {
        if(!userIntegrations.find(integration => integration.name === 'JIRA')) {
            const jiraIntegration = availableIntegrations.find(integration => integration.name === 'JIRA');
            if(jiraIntegration) {
                setToLocalStorage(REDIRECT_URL_KEY, location.pathname);
                const props = jiraIntegration.properties;
                const params = createQueryParamsFromObject({ ...props, state: 'JIRA' })

                window.location.replace(`${JIRA_AUTH_BASE_URL}&${params}`);
            }
        } else {

            setSelectedProjects(prevValue => [...prevValue, null]);
        }
    }

    return (
        isLoadingProjects ? <Flexbox fullWidth fullHeight align justify><Loader disableShrink/></Flexbox>
            : <Flexbox vertical fullWidth className={classes('mainContainer')}>
                {currentMappingProject && <Flexbox className={classes('breadcumbsContainer')}>
                    <Flexbox className={classes('first')} onClick={goBackToProjects}>Jira Projects</Flexbox> / <Flexbox className={classes('second')} onClick={currentMappingFields ? goBackToObject : () => {}}>{currentMappingProject.name}</Flexbox> {
                        currentMappingFields && (<> / <Flexbox className={classes('third')}>{currentMappingFields.object === JiraMappingObjectTypes.Initiative ? 'Initiative' : 'Story'} Fields</Flexbox></>)}
                </Flexbox>}
                <Flexbox vertical className={classesInfo('headerContainer')}>
                    <Flexbox className={classesInfo('headerInfoTop')}>
                        <Flexbox className={classesInfo('headerTitle')}>Map Prodmap with Jira Projects</Flexbox>
                    </Flexbox>
                </Flexbox>
                {currentMappingProject ?
                    currentMappingFields ? (<Flexbox className={classes('tableContainer')} vertical>
                        <Flexbox className={classes('fieldMapping')}>
                            {currentMappingFields.object}&nbsp; -&gt; &nbsp;{currentMappingFields.issueType.name}
                        </Flexbox>
                        <FieldMappings
                            mapping={mappings.find(mapping => mapping.projectId === currentMappingProject.id)}
                            object={currentMappingFields.object}
                            issueType={currentMappingFields.issueType}
                            onMappingField={onMappingField}
                            onUnmapingField={onUnmapingField}
                            projectId={currentMappingProject.id + ''}
                        />
                    </Flexbox>) :
                        (<Flexbox className={classes('tableContainer')}>
                            <ObjectToIssueType
                                project={currentMappingProject}
                                onMappingObjectSelect={onMappingObjectSelect}
                                startMappingFields={startMappingFields}
                                mapping={mappings.find(mapping => mapping.projectId === currentMappingProject.id)}
                                onUnmapObject={onUnmapObject}
                            />
                        </Flexbox>) : (<Flexbox className={classes('tableContainer')}>
                        <Table
                            header={jiraProjectsHeader}
                            data={[...selectedProjects.map((project, index) => {

                                const row: TableRowProps = {

                                    data: [
                                        project ? <Flexbox>{project.name}</Flexbox> : <Select
                                            value={undefined}
                                            options={allProjects.filter(project => !selectedProjects.includes(project))}
                                            onChange={(e, project) => {onProjectSelect(project, index)}}
                                            getOptionLabel={project => project.name}
                                            disableClearable
                                        />,
                                        <Flexbox>
                                            {project && <Button onClick={() => {setCurrentMappingProject(project)}}>Mappings</Button>}
                                            <ActionsMenu className={classes('actionMenu')}
                                                buttonItems={[{ label: 'Delete', action: () => {deleteSelectedProject(index)}, type: 'red' }]}
                                            />
                                        </Flexbox>

                                    ],

                                }
                                return row
                            }),
                            { data: [<Flexbox className={classes('actionsButtonContainer')}>
                                <Button className={classes('actionsButton')} onClick={onAddNewProject}  startIcon={<PlusIcon />}>
                        Add Jira Project to map
                                </Button>

                            </Flexbox>] }]}
                        // orderBy={orderBy}
                        // order={order}
                        // sortHandler={sortHandler}
                        />
                    </Flexbox>)}
            </Flexbox>
    )
}