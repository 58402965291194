import { DatePicker, Flexbox, Select, TabPanel, SwitchFilter } from 'components';
import classNames from 'classnames/bind';
import styles from '../../initiative.module.scss';
import { CalendarPresetsKeys, Product, Team, TeamResourceResponse, User } from 'utils/types';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productsSelector } from 'store/products';
import { getProducts } from 'pages/Products/products.api';
import { activeUsersSelector } from 'store/users-slice';
import { Preset } from 'components/Datepicker';
import { addDaysToDate, addMonths, addWeeks, getQuarterEndDate, workingDaysBetweenDates } from 'utils/date';
import { SwitchOption } from 'components/SwitchFilter';

const classes = classNames.bind(styles);

import { BryntumScheduler } from '@bryntum/scheduler-react';
import '@bryntum/scheduler/scheduler.material.css';

import { ResourceModelConfig, PresetManager, EventModel } from '@bryntum/scheduler';
import { addTeamResource, deleteTeamResource, editTeamResource, getTeamResources } from 'pages/Initiatives/initiatives.api';
import { IAfterEventDropParams, IBeforeEventDeleteParams, IBeforeEventEditParams, IDragCreateEndParams, IDragCreateStartParams, IEventRendererParams, IEventResizeEndParams } from 'utils/bryntumSchedulerTypes';

const ViewPresets = ['days', 'weeks', 'months']

const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

PresetManager.add({
    id: 'days',
    base: 'dayAndWeek', // Based on an existing preset
    columnLinesFor: 0,
    tickWidth: 40,
    // Override headers
    headers: [
        {
            unit: 'month',
            // Use different date format for top header 01.10.2020
            // dateFormat : 'DD.MM.YYYY'

            renderer: (start: Date, end: Date, headerConfig: any, index: number) => {

                return Months[start.getMonth()] + ' ' + start.getFullYear()
            }
        },
        {
            unit: 'day',
            // Use different date format for top header 01.10.2020
            // dateFormat : 'DD.MM.YYYY'

            renderer: (start: Date, end: Date, headerConfig: any, index: number) => {

                return start.getDate()
            }
        },
    ]
});
PresetManager.add({
    id: 'weeks',
    base: 'weekAndMonth', // Based on an existing preset
    columnLinesFor: 0,
    tickWidth: 200,
    // Override headers
    headers: [
        {
            unit: 'week',
            // Use different date format for top header 01.10.2020
            // dateFormat : 'DD.MM.YYYY'
            renderer: (start: Date, end: Date, headerConfig: any, index: number) => {
                return `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`
            }
        },
    ]
});
PresetManager.add({
    id: 'months',
    base: 'monthAndYear', // Based on an existing preset
    columnLinesFor: 0,
    // Override headers
    headers: [
        {
            unit: 'month',
            // Use different date format for top header 01.10.2020
            // dateFormat : 'DD.MM.YYYY'
            with: '50px',
            renderer: (start: any, end: any, headerConfig: any, index: any) => {
                return index + 1
            }
        },
    ]
});

const switchOptions: SwitchOption[] = [
    { id: 0, title: 'Days' },
    { id: 1, title: 'Weeks' },
    // { id: 2, title:'Months' },
]



interface PlanTabProps {
    active: boolean;
    owner: number | undefined;
    setOwner: (value: number | undefined) => void;
    startDate: Date | null;
    setStartDate: (value: Date | null) => void;
    endDate: Date | null;
    setEndDate: (value: Date | null) => void;
    releaseDate: Date | null;
    setReleaseDate: (value: Date | null) => void;
    selectedProducts: Product[];
    setSelectedProducts: (value: Product[]) => void;
    affectedProducts: Product[];
    setAffectedProducts: (value: Product[]) => void;
    teams: number[];
    setTeam: (value: number[]) => void;
    teamsList: Team[];
    isEditable?: boolean;
    initiativeId?: number;
}


const defaultPresets = [
    { id: CalendarPresetsKeys.oneWeek, title: 'One week' },
    { id: CalendarPresetsKeys.twoWeeks, title: 'Two weeks' },
    { id: CalendarPresetsKeys.oneMonth, title: 'One month' },
    { id: CalendarPresetsKeys.endOfQuarter, title: 'End of quarter' },
]

interface Resource {
    name: string;
    id: number;
}

interface ResourcesByTeam {
    resources: Resource[];
    teamId: number;
    teamName: string;
}

const Plan = ({
    active,
    owner,
    setOwner,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    releaseDate,
    setReleaseDate,
    selectedProducts,
    setSelectedProducts,
    affectedProducts,
    setAffectedProducts,
    teams,
    setTeam,
    teamsList,
    isEditable = true,
    initiativeId,
}: PlanTabProps) => {


    const products = useSelector(productsSelector);
    const dispatch = useDispatch();

    const [presets, setPresets] = useState<Preset[]>(defaultPresets)
    const [presetsRelease, setPresetsRelease] = useState<Preset[]>(defaultPresets)

    const scheduler = useRef<BryntumScheduler>(null);

    const [viewPreset, setViewPreset] = useState(switchOptions[0]);

    const [events, setEvents] = useState<{ [key: string]: EventModel[] }>({});

    useEffect(() => {
        dispatch(getProducts());
    }, [])

    useEffect(() => {
        if (initiativeId && teams.length) {
            teams.forEach(teamId => loadTeamResources(initiativeId, teamId))
            // loadTeamResources()
        }
    }, [initiativeId, teams]);


    const loadTeamResources = async (initiativeId: number, teamId: number) => {
        const allTeamEvents = (await dispatch(getTeamResources(initiativeId, teamId))) as unknown as TeamResourceResponse[];
        const teamEvents = allTeamEvents.filter((e) => e.startDate && e.endDate);
        const eventsList = teamEvents.map((e) => {
            const startDate = new Date(e.startDate);
            const endDate = new Date(e.endDate);
            return { id: e.id, resourceId: e.user.userId, startDate, endDate, name: `${workingDaysBetweenDates(startDate, endDate)} days` }
        })

        setEvents(prev => ({ ...prev, [teamId]: eventsList }))
    }

    const selectedProduct = products.find(product => product.id === selectedProducts[0]?.id) || selectedProducts[0]

    const users = useSelector(activeUsersSelector);

    const ownerUser = users.find(user => user.id === owner);


    const onOwnerChange = (_e: ChangeEvent<{}>, value: User | null) => {
        if (value) {
            setOwner(value.id);
        }
    }

    const onProductChange = (_e: ChangeEvent<{}>, value: Product | null) => {
        if (value) {
            setSelectedProducts([value])
        }
    }

    const onAffectedProductsChange = (_e: ChangeEvent<{}>, value: Product[]) => {
        setAffectedProducts(value);
    }

    const onTeamChange = (_e: ChangeEvent<{}>, value: Team[]) => {
        setTeam(value.map(t => t.id))
    }


    const onCalendarChange = ([startDate, endDate]: [Date | null, Date | null]) => {

        const presetsList = presets.map(preset => {
            return { ...preset, isSelected: false }
        })

        setPresetsRelease(presetsList)

        setStartDate(startDate);
        setEndDate(endDate);
    };

    const onReleaseCalendarChange = (date: Date | null) => {

        const presetsList = presetsRelease.map(preset => {
            return { ...preset, isSelected: false }
        })

        setPresets(presetsList)

        setReleaseDate(date);
    };

    const selectReleaseDateCalendar = (id: string) => {

        const presetsReleaseList = presetsRelease.map(preset => {
            if (preset.id === id) {
                return { ...preset, isSelected: true }
            }
            return { ...preset, isSelected: false }
        })

        setPresetsRelease(presetsReleaseList)

        if (id === CalendarPresetsKeys.oneWeek) {
            setReleaseDate(addWeeks());
        } else if (id === CalendarPresetsKeys.twoWeeks) {
            setReleaseDate(addWeeks(2));
        } else if (id === CalendarPresetsKeys.oneMonth) {
            setReleaseDate(addMonths());
        } else if (id === CalendarPresetsKeys.endOfQuarter) {
            setReleaseDate(getQuarterEndDate(new Date()));
        }
    }


    const selectCalendarRange = (id: string) => {

        const presetsList = presets.map(preset => {
            if (preset.id === id) {
                return { ...preset, isSelected: true }
            }
            return { ...preset, isSelected: false }
        })

        setPresets(presetsList)

        if (id === CalendarPresetsKeys.oneWeek) {
            setStartDate(new Date());
            setEndDate(addWeeks());
        } else if (id === CalendarPresetsKeys.twoWeeks) {
            setStartDate(new Date());
            setEndDate(addWeeks(2));
        } else if (id === CalendarPresetsKeys.oneMonth) {
            setStartDate(new Date());
            setEndDate(addMonths());
        } else if (id === CalendarPresetsKeys.endOfQuarter) {
            setStartDate(new Date());
            setEndDate(getQuarterEndDate(new Date()));
        }
    }

    const resources = useMemo(() => {
        const resourcesByTeams: ResourcesByTeam[] = [];
        teams.forEach((teamId) => {
            const team = teamsList.find(t => t.id === teamId);
            if (team) {

                const res = team.users.filter(user => {
                    return users.some(u => u.id === user.id)
                }).map(user => ({ id: user.id, name: user.fullName }))
                resourcesByTeams.push({
                    resources: res,
                    teamId: team.id,
                    teamName: team.name,
                });
            }
        })
        return resourcesByTeams
    }, [teamsList, teams, users])

    const createTeamResource = async (eventRecord: any, teamId: number) => {
        if (initiativeId) {

            // const teamId = teams[0];
            const res = (await dispatch(addTeamResource(initiativeId, teamId, { userId: eventRecord.resourceId, endDate: eventRecord.endDate, startDate: eventRecord.startDate }))) as unknown as TeamResourceResponse;
            eventRecord.id = res.id
        }
    }

    const updateTeamResource = (eventRecord: any, teamId: number) => {
        if (initiativeId) {

            // const teamId = teams[0];
            dispatch(editTeamResource(initiativeId, teamId, eventRecord.id as number, { userId: eventRecord.resourceId, endDate: eventRecord.endDate, startDate: eventRecord.startDate }))
        }
    }

    const onDeleteTeamResource = (eventRecord: any, teamId: number) => {
        if (initiativeId) {

            // const teamId = teams[0];
            dispatch(deleteTeamResource(initiativeId, teamId, eventRecord.id as number))
        }
    }

    const schedulerEndDate = useMemo(() => {
        if (endDate) {
            const date = new Date(endDate);
            date.setHours(23, 59, 59);
            console.log(date);

            return date
        }
        return undefined
    }, [endDate])

    return (
        <TabPanel active={active}>
            <Flexbox vertical>
                <Flexbox className={classes('tabContainer')} vertical>
                    <Flexbox className={classes('tabRow', 'mb24')}>
                        <Flexbox fullWidth className={classes('tabColSpacing')}>
                            <Select
                                onChange={onOwnerChange}
                                options={users}
                                disableClearable
                                value={ownerUser}
                                getOptionLabel={option => option.fullName}
                                label='Owner'
                                placeholder='Owner'
                                key={ownerUser?.id}
                                disabled={!isEditable}
                                getToolTipText={option => option.email}
                            />
                            <Select
                                onChange={onProductChange}
                                value={selectedProduct}
                                key={selectedProduct?.id}
                                disableClearable
                                options={products}
                                getOptionLabel={option => option.title}
                                label='Product'
                                placeholder='Product'
                                disabled={!isEditable}
                            />
                        </Flexbox>
                    </Flexbox>
                    <Flexbox className={classes('tabRow', 'mb24')}>
                        <Flexbox fullWidth className={classes('tabColSpacing')}>
                            <Select
                                onChange={onTeamChange}
                                options={teamsList}
                                disableClearable
                                value={teamsList.filter(t => teams.includes(t.id))}
                                getOptionLabel={option => option.name}
                                multiple
                                disabled={!isEditable}
                                label='Teams'
                                placeholder='Teams'
                            />
                        </Flexbox>
                    </Flexbox>
                    <Flexbox className={classes('tabRow', 'mb24')}>
                        <Flexbox fullWidth className={classes('tabColSpacing')}>
                            <Select
                                onChange={onAffectedProductsChange}
                                value={affectedProducts}
                                options={products.length ? products : affectedProducts}
                                disableClearable
                                multiple
                                getOptionLabel={option => option.title}
                                label='Affected products'
                                placeholder='Affected products'
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                disabled={!isEditable}
                            />
                        </Flexbox>
                    </Flexbox>
                    <Flexbox className={classes('tabRow', 'mb24')}>
                        <Flexbox fullWidth className={classes('tabColSpacing')}>
                            <DatePicker
                                selected={startDate}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={onCalendarChange}
                                disabled={!isEditable}
                                fullWidth
                                label="Development Timeline"
                                selectsRange={true}
                                monthsShown={2}
                                presets={{
                                    onPresetSelect: selectCalendarRange,
                                    presets
                                }}
                            />
                            <DatePicker
                                selected={releaseDate}
                                onChange={onReleaseCalendarChange}
                                fullWidth
                                disabled={!isEditable}
                                label='Live date'
                                placeholderText='Live date'
                                presets={{
                                    onPresetSelect: selectReleaseDateCalendar,
                                    presets: presetsRelease
                                }}
                                isClearable
                            />

                        </Flexbox>
                    </Flexbox>

                </Flexbox>
                {startDate && !!resources.length && (
                    <Flexbox className={classes('resourceManagement')} vertical>
                        {resources.map((resource) => {
                            return (
                                <Flexbox className={classes('row')} vertical key={resource.teamId}>
                                    <Flexbox className={classes('label')}>{resource.teamName} team resources</Flexbox>
                                    <BryntumScheduler
                                        ref={scheduler}
                                        autoHeight
                                        key={events[resource.teamId] + ''}
                                        width={'100%'}
                                        disabled={!isEditable}
                                        startDate={startDate}
                                        endDate={addDaysToDate(startDate, 89)}
                                        viewPreset={ViewPresets[viewPreset.id]}
                                        readOnly={!isEditable}
                                        enableDeleteKey={isEditable}
                                        enableUndoRedoKeys={isEditable}
                                        enableTextSelection={isEditable}
                                        features={{
                                            eventEdit: {
                                                disabled: !isEditable,
                                            },
                                            eventResize: {
                                                disabled: !isEditable,
                                            },
                                            cellEdit: {
                                                disabled: !isEditable,
                                            },
                                        }}
                                        listeners={{
                                            dragCreateStart: (event: IDragCreateStartParams) => {
                                                event.eventRecord.name = ''
                                            },
                                            dragCreateEnd: (event: IDragCreateEndParams) => {
                                                event.eventRecord.name = ''
                                            },
                                            eventResizeEnd: (event: IEventResizeEndParams) => {
                                                const eventRecord = event.eventRecord;

                                                let endDateValue = eventRecord.endDate;
                                                if (typeof endDateValue === 'string') {
                                                    endDateValue = new Date(endDateValue);
                                                }

                                                let endDate = new Date(endDateValue.getTime() - 1);

                                                let endDay = endDate.getDay();
                                                if (endDay === 0) {
                                                    endDay = 7;
                                                }
                                                if (endDay > 5) {
                                                    const diff = endDay - 5;
                                                    endDate.setDate(endDate.getDate() - diff);
                                                }

                                                eventRecord.endDate = endDate;

                                                let startDateValue = eventRecord.startDate;
                                                if (typeof startDateValue === 'string') {
                                                    startDateValue = new Date(startDateValue);
                                                }

                                                eventRecord.name = `${workingDaysBetweenDates(startDateValue, endDate)} days`;
                                                updateTeamResource(eventRecord, resource.teamId);
                                            },
                                            afterEventDrop: (event: IAfterEventDropParams) => {
                                                const eventRecord = event.eventRecords[0];
                                                updateTeamResource(eventRecord, resource.teamId)
                                            },
                                            beforeEventDelete: (event: IBeforeEventDeleteParams) => {
                                                const eventRecords = event.eventRecords;
                                                onDeleteTeamResource(eventRecords[0], resource.teamId)
                                            },
                                            beforeEventEdit: (event: IBeforeEventEditParams) => {
                                                const eventRecord = event.eventRecord;
                                                const eventStore = scheduler.current?.instance.eventStore
                                                const resourceId = eventRecord.resourceId

                                                // We need to reset this flag to tell scheduler that this is a real event
                                                eventRecord.isCreating = false;

                                                window.setTimeout(() => {
                                                    // source.eventRecord.resourceId = source.resourceRecord.id;

                                                    // Update the eventRecord using the default setters
                                                    eventRecord.beginBatch();
                                                    if (!eventRecord.resourceId) {
                                                        eventRecord.resourceId = resourceId;
                                                    }

                                                    let endDateValue = eventRecord.endDate;
                                                    if (typeof endDateValue === 'string') {
                                                        endDateValue = new Date(endDateValue);
                                                    }

                                                    let endDate = new Date(endDateValue.getTime() - 1);

                                                    let endDay = endDate.getDay();
                                                    if (endDay === 0) {
                                                        endDay = 7;
                                                    }
                                                    if (endDay > 5) {
                                                        const diff = Math.abs(5 - endDay);
                                                        endDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24 * diff);
                                                    }
                                                    eventRecord.endDate = endDate;
                                                    // eventRecord.id = 4;

                                                    let startDateValue = eventRecord.startDate;
                                                    if (typeof startDateValue === 'string') {
                                                        startDateValue = new Date(startDateValue);
                                                    }

                                                    eventRecord.name = `${workingDaysBetweenDates(startDateValue, endDate)} days`;
                                                    // eventRecord.startDate = new Date(2023,3,10);
                                                    // eventRecord.duration = 3;
                                                    // eventRecord.resourceId = 2;
                                                    // eventRecord.durationUnit = 'day';
                                                    // eventRecord.allDay = true;

                                                    // Add the eventRecord to the eventStore if it is not already there
                                                    if (eventStore) {
                                                        eventStore.add(eventRecord);
                                                    }
                                                    eventRecord.endBatch();
                                                    createTeamResource(eventRecord, resource.teamId)
                                                }, 100)

                                                return false;
                                            }
                                        }}

                                        // zoomKeepsOriginalTimespan
                                        zoomOnMouseWheel={false}
                                        zoomOnTimeAxisDoubleClick={false}
                                        allowOverlap={false}

                                        scheduleTooltipFeature={false}
                                        eventTooltipFeature={false}
                                        eventDragFeature={{
                                            showTooltip: false,
                                            disabled: !isEditable,
                                        }}
                                        cellTooltipFeature={false}

                                        eventDragCreateFeature={{
                                            showTooltip: false,
                                            disabled: !isEditable,
                                        }}
                                        eventMenuFeature={{
                                            items: {
                                                editEvent: false,
                                                copyEvent: false,
                                                cutEvent: false,
                                                splitEvent: false,
                                                deleteEvent: {
                                                    text: 'Delete'
                                                },
                                                unassignEvent: false,
                                            },
                                            disabled: !isEditable,
                                        }}
                                        headerMenuFeature={false}
                                        cellMenuFeature={false}
                                        cellEditFeature={false}
                                        timeAxisHeaderMenuFeature={false}
                                        scheduleMenuFeature={false}
                                        regionResizeFeature={false}
                                        eventRenderer={(detail: IEventRendererParams) => {
                                            // If a color is specified, apply it to all events
                                            detail.renderData.eventColor = '#025B62';
                                            return detail.eventRecord.name
                                        }}

                                        nonWorkingTimeFeature={{
                                            showHeaderElements: false,
                                            disabled: !isEditable,
                                        }}

                                        resources={resource.resources as Partial<ResourceModelConfig>[]}

                                        events={events[resource.teamId]}
                                        columns={[
                                            { text: 'Name', field: 'name', autoWidth: true, },
                                        ]}
                                        columnAutoWidthFeature={true}
                                        rowHeight={40}
                                        timeResolution={{
                                            unit: 'day',
                                            increment: 1
                                        }}
                                    />
                                </Flexbox>
                            )
                        })}
                        <Flexbox className={classes('viewPresetMainContainer')}>
                            <Flexbox className={classes('viewPresetContainer')}>
                                <SwitchFilter
                                    options={switchOptions}
                                    value={viewPreset}
                                    onChange={setViewPreset}
                                />
                            </Flexbox>
                        </Flexbox>
                    </Flexbox>
                )}

            </Flexbox>
        </TabPanel>
    )
}

export default Plan;
