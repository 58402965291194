import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Report } from 'utils/types'
import { RootState } from 'store';
import { reportApi } from './report-api';

interface ReportState {
    reports: Report[],
    currentReport: Report | null
}

const initialState: ReportState = {
    reports: [],
    currentReport: null
}

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setCurrentReport: (state, action: PayloadAction<any>) => {
            state.currentReport = action.payload
        },
        updateCurrentReport: (state, action: PayloadAction<any>) => {
            state.currentReport = {
                ...state.currentReport,
                ...action.payload
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            reportApi.endpoints.getReport.matchFulfilled,
            (state, { payload }: {payload: Report[]}) => {
                const sortedReports = payload.sort((date1, date2) => date2.createdDate - date1.createdDate);
                state.reports = sortedReports
                state.currentReport = sortedReports[0]
            },
        ),
        builder.addMatcher(
            reportApi.endpoints.generateReport.matchFulfilled,
            (state, { payload }) => {
                state.reports = [payload, ...state.reports]
            },
        )
    },
})

export const reportsSelector = (store: RootState) => store.report.reports;
export const currentReportSelector = (store: RootState) => store.report.currentReport;
export const currentReportTitleSelector = (store: RootState) => store.report.currentReport?.title;
export const currentReportIdSelector = (store: RootState) => store.report.currentReport?.id;
export const currentReportCreatedBySelector = (store: RootState) => store.report.currentReport?.createdBy;
export const currentReportSummarySelector = (store: RootState) => store.report.currentReport?.summary;
export const currentReportDataSelector = (store: RootState) => store.report.currentReport?.data;
export const currentReportTypeSelector = (store: RootState) => store.report.currentReport?.type;

export const { setCurrentReport, updateCurrentReport } = reportSlice.actions

export default reportSlice
