import { Button, Flexbox, Table, TabPanel, PriorityIcon, SearchField, Loader, FilterButton, Tooltip, TimeAgo } from 'components'
import styles from '../../products.module.scss';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { TableHeader, TableRowProps } from 'components/Table';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Initiative, InitiativeOKR, InitiativePriority, InitiativeStatus } from 'utils/types';
import { EmptyInitiativeIcon, PlusIcon } from 'components/icons';
import { useNavigate } from 'react-router-dom';
import { getEnumKeyByEnumValue } from 'utils';
import { useDebounce, useWorkspaceId } from 'utils/hooks';
import { FilterGroup, FilterOption } from 'components/FilterButton';
import EmptyState from 'common/emptyState';
import { Actions, hasPermission } from 'utils/permissions';
import { useGetInitiativesQuery } from 'store/product-api';
import { productTitleSelector, initiativesSelector } from 'store/product-slice';
import { sortByValue } from 'utils/tableSorting';
const classes = classNames.bind(styles);

interface InitiativesTabProps{
    active: boolean;
    productId: number;
}
interface ProductsTableHeaders{
    owner: string;
    title: string;
    okrs: string;
    priority: string;
    status: string;
    startDate: string;
    releaseDate: string;
    productTitle: string;
    lastModifiedDate: string
}
interface ProductFilter{
    productTitle?: string;
    title: string;
    id: number;
    initiatives: ProductInitiative[];
}

interface ProductInitiative extends Omit <Initiative, 'problem' | 'solution' | 'validation' | 'impact'> {
    okrs: InitiativeOKR[];
    productTitle?: string;
}

const productsHeader: TableHeader<ProductsTableHeaders>[] = [
    {
        id: 'productTitle',
        text: 'Product',
        sortable: true,
    },
    {
        id: 'title',
        text: 'Title',
        width: '280px',
        sortable: true,
    },
    {
        id: 'owner',
        text: 'Owner',
        width: '120px',
        sortable: true,
    },
    {
        id: 'priority',
        text: 'P',
        sortable: true,
    },
    {
        id: 'status',
        text: 'Status',
        sortable: true,
    },
    {
        id: 'startDate',
        text: 'Start date',
        sortable: true,
    },
    {
        id: 'releaseDate',
        text: 'Live date',
        sortable: true,
    },
    {
        id: 'lastModifiedDate',
        text: 'Last updated',
        sortable: true,
    },
]

const InitiativesTab = ({ active, productId } : InitiativesTabProps) => {
    const navigate = useNavigate();
    const workspaceId = useWorkspaceId()

    const { isLoading } = useGetInitiativesQuery({ workspaceId, productId })

    const initiatives = useSelector(initiativesSelector)
    const productTitle = useSelector(productTitleSelector);

    const [allProducts, setAllProducts] = useState<ProductFilter[]>([])
    const [selectedProducts, setSelectedProducts] = useState<FilterOption[]>([])
    const [tableData, setTableData] = useState<ProductInitiative[]>([])
    const [optionsList, setOptionsList] = useState<FilterGroup[]>([])

    const [searchValue, searchDebounceValue, setSearchValue] = useDebounce('');
    const [orderBy, setOrderBy] = useState<keyof ProductsTableHeaders | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc' | undefined>();

    const createNewInitiative = () => {
        navigate('/initiatives/initiative');
    }

    useEffect(() => {
        let initiativeData: ProductFilter[] = []

        if(initiatives){
            initiativeData.push({
                title: `${productTitle} (${initiatives.selfInitiatives.length})`,
                initiatives: initiatives.selfInitiatives.map(i => ({ ...i, productTitle })),
                id: -1
            })

            const affectedInitiatives = initiatives['affectedProductInitiatives'].reduce<ProductInitiative[]>((acc, current) => {
                const currentData = current.initiatives.map(i => ({ ...i, productTitle: current.product.title }))

                return [...acc, ...currentData]},
            [])

            initiativeData.push({
                title: `Affected (${affectedInitiatives.length})`,
                initiatives: affectedInitiatives,
                id: -2
            })

            initiatives['childProductInitiatives']?.map((childProduct) => {
                initiativeData.push({
                    title: `${childProduct.product.title} (${childProduct.initiatives.length})`,
                    productTitle: childProduct.product.title,
                    id: childProduct.product.id,
                    initiatives: childProduct.initiatives.map(i => ({ ...i, productTitle: childProduct.product.title })),
                })
            })
        }

        setAllProducts(initiativeData)

        setOptionsList([
            { items: [
                { id: -1, title: initiativeData.find(d => d.id === -1)?.title || productTitle },
                { id: -2, title: initiativeData.find(d => d.id === -2)?.title || 'Affected'  },
            ] },
            {
                title: 'Child',
                items: initiativeData.filter(product => product.id !== -1 && product.id !== -2).map(product => ({ id: product.id, title: product.title }))
            }
        ])

    }, [initiatives])

    const onSelectedChange = (selectedData: FilterOption[]) => {
        setSelectedProducts(selectedData)
    }

    useEffect(() => {
        const tableData: ProductInitiative[] = []

        allProducts.forEach(initiative => {

            if(selectedProducts.find((selectedInitiative) => selectedInitiative.id === initiative.id) || selectedProducts.length === 0){
                tableData.push(...initiative.initiatives)
            }
        })

        let data = tableData.filter(d => d.title.toUpperCase().includes(searchValue.toUpperCase()) || d.productTitle?.toUpperCase().includes(searchValue.toUpperCase()))

        if(orderBy && order) {
            data.sort((d1, d2) => {
                if(orderBy === 'owner'){
                    if(d1[orderBy].fullName > d2[orderBy].fullName) {
                        return order === 'asc' ? 1 : -1
                    } else {
                        return order === 'asc' ? -1 : 1
                    }
                } else if(orderBy === 'priority') {
                    return sortByValue(d1.priority, d2.priority, order, InitiativePriority)
                }  else if(orderBy === 'status') {
                    return sortByValue(d1.status, d2.status, order, InitiativeStatus)
                }
                else{
                    const value1 = d1[orderBy];
                    const value2 = d2[orderBy]
                    if(value1 && value2 && value1 > value2) {
                        return order === 'asc' ? 1 : -1
                    } else {
                        return order === 'asc' ? -1 : 1
                    }
                }
            })
        }

        setTableData(data)
    }, [selectedProducts, order, orderBy, allProducts, searchValue])

    const sortHandler = (header: keyof ProductsTableHeaders) => {
        setOrderBy(header);
        setOrder(oldOrder => {
            return oldOrder === 'asc' ? 'desc' : 'asc'
        })
    }

    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    }

    const onSearchClear = () => {
        setSearchValue('');
    }

    const buttonItemsEmptyState = useMemo(() => {
        const buttons = []

        if(hasPermission(Actions.create)){
            buttons.push({
                onClick: createNewInitiative,
                text: 'Create',
            },)
        }

        return buttons
    },[])

    return(
        <TabPanel className={classes('tabPanel')} active={active} vertical>
            <Flexbox className={classes('initiativesBox')} fullWidth vertical>
                {initiatives &&
                <Flexbox fullWidth className={classes('tableHead')}>
                    <Flexbox className={classes('tableHeadLeft')}>
                        <SearchField
                            className={classes('searchField')}
                            value={searchValue}
                            onChange={onSearchValueChange}
                            onClear={onSearchClear}
                            placeholder='Search products'
                        />
                        <FilterButton
                            label='Product'
                            options={optionsList}
                            value={selectedProducts}
                            multiple
                            onChange={(selectedOptions: FilterOption[]) => onSelectedChange(selectedOptions)}
                        />
                    </Flexbox>
                    <Flexbox className={classes('tableHeadRight')}>
                        <Button className={classes('createButton')}
                            variant='text'
                            onClick={createNewInitiative}
                        >
                            Create Initiative <PlusIcon />
                        </Button>
                    </Flexbox>
                </Flexbox>
                }
                {isLoading ? <Flexbox fullWidth fullHeight align justify><Loader disableShrink/></Flexbox>
                    :
                    !initiatives || Object.keys(initiatives).length > 0 ?
                        <Table
                            header={productsHeader}
                            data={tableData.map((i: ProductInitiative) => {
                                const startDate = i.startDate ? new Date(i.startDate) : null;
                                const releaseDate = i.releaseDate ? new Date(i.releaseDate) : null;

                                const row: TableRowProps = {
                                    url: `/initiatives/initiative/${i.id}`,
                                    data: [
                                        <Flexbox>{i.productTitle}</Flexbox>,
                                        <Flexbox>{i.title || 'Untitled'}</Flexbox>,
                                        <Flexbox>{i.owner?.fullName}</Flexbox>,
                                        <Tooltip title={i.priority}>
                                            <Flexbox>
                                                <PriorityIcon priority={i.priority} />
                                            </Flexbox>
                                        </Tooltip>,
                                        <Flexbox>{getEnumKeyByEnumValue(InitiativeStatus, i.status)}</Flexbox>,
                                        <Flexbox>{startDate?.toLocaleDateString()}</Flexbox>,
                                        <Flexbox>{releaseDate?.toLocaleDateString()}</Flexbox>,
                                        <Flexbox>
                                            {i.lastModifiedDate &&
                                                <TimeAgo datetime={i.lastModifiedDate} live={false} />
                                            }
                                        </Flexbox>,
                                    ],
                                }
                                return row
                            })}
                            orderBy={orderBy}
                            order={order}
                            sortHandler={sortHandler}
                            emptyText={tableData.length === 0 ? 'There are no initiatives yet' : undefined}
                        />
                        :
                        <EmptyState
                            icon={<EmptyInitiativeIcon />}
                            title='There are no Initiatives yet'
                            titleSmall={buttonItemsEmptyState.length ? 'Press the button and start to' : undefined}
                            buttonItems={buttonItemsEmptyState}
                        />
                }
            </Flexbox>
        </TabPanel>
    )
}

export default InitiativesTab