import { Flexbox } from 'components'

import classNames from 'classnames/bind';
import styles from './emptyCard.module.scss';
const classes = classNames.bind(styles);


export default () => {
    return (
        <Flexbox className={classes('emptyCard')}></Flexbox>
    )
}