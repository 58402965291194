const ArrowExpendedIcon = () => {
    return (
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 6.81258L0 1.81258L0.895833 0.916748L5 5.04175L9.10417 0.937581L10 1.83341L5 6.81258Z" fill="currentColor"/>
        </svg>


    )
}

export default ArrowExpendedIcon;