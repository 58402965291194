import { Flexbox, HorizontalSeparator, Link, TimeAgo, Tooltip } from 'components'
import { Initiative, InitiativeStatus } from 'utils/types'

import classNames from 'classnames/bind';
import styles from '../../roadMap.module.scss';
import { getEnumKeyByEnumValue } from 'utils';
const classes = classNames.bind(styles);


interface InitiativeCardProps {
    initiative: Initiative;
    isKanbanVew: boolean;
    groupItemId?: number;
}

export default ({ initiative, isKanbanVew, groupItemId }: InitiativeCardProps) => {
    const releaseDate = initiative.releaseDate ? new Date(initiative.releaseDate) : null
    return (
        <Flexbox className={classes('initiativeCardContainer', { unassignedInitiative: groupItemId === -1 })} vertical>

            <Flexbox className={classes('initiativeTitle')}>
                <Link to={`/initiatives/initiative/${initiative.id}`}>
                    <Tooltip title={initiative.title}>
                        <Flexbox className={classes('text')}>{initiative.title}</Flexbox>
                    </Tooltip>
                </Link>
            </Flexbox>

            {
                isKanbanVew ?
                    <Flexbox className={classes('initiativeTwoColItem')}>
                        <Flexbox className={classes('initiativeReleaseDate')}>{releaseDate && releaseDate.toLocaleDateString()}</Flexbox>
                        <Tooltip title='In this status'>
                            <Flexbox className={classes('initiativeLastModifiedDate')}>
                                <TimeAgo datetime={initiative.statusLastModifiedDate} live={false} locale='en-short' />
                            </Flexbox>
                        </Tooltip>
                    </Flexbox>
                    :
                    <Flexbox className={classes('initiativeStatus')}>{getEnumKeyByEnumValue(InitiativeStatus, initiative.status)}</Flexbox>
            }

            <HorizontalSeparator dashed />
            <Flexbox className={classes('initiativeOwner')}>{initiative.owner.fullName}</Flexbox>
        </Flexbox>
    )
}