import { Flexbox } from 'components'
import { useSelector } from 'react-redux'
import { userSelector } from 'store/user'

import classNames from 'classnames/bind';
import styles from './userLogo.module.scss';
import { getInitialsFromName } from 'utils';
const classes = classNames.bind(styles);


export default () => {
    const user = useSelector(userSelector)
    return (
        <Flexbox className={classes('userInitials')} justify align>{user.fullName && getInitialsFromName(user.fullName)}</Flexbox>
    )
}