import { Flexbox, Loader, Tab, Tabs } from 'components'
import { ChangeEvent, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { OverviewTab, InitiativesTab, RequirementsTab, ResourcesTab } from './components/tabs'
import styles from './products.module.scss';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux'
import { userSelector } from 'store/user'
import { Actions, hasPermission } from 'utils/permissions'
import ProductHead from './components/productHead';
import { useLazyGetProductQuery, useCreateProductMutation } from 'store/product-api';
import { Product } from 'utils/types';
import store from 'store';
import { useWorkspaceId } from 'utils/hooks';
const classes = classNames.bind(styles);

const tabsList: string[] = [
    'overview', 'initiatives', 'requirements', 'resources',
]
const urlTabParamKey = 'tab';

export const defaultTitle = 'Untitled Product';

export const updateProductBody = () => {
    const currentProduct: Product = store.getState().product.currentProduct

    return {
        title: currentProduct.title,
        owner: currentProduct.owner.id,
        teams: currentProduct.teams.map(team => team.id),
        parentProduct: currentProduct.parentProduct?.id || null,
    }
}

const ProductItem = () => {
    const params = useParams()
    const productId = params['id'] ? parseInt(params['id']) : undefined;

    const workspaceId = useWorkspaceId()

    const [loadProduct, { data: currentProduct, isLoading }] = useLazyGetProductQuery()
    const [createProductMutation] = useCreateProductMutation()

    const user = useSelector(userSelector);

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const onTabChange = (_event: ChangeEvent<{}>, value: number) => {
        setSearchParams(`${urlTabParamKey}=${tabsList[value]}`,{ replace: true })
    }

    const selectedTab = tabsList.indexOf(searchParams.get(urlTabParamKey) || '');

    useEffect(() => {
        if(!productId){
            createProductHandler()
        } else{
            loadProduct({ productId, workspaceId })
        }

        const tab = searchParams.get(urlTabParamKey);
        if(!tab || !tabsList.includes(tab)) {
            setSearchParams(`${urlTabParamKey}=${tabsList[1]}`,  { replace: true })
        }
    },[productId])

    const createProductHandler = async () => {
        try {
            const res = await createProductMutation({
                workspaceId,
                product: {
                    title: defaultTitle,
                    parentProduct: null,
                    owner: user.id,
                    teams: [],
                } }).unwrap()
            navigate(`/catalog/product/${res.id}`, { replace: true })
        } catch (error) {
            console.error('Failed to create product', error);
        }
    }

    const isEditable = hasPermission(Actions.edit, currentProduct)

    return(isLoading ? <Flexbox fullWidth align justify><Loader/></Flexbox> :
        <Flexbox vertical fullWidth>
            {
                currentProduct && productId && (
                <>
                    <ProductHead productId={productId} isEditable={isEditable} />
                    <Tabs
                        value={selectedTab !== -1 ? selectedTab : false}
                        onChange={onTabChange}
                        className={classes('tabs')}
                    >
                        <Tab label="Overview" id='0' />
                        <Tab label="Initiatives" id='1' />
                        <Tab label="Requirements" id='2' />
                        <Tab label="Resources" id='3' />
                    </Tabs>

                    <OverviewTab
                        active={selectedTab === 0}
                        productId={productId}
                        isEditable={isEditable}
                    />
                    <InitiativesTab
                        active={selectedTab === 1}
                        productId={productId}
                    />
                    <RequirementsTab
                        active={selectedTab === 2}
                        productId={productId}
                    />
                    <ResourcesTab
                        active={selectedTab === 3}
                        productId={productId}
                        isEditable={isEditable}
                    />
                </>
            )}
        </Flexbox>
    )
}

export default ProductItem