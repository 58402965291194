export const jobTitles =
    [
        'Systems Administrator',
        'Network Administrator',
        'Server Administrator',
        'Database Administrator',
        'Storage Administrator',
        'Desktop Administrator',
        'Helpdesk Technician',
        'IT Support Specialist',
        'IT Consultant',
        'Systems Engineer',
        'Software Developer',
        'Programmer',
        'Developer',
        'Coder',
        'QA Engineer',
        'Tester',
        'Scrum Master',
        'Product Owner',
        'UX Designer',
        'UI Designer',
        'Technical Writer',
        'IT Manager',
        'CIO',
        'CTO',
        'Director of IT',
        'IT Director',
        'IT Supervisor',
        'IT Specialist',
        'IT Consultant',
        'IT Architect',
        'IT Security Manager',
        'IT Support Specialist',
        'Helpdesk Technician',
        'Desktop Administrator',
        'IT Consultant',
        'Systems Engineer',
        'Cloud Architect',
        'Cloud Engineer',
        'AWS Architect',
        'Azure Architect',
        'Google Cloud Architect',
        'DevOps Engineer',
        'SRE Engineer',
        'Cloud Consultant',
        'Cloud Administrator',
        'Business Intelligence Analyst',
        'Data Analyst',
        'Data Visualization Specialist',
        'Big Data Engineer',
        'Hadoop Developer',
        'Spark Developer',
        'Hive Developer',
        'Pig Developer',
        'Impala Developer',
        'Kafka Developer',
        'Cassandra Developer',
        'MongoDB Developer',
        'Elasticsearch Developer',
        'Solr Developer',
        'Web Developer',
        'Web Designer',
        'Front-End Developer',
        'Back-End Developer',
        'Full-Stack Developer',
        'UI/UX Designer',
        'SEO Specialist',
        'WordPress Developer',
        'Drupal Developer',
        'Joomla Developer',
        'Magento Developer',
        'Shopify Developer',
        'E-Commerce Developer',
        'LAMP Developer',
        'MEAN Developer',
        'Ruby on Rails Developer',
        'Python Developer',
        'JavaScript Developer',
        'CSS Developer',
        'HTML Developer',
        'Mobile Developer',
        'iOS Developer',
        'Android Developer',
        'Xamarin Developer',
        'React Native Developer',
        'Flutter Developer',
        'Kotlin Developer',
        'Swift Developer',
        'Java Developer',
        'C++ Developer',
        'Objective-C Developer',
        'Data Scientist',
        'Machine Learning Engineer',
        'Deep Learning Engineer',
        'Statistician',
        'Research Scientist',
        'Data Analyst',
        'Business Intelligence Analyst',
        'Data Visualization Specialist',
        'Cybersecurity Engineer',
        'Information Security Analyst',
        'Network Security Engineer',
        'Systems Security Engineer',
        'Security Architect',
        'Security Consultant',
        'Penetration Tester',
        'Ethical Hacker',
        'Incident Response Specialist',
        'Forensic Analyst',
        'Risk Manager',
        'Compliance Officer',
        'CEO',
        'CTO',
        'CFO',
        'COO',
        'CMO',
        'CRO',
        'CIO',
        'VP of Sales',
        'VP of Marketing',
        'VP of Operations',
        'VP of Finance',
        'VP of Human Resources',
        'Director of Sales',
        'Director of Marketing',
        'Director of Operations',
        'Director of Finance',
        'Director of Human Resources',
        'Manager of Sales',
        'Manager of Marketing',
        'Manager of Operations',
        'Manager of Finance',
        'Manager of Human Resources',
        'Senior Software Engineer',
        'Engineering Manager',
        'Staff Engineer',
        'Associate Product Manager',
        'Product Manager',
        'Senior Product Manager',
        'Associate Product Operations Manager',
        'Product Operations Manager',
        'Senior Product Operations Manager',
        'Junior Product Designer',
        'Product Designer',
        'Senior Product Designer',
    ]