import { RootState } from './index';
import { Idea } from 'utils/types';

interface IdeasReducerState {
    ideas: Idea[];
    loading: boolean;
}

export const ACTION_GET_IDEAS = 'getIdeas';
export const ACTION_SET_IDEAS = 'setIdeas';
export const ACTION_DELETE_IDEA = 'deleteIdea';
export const ACTION_ADD_IDEA = 'addIdea';
export const ACTION_EDIT_IDEA = 'editIdea';


const initialState: IdeasReducerState = {
    loading: false,
    ideas: [],
}

const ideas = (state: IdeasReducerState = initialState, action: {type: string, payload: any}): IdeasReducerState => {
    switch (action.type) {
    case ACTION_GET_IDEAS:
        return { ...state, ideas: [], loading: true }
    case ACTION_SET_IDEAS:
        return { ...state, loading: false, ideas: [...action.payload] }
    case ACTION_DELETE_IDEA:
        return { ...state, ideas: state.ideas.filter(idea => idea.id !== action.payload) }
    case ACTION_ADD_IDEA:
        return { ...state, ideas: [...state.ideas, action.payload] }
    case ACTION_EDIT_IDEA:
        const ideaIndex = state.ideas.findIndex(idea => idea.id === action.payload.id);
        if(ideaIndex >= 0) {
            state.ideas.splice(ideaIndex, 1, action.payload);
        }
        return { ...state, ideas: [...state.ideas] }

    default:
        return state
    }
}
export default ideas;

export const ideasSelector = (store: RootState) => store.ideas.ideas;
export const ideasLoadingSelector = (store: RootState) => store.ideas.loading;