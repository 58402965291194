import { RootState } from './index';
import { Workspace } from 'utils/types';

interface GlobalErrorReducerState {
   error: string;
   notFound: boolean;
}

export const ACTION_SET_GLOBAL_ERROR = 'setGlobalError';
export const ACTION_CLEAR_GLOBAL_ERROR = 'clearGlobalError';

export const ACTION_UPDATE_NOT_FOUND = 'setNotFound';

const initialState: GlobalErrorReducerState = {
    error: '',
    notFound: false,
}

const globalError = (state: GlobalErrorReducerState = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {
    case ACTION_SET_GLOBAL_ERROR:
        return { ...state, error: action.payload }
    case ACTION_CLEAR_GLOBAL_ERROR:
        return { ...state, error: '' }
    case ACTION_UPDATE_NOT_FOUND:
        return { ...state, notFound: action.payload }
    default:
        return state
    }
}
export default globalError;

export const globalErrorSelector = (store: RootState) => store.globalError.error;
export const notFoundSelector = (store: RootState) => store.globalError.notFound;