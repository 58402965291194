import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const initiativeGradingApi = createApi({
  reducerPath: 'initiativeGrading.api',
  baseQuery: rtkBaseQueryWithReauth,
  tagTypes: ['initiativeGrading'],
  endpoints: builder => ({
    getInitiativeScore: builder.query({
      query: ({ workspaceId, id }) => `/workspaces/${workspaceId}/initiatives/${id}/score/latest`,
    }),
    reEvaluateScore: builder.query({
      query: ({ workspaceId, id }) => `/workspaces/${workspaceId}/initiatives/${id}/score`,
    }),
    getAllIrrelevantPoints: builder.query({
      query: ({ workspaceId, id }) => `/workspaces/${workspaceId}/initiatives/${id}/insights/irrelevant`,
    }),
    markPointAsIrrelevant: builder.mutation({
      query: ({ workspaceId, id, insightId, pointId }) => {
        return {
          url: `/workspaces/${workspaceId}/initiatives/${id}/insights/${insightId}/points/${pointId}/irrelevant`,
          method: 'POST',
        }
      },
    }),
    deleteIrrelevantPoint: builder.mutation({
      query: ({ workspaceId, id, insightId, pointId, irrelevanceId }) => {
        return {
          url: `/workspaces/${workspaceId}/initiatives/${id}/insights/${insightId}/points/${pointId}/irrelevant-point/${irrelevanceId}`,
          method: 'DELETE',
        };
      },
    }),
    getInitiativeTracking: builder.query({
      query: ({ workspaceId, id }) => `/workspaces/${workspaceId}/initiatives/${id}/tracking`,
    }),
  })
});

export const {
  useGetAllIrrelevantPointsQuery,
  useGetInitiativeScoreQuery,
  useLazyGetAllIrrelevantPointsQuery,
  useLazyGetInitiativeScoreQuery,
  useMarkPointAsIrrelevantMutation,
  useDeleteIrrelevantPointMutation,
  useGetInitiativeTrackingQuery,
  useLazyReEvaluateScoreQuery,
  useLazyGetInitiativeTrackingQuery
} = initiativeGradingApi;