import { Button, Dialog, Flexbox, Input, Link, Loader } from 'components';
import styles from './jiraImportPopup.module.scss';
import classNames from 'classnames/bind';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { urlValidation } from 'utils/validations';
import { useDispatch, useSelector } from 'react-redux';
import { importJiraData } from 'common/Story/index.api';
import { workspaceIntegrationSelector } from 'store/integrations';
import { JiraMapping } from 'utils/types';
import { getWorkspaceIntegrations } from 'pages/Integrations/integrations.api';
import { Permissions, getUserPermissionName } from 'utils/permissions';
const classes = classNames.bind(styles);


interface JiraImportPopupProps{
    open: boolean;
    onClose: () => void;
    baseUrl: string;
    onImport: (data: any) => void;
    dialogTitle: string;
    requiredMappingField?: keyof JiraMapping;
}

const JiraImportPopup = ({ open, onClose, baseUrl, onImport, dialogTitle, requiredMappingField } : JiraImportPopupProps) => {
    const dispatch = useDispatch();
    const workspaceIntegrations = useSelector(workspaceIntegrationSelector);

    const [issueLink, setIssueLink] = useState('');
    const [issueLinkError, setIssueLinkError] = useState('');

    const [mappings, setMappings] = useState<JiraMapping[]>([]);

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch(getWorkspaceIntegrations())
    },[]);

    useEffect(() => {
        const jiraIntegration = workspaceIntegrations.find(integration => integration.name === 'JIRA');
        setMappings(jiraIntegration?.mappings || [])
    }, [workspaceIntegrations])

    useEffect(() => {
        if(open) {
            setIssueLink('')
        }
    }, [open])

    const checkRequiredMappingField = useMemo(() => {
        if(requiredMappingField){
            return mappings.some(mapping => mapping[`${requiredMappingField}`])
        }
        return true
    }, [mappings])

    const onIssueLinkChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIssueLink(e.target.value);
    }

    const onIssueCreate = async () => {
        if(urlValidation(issueLink)) {
            try {
                setIsLoading(true)
                const data = await dispatch(importJiraData(baseUrl, issueLink));
                onImport(data)
                setIssueLinkError('');
            } catch (e: any) {
                if(e.data.error){
                    setIssueLinkError(e.data.error)
                }
            }finally{
                setIsLoading(false)
            }
        } else {
            setIssueLinkError('Not valid url');
        }
    }

    return(
        <Dialog
            open={open}
            onClose={onClose}
            title={dialogTitle}
            aria-labelledby="form-dialog-title"
        >
            {checkRequiredMappingField &&
                <Flexbox className={classes('issueLinkContainer')}>
                    <Flexbox>
                        <Input className={classes('inputContainer')} placeholder='https://' fullWidth value={issueLink} onChange={onIssueLinkChange} errorText={issueLinkError} />
                    </Flexbox>
                    <Flexbox className={classes('issueLinkButton')}>
                        <Button variant='contained' onClick={onIssueCreate} disabled={isLoading}>
                            Link
                            {isLoading && <Loader className={classes('buttonLoader')} disableShrink size={18} />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            }

            {(!checkRequiredMappingField && getUserPermissionName() === Permissions.ROLE_ADMIN) &&
                <Flexbox>
                        Please navigate to the provided link to
                    <Link to={'/integrations/jiramapping'} className={classes('jiraMappingLink')}>
                        Map Prodmap with Jira Projects
                    </Link>
                </Flexbox>
            }

            {!checkRequiredMappingField && getUserPermissionName() !== Permissions.ROLE_ADMIN &&
                <Flexbox>
                    Kindly alert the administrator to initiate the mapping process.
                </Flexbox>
            }
        </Dialog>
    )
}

export default JiraImportPopup