import { Dispatch, GetState } from 'store';
import request from 'utils/request';
import { Preferences, PreferenceValues } from 'utils/types';


export const getPreferences = (type: string) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();

    try {
        const res = await request.get(`/workspaces/${storeState.user.workspace.id}/preferences?filter=${type}`);
        return res.data as Preferences<any>[];
    } catch(e) {
        return []
    }
}

export const updatePreferences = (filterValues: PreferenceValues<any>, type: string) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    await request.put(`/workspaces/${storeState.user.workspace.id}/preferences`, {
        objectKey: type,
        value: filterValues
    })
}