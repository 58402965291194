import { ConfirmationDialog, Flexbox, Select, TabPanel } from 'components';
import classNames from 'classnames/bind';
import styles from '../../../initiative.module.scss';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { okrsSelector } from 'store/okrs';
import { getOkrs } from 'pages/Okrs/okrs.api';
import { Contribution, InitiativeOKR, InitiativeStatus, OKR } from 'utils/types';
import SelectedOkr from './selectedOkr';
import { userSelector } from 'store/user';
import { CommentFieldEditor } from 'components/Comments/CommentField/CommentFieldEditor';
import { useWorkspaceId } from 'utils/hooks';
const classes = classNames.bind(styles);


interface OkrTargetTabProps {
    active: boolean;
    successDefinition: string;
    setSuccessDefinition: (value: string) => void;
    status: InitiativeStatus;
    initiativeReleaseDate: Date | null;
    isEditable?: boolean;
    ownerId?: number;
    initiativeId?: number;
    setOkrs: (param: ((okrs: InitiativeOKR[]) => InitiativeOKR[]) | InitiativeOKR[]) => void;
    okrs: InitiativeOKR[];
    filteredOkrs: OKR[];
    setFilteredOkrs: React.Dispatch<React.SetStateAction<OKR[]>>;
}

const OkrTargetTab = ({
    active,
    successDefinition,
    setSuccessDefinition,
    status,
    initiativeReleaseDate,
    isEditable = true,
    ownerId,
    initiativeId,
    setOkrs,
    okrs,
    filteredOkrs,
    setFilteredOkrs
}: OkrTargetTabProps) => {
    const okrsData = useSelector(okrsSelector);
    const currentUser = useSelector(userSelector);
    const workspaceId = useWorkspaceId();
    const dispatch = useDispatch();

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [itemToRemove, setItemToRemove] = useState<InitiativeOKR | null>(null);

    useEffect(() => {
        dispatch(getOkrs());
    }, [])

    useEffect(() => {
        if (okrsData.length) {
            const filterByDate = okrsData.filter(okr => {
                const okrEndDate = okr.endDate ? new Date(okr.endDate) : null

                if (okrEndDate) {
                    okrEndDate.setHours(okrEndDate.getHours() + 24);
                }

                if (!initiativeReleaseDate || !okrEndDate || initiativeReleaseDate <= okrEndDate) {
                    return okr
                }
            })
            setFilteredOkrs(filterByDate)
        }
    }, [okrsData, initiativeReleaseDate])


    const onSelectOkr = (e: SyntheticEvent<Element, Event>, value: OKR[], reason: string) => {
        if (reason === 'removeOption') {
            const removedItem = okrs.find(okr => !value.some(v => v.id === okr.okrId));
            if (removedItem) {
                setItemToRemove(removedItem);
                setOpenDeleteConfirmation(true);
            }
        } else {
            const newOkrs = value.map(okr => {
                const exsOkr = okrs.find(pOkr => pOkr.okrId === okr.id);

                if (exsOkr) {
                    return exsOkr;
                } else {
                    return {
                        okrId: okr.id,
                        contributions: okr.keyResults.map(kr => ({
                            keyResult: kr
                        }))
                    };
                }
            });

            setOkrs(newOkrs);
        }
    };

    const handleConfirmDelete = () => {
        if (itemToRemove) {
            setOkrs(okrs.filter((okr) => okr.okrId !== itemToRemove.okrId));
            setOpenDeleteConfirmation(false);
            setItemToRemove(null);
        }
    };

    const handleCancelDelete = () => {
        setOpenDeleteConfirmation(false);
        setItemToRemove(null);
    };

    const onImpactSelect = (value: string, krId: number, okrId: number) => {
        setOkrs((prevOkrs: InitiativeOKR[]) => {
            return prevOkrs.map(okr => {
                if (okr.okrId === okrId) {
                    let contributions: Contribution[] = okr.contributions;
                    const sourceOkr = okrsData.find((el) => el.id === okr.okrId);

                    if (sourceOkr) {
                        contributions = sourceOkr.keyResults.map((kr) => {
                            const contribution = okr.contributions.find(cont => cont.keyResult.id === kr.id)

                            return {
                                keyResult: kr,
                                impact: kr.id === krId ? value : contribution?.impact,
                                actualImpact: contribution?.actualImpact ?? '',
                            } as Contribution;
                        })

                    }

                    return {
                        ...okr,
                        contributions
                    }
                } else {
                    return okr
                }
            })
        })
    }

    const onActualImpactValue = (value: number, krId: number, okrId: number) => {
        setOkrs((prevOkrs: InitiativeOKR[]) => {
            return prevOkrs.map(okr => {
                if (okr.okrId === okrId) {
                    return {
                        ...okr,
                        contributions: okr.contributions.map((cont: Contribution) => {
                            if (cont.keyResult.id === krId) {
                                return {
                                    ...cont,
                                    actualImpact: value
                                }
                            } else {
                                return cont
                            }
                        })
                    }
                } else {
                    return okr
                }
            })
        })
    }

    const onSuccessDefinitionChange = (value: string) => {
        setSuccessDefinition(value)
    }

    const loadKrs = (okr: InitiativeOKR) => {
        let contributions: Contribution[] = []
        const sourceOkr = okrsData.find((el) => el.id === okr.okrId);

        if (sourceOkr) {
            contributions = sourceOkr.keyResults.map((kr) => {
                const contribution = okr.contributions.find((cont) => cont.keyResult.id === kr.id);

                const dynamicProps = contribution
                    ? {
                        impact: contribution.impact ?? '',
                        actualImpact: contribution.actualImpact ?? '',
                    }
                    : {};

                return {
                    ...dynamicProps,
                    keyResult: kr,
                } as Contribution;
            })
        }

        return contributions
    }

    return (
        <TabPanel active={active} vertical>
            <Flexbox vertical fullWidth className={classes('tabContainer')}>
                <Flexbox fullWidth className={classes('tabColSpacing', 'tabRow', 'mb24')}>
                    <CommentFieldEditor
                        value={successDefinition}
                        onChange={onSuccessDefinitionChange}
                        placeholder='Success definition'
                        label='Success definition'
                        readOnly={!isEditable}
                        files={[]}
                        user={currentUser}
                        ownerId={ownerId}
                        commentsUrl={`initiatives/${initiativeId}/successDefinition/comments/`}
                        initiativeId={initiativeId || 0}
                        objectKey="initiatives"
                        objectId={initiativeId || 0}
                        fieldKey="impact.successDefinition"
                        workspaceId={workspaceId}
                    // dataForAiGenerate={{
                    //     name: 'generate_prd_component',
                    //     initiativeId: initiativeId,
                    //     component: 'validation',
                    // }}
                    // hasAiDialog
                    // ref={validationFieldRef}
                    />
                </Flexbox>
                <Flexbox className={classes('okrSelectContainer')}>
                    <Flexbox fullWidth className={classes('tabColSpacing')}>
                        <Select
                            onChange={onSelectOkr}
                            value={okrsData.filter(okr => okrs.some(el => el.okrId === okr.id))}
                            options={filteredOkrs}
                            multiple
                            placeholder='Select OKR'
                            getOptionLabel={option => option.objective || ''}
                            label='Affected OKR (Filtered by live date)'
                            disabled={!isEditable}
                            clearIcon={false}
                        />
                    </Flexbox>
                </Flexbox>
            </Flexbox>
            <Flexbox className={classes('selectedOkrsContainer')} fullWidth vertical>
                {
                    okrs.map((okr, index) => (
                        <SelectedOkr
                            key={index}
                            okrId={okr.okrId}
                            contributions={loadKrs(okr)}
                            onImpactSelect={onImpactSelect}
                            onActualImpactValue={onActualImpactValue}
                            status={status}
                            isEditable={isEditable}
                            objective={okrsData.find(fOkr => fOkr.id === okr.okrId)?.objective}
                        />
                    ))
                }
            </Flexbox>
            <ConfirmationDialog
                open={openDeleteConfirmation}
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                confirmButtonStyle='danger'
                title='Remove this OKR?'
            >
                <Flexbox>
                    Are you sure you want to remove this OKR from this section?
                </Flexbox>
            </ConfirmationDialog>
        </TabPanel>
    )
}

export default OkrTargetTab