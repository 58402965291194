const Flag = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_701_973)">
                <path d="M3.125 16.8755V3.75049" stroke="#FF9429" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.12494 13.1253C8.12494 9.37526 11.8749 16.8753 16.8749 13.1253V3.75026C11.8749 7.50026 8.12494 0.000256345 3.12494 3.75026" stroke="#FF9429" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_701_973">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Flag