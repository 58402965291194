import React, { forwardRef } from 'react';
import TimeAgo, { TimeAgoProps } from 'timeago-react';
import * as timeago from 'timeago.js';


const enShort =  (number: number, index: number): [string, string] => {
    return [
        ['just now', 'right now'],
        ['%ss', 'in %ss'],
        ['1m', 'in 1m'],
        ['%sm', 'in %sm'],
        ['1h', 'in 1h'],
        ['%sh', 'in %sh'],
        ['1d', 'in 1d'],
        ['%sd', 'in %sd'],
        ['1w', 'in 1w'],
        ['%sw', 'in %sw'],
        ['1mo', 'in 1mo'],
        ['%smo', 'in %smo'],
        ['1yr', 'in 1yr'],
        ['%syr', 'in %syr'],
    ][index] as [string, string];
}

timeago.register('en-short', enShort);

export default forwardRef<HTMLTimeElement, TimeAgoProps>(({ ...props }, ref: any) => {

    return(
        <TimeAgo
            ref={ref}
            {...props}
        />
    )
})

