const PriorityCriticalIcon = () => {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7671 8.62809C17.409 9.06357 16.7664 9.12558 16.3319 8.7666L7.67812 1.61661L8.97514 0.0395865L17.6289 7.18957C18.0634 7.54856 18.1253 8.1926 17.7671 8.62809Z" fill="#FF5050"/>
            <path d="M0.238552 8.62557C-0.123389 8.19324 -0.0671255 7.54868 0.36422 7.18591L8.90847 0L10.2192 1.56562L1.67493 8.75153C1.24358 9.1143 0.600494 9.05791 0.238552 8.62557Z" fill="#FF5050"/>
            <path d="M17.7671 15.6281C17.409 16.0636 16.7664 16.1256 16.3319 15.7666L7.67812 8.61661L8.97514 7.03959L17.6289 14.1896C18.0634 14.5486 18.1253 15.1926 17.7671 15.6281Z" fill="#FE7E7E"/>
            <path d="M0.238552 15.6256C-0.123389 15.1932 -0.0671255 14.5487 0.36422 14.1859L8.90847 7L10.2192 8.56562L1.67493 15.7515C1.24358 16.1143 0.600494 16.0579 0.238552 15.6256Z" fill="#FE7E7E"/>
        </svg>

    )
}

export default PriorityCriticalIcon;