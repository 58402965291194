import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const teamsApi = createApi({
    reducerPath: 'teams.api',
    baseQuery: rtkBaseQueryWithReauth,
    tagTypes: ['teams'],
    endpoints: builder => ({
        getTeams: builder.query({
            query: ({ workspaceId }) => `/workspaces/${workspaceId}/teams`,
        }),
        createNewTeam: builder.mutation({
            query: ({ workspaceId, data }) => {
                return {
                    url: `/workspaces/${workspaceId}/teams`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
        editTeam: builder.mutation({
            query: ({ workspaceId, teamId, data }) => {
                return {
                    url: `/workspaces/${workspaceId}/teams/${teamId}`,
                    method: 'PUT',
                    body: data
                };
            },
        }),
        deleteTeam: builder.mutation({
            query: ({ workspaceId, teamId }) => {
                return {
                    url: `/workspaces/${workspaceId}/teams/${teamId}`,
                    method: 'DELETE',
                };
            },
        }),
        getTeamGroups: builder.query({
            query: ({ workspaceId }) => `/workspaces/${workspaceId}/team-groups`,
        }),
    })
});

export const {
    useCreateNewTeamMutation,
    useDeleteTeamMutation,
    useEditTeamMutation,
    useGetTeamsQuery,
    useLazyGetTeamsQuery,
    useGetTeamGroupsQuery,
    useLazyGetTeamGroupsQuery
} = teamsApi;