import { Loader, Flexbox } from 'components';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './dependency.module.scss';
import classNames from 'classnames/bind';
import { getInitiatives } from 'pages/Initiatives/initiatives.api';
import { userSelector } from 'store/user';
import { useLazyGetDependencyQuery } from 'store/dependency-api';
import { useWorkspaceId } from 'utils/hooks';
import DependencyHead from './components/DependencyHead';
import DependencyLeftPanel from './components/DependencyLeftPanel';
import { dependencyTeamSelector } from 'store/dependency-slice';
import DependencyRightPanel from './components/DependencyRightPanel';
import { useGetTeamsQuery } from 'store/teams-api';
import { teamsSelector } from 'store/teams-slice';
const classes = classNames.bind(styles);

const DependencyView = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();

    const dependencyId = params['id'] ? parseInt(params['id']) : undefined;

    const [getDependency, { data: dependency, isLoading }] = useLazyGetDependencyQuery()
    useGetTeamsQuery({ workspaceId });

    const currentUser = useSelector(userSelector);
    const teamId = useSelector(dependencyTeamSelector);
    const teams = useSelector(teamsSelector);

    useEffect(() => {
        if(dependencyId){
            getDependency({ workspaceId, dependencyId })
        }
    },[dependencyId])

    useEffect(() => {
        dispatch(getInitiatives());
    }, [])

    const assigned = useMemo(() => {
        return teams.find(team => team.id === teamId)?.lead?.id === currentUser.id
    }, [teams, currentUser, teamId])

    return(
        isLoading ? (<Flexbox fullWidth align justify><Loader disableShrink/></Flexbox>) : (
            <Flexbox vertical fullWidth>
                {
                    dependencyId && dependency && (
                        <>
                            <DependencyHead assigned={assigned} dependencyId={dependencyId} />
                            <Flexbox className={classes('container')}>
                                <DependencyLeftPanel dependencyId={dependencyId} />
                                <DependencyRightPanel dependencyId={dependencyId} assigned={assigned} />
                            </Flexbox>
                        </>
                    )
                }
            </Flexbox>
        )
    )
}

export default DependencyView