import { Dialog, Flexbox, Input, Select, Switch } from 'components'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { teamsSelector } from 'store/teams-slice';
import { OKR, ReportCreateModel, ReportVariantTypes, Team } from 'utils/types'
import styles from './createReportDialog.module.scss';
import classNames from 'classnames/bind';
import { userSelector } from 'store/user'
import { getOkrs } from 'pages/Okrs/okrs.api'
import { okrsSelector } from 'store/okrs'
import { getCurrentYearEndDate, getCurrentYearStartDate, getQuarterEndDate, getQuarterStartDate } from 'utils/date'
import { useWorkspaceId } from 'utils/hooks';
import { useLazyGetTeamsQuery } from 'store/teams-api';
const classes = classNames.bind(styles);

interface TimelineOption {
    id: number;
    label: string;
}
interface CreateReportDialogProps {
    open: boolean,
    onClose: () => void,
    onConfirm: (reportSaveData: ReportCreateModel) => void,
    reportType: ReportVariantTypes,
    createLoading: boolean
}

const defaultError = {
    titleError: '',
    teamError: '',
    okrError: ''
}

const timelineOptions = [
    { id: 0, label: 'This quarter' },
    { id: 1, label: 'This year' },
];

const CreateReportDialog = ({ open, onClose, onConfirm, reportType, createLoading }: CreateReportDialogProps) => {
    const dispatch = useDispatch()
    const workspaceId = useWorkspaceId()

    const [getTeams] = useLazyGetTeamsQuery()

    const okrsList = useSelector(okrsSelector);
    const teamsList = useSelector(teamsSelector);
    const currentUser = useSelector(userSelector);

    const [title, setTitle] = useState('')
    const [teams, setTeams] = useState<Team[]>([])

    const [error, setError] = useState(defaultError)

    const [okrs, setOkrs] = useState<OKR[]>([]);
    const [selectedOkrs, setSelectedOkrs] = useState<OKR[]>([]);
    const [isChildOkrIncluded, setIsChildOkrIncluded] = useState(false);
    const [selectedTimeline, setSelectedTimeline] = useState<TimelineOption>(timelineOptions[0]);

    useEffect(() => {
        if(reportType === ReportVariantTypes['By Team']){
            getTeams({ workspaceId });
        }
        if(reportType === ReportVariantTypes['By Okr']){
            dispatch(getOkrs());
        }
    }, []);

    const isOkrWithinTimeline = (startDate: Date | null, endDate: Date | null, startTimeLine: Date, endTimeLine: Date) =>
        (startDate && startDate >= startTimeLine && startDate <= endTimeLine) ||
    (endDate && endDate >= startTimeLine && endDate <= endTimeLine) ||
    (startDate && startDate <= startTimeLine && endDate && endDate >= endTimeLine);

    useEffect(() => {
        if(reportType === ReportVariantTypes['By Okr'] && okrsList.length){
            const filterByDate = okrsList.filter(okr => {
                const { startDate, endDate } = okr;
                const { label } = selectedTimeline;

                const startDateTimeLine = label ===  timelineOptions[0].label ? getQuarterStartDate(new Date()) : getCurrentYearStartDate();
                const endDateTimeLine = label === timelineOptions[0].label ? getQuarterEndDate(new Date()) : getCurrentYearEndDate();

                return isOkrWithinTimeline(startDate ? new Date(startDate) : null, endDate ? new Date(endDate) : null, startDateTimeLine, endDateTimeLine);

            })
            setOkrs(filterByDate)
        }

    }, [okrsList, selectedTimeline])

    const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)

        if(error.titleError){
            setError({ ...error, titleError: '' })
        }
    }

    const onTeamChange = (_e: ChangeEvent<{}>, value: Team[]) => {
        setTeams(value)

        if(error.teamError){
            setError({ ...error, teamError: '' })
        }
    }

    const onTimelineChange = (_e: ChangeEvent<{}>, value: TimelineOption) => {
        setSelectedTimeline(value)
    }

    const onOkrChange = (_e: ChangeEvent<{}>, value: OKR[]) => {
        setSelectedOkrs(value)

        if(error.okrError){
            setError({ ...error, okrError: '' })
        }
    }

    const onChildOkrChange = () => {
        setIsChildOkrIncluded(!isChildOkrIncluded)
    }

    const validateAndGenerate = (requiredField: string, dataArray: Team[] | OKR[], errorField: string) => {
        if (title && dataArray.length) {
            const data = { title, [requiredField]: dataArray.map((d) => d.id) };
            if (reportType === ReportVariantTypes['By Okr']) {
                onConfirm({ ...data, isChildOkrIncluded });
            } else {
                onConfirm(data);
            }
        } else {
            setError({
                ...error,
                [errorField]: !dataArray.length ? 'This field is required' : '',
                titleError: !title ? 'This field is required' : '',
            });
        }
    };

    const confirm = () => {
        switch (reportType) {
        case ReportVariantTypes['By Team']:
            validateAndGenerate('teams', teams, 'teamError');
            break;
        case ReportVariantTypes['By Okr']:
            validateAndGenerate('okrs', selectedOkrs, 'okrError');
            break;
        default:
            break;
        }
    }

    return(
        <Dialog
            open={open}
            onClose={onClose}
            title='Create new report'
            confirmButton
            confirmButtonLoading={createLoading}
            confirmButtonLabel='Generate'
            onConfirm={confirm}
            cancelButton
        >
            <Flexbox vertical justify className={classes('dialogContainer')}>
                <Flexbox className={classes('dialogTwoCol')}>
                    <Input
                        value={title}
                        onChange={onTitleChange}
                        placeholder='Type title'
                        label='Title'
                        fullWidth
                        alwaysEditable
                        required
                        errorText={error.titleError}
                    />
                    <Select
                        value={currentUser.fullName}
                        options={[]}
                        label='Owner'
                        disabled
                    />
                </Flexbox>
                {reportType === ReportVariantTypes['By Okr'] &&
                        <>
                            <Flexbox className={classes('dialogOneCol')}>
                                <Select
                                    onChange={onTimelineChange}
                                    options={timelineOptions}
                                    value={selectedTimeline}
                                    getOptionLabel={option => option.label}
                                    label='Timeline'
                                    placeholder='Timeline'
                                    disableClearable
                                    required
                                />
                            </Flexbox>
                            <Flexbox className={classes('dialogOneCol')}>
                                <Select
                                    onChange={onOkrChange}
                                    options={okrs}
                                    value={selectedOkrs}
                                    getOptionLabel={option => option.objective || 'Untitled OKR'}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    label='OKRs'
                                    placeholder='OKRs'
                                    multiple
                                    disableClearable
                                    required
                                    errorText={error.okrError}
                                />
                                <Flexbox className={classes('switchContainer')}>
                                    <Switch
                                        size='small'
                                        label='Include child OKRs'
                                        checked={isChildOkrIncluded}
                                        onChange={onChildOkrChange}
                                    />
                                </Flexbox>
                            </Flexbox>
                        </>
                }
                {reportType === ReportVariantTypes['By Team'] &&
                        <Flexbox className={classes('dialogOneCol')}>
                            <Select
                                onChange={onTeamChange}
                                options={teamsList}
                                value={teams}
                                getOptionLabel={option => option.name}
                                label='Teams'
                                placeholder='Teams'
                                multiple
                                disableClearable
                                required
                                errorText={error.teamError}
                            />
                        </Flexbox>
                }
            </Flexbox>
        </Dialog>
    )
}

export default CreateReportDialog