import { ClickAwayListener, Flexbox, IconButton, Link, RichTextEditor, Select } from 'components';
import styles from '../../dependency.module.scss';
import classNames from 'classnames/bind';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dependencyNotesSelector, dependencyResolvingInitiativeSelector, editDependency } from 'store/dependency-slice';
import { EditIcon } from 'components/icons';
import { Initiative, InitiativePriority, InitiativeSize, InitiativeStatus, InitiativeType } from 'utils/types';
import { addMonths, addWeeks } from 'utils/date';
import { initiativesSelector } from 'store/initiatives';
import { userSelector } from 'store/user';
import { createFilterOptions } from 'components/Select';
import StatusActionButtons from './StatusActionButtons';
const classes = classNames.bind(styles);

export interface InitiativeWithExtraValue extends Omit<Initiative, 'lastModifiedDate' | 'statusLastModifiedDate'> {
    extraValue?: string;
}

const filter = createFilterOptions<InitiativeWithExtraValue>();

export const defaultError = {
    noteError: '',
    initiativeError: '',
}

export type ErrorType = {
    noteError: string,
    initiativeError: string,
}

interface DependencyRightPanelProps {
    assigned: boolean,
    dependencyId: number
}

const DependencyRightPanel: FC<DependencyRightPanelProps> = ({ assigned, dependencyId }) => {
    const dispatch = useDispatch();

    const notes = useSelector(dependencyNotesSelector);
    const allInitiatives: InitiativeWithExtraValue[] = useSelector(initiativesSelector);
    const currentUser = useSelector(userSelector);
    const resolvingInitiativeId = useSelector(dependencyResolvingInitiativeSelector)?.id

    const [selectedInitiative, setSelectedInitiative] = useState<InitiativeWithExtraValue>();
    const [initiativeMode, setInitiativeMode] = useState(false);
    const [error, setError] = useState<ErrorType>(defaultError);

    useEffect(() => {
        const selectedInitiative = allInitiatives.find(i => i.id === resolvingInitiativeId)
        setSelectedInitiative(selectedInitiative)
    }, [resolvingInitiativeId, allInitiatives.length])

    const onInitiativeChange = (_e: ChangeEvent<{}>, value: InitiativeWithExtraValue | null) => {
        if(value && value.extraValue){
            const newData = { ...value, title : value.extraValue }
            setSelectedInitiative(newData)
        } else if(value){
            setSelectedInitiative(value)
        } else{
            setSelectedInitiative(undefined)
        }

        setInitiativeMode(!initiativeMode)

        if(error.initiativeError){
            setError({ ...error, initiativeError: '' })
        }
    }

    const editInitiativeLink = () => {
        setInitiativeMode(!initiativeMode)
    }

    const clickAwayResolvingInitiative = () => {
        setInitiativeMode(false);
    }

    const onNotesChange = (value: string) => {
        dispatch(editDependency({ notes: value }));

        if(error.noteError){
            setError({ ...error, noteError: '' })
        }
    }

    return (
        <Flexbox vertical className={classes('colRight')}>
            <Flexbox vertical className={classes('fieldContainer')}>
                {initiativeMode ?
                    <ClickAwayListener onClickAway={clickAwayResolvingInitiative}>
                        <Flexbox>
                            <Select
                                onChange={onInitiativeChange}
                                options={allInitiatives}
                                value={selectedInitiative}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    const { inputValue } = params;

                                    const isExisting = options.some((option) => inputValue === option.title);
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            id: -1,
                                            problem: '',
                                            title: `Create "${inputValue}"`,
                                            priority: InitiativePriority.Medium,
                                            size: InitiativeSize.M,
                                            type: InitiativeType.Improvement,
                                            status: InitiativeStatus.Backlog,
                                            validation: '',
                                            solution: '',
                                            okrs: [],
                                            startDate: new Date().getTime(),
                                            endDate: addMonths().getTime(),
                                            releaseDate: addWeeks(1, addMonths()).getTime(),
                                            owner: currentUser,
                                            affectedProducts: [],
                                            products: [],
                                            teams: [],
                                            blocks: [],
                                            stories: [],
                                            extraValue: inputValue
                                        });
                                    }
                                    return filtered;
                                }}
                                getOptionLabel={(option) =>  option.title}
                                label='Resolving Initiative'
                                placeholder='Resolving Initiative'
                                open={initiativeMode}
                                disabled={!assigned}
                                errorText={error.initiativeError}
                            />
                        </Flexbox>
                    </ClickAwayListener>
                    :
                    <>
                        <Flexbox className={classes('fieldLabel')}>
                            Resolving Initiative
                        </Flexbox>
                        <Flexbox className={classes('fieldTitle', 'fieldInitiativeLink', { errorField: error.initiativeError })}>
                            {(selectedInitiative?.id && selectedInitiative?.id !== -1) ?
                                <Link to={`/initiatives/initiative/${selectedInitiative.id}`}>
                                    {selectedInitiative.title}
                                </Link>
                                :
                                <Flexbox>
                                    {selectedInitiative?.title}
                                </Flexbox>
                            }
                            {assigned &&
                                <IconButton onClick={editInitiativeLink}>
                                    <EditIcon />
                                </IconButton>
                            }
                        </Flexbox>
                        {error.initiativeError &&
                            <Flexbox className={classes('errorText')}>
                                {error.initiativeError}
                            </Flexbox>
                        }
                    </>
                }
            </Flexbox>
            <Flexbox vertical className={classes('fieldContainer')}>
                <RichTextEditor
                    value={notes}
                    onChange={onNotesChange}
                    placeholder='Notes'
                    label='Notes'
                    disabled={!assigned}
                    errorText={error.noteError}
                    files={[]}
                />
            </Flexbox>
            {assigned && (
                <StatusActionButtons
                    dependencyId={dependencyId}
                    error={error}
                    selectedInitiative={selectedInitiative}
                    setError={setError}
                    setSelectedInitiative={setSelectedInitiative}
                />
            )}
        </Flexbox>
    )
}

export default DependencyRightPanel;