import classNames from 'classnames/bind';
import { FC, PropsWithChildren } from 'react';
import { CommentsSidebarHeader } from '../CommentsSidebarHeader/CommentsSidebarHeader';
import styles from './commentsSidebar.module.scss';
const classes = classNames.bind(styles);

interface CommentsSidebarProps {
    onClose?: () => void;
}

export const CommentsSidebar: FC<PropsWithChildren<CommentsSidebarProps>> = ({
    children,
    onClose,
}) => {
    return (
        <div className={classes('comments-sidebar')}>
            <CommentsSidebarHeader onClose={onClose} />
            <div className={classes('content-container')}>{children}</div>
        </div>
    );
};
