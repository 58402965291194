import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeOptions, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from 'store';
import { documentDefaultTitle } from 'utils/constants';

if (process.env.REACT_APP_IS_DEV === 'true') {
    document.title = documentDefaultTitle;
    let link: HTMLLinkElement | null = document.querySelector('link[rel~=\'icon\']');
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
    }
    link.href = '/logo_dev.ico';
}


export const themeOptions: ThemeOptions = {
    typography: {
        button: {
            textTransform: undefined,
        },
        fontFamily: [
            'DM Sans',
            'Quicksand',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif',
        ].join(',')
    },
    palette: {
        primary: {
            main: '#025B62',
            contrastText: '#fff',
        },
        secondary: {
            main: '#f50057',

        },
        tonalOffset: 0.1
    },

};

const theme = createTheme(themeOptions);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

/*
  Note: React.StrictMode is intentionally removed due to its impact on the behavior of useEffect in React 18.

  1. **API Calls Behavior:**
     In React 18, useEffect might behave differently within StrictMode, causing API calls to be invoked twice.
     By removing StrictMode, we avoid this behavior change and ensure consistent API call behavior.

  2. **CSS Loading:**
     In some cases, CSS styles may load later when StrictMode is enabled.
     Removing StrictMode helps to ensure that styles are loaded in a more predictable manner.

  Please be aware that StrictMode provides valuable development warnings.
  If any issues arise, consider re-enabling StrictMode during development to catch potential problems early.

  Remember to test thoroughly after making this change to address any issues that might arise without StrictMode.
*/

root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </StyledEngineProvider>
        </BrowserRouter>
    </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
