import { ChangeEvent, useEffect, useState } from 'react';
import { Dialog, Flexbox, Select } from 'components'
import { User } from 'utils/types';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import styles from './addMembers.module.scss';
import { usersSelector } from 'store/users-slice';
const classes = classNames.bind(styles);




interface AddMembersProps {
    open: boolean;
    onClose: () => void;
    onAdd: (users: User[]) => void;
    existingUsers: number[];
}

export default ({ open, onClose, onAdd, existingUsers }: AddMembersProps) => {

    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const users: User[] = useSelector(usersSelector);
    const onConfirm = () => {
        onAdd(selectedUsers)
    }

    useEffect(() => {
        if(open) {
            setSelectedUsers([])
        }
    }, [open])

    const onSelectedUserChange = (e: ChangeEvent<{}>, value: User[]) => {
        setSelectedUsers(value);

    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title='Add team members'
            confirmButton
            confirmButtonLabel='Add'
            onConfirm={onConfirm}

        >
            <Flexbox className={classes('addMembersContainer')} align>
                <Flexbox className={classes('title')}>Users: </Flexbox>
                <Select
                    openOnFocus
                    options={users.filter(user => !existingUsers.includes(user.id))}
                    value={selectedUsers}
                    multiple
                    onChange={onSelectedUserChange}
                    getOptionLabel={option => option.fullName || ''}
                />
            </Flexbox>

        </Dialog>
    )
}