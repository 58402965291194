import request from 'utils/request';
import { Dispatch, GetState } from 'store';


export const getChangeLog = (initiativeId: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    const data = await request.get(`/workspaces/${storeState.user.workspace.id}/initiatives/${initiativeId}/change-log`, {
        timeout: 1000 * 60 * 2, // 2 mins
    });
    return data.data;
}