import { FilledInput, FilledInputProps, InputAdornment } from '@mui/material';

import classNames from 'classnames/bind';
import { CloseIcon, SearchIcon } from 'components/icons';
import styles from './searchField.module.scss';
const classes = classNames.bind(styles);

interface SearchFieldProps extends FilledInputProps {
    onClear?: () => void;
    className?: string;
}

const SearchField = ({ value, onClear, className, ...props }: SearchFieldProps) => {

    return (
        <FilledInput
            className={`searchFieldContainer ${className || ''}`}
            startAdornment={(<InputAdornment position="start"><SearchIcon className={classes('searchIcon')} /></InputAdornment>)}
            endAdornment={value && onClear ? (<InputAdornment position="end" className={classes('adornedEnd')}><CloseIcon onClick={onClear}/></InputAdornment>) : null}
            classes={{
                root: classes('searchField', { fullWidth: props.fullWidth }),
                input: classes('input'),
                focused: classes('focused'),
            }}
            value={value}
            {...props}
        />
    )
}

export default SearchField;
