import { Close } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { IconButton, SearchField, Stack } from 'components';
import { FC } from 'react';
import { useCommentFilter, useCommentSearch } from 'store/comments-slice';
import CommentFilter from '../CommentFilter';
import styles from './commentsSidebarHeader.module.scss';
const classes = classNames.bind(styles);

interface CommentsSidebarHeaderProps {
    onClose?: () => void;
}

export const CommentsSidebarHeader: FC<CommentsSidebarHeaderProps> = ({ onClose }) => {
    const [filter, setFilter] = useCommentFilter();
    const [searchValue, onSearchChange] = useCommentSearch();
    return (
        <Stack className={classes('comments-sidebar-header')} spacing={1} padding={2}>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                    <h3 className={classes('heading')}>Comments</h3>
                    <CommentFilter filter={filter} onChange={setFilter} />
                </Stack>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </Stack>
            <SearchField
                fullWidth
                placeholder="Search comments"
                value={searchValue}
                onChange={onSearchChange}
            />
        </Stack>
    );
};
