import { Editor } from 'tinymce';
import { CommentExtended, CommentFieldKey } from './types';
import {
    ContentState,
    EditorState,
    convertFromRaw,
    genKey,
    RawDraftContentState,
    convertToRaw,
} from 'draft-js';
import { MentionData } from '@draft-js-plugins/mention';
import {
    COMMENT_EDITOR_FIELDS,
    COMMENT_FIELD_KEY_TO_TITLE_MAP,
    INITIATIVE_TAB_TO_TITLE_MAP,
} from './constants';

export const scrollToToolbar = (thread: CommentExtended) => {
    const toolbar = document.querySelector(`[data-comment-toolbar-id="${thread.id}"]`);
    if (toolbar) {
        toolbar.scrollIntoView({ behavior: 'smooth' });
    }
};

export const scrollToThreadElement = (thread: CommentExtended) => {
    const comment = document.querySelector(`[data-thread-id="${thread.id}"]`);
    if (comment) {
        comment.scrollIntoView({ behavior: 'smooth' });
    }
};

export const createCustomEvent = <T extends keyof GlobalEventHandlersEventMap>(
    type: T,
    eventInitDict: CustomEventInit<
        GlobalEventHandlersEventMap[T] extends CustomEvent<infer T> ? T : never
    >
) => new CustomEvent(type, eventInitDict);

export const isEditorField = (fieldKey: CommentFieldKey) => COMMENT_EDITOR_FIELDS.has(fieldKey);

export const highlightNewCommentSegment = (editor: Editor, temporaryId: string) => {
    const openingTag = `<span data-id="${temporaryId}">`;
    const closingTag = '</span>';

    const range = editor.selection.getRng();
    const blocks = editor.selection.getSelectedBlocks();

    if (blocks.length > 1 || blocks[0].nodeName === 'TD' || blocks[0].nodeName === 'TH') {
        for (let i = 0; i < blocks.length; i++) {
            const block = blocks[i];
            if (block.nodeName === 'UL' || block.nodeName === 'OL') {
                continue;
            }
            const text = block.innerHTML;

            if (i === 0) {
                let offset = range.startOffset;
                let element: any = range.startContainer.previousSibling;

                while (element && !(element.nodeName === 'TD' && element.nodeName === 'TH')) {
                    if (element.outerHTML) {
                        offset += element.outerHTML.length;
                    } else {
                        offset += element.textContent.length;
                    }
                    element = element.previousSibling;
                }

                block.innerHTML =
                    text.slice(0, offset) + openingTag + text.slice(offset) + closingTag;
            } else if (i === blocks.length - 1) {
                const offset = range.endOffset;
                block.innerHTML =
                    openingTag + text.slice(0, offset) + closingTag + text.slice(offset);
            } else {
                block.innerHTML = openingTag + text + closingTag;
            }
        }
        const sel = editor.selection.dom.createRng();
        const body = editor.getDoc();
        const query = `[data-id="${temporaryId}"]`;

        const spans = body.querySelectorAll(query);
        sel.setEndBefore(spans[0]);
        sel.setEndAfter(spans[spans.length - 1]);
    } else {
        const content = editor.selection.getContent({ format: 'html' });

        const wrappedContent = openingTag + content + closingTag;
        editor.selection.setContent(wrappedContent);

        const newSelection = new Range();
        const body = editor.getDoc();
        const query = `span[data-id="${temporaryId}"]`;

        const span = body.querySelector(query);
        if (span) {
            newSelection.selectNode(span);
            editor.selection.setRng(newSelection);
        }
    }
};

export const removeNewCommentSegment = (editor: Editor, id: CommentExtended['id'] | string) => {
    const document = editor.getDoc();
    const query = `span[data-id="${id}"]`;
    const spans = document.querySelectorAll(query);
    spans.forEach(span => {
        const content = span.innerHTML;
        span.outerHTML = content;
    });
};

export const highLightCommentSegment = (editor: Editor, id: CommentExtended['id'] | string, remove?: boolean) => {
    const document = editor.getDoc();
    const comment = document.querySelector(`[data-id="${id}"]`) as HTMLElement;

    if (comment) {
        if (remove) {
            comment.classList.remove('currentComment');
        } else {
            comment.classList.add('currentComment');
            comment.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }
};

export const highLightEditor = (editor: Editor, fieldKey: CommentFieldKey, comment: CommentExtended, objectId?: number) => {
    const document = editor.getDoc();

    const commentSpan = document?.querySelector(`[data-id="${comment.id}"]`) as HTMLElement;
    const body = document?.querySelector('body')

    if (!commentSpan && body && ((fieldKey === 'description' && comment.objectId === objectId) || (fieldKey !== 'description' && comment.fieldKey === fieldKey))) {
        body.scrollIntoView({ behavior: 'smooth', block: 'center' });
        body.style.transition = 'background-color 0.5s ease-in-out'
        body.style.backgroundColor = '#ff942921'
        setTimeout(() => body.style.backgroundColor = 'transparent', 1000)
    }
};

export const confirmNewCommentSegment = (
    editor: Editor,
    temporaryId: string,
    newCommentId: CommentExtended['id']
) => {
    const document = editor.getDoc();
    const query = `span[data-id="${temporaryId}"]`;
    const elements = document.querySelectorAll(query);
    elements.forEach(element => {
        element.setAttribute('data-id', String(newCommentId));
        element.classList.add('highLight')
    });
    return elements.length > 0;
};

export const resolveCommentSegment = (
    editor: Editor,
    id: CommentExtended['id'],
    resolved: boolean
) => {
    const document = editor.getDoc();
    const query = `span[data-id="${id}"]`;
    const elements = document.querySelectorAll(query);
    elements.forEach(element => {
        if (resolved) {
            element.classList.remove('highLight');
        } else {
            element.classList.add('highLight');
        }
    });
};

export const getEditorStateFromMessage = (message: string) => {
    const regex = /@\[(.*?)\]\((\d+)\)/g;
    let matchIndex = 0;
    const entityMap: RawDraftContentState['entityMap'] = {};
    let blocks: RawDraftContentState['blocks'] = [];

    let messageBlocks = message.split('\n');
    blocks = messageBlocks.map(block => {
        let match,
            matches = [];
        while ((match = regex.exec(block)) !== null) {
            matches.push(match);
        }

        const keyedMatches: [number, RegExpExecArray][] = [];

        matches.forEach(match => {
            const [fullMatch, name, userId] = match;
            block = block.replace(fullMatch, name);
            const id = matchIndex++;
            keyedMatches.push([id, match]);
            entityMap[String(id)] = {
                type: 'mention',
                mutability: 'IMMUTABLE',
                data: {
                    mention: {
                        id: userId,
                        name,
                    },
                },
            };
        });

        let replaceOffset = 0; // track the offset difference caused by replacing mentions

        return {
            key: genKey(),
            text: block,
            type: 'unstyled',
            inlineStyleRanges: [],
            depth: 0,
            entityRanges: keyedMatches.map(([key, match]) => {
                const [original, name] = match;
                const offset = match.index - replaceOffset; // adjust the offset based on previous insertions
                replaceOffset += original.length - name.length; // update the offset difference
                return {
                    offset,
                    length: name.length,
                    key,
                };
            }),
        };
    });

    const contentState = convertFromRaw({
        blocks,
        entityMap,
    });

    const editorState = EditorState.createWithContent(contentState);
    return editorState;
};

export const getMessageFromContentState = (contentState: ContentState) => {
    const rawContentState = convertToRaw(contentState);
    const { blocks, entityMap } = rawContentState;
    let message = '';
    blocks.forEach((block, index) => {
        let blockText = block.text;
        let offsetDiff = 0; // track the offset difference caused by inserting mentions
        block.entityRanges.forEach(({ key, offset, length }, index) => {
            const { name, id: userId } = entityMap[String(key)].data.mention;
            const mentionText = `@[${name}](${userId})`;
            const adjustedOffset = offset + offsetDiff; // adjust the offset based on previous insertions
            blockText =
                blockText.slice(0, adjustedOffset) +
                mentionText +
                blockText.slice(adjustedOffset + length);
            offsetDiff += mentionText.length - length; // update the offset difference
        });
        message += blockText;
        if (index !== blocks.length - 1) {
            message += '\n'; // add a new-line after each block except the last one
        }
    });
    return message;
};

export const getMentionsFromContentState = (contentState: ContentState) => {
    const rawContentState = convertToRaw(contentState);
    const { entityMap } = rawContentState;
    const mentions = Object.values(entityMap)
        .filter(entity => entity.type === 'mention')
        .map(entity => entity.data.mention);
    return mentions;
};

export const filterMentions = (mentions: MentionData[], query: string) => {
    if (!query) {
        return mentions;
    }
    return mentions.filter(mention => mention.name.toLowerCase().includes(query.toLowerCase()));
};

export const getSourceForCommentThread = (comment: CommentExtended) => {
    let sourceTab: string | null = null;
    if (comment.fieldKey.startsWith('overview')) {
        sourceTab = INITIATIVE_TAB_TO_TITLE_MAP.overview;
    } else if (comment.fieldKey.startsWith('impact')) {
        sourceTab = INITIATIVE_TAB_TO_TITLE_MAP.impact;
    } else {
        sourceTab = INITIATIVE_TAB_TO_TITLE_MAP.requirements;
    }
    return `${sourceTab} - ${COMMENT_FIELD_KEY_TO_TITLE_MAP[comment.fieldKey]}`;
};
