import { FC, useMemo } from 'react'
import classNames from 'classnames/bind';
import styles from 'common/infoHeader/infoHeader.module.scss';
import { Button, Flexbox } from 'components';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import { GridApi } from 'ag-grid-enterprise';
import SavingStatus, { SaveStatus } from 'common/savingStatus';
import { useDispatch, useSelector } from 'react-redux';
import { initiativeScoreSavingStatusSelector, initiativeScoresSelector, updateScoresSavingStatus } from 'store/initiativeScores-slice';
import { useEditInitiativeScoresMutation } from 'store/initiativeScores-api';
import { useWorkspaceId } from 'utils/hooks';
import { InitiativeScoreKey } from 'utils/types';

const classes = classNames.bind(styles);

interface InitiativeScoresHeaderProps {
    gridApi: GridApi<any> | null,
}

type Scores = {
    -readonly [key in keyof typeof InitiativeScoreKey]?: number;
}

const InitiativeScoresHeader: FC<InitiativeScoresHeaderProps> = ({ gridApi }) => {
    const workspaceId = useWorkspaceId();
    const dispatch = useDispatch();

    const savingStatus = useSelector(initiativeScoreSavingStatusSelector);
    const initiativeScores = useSelector(initiativeScoresSelector);

    const [editInitiativeScores] = useEditInitiativeScoresMutation()

    const scoresSum = useMemo(() => initiativeScores.reduce((sum, score) => {
        return sum + score.percentage
    }, 0), [initiativeScores])

    const onSaveScores = async () => {
        dispatch(updateScoresSavingStatus(SaveStatus.SAVING));
        let scores: Scores = {};

        const extractedScores = initiativeScores.flatMap(score => {
            if (score.childElement) {
                return [score, { ...score.childElement }];
            } else {
                return score;
            }
        });

        extractedScores.forEach((score) => scores[score.id] = score.percentage);
        try {
            await editInitiativeScores({ workspaceId, data: { scores } });
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Flexbox vertical className={classes('headerContainer')}>
            <Flexbox className={classes('headerInfoTop')}>
                <Flexbox className={classes('headerTitle')}>Initiative Scores</Flexbox>
                <Flexbox>
                    {gridApi && <ExportButton api={gridApi} />}
                    {savingStatus ? <SavingStatus savingStatus={savingStatus} /> : null}
                    <Button
                        variant='contained'
                        className={classes('createButton')}
                        disabled={scoresSum !== 100}
                        onClick={onSaveScores}
                    >Save</Button>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    )
}

export default InitiativeScoresHeader;