import { Flexbox } from 'components';
import classNames from 'classnames/bind';

import styles from './checkboxButton.module.scss';

const classes = classNames.bind(styles);

interface CheckboxButtonProps {
    title: string,
    checked: boolean,
    onClick: () => void;
    className?: string;
}

const CheckboxButton = ({ onClick, title, checked, className = '' }: CheckboxButtonProps) => {
    return (
        <Flexbox
            className={classes('container', { checked }, className)}
            onClick={onClick}
            align
            justify
        >
            {title}
        </Flexbox>
    )
}

export default CheckboxButton;