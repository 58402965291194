
import { Button, Flexbox, Menu, MenuItem } from 'components'
import { ExpandLessIcon, ExpandMoreIcon, } from 'components/icons'
import { useState, MouseEvent, ReactNode } from 'react';
import styles from './index.module.scss';
const classes = classNames.bind(styles);
import classNames from 'classnames/bind';


interface ActionMenuProps {
    buttonItems: ButtonItem[];
    variant?: 'text' | 'contained' | 'outlined';
    className?: string;
    buttonText?: string;
    buttonIcon?: ReactNode;
    btnClassName?: string
}

export interface ButtonItem {
    label: string;
    action: (e: MouseEvent<HTMLElement>) => void;
    type?: 'red';
    icon?: ReactNode;
}


const ActionsButton = ({
    buttonItems,
    variant = 'contained',
    className = '',
    buttonText = 'Create Initiative',
    buttonIcon,
    btnClassName = ''
} : ActionMenuProps) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const openMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation()
    };

    const close = (e: MouseEvent<HTMLElement>) => {
        setAnchorEl(null);
        e.stopPropagation()
    };

    const onClickHandler = (e: MouseEvent<HTMLElement>, action: (e: MouseEvent<HTMLElement>) => void) => {
        action(e)
        close(e)
    }

    return (
        <Flexbox className={classes('actionButtonContainer', className)}>
            <Button
                variant={variant}
                className={classes('actionButton', { 'active': open }, btnClassName)}
                onClick={openMenu}
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
                {buttonIcon && <Flexbox className={classes('buttonIcon')}>{buttonIcon}</Flexbox>}
                {buttonText}
            </Button>
            <Menu
                className={classes('menu')}
                anchorEl={anchorEl}
                open={open}
                onClick={close}
                PaperProps={{
                    style: {
                        minWidth: '185px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {buttonItems.map(c => (
                    <MenuItem
                        className={classes('menuItem', c.type || '')}
                        key={c.label}
                        value={c.label}
                        onClick={(e: MouseEvent<HTMLElement>) => {
                            onClickHandler(e, c.action);
                        }}
                    >
                        {c.icon && <Flexbox className={classes('menuItemIcon')}>{c.icon}</Flexbox>}
                        {c.label}
                    </MenuItem>
                ))}
            </Menu>
        </Flexbox>
    );
}

export default ActionsButton

