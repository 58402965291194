import { Flexbox, Loader } from 'components';
import { setIntegrationCode, setWorkspaceIntegrationCode } from 'pages/Integrations/integrations.api';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ACTION_SET_GLOBAL_ERROR } from 'store/globalError';
import { INTEGRATIONS_TYPE, REDIRECT_URL_KEY } from 'utils/constants';
import { getFromLocalStorage, removeFromLocalStorage } from 'utils/localStorage';



export default () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    useEffect(() => {
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        if(code) {
            if(state) {
                const isWorkspace = state.includes('_workspace');
                const integrationName = state.replace('_workspace', '');
                const integration = INTEGRATIONS_TYPE.find((integration => integration.name === integrationName && !!integration.workspace === isWorkspace))
                if(integration) {
                    if(isWorkspace) {
                        sendWorkspaceIntegrationCode(code, integration.name)
                    } else {
                        sendIntegrationCode(integration.id, code)
                    }
                }
            }
            // send code to back
            redirect();
        } else {
            const errorMessage = searchParams.get('error_description');
            if(errorMessage) {
                dispatch({ type: ACTION_SET_GLOBAL_ERROR, payload: errorMessage });
            }
            redirect()
        }
    }, [])

    const sendWorkspaceIntegrationCode = async (code: string, name: string) => {
        await dispatch(setWorkspaceIntegrationCode(code, name));
        redirect();
    }

    const sendIntegrationCode = async (type: number, code: string) => {
        await dispatch(setIntegrationCode(type, code));
        redirect();
    }
    const redirect = () => {
        const redirectUrl = getFromLocalStorage(REDIRECT_URL_KEY);

        if(redirectUrl) {
            console.log(redirectUrl);
            removeFromLocalStorage(REDIRECT_URL_KEY)
            navigate(redirectUrl);
        }
    }
    return <Flexbox fullWidth align justify><Loader/></Flexbox>
}