
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { Button, CustomTyphography, Flexbox, Loader } from '../index';
import { CloseIcon } from '../icons';

import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import { IconButton } from '@mui/material';
const classes = classNames.bind(styles);

interface CustomDialogProps {
    title: string;
    cancelButton?: boolean;
    confirmButton?: boolean;
    confirmButtonLoading?: boolean;
    confirmButtonLabel?: string;
    hasTitleBar?: boolean;
    contentClassName?: string;
    confirmButtonStyle?: 'normal' | 'danger';
    size?: 'auto' | 'small' | 'middle';
    onConfirm?: () => void;
    onClose: () => void;
    confirmButtonFullWidth?: boolean;
    description?: string,
    disabled?: boolean
}


const CustomDialog = ({
    title,
    children,
    cancelButton,
    confirmButton,
    confirmButtonLoading,
    confirmButtonLabel,
    onClose,
    onConfirm,
    confirmButtonStyle = 'normal',
    size = 'auto',
    hasTitleBar = true,
    contentClassName = '',
    confirmButtonFullWidth,
    description,
    disabled,
    ...props
}: DialogProps & CustomDialogProps) => {

    return (
        <Dialog
            onClose={onClose}
            classes={{
                root: 'customDialogRoot',
                paper: 'customDialogPaper'
            }}
            {...props}
        >
            {hasTitleBar && (
                <DialogTitle className={classes('customDialogTitleContainer')}>
                    <Flexbox vertical className={classes('gap-2')}>
                        <CustomTyphography className={classes('customDialogTitle')} type='primary'>{title}</CustomTyphography>
                        {
                            description && (
                                <CustomTyphography className={classes('customDialogDescription', 'ellipsisText')}>
                                    {description}
                                </CustomTyphography>
                            )
                        }
                    </Flexbox>
                    <IconButton onClick={onClose} className={classes('customDialogCloseIcon')}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            )}

            <DialogContent className={classes('customDialogContent', contentClassName)}>
                {
                    children
                }
            </DialogContent>
            {cancelButton || confirmButton ?
                <DialogActions className={classes('customDialogActions')}>
                    {cancelButton && (
                        <Button onClick={onClose}>Cancel</Button>
                    )}
                    {confirmButton && (
                        <Button
                            disabled={disabled || confirmButtonLoading}
                            className={classes('confirmButton', confirmButtonStyle, { 'w-full': confirmButtonFullWidth })}
                            variant='contained'
                            onClick={onConfirm}>
                            {confirmButtonLabel || 'Save'}
                            {confirmButtonLoading && <Loader disableShrink size={18} />}
                        </Button>
                    )}
                </DialogActions> : null
            }
        </Dialog>
    )
}

export default CustomDialog;