const BookBookmark = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_763_8915)">
                <path opacity="0.2" d="M13.75 2.5V10L11.2494 8.125L8.75 10V2.5H5.625C5.37877 2.5 5.13495 2.5485 4.90747 2.64272C4.67998 2.73695 4.47328 2.87506 4.29917 3.04917C4.12506 3.22328 3.98695 3.42998 3.89272 3.65747C3.7985 3.88495 3.75 4.12877 3.75 4.375V16.875C3.75 16.6288 3.7985 16.385 3.89272 16.1575C3.98695 15.93 4.12506 15.7233 4.29917 15.5492C4.47328 15.3751 4.67998 15.237 4.90747 15.1427C5.13495 15.0485 5.37877 15 5.625 15H16.25V2.5H13.75Z" fill="#303030" />
                <path d="M3.75 16.875V17.5H15" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.75 2.5V10L11.2495 8.125L8.75 10V2.5" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.75 16.875C3.75 16.3777 3.94754 15.9008 4.29917 15.5492C4.65081 15.1975 5.12772 15 5.625 15H16.25V2.5H5.625C5.12772 2.5 4.65081 2.69754 4.29917 3.04917C3.94754 3.40081 3.75 3.87772 3.75 4.375V16.875Z" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_763_8915">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default BookBookmark