import FormControlLabel from '@mui/material/FormControlLabel';
import MSwitch, { SwitchProps as MSwitchProps } from '@mui/material/Switch';
import './switch.scss'

interface SwitchProps extends MSwitchProps {
    label?: String;
}

const Switch = ({ label, ...props }: SwitchProps) => {

    return (
        <FormControlLabel classes={{ label: 'formControlLabel', }}
            control={
                <MSwitch
                    color='primary'
                    {...props}
                />
            }
            label={label}
        />
    )
}

export default Switch;