import { ActionsMenu, Button, Dialog, Flexbox, IconButton, Input, Loader, RichTextEditor, Select, TabPanel, TextArea, Tooltip } from 'components';
import { AttachmentMetadata, Block, BlockTypes, Initiative, InitiativeMockup, Story } from 'utils/types';
import classNames from 'classnames/bind';
import styles from './mockups.module.scss';
import { FullscreenExitIcon, FullscreenIcon, GenerateIcon } from 'components/icons';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getStory } from 'common/Story/index.api';
import { createMockup, deleteMockup, editMockup, getMockups } from './mockups.api';
const classes = classNames.bind(styles);

interface MockupTabProps{
    active: boolean;
    allMockups: InitiativeMockup[],
    setAllMockups:React.Dispatch<React.SetStateAction<InitiativeMockup[]>>;
    isLoading: boolean;
    initiativeId?: number;
}

const MockupTab = ({ active, initiativeId, allMockups, setAllMockups, isLoading }: MockupTabProps) => {

    const dispatch = useDispatch();

    const [generatingMockup, setGeneratingMockup] = useState(false);
    const [openRefineMockup, setOpenRefineMockup] = useState(false);
    const [openCreateMockup, setOpenCreateMockup] = useState(false);
    const [maximizeMockup, setMaximizeMockup] = useState<InitiativeMockup | null>(null);
    const [refineText, setRefineText] = useState('');
    const [mockupId, setMockupId] = useState<number | undefined>();
    const [refiningMockup, setRefiningMockup] = useState<number[]>([]);
    const [newMockupTitle, setNewMockupTitle] = useState('');
    const [selectedRequirements, setSelectedRequirements] = useState<Story[]>([]);
    const [maximizedDescription, setMaximizedDescription] = useState('');

    const [stories, setStories] = useState<Story[]>([]);

    const loadStory = async () => {
        const baseUrl = `initiatives/${initiativeId}/stories`;
        const stories: Story[] = (await dispatch(getStory(baseUrl))) as unknown as Story[]
        setStories(stories);
    }

    const refine = (mockupId: number) => {
        setMockupId(mockupId)
        setRefineText('');
        setOpenRefineMockup(true)
    }

    const openCreateDialog = () => {
        setStories([]);
        setRefineText('');
        setNewMockupTitle('');
        setSelectedRequirements([]);
        setOpenCreateMockup(true);
        loadStory();
    }

    const onHideRefineMockup = () => {
        setOpenRefineMockup(false)
    }

    const onHideCreateMockup = () => {
        setOpenCreateMockup(false);
    }

    const onRefineMockup = async () => {
        if(initiativeId && refineText && mockupId){
            const mockup = allMockups.find(m => m.id === mockupId)
            if(mockup) {

                const version = getLatestVersion(mockup);
                setRefiningMockup(oldValue => [...oldValue, mockupId]);
                onHideRefineMockup();
                onCloseMaximizePopup();
                const newMockup = (await dispatch(editMockup(initiativeId, mockupId, {
                    versionId: version.id,
                    prompt: refineText
                }))) as unknown as InitiativeMockup;

                setAllMockups(oldValue => {
                    const index = oldValue.findIndex(m => m.id === mockupId);
                    if(index >= 0) {
                        oldValue[index] = newMockup;
                    }
                    return [...oldValue]
                })
                setRefiningMockup(oldValue => oldValue.filter(id => id !== mockupId))
            }
        }
    }

    const onCreateMockup = async () => {
        if(initiativeId) {
            setGeneratingMockup(true);
            onHideCreateMockup();
            const mockups = (await dispatch(createMockup(initiativeId, {
                title: newMockupTitle,
                prompt: refineText,
                stories: selectedRequirements.map(req => req.id)
            }))) as unknown as InitiativeMockup[];
            setAllMockups(all => [...all, ...mockups]);
            setGeneratingMockup(false);
        }
    }

    const onRefineTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setRefineText(e.target.value);
    }

    const onRequirementsChange = (_e: ChangeEvent<{}>, value: Story[]) => {
        setSelectedRequirements(value);
    }
    const onOpenMaximizeDialog = (mockup: InitiativeMockup) => {
        setMaximizedDescription(mockup.description);
        setMaximizeMockup(mockup);
    }
    const onCloseMaximizePopup = () => {
        setMaximizeMockup(null);
    }

    const saveMaximizedDescription = async () => {
        if(initiativeId && maximizeMockup) {
            const latestVersion = getLatestVersion(maximizeMockup)
            const newMockup = (await dispatch(editMockup(initiativeId, maximizeMockup.id, {
                versionId: latestVersion.id,
                description: maximizedDescription
            }))) as unknown as InitiativeMockup;
            const index = allMockups.findIndex(m => m.id === mockupId);
            if(index >= 0) {
                allMockups[index] = newMockup;
                setAllMockups([...allMockups]);
            }
        }
    }

    const onDeleteMockup = async (mockupId: number) => {
        if(initiativeId) {
            await dispatch(deleteMockup(initiativeId, mockupId));
            setAllMockups(all => all.filter(m => m.id !== mockupId));
            onCloseMaximizePopup()
        }
    }

    const onUndo = async (mockup: InitiativeMockup) => {

        if(initiativeId && canUndo(mockup)){
            const latestIndex = mockup.versions.findIndex(v => v.latest);
            const newVersion = mockup.versions[latestIndex + 1];

            if(newVersion) {
                const newMockup = (await dispatch(editMockup(initiativeId, mockup.id, {
                    versionId: newVersion.id,
                    isLatest: true
                }))) as unknown as InitiativeMockup;

                setAllMockups(oldValue => {
                    const index = oldValue.findIndex(m => m.id === mockup.id);

                    if(index >= 0) {
                        oldValue[index] = newMockup;

                    }
                    return [...oldValue]
                })
                if(maximizeMockup && maximizeMockup.id === mockup.id) {
                    setMaximizeMockup(newMockup);
                }

            }

        }
    }

    const onRedo = async (mockup: InitiativeMockup) => {

        if(initiativeId && canRedo(mockup)){
            const latestIndex = mockup.versions.findIndex(v => v.latest);
            const newVersion = mockup.versions[latestIndex - 1];
            if(newVersion) {
                const newMockup = (await dispatch(editMockup(initiativeId, mockup.id, {
                    versionId: newVersion.id,
                    isLatest: true
                }))) as unknown as InitiativeMockup;

                setAllMockups(oldValue => {
                    const index = oldValue.findIndex(m => m.id === mockup.id);

                    if(index >= 0) {
                        oldValue[index] = newMockup;

                    }return [...oldValue]
                })
                if(maximizeMockup && maximizeMockup.id === mockup.id) {
                    setMaximizeMockup(newMockup);
                }


            }

        }
    }

    const getLatestVersion = useCallback((mockup: InitiativeMockup) => {
        const latestVersion = mockup.versions.find(v => v.latest);
        return latestVersion || mockup.versions[0];
    }, [])

    const canUndo = useCallback((mockup: InitiativeMockup) => {
        if(mockup.versions.length === 1) {
            return false;
        }
        const latestIndex = mockup.versions.findIndex(v => v.latest);
        return latestIndex < mockup.versions.length - 1
    }, [])

    const canRedo = useCallback((mockup: InitiativeMockup) => {
        if(mockup.versions.length === 1) {
            return false;
        }
        const latestIndex = mockup.versions.findIndex(v => v.latest);
        return latestIndex > 0

    }, [])

    return(
        <TabPanel vertical active={active}>
            <Flexbox fullWidth className={classes('wireframeTabContainer')}>

                {!isLoading ?
                    <Flexbox className={classes('wireframeListContainer')}>
                        {
                            allMockups.map(m =>
                                <Flexbox vertical className={classes('wireframeContainer')}>
                                    {
                                        refiningMockup.includes(m.id) ? <Flexbox fullWidth fullHeight align justify vertical><Loader disableShrink/></Flexbox>
                                            : <>
                                                <Flexbox className={classes('wireframeTop')} align>
                                                    <Flexbox>
                                                        {m.title}
                                                    </Flexbox>
                                                    <Flexbox align>
                                                        <Tooltip title='Full Screen'>
                                                            <IconButton
                                                                disableRipple
                                                                size="small"
                                                                onClick={() => {onOpenMaximizeDialog(m)}}
                                                                className={classes('iconButton')}
                                                            >
                                                                <FullscreenIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Flexbox className={classes('actionsButtonContainer')}>
                                                            <ActionsMenu
                                                                buttonItems={[
                                                                    { label: 'Refine', action: () => {refine(m.id)} },
                                                                    { label: 'Undo', action: () => {onUndo(m)}, disabled: !canUndo(m) },
                                                                    { label: 'Redo', action: () => {onRedo(m)}, disabled: !canRedo(m) },
                                                                    { label: 'Delete', type: 'red', action: () => {onDeleteMockup(m.id)} },
                                                                ]}
                                                            />
                                                        </Flexbox>
                                                    </Flexbox>
                                                </Flexbox>
                                                <Flexbox className={classes('iframeContainer')} onClick={() => {onOpenMaximizeDialog(m)}}>
                                                    <iframe className={classes('iframeItem')} src={getLatestVersion(m).downloadUrl} allowFullScreen scrolling="no" />
                                                </Flexbox>
                                            </>
                                    }

                                </Flexbox>
                            )
                        }
                        <Flexbox vertical className={classes('wireframeContainer')} justify align>

                            <Flexbox className={classes('iframeContainer', 'newButtonContainer')}>
                                {
                                    generatingMockup ?
                                        <Flexbox fullWidth fullHeight align justify vertical><Loader disableShrink/></Flexbox>
                                        : <Button variant='text' className={classes('newButton')} onClick={openCreateDialog} endIcon={<GenerateIcon />}>Generate new Mockup</Button>
                                }
                            </Flexbox>
                        </Flexbox>

                    </Flexbox> :
                    <Flexbox fullWidth fullHeight align justify vertical><Loader disableShrink/></Flexbox>
                }
            </Flexbox>

            <Dialog
                open={openRefineMockup}
                onClose={onHideRefineMockup}
                title='Refine'
                confirmButton
                confirmButtonLabel='Refine'
                onConfirm={onRefineMockup}
            >
                <Flexbox className={classes('refineDialog')} justify>
                    <TextArea
                        fullWidth
                        placeholder="Type your expectations"
                        value={refineText}
                        onChange={onRefineTextChange}
                        minRows={3}
                        maxRows={6}
                    />
                </Flexbox>
            </Dialog>

            <Dialog
                open={openCreateMockup}
                onClose={onHideCreateMockup}
                title='Create'
                confirmButton
                confirmButtonLabel='Create'
                onConfirm={onCreateMockup}
            >
                <Flexbox className={classes('refineDialog')} justify vertical>
                    <Input
                        placeholder='Title'
                        label="Title"
                        value={newMockupTitle}
                        onChange={(e) => setNewMockupTitle(e.target.value)}
                    />
                    <TextArea
                        fullWidth
                        label="Prompts"
                        placeholder="Type your expectations"
                        value={refineText}
                        onChange={onRefineTextChange}
                        minRows={3}
                        maxRows={6}
                    />
                    <Select
                        onChange={onRequirementsChange}
                        value={selectedRequirements}
                        options={stories}
                        disableClearable
                        multiple
                        getOptionLabel={option => option.title || ''}
                        label='Requirements'
                        placeholder='Requirements'
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                </Flexbox>
            </Dialog>

            <Dialog
                open={!!maximizeMockup}
                onClose={onCloseMaximizePopup}
                title={maximizeMockup?.id + ''}
                hasTitleBar={false}
                classes={{
                    root: classes('maximizeDialogComponentRoot',),
                    paper: classes('maximizeDialogComponentPaper',)
                }}
                contentClassName={classes('dialogContent')}
            >   {maximizeMockup ? (
                    <>
                        <Flexbox className={classes('titleContainer')} >
                            <Flexbox align>{maximizeMockup.title}</Flexbox>

                            <Flexbox align>
                                <Tooltip title='Exit Full Screen'>
                                    <IconButton
                                        disableRipple
                                        size="small"
                                        onClick={() => {onCloseMaximizePopup()}}
                                        className={classes('iconButton')}
                                    >
                                        <FullscreenExitIcon />
                                    </IconButton>
                                </Tooltip>
                                <Flexbox className={classes('iconsContainer')}>

                                    <ActionsMenu
                                        buttonItems={[
                                            { label: 'Refine', action: () => {refine(maximizeMockup.id)} },
                                            { label: 'Undo', action: () => {onUndo(maximizeMockup)}, disabled: !canUndo(maximizeMockup) },
                                            { label: 'Redo', action: () => {onRedo(maximizeMockup)}, disabled: !canRedo(maximizeMockup) },
                                            { label: 'Delete', type: 'red', action: () => {onDeleteMockup(maximizeMockup.id)} },
                                        ]}
                                    />
                                </Flexbox>
                            </Flexbox>
                        </Flexbox>
                        <Flexbox style={{ height: '580px' }}>
                            <iframe width={'100%'} style={{ border: 'none' }} src={getLatestVersion(maximizeMockup).downloadUrl} allowFullScreen />
                        </Flexbox>
                        <Flexbox>
                            <RichTextEditor files={[]} onBlur={saveMaximizedDescription} onChange={(value) => {setMaximizedDescription(value)}} value={maximizedDescription}/>
                        </Flexbox>
                    </>
                ) : ''}
            </Dialog>

        </TabPanel>
    )
}

export default MockupTab