import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const dependencyApi = createApi({
    reducerPath: 'dependency.api',
    baseQuery: rtkBaseQueryWithReauth,
    tagTypes: ['dependency'],
    endpoints: builder => ({
        getDependency: builder.query({
            query: ({ workspaceId, dependencyId }) => `/workspaces/${workspaceId}/dependency-tickets/${dependencyId}`,
            providesTags: ['dependency']
        }),
        updateDependency: builder.mutation({
            query: ({ workspaceId, dependencyId, data }) => {
                return {
                    url: `/workspaces/${workspaceId}/dependency-tickets/${dependencyId}`,
                    method: 'PUT',
                    body: data
                };
            },
        }),
        deleteDependency: builder.mutation({
            query: ({ workspaceId, dependencyId }) => {
                return {
                    url: `/workspaces/${workspaceId}/dependency-tickets/${dependencyId}`,
                    method: 'DELETE',
                };
            },
        }),
    })
});

export const {
    useGetDependencyQuery,
    useLazyGetDependencyQuery,
    useDeleteDependencyMutation,
    useUpdateDependencyMutation
} = dependencyApi