export const searchFilter =
    <T>(searchTerm: string, selector: (item: T) => string) =>
    (item: T): boolean => {
        if (!searchTerm) {
            return true;
        }
        return selector(item).toLowerCase().includes(searchTerm.toLowerCase());
    };

type UniqueFilterType = string | number;

export const uniqueFilter = <T>(selector: (item: T) => UniqueFilterType) => {
    const unique = new Set<UniqueFilterType>();
    return (item: T): boolean => {
        const key = selector(item);
        if (unique.has(key)) {
            return false;
        }
        unique.add(key);
        return true;
    };
}
