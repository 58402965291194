
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import store, { RootState } from 'store';
import { SaveStatus } from 'common/savingStatus';
import { IInitiativeScore, InitiativeScoreKey } from 'utils/types';
import { initiativeScoresApi } from './initiativeScores-api';

interface InitiativeScoresState {
    data: IInitiativeScore[],
    savingStatus: SaveStatus
}

function createArrayOfScoresFromEnum() {
    const arrayOfObjects: IInitiativeScore[] = [];
    for (const key in InitiativeScoreKey) {
        if (key !== 'aiPoint' && InitiativeScoreKey.hasOwnProperty(key)) {
            const enumKey = key as keyof typeof InitiativeScoreKey;
            arrayOfObjects.push({ id: enumKey, title: InitiativeScoreKey[enumKey], percentage: 0 });
        }
    }
    return arrayOfObjects;
}

const initialState: InitiativeScoresState = {
    data: createArrayOfScoresFromEnum(),
    savingStatus: SaveStatus.UNSET
}

const initiativeScoresSlice = createSlice({
    name: 'initiativeScores',
    initialState,
    reducers: {
        updateScoresSavingStatus: (state, action: PayloadAction<SaveStatus>) => {
            state.savingStatus = action.payload
        },
        updateScoreAction: (state, action: PayloadAction<IInitiativeScore>) => {
            state.data = state.data.map(score => {
                if(score.id === action.payload.id) {
                    return action.payload
                } else if(score.childElement?.id === action.payload.id) {
                    return {
                        ...score,
                        childElement: action.payload
                    }
                } else {
                    return score
                }
            })
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
          initiativeScoresApi.endpoints.getInitiativeScores.matchFulfilled,
          (state, { payload }) => {
            state.data = state.data.map(score => {
                const childElement = score.id === 'aiTotal' ?
                { childElement: { id: 'aiPoint' as keyof typeof InitiativeScoreKey, percentage: payload.scores.aiPoint as number,  title: InitiativeScoreKey.aiPoint } } : {}

                return {
                    ...score,
                    percentage: payload.scores[score.id],
                    ...childElement
                }
            })
          },
        ),
        builder.addMatcher(
            initiativeScoresApi.endpoints.editInitiativeScores.matchFulfilled,
            (state, { payload }) => {
                state.savingStatus = SaveStatus.SAVED

                setTimeout(() => {
                    store.dispatch(updateScoresSavingStatus(SaveStatus.UNSET));
                }, 2000);
            }
        ),
        builder.addMatcher(
            initiativeScoresApi.endpoints.editInitiativeScores.matchRejected,
            (state, { payload }) => {
                state.savingStatus = SaveStatus.UNSET
            }
        )
    },
})

export const initiativeScoresSelector = (store: RootState) => store.initiativeScores.data;
export const initiativeScoreSavingStatusSelector = (store: RootState) => store.initiativeScores.savingStatus;

export const {
    updateScoresSavingStatus,
    updateScoreAction
} = initiativeScoresSlice.actions;

export default initiativeScoresSlice;
