import { IconButton } from 'components';
import { FC, ReactNode } from 'react';
import styles from './commentFieldToolbar.module.scss';
import classNames from 'classnames/bind';
const classes = classNames.bind(styles);

interface CommentFieldToolbarButtonProps {
    onClick?: () => void;
    children: ReactNode
}

export const CommentFieldToolbarButton: FC<CommentFieldToolbarButtonProps> = ({
    onClick,
    children,
}) => {
    return (
        <IconButton className={classes('toolbar-button')} onClick={onClick}>
            {children}
        </IconButton>
    );
};
