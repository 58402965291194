import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { MentionData } from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './mention-editor.module.scss';
import { EditorState, getDefaultKeyBinding } from 'draft-js';
import { filterMentions } from 'utils/comments';
import { MentionSuggestionsPubProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/MentionSuggestions';
const classNames = require('classnames/bind');
const classes = classNames.bind(styles);

export interface MentionEditorProps {
    className?: string;
    autoFocus?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    placeholder?: string;
    mentions: MentionData[];
    editorState: EditorState;
    onEditorStateChange: (editorState: EditorState) => void;
    onClick?: () => void;
    onEnter?: () => void;
}

const MentionEditor: FC<MentionEditorProps> = ({
    className,
    mentions,
    placeholder,
    autoFocus = false,
    readonly = false,
    disabled = false,
    editorState,
    onEditorStateChange,
    onClick,
    onEnter,
}) => {
    const ref = useRef<Editor>(null);
    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState<MentionData[]>(mentions);

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            supportWhitespace: true,
            theme: styles,
        });
        const { MentionSuggestions } = mentionPlugin;
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const keyBindingFn = (e: React.KeyboardEvent) => {
        let selectionState = editorState.getSelection()

        if (!onEnter) {
            return getDefaultKeyBinding(e);
        }
        if (e.key === 'Enter' && !e.shiftKey) {
            onEnter();
            return null;
        } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            return undefined;
        } else if (e.key === 'Enter' && e.shiftKey) {
            return 'split-block';
        }

        onEditorStateChange(EditorState.forceSelection(editorState, selectionState));
        return getDefaultKeyBinding(e);
    };

    const handleSearchChange: MentionSuggestionsPubProps['onSearchChange'] = ({ value }) =>
        setSuggestions(filterMentions(mentions, value));

    const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(e => {
        if (!readonly) {
            e.stopPropagation();
        }
        ref.current?.focus();
        onClick?.();
    }, [readonly]);

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => ref.current?.focus(), 0);
        }
    }, [autoFocus]);

    useEffect(() => {
        setSuggestions(mentions);
    }, [mentions]);

    const isReadOnly = readonly || disabled;

    return (
        <div className={classes('editor', className, { readonly })} onClick={handleClick}>
            <Editor
                ref={ref}
                readOnly={isReadOnly}
                editorKey="mentions-editor"
                plugins={plugins}
                placeholder={placeholder}
                editorState={editorState}
                onChange={onEditorStateChange}
                keyBindingFn={keyBindingFn}
            />
            <MentionSuggestions
                open={open}
                suggestions={suggestions}
                onOpenChange={setOpen}
                onSearchChange={handleSearchChange}
            />
        </div>
    );
};

export default MentionEditor;
