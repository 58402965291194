
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store';
import { InitiativeTemplate } from 'utils/types';
import { initiativeTemplatesApi } from './initiativeTemplates-api';

interface InitiativeTemplatesState {
    data: InitiativeTemplate[]
    templateData: InitiativeTemplate | null
}

const initialState: InitiativeTemplatesState = {
    data: [],
    templateData: null
}

const initiativeTemplatesSlice = createSlice({
    name: 'initiativeTemplates',
    initialState,
    reducers: {
        updateTemplateAction: (state, action: PayloadAction<{ templateIndex: number, key: string, value: string | boolean, exIndex?: number }>) => {
            if (!!state.templateData) {
                state.templateData = {
                    ...state.templateData,
                    template: state.templateData.template.map((template, index) => {
                        if (index === action.payload.templateIndex) {
                            const newData = action.payload.exIndex !== undefined ? {
                                ...template,
                                examples: template.examples.map((ex, exIndex) => {
                                    if (exIndex === action.payload.exIndex) {
                                        return {
                                            ...ex,
                                            [action.payload.key]: action.payload.value
                                        }
                                    } else {
                                        return ex
                                    }
                                })
                            } : {
                                ...template,
                                [action.payload.key]: action.payload.value
                            }
                            return newData
                        } else {
                            return template
                        }
                    })
                }
            }
        },
        setTemplateData: (state, action: PayloadAction<InitiativeTemplate | null>) => {
            state.templateData = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            initiativeTemplatesApi.endpoints.getInitiativeTemplates.matchFulfilled,
            (state, { payload }) => {
                state.data = payload
            },
        ),
            builder.addMatcher(
                initiativeTemplatesApi.endpoints.getInitiativeTemplate.matchFulfilled,
                (state, { payload }) => {
                    state.templateData = payload
                },
            )
    },
})

export const initiativeTemplatesSelector = (store: RootState) => store.initiativeTemplates.data;
export const currentTemplateSelector = (store: RootState) => store.initiativeTemplates.templateData;

export const {
    updateTemplateAction,
    setTemplateData
} = initiativeTemplatesSlice.actions;

export default initiativeTemplatesSlice;
