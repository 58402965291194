import { MentionData } from '@draft-js-plugins/mention';
import classNames from 'classnames/bind';
import { Button, Stack } from 'components';
import MentionEditor from 'components/MentionEditor/MentionEditor';
import { MentionEditorWithTools } from 'components/MentionEditor/MentionEditorWithTools';
import { FC } from 'react';
import { useCommentEdit, useDeleteCommentMutation, useGetWorkspaceMentions } from 'store/comments-api';
import { CommentExtended } from '../../../utils/types';
import { CommentActions } from '../CommentActions';
import { CommentHeader } from '../CommentHeader/CommentHeader';
import styles from './commentItem.module.scss';
import { useCurrentUser } from 'store/user';
import { useWorkspaceId } from 'utils/hooks';
const classes = classNames.bind(styles);

const emptyMentions: MentionData[] = [];

export interface CommentItemProps {
    actions?: boolean;
    avatar?: boolean;
    comment: CommentExtended;
}

export const CommentItem: FC<CommentItemProps> = ({ actions = false, avatar, comment }) => {
    const currentUser = useCurrentUser();
    const workspaceId = useWorkspaceId();
    const [deleteComment, { isLoading: isDeletingComment }] = useDeleteCommentMutation();
    const {
        editorState,
        setEditorState,
        isEditing,
        isUpdatingComment,
        handleCancel,
        handleEdit,
        handleSave,
    } = useCommentEdit(comment);
    const { mentions = [] } = useGetWorkspaceMentions();

    const handleDelete = async () => {
        deleteComment({
            workspaceId,
            objectKey: comment.objectKey,
            objectId: comment.objectId,
            fieldKey: comment.fieldKey,
            parentId: comment.parentId,
            commentId: comment.id,
        });
    };

    const isDisabled = isDeletingComment || isUpdatingComment;
    const canEditOrDelete = currentUser.id === comment.author.id;
    const shouldShowActions = actions && canEditOrDelete;

    return (
        <div className={classes('comment-item')}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={1}
            >
                <CommentHeader
                    className={classes('header')}
                    avatar={avatar}
                    dateCreated={comment.createdDate}
                >
                    {comment.author.fullName}
                </CommentHeader>
                {shouldShowActions && <CommentActions onDelete={handleDelete} onEdit={handleEdit} />}
            </Stack>
            {isEditing ? (
                <MentionEditorWithTools
                    mentions={mentions}
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    onEnter={handleSave}
                    actions={
                        <Stack direction="row" gap={1}>
                            <Button
                                fullWidth
                                disableElevation
                                size="small"
                                disabled={isDisabled}
                                variant="contained"
                                color="inherit"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                fullWidth
                                disableElevation
                                size="small"
                                disabled={isDisabled}
                                variant="contained"
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </Stack>
                    }
                />
            ) : (
                <MentionEditor
                    readonly
                    mentions={emptyMentions}
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                />
            )}
        </div>
    );
};
