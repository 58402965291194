const Sticker = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_763_13304)">
                <path opacity="0.2" d="M10.625 16.875V14.375C10.625 13.3804 11.0201 12.4266 11.7233 11.7233C12.4266 11.0201 13.3804 10.625 14.375 10.625H16.875C16.25 12.5 12.5 16.25 10.625 16.875Z" fill="#303030" />
                <path d="M10.625 16.875H6.875C5.88044 16.875 4.92661 16.4799 4.22335 15.7767C3.52009 15.0734 3.125 14.1196 3.125 13.125V6.875C3.125 5.88044 3.52009 4.92661 4.22335 4.22335C4.92661 3.52009 5.88044 3.125 6.875 3.125H13.125C14.1196 3.125 15.0734 3.52009 15.7767 4.22335C16.4799 4.92661 16.875 5.88044 16.875 6.875V10.625C16.25 12.5 12.5 16.25 10.625 16.875Z" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.625 16.875V14.375C10.625 13.3804 11.0201 12.4266 11.7233 11.7233C12.4266 11.0201 13.3804 10.625 14.375 10.625H16.875" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_763_13304">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Sticker