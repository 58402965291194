import { Button, Flexbox, Input, Loader, Popover } from 'components'
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createAction, editAction } from 'common/resourcesBlock/index.api';
import { Block, LinkMetadata } from 'utils/types';
const classes = classNames.bind(styles);


interface LinkPopoverProps{
    anchorEl: HTMLElement | null;
    setAnchorEl: (value: HTMLElement | null) => void;
    linkData?: Block<LinkMetadata>;
    baseUrl: string;
    editLinkAction: (data: any) => void;
    createLinkAction: (data: Block<LinkMetadata>) => void
}

const LinkPopover = ({ anchorEl, setAnchorEl, linkData, baseUrl, editLinkAction, createLinkAction }: LinkPopoverProps) => {
    const dispatch = useDispatch()

    const [title, setTitle] = useState(linkData?.metadata.title || '');
    const [url, setUrl] = useState(linkData?.metadata.url || '');

    const [linkError, setLinkError] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
    }

    const onLinkChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value)
        setLinkError('')
    }

    const save = () => {
        let linkUrl = url.trim();

        if(url && !url.startsWith('http://') && !url.startsWith('https://')) {
            linkUrl = `http://${url}`;
            setUrl(linkUrl)
        }

        if(linkUrl){
            if(linkData?.id){
                update(linkUrl)
            }else{
                create(linkUrl)
            }
        }else {
            setLinkError('The Link field cannot be empty')
        }
    }

    const create = async (linkUrl: string) => {
        setIsLoading(true)

        try {
            const linkMetadata = (await dispatch(createAction({ url: linkUrl, title }, baseUrl))) as unknown as Block<LinkMetadata>
            createLinkAction(linkMetadata)
            setAnchorEl(null);
            setTitle('');
            setUrl('');
        } catch (e: any) {
            if(e.data.error) {
                setLinkError(e.data.error)
            }
        }finally{
            setIsLoading(false)
        }

    }

    const update = async (linkUrl: string) => {
        if(linkData?.id){
            setIsLoading(true)
            try {
                await dispatch(editAction(linkData.id, { url: linkUrl, title }, baseUrl))
                editLinkAction({ id: linkData.id, url: linkUrl, title })
                setAnchorEl(null);
            } catch (e: any) {
                if(e.data.error) {
                    setLinkError(e.data.error)
                }
            }finally{
                setIsLoading(false)
            }
        }
    }

    const cancel = () => {
        setAnchorEl(null);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return(
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
        >
            <Flexbox vertical className={classes('popoverContainer')}>
                <Input
                    value={title}
                    onChange={onTitleChange}
                    label='Text'
                    placeholder='Type text'
                    alwaysEditable
                    fullWidth
                    className={classes('input')}
                    disabled={isLoading}
                />
                <Input
                    value={url}
                    onChange={onLinkChange}
                    label='Link'
                    placeholder='Type URL'
                    alwaysEditable
                    fullWidth
                    required
                    errorText={linkError}
                    className={classes('input')}
                    disabled={isLoading}
                />
                <Flexbox justifyEnd className={classes('buttonsContainer')}>
                    <Button className={classes('button')} onClick={cancel}>
                        Cancel
                    </Button>
                    <Button className={classes('button')} variant='contained' onClick={save} disabled={isLoading}>
                        Save
                        {isLoading && <Loader className={classes('buttonLoader')} disableShrink size={18} />}
                    </Button>
                </Flexbox>
            </Flexbox>
        </Popover>
    )
}

export default LinkPopover