import { ExpandMore } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Button, Menu, MenuItem, RadioButton } from 'components';
import { FC, Fragment, useState } from 'react';
import styles from './comments.module.scss';
const classes = classNames.bind(styles);

export type CommentFilterOption = 'all' | 'forYou' | 'open' | 'resolved';

const CommentFilterOption: Record<CommentFilterOption, string> = {
    all: 'All',
    forYou: 'For you',
    open: 'Open',
    resolved: 'Resolved',
};

interface CommentFilterProps {
    filter: CommentFilterOption;
    onChange: (filter: CommentFilterOption) => void;
}

const CommentFilter: FC<CommentFilterProps> = ({ filter, onChange }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    return (
        <Fragment>
            <Button
                className={classes('comment-filter')}
                disableElevation
                color='inherit'
                variant="contained"
                onClick={e => setAnchorEl(e.currentTarget)}
                endIcon={<ExpandMore />}
            >
                {CommentFilterOption[filter]}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                disableScrollLock
            >
                {Object.keys(CommentFilterOption).map(option => (
                    <MenuItem
                        className={classes('comment-filter-menu-item')}
                        key={option}
                        onClick={() => {
                            setAnchorEl(null);
                            onChange(option as CommentFilterOption);
                        }}
                    >
                        <RadioButton
                            className={classes('comment-filter-radio-button')}
                            size="small"
                            checked={option === filter}
                        />
                        {CommentFilterOption[option as CommentFilterOption]}
                    </MenuItem>
                ))}
            </Menu>
        </Fragment>
    );
};

export default CommentFilter;
