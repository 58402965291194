import { CircularProgress, ErrorMessage, Flexbox, HorizontalSeparator, ImageViewer, Tooltip } from 'components'
import FileSelector, { UploadedFile } from 'components/FileUploader'
import { DeleteIcon, DocIcon, DriveFileIcon, EmptyResourcesIcon, PDFIcon } from 'components/icons'
import { AttachmentMetadata, Block, isAttachmentBlock, isLinkBlock, LinkMetadata } from 'utils/types'
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteAction } from './index.api';
import { ImageViewerModel } from 'components/ImageViewer';
import EmptyState from 'common/emptyState';
import { fileFormats } from 'utils/constants';
import LinkResources from './link';
const classes = classNames.bind(styles);


interface ResourcesBlockProps {
    blocks: Block[];
    baseUrl: string;
    isEditable?: boolean;
}

interface EditableBlock<T = AttachmentMetadata | LinkMetadata> extends Block<T> {
    editing?: boolean;
    isError?: boolean;
}

const ResourcesBlock = ({ blocks, baseUrl, isEditable = true } : ResourcesBlockProps) => {

    const dispatch = useDispatch()

    const [links, setLinks] = useState<EditableBlock<LinkMetadata>[]>([])
    const [attachment, setAttachment] = useState<EditableBlock<AttachmentMetadata>[]>([])
    const [files, setFiles] = useState<{[key: string]: UploadedFile}>({});

    const [imageActiveIndex, setImageActiveIndex] = useState(0);
    const [imageViewerVisible, setImageViewerVisible] = useState(false)
    const [viewerImages, setViewerImages] = useState<ImageViewerModel[]>([])

    useEffect(() => {
        const linkBlock  = (blocks.filter((el) => isLinkBlock(el))) as EditableBlock<LinkMetadata>[]
        const attachmentBlock = (blocks.filter((el) => isAttachmentBlock(el))) as EditableBlock<AttachmentMetadata>[]
        setLinks(linkBlock)
        setAttachment(attachmentBlock)
    }, [])

    useEffect(() => {
        const images: ImageViewerModel[] = attachment.map(({ metadata }) => {
            return {
                src: metadata.signedUrl || '',
                alt: metadata.fileName || '',
                downloadUrl: metadata.signedUrl || '',
            }
        })
        setViewerImages(images)
    },[attachment])

    const deleteLinkAction = (id: number) => {
        const filtered = links.filter((b) => b.id !== id)
        setLinks(filtered)
    }

    const editLinkAction = async (data: any) => {
        setLinks(prevLinks =>
            prevLinks.map(link =>
                link.id === data.id
                    ? { ...link, metadata: { title: data.title, url: data.url } }
                    : link
            )
        );
    }

    const createLinkAction = async (data: Block<LinkMetadata>) => {
        setLinks((current) => {
            return [...current, data]
        })
    }

    const onUploadFinish = (uploadedItem: EditableBlock<AttachmentMetadata>) => {
        setAttachment((current) => {
            return [...current, uploadedItem]
        })
        setFiles(prevFiles => {
            if(uploadedItem.metadata.fileName){
                delete prevFiles[uploadedItem.metadata.fileName];
            }
            return { ...prevFiles }
        })
    }

    const removeFile = (id: number) => {
        dispatch(deleteAction(id, baseUrl))
        const filtered = attachment.filter((b) => b.id !== id)
        setAttachment(filtered)
    }

    const onUploadProgress = (name: string, status: number) => {
        setFiles(res => {
            res[name].status = status;
            return { ...res }
        })
    }

    const onUploadStart = (files: UploadedFile[]) => {
        const filesList: {[key: string]: UploadedFile} = {}
        for(let i = 0; i < files.length; i++) {
            filesList[files[i].name] = files[i]
        }
        setFiles(filesList);
    }

    const openFile = (url? : string) => {
        if(url){
            window.open(url, '_blank',)
        }
    }

    const onCloseImageViewer = () => {
        setImageViewerVisible(false)
    }

    const fileType = (metadata: AttachmentMetadata, index?: number) => {

        if(fileFormats.docs.some(f => f === metadata.extension)) {
            return <DocIcon onClick={() => openFile(metadata.signedUrl)} className={classes('previewImage','googleDoc')} />
        } else if(fileFormats.pdfs.some(f => f === metadata.extension)) {
            return <PDFIcon onClick={() => openFile(metadata.signedUrl)} className={classes('previewImage','pdf')}  />
        }else if(fileFormats.images.some(f => f === metadata.extension)){
            return <img onClick={() => {
                if(index !== undefined){
                    setImageActiveIndex(index)
                    setImageViewerVisible(true)
                }} }
            className={classes('previewImage', 'imageType')} src={metadata.signedUrl} />;
        }
        return <DriveFileIcon onClick={() => openFile(metadata.signedUrl)} className={classes('previewImage','fileIcon')}  />
    }

    return(
        <Flexbox vertical fullWidth>
            {isEditable && <Flexbox className={classes('resourcesIconBox')}>
                <Tooltip title="Attach file">
                    <Flexbox className={classes('icon')}>
                        <FileSelector
                            url={baseUrl}
                            buttonType="iconButton"
                            onUploadFinish={onUploadFinish}
                            onUploadProgress={onUploadProgress}
                            onUploadStart={onUploadStart}
                        />
                    </Flexbox>
                </Tooltip>
            </Flexbox>}
            <Flexbox className={classes('resourcesLinksContainer')} vertical>
                <LinkResources links={links} isEditable={isEditable} baseUrl={baseUrl} horizontalSeparator={false} deleteLinkAction={deleteLinkAction} editLinkAction={editLinkAction} createLinkAction={createLinkAction} />
            </Flexbox>
            {attachment.length && links.length > 0 ? <HorizontalSeparator dashed className={classes('editLinkBox')}/> : null}
            <Flexbox className={classes('resourcesImgContainer')} fullWidth>
                {attachment.map(({ metadata, id }, index) => {
                    return(
                        <Flexbox key={id} className={classes('resourcesImgItem')}>
                            {fileType(metadata, index)}
                            <Flexbox align className={classes('imgItemOverlay')}>
                                <Flexbox className={classes('resourcesImgDesc')}>{metadata.fileName}</Flexbox>
                                {isEditable && <DeleteIcon onClick={() => removeFile(id)} className={classes('resourcesCloseIcon')} />}
                            </Flexbox>
                        </Flexbox>
                    )
                })}
                {Object.values((files)).map((f, index) => {
                    return (
                        <Flexbox key={index} vertical className={classes('resourcesImgItem')}>
                            {fileType(f)}
                            {
                                !f.status || f.status < 100 ? (
                                    <Flexbox align justify className={classes('circularProgress')}>
                                        <CircularProgress value={f.status || 0} />
                                    </Flexbox>
                                ) : (
                                    f.error ? (
                                        <ErrorMessage>{f.error}</ErrorMessage>
                                    ) : null
                                )
                            }
                        </Flexbox>
                    )})
                }
            </Flexbox>
            <ImageViewer
                imageViewerVisible={imageViewerVisible}
                onClose={onCloseImageViewer}
                viewerImages={viewerImages}
                imageActiveIndex={imageActiveIndex}
            />
            {!attachment.length && !links.length &&
                <EmptyState
                    icon={<EmptyResourcesIcon />}
                    title='There are no resources yet'
                />
            }
        </Flexbox>
    )
}

export default ResourcesBlock