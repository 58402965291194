import classNames from 'classnames/bind';
import { Avatar, TimeAgo } from 'components';
import { FC } from 'react';
import { getInitialsFromName } from 'utils';
import styles from './commentHeader.module.scss';
const classes = classNames.bind(styles);

interface CommentHeaderProps {
    className?: string;
    avatar?: boolean;
    dateCreated?: number;
    children: string;
}

export const CommentHeader: FC<CommentHeaderProps> = ({
    className,
    avatar = false,
    dateCreated,
    children,
}) => {
    return (
        <div className={classes('comment-header', className)}>
            {avatar && (
                <Avatar className={classes('avatar')}>{getInitialsFromName(children)}</Avatar>
            )}
            {children}
            {dateCreated && (
                <div className={classes('time-ago')}>
                    <TimeAgo datetime={dateCreated} live={false} />
                </div>
            )}
        </div>
    );
};
