import MClickAwayListener, { ClickAwayListenerProps } from '@mui/material/ClickAwayListener'


const ClickAwayListener = (props: ClickAwayListenerProps) => {
    return(
        <MClickAwayListener
            {...props}
        />
    )
}

export default ClickAwayListener