const NotFound = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="196"
            height="196"
            fill="none"
            viewBox="0 0 196 196"
        >
            <g clipPath="url(#clip0_12948_58610)">
                <path
                    fill="currentColor"
                    d="M130.243 49.529a1.782 1.782 0 011.263.224 1.756 1.756 0 011.096 1.617l.372 51.058a1.75 1.75 0 01-.861 1.593l-21.055 12.438a1.777 1.777 0 01-1.069.354c-.098 0-.195-.005-.288-.022l-54.639-6.017a1.77 1.77 0 01-1.688-1.761V57.092h.003a1.755 1.755 0 011.06-1.663l27.408-11.932.002.002c.286-.125.61-.175.943-.134l47.453 6.164zM91.885 8.164c20.84 0 39.742 8.4 53.434 21.904 13.692 13.56 22.116 32.224 22.116 52.92a74.158 74.158 0 01-12.305 40.953l32.703 35.3-22.56 20.423-31.538-34.365c-11.974 7.905-26.386 12.515-41.849 12.515-20.842 0-39.744-8.399-53.434-21.902-13.693-13.563-22.119-32.228-22.119-52.924 0-20.642 8.482-39.36 22.117-52.92C52.14 16.507 70.987 8.163 91.885 8.163zm47.447 27.832a67.274 67.274 0 00-47.447-19.489 67.277 67.277 0 00-47.448 19.49c-12.14 12.022-19.678 28.656-19.678 46.991 0 18.336 7.538 34.97 19.676 46.993a67.284 67.284 0 0047.448 19.488 67.284 67.284 0 0047.448-19.488c12.139-12.023 19.678-28.657 19.678-46.993.002-18.335-7.538-34.968-19.677-46.992zm-26.928 28.448v46.247l16.383-9.17-.343-46.623-16.04 9.546zm-5.469 46.753V64.935l-22.026-2.312-.764 22.054-8.182-5.519-8.181 4.58L69.47 60.67l-11.265-1.584v47.095l48.73 5.016zm-2.41-61.467l-16.548 9.03 22.354 2.826 15.031-9.15-20.837-2.706zm-32.23 7.05l16.027-9.155-5.494-.713-20.563 8.598 10.03 1.27z"
                ></path>
                <circle cx="94" cy="85" r="53" fill="#fff"></circle>
                <path
                    fill="currentColor"
                    d="M77.86 103.897c-1.207 1.206-2.624 1.84-4.254 1.9-1.629-.06-3.077-.724-4.344-1.991-1.207-1.207-1.78-2.564-1.72-4.073.06-1.629.694-3.047 1.9-4.254l37.019-37.018c1.207-1.207 2.595-1.81 4.164-1.81 1.629-.06 3.077.543 4.344 1.81 1.147 1.146 1.72 2.564 1.72 4.254 0 1.569-.603 2.956-1.81 4.163L77.86 103.897zm36.657-.091c-1.388 1.388-3.017 2.112-4.888 2.172-1.81 0-3.439-.724-4.887-2.172L68.447 67.512c-1.388-1.388-2.051-3.017-1.99-4.888.06-1.87.784-3.5 2.171-4.887 1.448-1.448 3.047-2.142 4.797-2.082 1.81 0 3.41.694 4.797 2.082l36.295 36.294c1.448 1.448 2.172 3.077 2.172 4.888 0 1.81-.724 3.439-2.172 4.887z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_12948_58610">
                    <path fill="#fff" d="M0 0H196V196H0z"></path>
                </clipPath>
            </defs>
        </svg>

    )
}

export default NotFound;