import { RootState } from './index';
import { Workspace } from 'utils/types';

interface WorkspacesReducerState {
    workspaces: Workspace[];
    loading: boolean;
}

export const ACTION_GET_WORKSPACES = 'getWorkspaces';
export const ACTION_SET_WORKSPACES = 'setWorkspaces';

const initialState: WorkspacesReducerState = {
    loading: false,
    workspaces: [],
}

const workspaces = (state: WorkspacesReducerState = initialState, action: {type: string, payload: Workspace[]}) => {
    switch (action.type) {
    case ACTION_GET_WORKSPACES:
        return { ...state, workspaces: [], loading: true }
    case ACTION_SET_WORKSPACES:
        return { ...state, loading: false, workspaces: [...action.payload] }
    default:
        return state
    }
}
export default workspaces;

export const workspacesSelector = (store: RootState) => store.workspaces.workspaces;
export const workspacesLoadingSelector = (store: RootState) => store.workspaces.loading;