const Disc = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_497_776)">
                <path
                    opacity="0.2"
                    d="M12.5 10C12.5 10.5784 12.2995 11.1389 11.9325 11.586C11.5656 12.0331 11.055 12.3391 10.4877 12.452C9.92045 12.5648 9.3316 12.4775 8.82151 12.2048C8.31142 11.9322 7.91164 11.4911 7.6903 10.9567C7.46896 10.4224 7.43975 9.82778 7.60765 9.2743C7.77555 8.72082 8.13016 8.24267 8.61107 7.92134C9.09199 7.6 9.66944 7.45536 10.245 7.51205C10.8206 7.56874 11.3588 7.82326 11.7678 8.23224L15.3033 4.69671C14.0764 3.46976 12.4619 2.7062 10.7351 2.53613C9.00832 2.36605 7.27596 2.79998 5.83322 3.76399C4.39049 4.728 3.32664 6.16243 2.82295 7.82288C2.31926 9.48333 2.40689 11.2671 3.07091 12.8701C3.73493 14.4732 4.93425 15.7965 6.46453 16.6144C7.99481 17.4324 9.76136 17.6944 11.4632 17.3559C13.165 17.0174 14.6968 16.0993 15.7976 14.758C16.8984 13.4167 17.5 11.7352 17.5 10H12.5Z"
                    fill="#303030"
                />
                <path
                    d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                    stroke="#303030"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                    stroke="#303030"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.5 10H12.5"
                    stroke="#303030"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.3032 4.69672L11.7677 8.23226"
                    stroke="#303030"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_497_776">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Disc;
