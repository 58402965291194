import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';

import request from 'utils/request';
import { KBTypes } from 'utils/types';

export const addKnowledge = createAsyncThunk(
    'kb/addKnowledge',
    async (data: { blockIds: number[], type: KBTypes, name: string }, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/kb`;
        const response = await request.post(
            url,
            data,
            {
                timeout: 1000 * 180,
            }
        );

        return response.data;
    }
);

export const getKnowledgeByType = createAsyncThunk(
    'kb/getKnowledgeByType',
    async (type: KBTypes, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/kb/${type}/items`;
        const response = await request.get(url);

        return response.data;
    }
);

export const getGeneralKnowledge = createAsyncThunk(
    'kb/getGeneralKnowledge',
    async (_, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/kb/general/sources`;
        const response = await request.get(url);

        return response.data;
    }
);

export const getKBSources = createAsyncThunk(
    'kb/getKBSources',
    async (type: KBTypes, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/kb/${type}/sources`;
        const response = await request.get(url);

        return response.data;
    }
);

export const deleteKnowledgeSource = createAsyncThunk(
    'kb/deleteKnowledgeSource',
    async ({ kbId, datasourceId }: { kbId: number, datasourceId: number }, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/kb/${kbId}/sources/${datasourceId}`;
        const response = await request.delete(url);

        return response.data;
    }
);

export const deleteBlockFromKB = createAsyncThunk(
    'kb/deleteBlockFromKB',
    async (blockId: number, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/blocks/${blockId}`;
        const response = await request.delete(url);

        return response.data;
    }
);

export const editKBLinkAttachment = createAsyncThunk(
    'kb/editKBLinkAttachment',
    async ({ blockId, data }: {
        blockId: number,
        data: {
            url: string,
            title: string
        }
    }, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/blocks/link/${blockId}`;
        const response = await request.put(url, data);

        return response.data;
    }
);

