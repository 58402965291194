const PriorityMediumIcon = () => {
    return (
        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 1.08656C0 0.486468 0.475474 0 1.062 0H16.938C17.5245 0 18 0.486468 18 1.08656C18 1.68664 17.5245 2.17311 16.938 2.17311H1.062C0.475474 2.17311 0 1.68664 0 1.08656Z" fill="#FFA500"/>
            <path d="M0 8.91344C0 8.31336 0.475474 7.82689 1.062 7.82689H16.938C17.5245 7.82689 18 8.31336 18 8.91344C18 9.51353 17.5245 10 16.938 10H1.062C0.475474 10 0 9.51353 0 8.91344Z" fill="#FFA500"/>
        </svg>

    )
}

export default PriorityMediumIcon;