import { Dispatch, GetState } from 'store';
import { ACTION_ADD_IDEA, ACTION_DELETE_IDEA, ACTION_EDIT_IDEA, ACTION_GET_IDEAS, ACTION_SET_IDEAS } from 'store/ideas';
import request from 'utils/request';
import { IdeaSaveModel } from 'utils/types';

export const getIdeas = () => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    dispatch({ type: ACTION_GET_IDEAS, payload: null })
    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/ideas`);

    dispatch({ type: ACTION_SET_IDEAS, payload: res.data })

}

export const deleteIdea = (id: number) => async (dispatch: Dispatch, getState: GetState) => {
    try{
        const storeState = getState();
        const res = await request.delete(`/workspaces/${storeState.user.workspace.id}/ideas/${id}`)

        dispatch({ type: ACTION_DELETE_IDEA, payload: id })

        return res;
    } catch(err: any) {
        console.log(err);
        return { error: err.message }
    }

}

export const createIdea = (idea: IdeaSaveModel) => async (dispatch: Dispatch, getState: GetState) => {
    try{
        const storeState = getState();
        const res = await request.post(`/workspaces/${storeState.user.workspace.id}/ideas`, idea)
        if(res.data) {
            dispatch({ type: ACTION_ADD_IDEA, payload: res.data })
        }
        return res.data;

    } catch(err: any) {
        console.log(err);
        return { error: err.message }
    }

}

export const editIdea = (ideaId: number, idea: IdeaSaveModel) => async (dispatch: Dispatch, getState: GetState) => {
    try{
        const storeState = getState();
        const res = await request.put(`/workspaces/${storeState.user.workspace.id}/ideas/${ideaId}`, idea)
        if(res.data) {
            dispatch({ type: ACTION_EDIT_IDEA, payload: res.data })
        }
        return res.data;

    } catch(err: any) {
        console.log(err);
        return { error: err.message }
    }

}