import { ActionsMenu, ConfirmationDialog, CustomTyphography, Flexbox, Link } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind';
import { DescriptionIcon } from 'components/icons';
import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteKnowledgeSource } from 'store/knowledgeBase-api';
import { deleteKbSource, deleteSourceLoadingSelector } from 'store/knowledgeBase-slice';
import { KBSource, KBTypes } from 'utils/types';
import { Actions, hasPermission } from 'utils/permissions';
import { ButtonItem } from 'components/ActionsMenu';

const classes = classNames.bind(styles);

interface SourceProps {
    source: {
        id: number,
        name: string,
        sources: KBSource[]
    },
    kbType?: KBTypes,
}

const Source: FC<SourceProps> = ({ source, kbType }) => {
    const dispatch = useDispatch();

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [currentSourceId, setCurrentSourceId] = useState<number | null>(null)

    const deleteSourceLoading = useSelector(deleteSourceLoadingSelector)

    const handleDeleteSource = async () => {
        if (currentSourceId) {
            try {
                await dispatch(deleteKnowledgeSource({ kbId: source.id, datasourceId: currentSourceId }))
                dispatch(deleteKbSource({ kbId: source.id, sourceId: currentSourceId, kbType }))
            } catch (err) {
                console.log(err, 'err');
            }
        }

        setOpenDeleteConfirmation(false)
        setCurrentSourceId(null)
    }


    const showDeleteConfirmation = (id: number) => {
        setCurrentSourceId(id)
        setOpenDeleteConfirmation(true)
    }

    const onCancelDelete = () => {
        setOpenDeleteConfirmation(false)
    }

    return (
        <Flexbox vertical className={classes('gap-2')}>
            <CustomTyphography className={classes('sourceName')}>{source.name}</CustomTyphography>
            <Flexbox vertical className={classes('sourceContainer', 'gap-2')}>
                {
                    source.sources.map(el => {
                        const buttonItems: ButtonItem[] = []

                        if (hasPermission(Actions.delete)) {
                            buttonItems.push({
                                action: () => { showDeleteConfirmation(el.id) },
                                type: 'red',
                                label: 'Delete',
                            })
                        }

                        return (
                            <Link to={el.source} target='_blank'>
                                <Flexbox justifyBetween align className={classes('sourceLink-box', 'gap-4', 'p-3',
                                    // { error: source.withError }
                                )}
                                >
                                    <Flexbox align className={classes('gap-2')}>
                                        <Flexbox align justify className={classes('sourceType-iconBox')}>
                                            <DescriptionIcon />
                                        </Flexbox>
                                        <CustomTyphography type='primary' className={classes('source-title')}>{el.name}</CustomTyphography>
                                    </Flexbox>
                                    {!!buttonItems?.length && <ActionsMenu buttonItems={buttonItems} />}
                                </Flexbox>
                            </Link>
                        )
                    })
                }
            </Flexbox>
            {/* <Link to={source.sources[0].source} target='_blank'>
                <Flexbox justifyBetween align className={classes('sourceLink-box', 'gap-4', 'p-3',
                    // { error: source.withError }
                )}
                >
                    <Flexbox align className={classes('gap-2')}>
                        <Flexbox align justify className={classes('sourceType-iconBox')}>
                            <DescriptionIcon />
                        </Flexbox>
                        <CustomTyphography type='primary' className={classes('source-title')}>{source.name}</CustomTyphography>
                    </Flexbox>
                    <ActionsMenu
                        buttonItems={[
                            // {
                            //     label: 'Update Source',
                            //     action: () => { }
                            // },
                            {
                                action: () => { showDeleteConfirmation(source.id) },
                                type: 'red',
                                label: 'Delete',
                            }
                        ]}
                    />
                </Flexbox>
            </Link> */}
            {/* {source.withError && <Flexbox vertical className={classes('gap-6', 'sourceErrorSection')}>
                <Flexbox className={classes('errorText')}>
                    Issue with this link. It may be broken due to changes. Please update or remove it.
                </Flexbox>
                <Flexbox className={classes('primaryText', 'gap-1')}>You need to <Link className={classes('linkText')} to={'/'}><span>contact admin</span></Link></Flexbox>
            </Flexbox>
            } */}

            <ConfirmationDialog
                open={openDeleteConfirmation}
                onClose={onCancelDelete}
                confirmButtonLoading={deleteSourceLoading}
                onConfirm={handleDeleteSource}
                confirmButtonStyle='danger'
                title='Delete the source?'
            >
                <Flexbox>
                    Are you sure you want to delete the source?
                </Flexbox>
            </ConfirmationDialog>
        </Flexbox>
    )
}

export default Source