const MicrosoftIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_21406_33181)">
                <path d="M12.75 11.25H24V0.75C24 0.551088 23.921 0.360322 23.7803 0.21967C23.6397 0.0790176 23.4489 0 23.25 0L12.75 0V11.25Z" fill="#4CAF50"/>
                <path d="M11.25 11.25V0H0.75C0.551088 0 0.360322 0.0790176 0.21967 0.21967C0.0790176 0.360322 0 0.551088 0 0.75L0 11.25H11.25Z" fill="#F44336"/>
                <path d="M11.25 12.75H0V23.25C0 23.4489 0.0790176 23.6397 0.21967 23.7803C0.360322 23.921 0.551088 24 0.75 24H11.25V12.75Z" fill="#2196F3"/>
                <path d="M12.75 12.75V24H23.25C23.4489 24 23.6397 23.921 23.7803 23.7803C23.921 23.6397 24 23.4489 24 23.25V12.75H12.75Z" fill="#FFC107"/>
            </g>
            <defs>
                <clipPath id="clip0_21406_33181">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default MicrosoftIcon;