import { PriorityCriticalIcon, PriorityHightIcon, PriorityLowIcon, PriorityMediumIcon } from 'components/icons'
import { InitiativePriority } from 'utils/types'


interface PriorityIconProps{
    priority: InitiativePriority
}

const PriorityIcon = ({ priority } : PriorityIconProps) => {

    if(priority === InitiativePriority.Low) {
        return <PriorityLowIcon/>
    }
    if(priority === InitiativePriority.Medium) {
        return <PriorityMediumIcon/>
    }
    if(priority === InitiativePriority.High) {
        return <PriorityHightIcon/>
    }
    if(priority === InitiativePriority.Critical) {
        return <PriorityCriticalIcon/>
    }
    return null
}

export default PriorityIcon