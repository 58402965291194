import { DependencyAcceptedIcon, DependencyDeclineIcon, DependencyOpenIcon, DependencyResolveIcon } from 'components/icons'
import { DependencyStatuses } from 'utils/types'

import styles from './dependencyStatusIcon.module.scss';
import classNames from 'classnames/bind';
const classes = classNames.bind(styles);


interface DependencyStatusIconProps{
    status?: DependencyStatuses;
}

const DependencyStatusIcon = ({ status } : DependencyStatusIconProps) => {

    if(status === DependencyStatuses.Open) {
        return(
            <DependencyOpenIcon className={classes('icon', 'icon-orange')} />
        )
    }
    if(status === DependencyStatuses.Resolved) {
        return (
            <DependencyResolveIcon className={classes('icon', 'icon-green')} />
        )
    }
    if(status === DependencyStatuses.Declined) {
        return (
            <DependencyDeclineIcon className={classes('icon', 'icon-red')} />
        )
    }
    if(status === DependencyStatuses.Accepted) {
        return (
            <DependencyAcceptedIcon className={classes('icon', 'icon-blue')} />
        )
    }
    return null
}

export default DependencyStatusIcon