import { Button, Flexbox, SearchField } from 'components'
import { ChangeEvent, FC, useState } from 'react';
import classNames from 'classnames/bind';
import styles from 'common/infoHeader/infoHeader.module.scss';
import SavingStatus from 'common/savingStatus';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import { PlusIcon } from 'components/icons';
import UsersInviteDialog from './usersInviteDialog';
import { GridApi } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import { usersSavingStatusSelector } from 'store/users-slice';
import { useSearchParams } from 'react-router-dom';

const classes = classNames.bind(styles);

interface UsersHeaderProps {
    gridApi: GridApi<any> | null,
    updateQueryPreference: (value: string) => void,
    searchValue: string
}

const UsersHeader: FC<UsersHeaderProps> = ({ gridApi, updateQueryPreference, searchValue }) => {
    const savingStatus = useSelector(usersSavingStatusSelector);

    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const updateQueryParam = (value: string) => {
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if(!!value) {
            modifiedSearchParams.set('query', value)
        } else {
            modifiedSearchParams.delete('query')
        }

        setSearchParams(modifiedSearchParams, { replace: true });
        updateQueryPreference(value);
    }

    const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateQueryParam(e.target.value)
    }

    const handleSearchClear = () => {
        updateQueryParam('')
    }

    return (
        <Flexbox vertical className={classes('headerContainer')}>
            <Flexbox className={classes('headerInfoTop')}>
                <Flexbox className={classes('headerTitle')}>Users</Flexbox>
                <Flexbox>
                    { gridApi && <ExportButton api={gridApi}/> }
                    { savingStatus ? <SavingStatus savingStatus={savingStatus} /> : null}
                    <Button variant='contained' className={classes('createButton')} onClick={() => setInviteDialogOpen(true)}>Invite user<PlusIcon/></Button>
                </Flexbox>
            </Flexbox>
            <Flexbox className={classes('headerInfo')}>
                <SearchField
                    value={searchValue}
                    onChange={handleSearchValueChange}
                    onClear={handleSearchClear}
                    placeholder='Search User'
                />
            </Flexbox>
            <UsersInviteDialog inviteDialogOpen={inviteDialogOpen} setInviteDialogOpen={setInviteDialogOpen} />
        </Flexbox>
    )
}

export default UsersHeader;