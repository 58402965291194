import classNames from 'classnames/bind';
import { FC, PropsWithChildren, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFieldComments } from 'store/comments-api';
import { v4 as uuid } from 'uuid';
import {
    selectFieldToolbar,
    startCommentCreation,
    useIsCreatingComment,
} from '../../../store/comments-slice';
import { createCustomEvent } from '../../../utils/comments';
import { CommentFieldOptions, Initiative } from '../../../utils/types';
import { CommentFieldToolbar } from '../CommentFieldToolbar/CommentFieldToolbar';
import styles from './commentField.module.scss';
const classes = classNames.bind(styles);

export interface CommentFieldProps extends CommentFieldOptions {
    initiativeId: Initiative['id'];
    rightGutter?: boolean;
    hideCommentToolbar?: boolean
}

export const CommentField: FC<PropsWithChildren<CommentFieldProps>> = ({
    workspaceId,
    initiativeId,
    objectKey,
    objectId,
    fieldKey,
    children,
    rightGutter,
    hideCommentToolbar
}) => {
    const dispatch = useDispatch();
    const { comments, isSelected, isHighlighted } = useFieldComments(
        { workspaceId, objectKey, objectId },
        fieldKey,
        initiativeId
    );
    const isCreatingCommentForThisField = useIsCreatingComment({
        workspaceId,
        objectKey,
        objectId,
        fieldKey,
    });

    const [isHovering, setIsHovering] = useState(false);

    const hasComments = comments.length > 0;

    const shouldShowPin = !hideCommentToolbar && (hasComments || isHovering || isCreatingCommentForThisField);
    const shouldHighlightNewComment = hasComments || isHovering || isCreatingCommentForThisField;
    const shouldShowNewCommentButton = isHovering || isCreatingCommentForThisField;

    const handleNewCommentClick = shouldShowNewCommentButton
        ? () => {
            const temporaryId = uuid();
            dispatch(
                startCommentCreation({ workspaceId, objectKey, objectId, fieldKey, temporaryId })
            );
            document.dispatchEvent(createCustomEvent('comment-create-start', {}));
        }
        : undefined;

    const handleToolbarClick = () => {
        dispatch(selectFieldToolbar({ comment: comments[0] }));
    };

    const isOutlined = isHighlighted || isSelected || isCreatingCommentForThisField;

    return (
        <div
            className={classes('comment-field', {
                'new-comment': shouldHighlightNewComment,
                'outlined': isOutlined,
            })}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            {children}
            {shouldShowPin && (
                <div className={classes('pin-container', { 'right-gutter': rightGutter })}>
                    <CommentFieldToolbar
                        outlined={isOutlined}
                        comments={comments}
                        onClick={handleToolbarClick}
                        onNewComment={handleNewCommentClick}
                    />
                </div>
            )}
        </div>
    );
};
