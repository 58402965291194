import { FC } from 'react';
import classNames from 'classnames/bind';
import styles from '../../../initiative.module.scss';
import { riskLevel } from './constants';
import { Box, Grid, Typography } from 'components';

const classes = classNames.bind(styles);

interface InitiativeStateProps {
    label: string;
    value: string | number;
    type: string
}

const InitiativeState: FC<InitiativeStateProps> = ({
    label,
    value,
    type
}) => {
    return (
        <Grid item xs={6}>
            <Box className={classes('initiativeState')}>
                <Typography className={classes('stateLabel')}>{label}</Typography>
                {
                    type === 'overallRisk' ? (
                        <Typography className={classes('stateValue', riskLevel[value].color)}>
                            {riskLevel[value].label}
                        </Typography>
                    ) : <Typography className={classes('stateValue')}>{value}</Typography>
                }
            </Box>
        </Grid>
    )
}

export default InitiativeState