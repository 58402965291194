
import { Flexbox, IconButton, Loader, Menu, MenuItem } from 'components'
import { MoreVertIcon } from 'components/icons'
import { useState, MouseEvent, ReactNode } from 'react';
import styles from './actionsMenu.module.scss';
const classes = classNames.bind(styles);
import classNames from 'classnames/bind';


interface ActionMenuProps {
    buttonItems: ButtonItem[];
    className?: string;
    menuTarget?: (e: MouseEvent<HTMLElement | null>) => void;
    disabled?: boolean
    btnClassName?: string
    activeClassName?: string
}

export interface ButtonItem {
    label: string;
    action: (e: MouseEvent<HTMLElement>) => (void | Promise<void>);
    type?: 'red';
    disabled?: boolean;
    icon?: ReactNode;
    isLoading?: boolean
}


const ActionsMenu = ({ buttonItems, className = '', menuTarget, disabled, btnClassName = '', activeClassName = '' }: ActionMenuProps) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const openMenu = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);

        if (menuTarget) {
            menuTarget(event)
        }

    };

    const close = (e: MouseEvent<HTMLElement>) => {
        setAnchorEl(null);
        e.stopPropagation()
    };

    const onClickHandler = (action: (e: MouseEvent<HTMLElement>) => void) => async (e: MouseEvent<HTMLElement>) => {
        await action(e);
        close(e);
    }

    return (
        <Flexbox className={classes('actionsMenu', className)}>
            <IconButton
                className={classes('iconButton', btnClassName, { active: open, [activeClassName]: open })}
                disableRipple
                size="small"
                onClick={openMenu}
                disabled={disabled}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                className={classes('menu')}
                anchorEl={anchorEl}
                open={open}
                onClick={close}
                PaperProps={{
                    style: {
                        minWidth: '150px',
                    },
                }}
            >
                {buttonItems.map((c) => (
                    <MenuItem
                        className={classes('menuItem', c.type || '')}
                        key={c.label}
                        value={c.label}
                        disabled={c.disabled}
                        onClick={onClickHandler(c.action)}>
                        {c.icon && <Flexbox className={classes('menuItemIcon')}>{c.icon}</Flexbox>}
                        {c.label}
                        {c.isLoading && <Loader className={classes('menuItemLoader')} disableShrink size={16} />}
                    </MenuItem>
                ))}
            </Menu>
        </Flexbox>
    )
}

export default ActionsMenu

