import { GridReadyEvent, SortChangedEvent } from 'ag-grid-community'
import { GridApi } from 'ag-grid-enterprise'
import classNames from 'classnames/bind'
import EmptyState from 'common/emptyState'
import { Flexbox, Loader } from 'components'
import AgGridTable, { AgColumn, ColumnTypes, GridStatePreferences } from 'components/AgGridTable'
import { DataScience } from 'components/icons'
import AddKnowledgeSourcePopup from 'pages/KnowledgeBase/components/AddKnowledgeSourcePopup'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Actions, hasPermission } from 'utils/permissions'
import styles from './styles.module.scss'
import KBGeneralHeader from './KBGeneralHeader'
import { KBTypes } from 'utils/types'
import { useDispatch, useSelector } from 'react-redux'
import { getKnowledgeLoadingSelector, knowledgeItemsSelector } from 'store/knowledgeBase-slice'
import { getGeneralKnowledge } from 'store/knowledgeBase-api'

const classes = classNames.bind(styles);

let timeoutId: NodeJS.Timeout;

export enum FilterKeys {
    query = 'query',
    order = 'order',
    orderBy = 'orderBy',
    kbGeneral = 'kbGeneral',
}

export interface IPreferencesData {
    query?: string,
    order?: 'asc' | 'desc',
    orderBy?: keyof Node,
    kbGeneral?: any,
}

const KBGeneral = () => {
    const dispatch = useDispatch();
    const preferencesRef = useRef<IPreferencesData>({});

    const [searchParams, setSearchParams] = useSearchParams();

    const knowledgeItems = useSelector(knowledgeItemsSelector);
    const getKnowledgeLoading = useSelector(getKnowledgeLoadingSelector)

    const [openAddSourcePopup, setOpenAddSourcePopup] = useState(false);
    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);
    const [debouncedQuery, setDebouncedQuery] = useState<null | string>(null);
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState(knowledgeItems)

    useEffect(() => {
        dispatch(getGeneralKnowledge())
    }, [])

    const columns: AgColumn[] = useMemo(() => [
        {
            headerName: 'Name',
            field: 'name',
            minWidth: 250,
            sortable: true,
            pinned: 'left' as boolean | 'left' | 'right' | null | undefined,
            suppressMovable: true,
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell',
        },
        {
            colType: ColumnTypes.Sources,
            headerName: 'Sources',
            field: 'sources',
            minWidth: 150,
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell',
        },
        {
            colType: ColumnTypes.Date,
            headerName: 'Created Date',
            field: 'createdDate',
            minWidth: 130,
            sortable: true,
        },
        {
            colType: ColumnTypes.Date,
            headerName: 'Updated Date',
            field: 'lastModifiedDate',
            minWidth: 130,
            sortable: true,
        },
    ], []);


    useEffect(() => {
        if (debouncedQuery !== null) {
            // dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.executionView));
        }

        return () => clearTimeout(timeoutId);
    }, [debouncedQuery])

    useEffect(() => {
        if (knowledgeItems) {
            const filteredItems = knowledgeItems.filter((item: any) => item.name?.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase()))
            setFilteredData(filteredItems)
        }
    }, [searchValue, knowledgeItems])

    // _____________GRID Functions______________
    const buttonItemsEmptyState = useMemo(() => {
        const buttons = []

        if (hasPermission(Actions.create)) {
            buttons.push({
                onClick: () => { setOpenAddSourcePopup(true) },
                text: 'Add Knowledge Source',
            })
        }
        return buttons
    }, [])

    const updateQueryPreference = useCallback((value: string) => {
        setSearchValue(value);
        preferencesRef.current.query = value;

        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setDebouncedQuery(value);
        }, 500);

    }, [preferencesRef])

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    const onSortChanged = useCallback((e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        // if (preferencesRef.current.order !== value?.sort || preferencesRef.current.orderBy !== value?.colId) {
        //     preferencesRef.current = { executionViewGridLayout: preferencesRef.current.executionViewGridLayout, ...modifiedSearchParamsObject }
        //     dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.executionViewGridLayout));
        // }
    }, [preferencesRef, searchParams])

    const onGridStateChanged = useCallback((data: GridStatePreferences) => {
        // preferencesRef.current.executionViewGridLayout = data

        // dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.executionViewGridLayout));
    }, [preferencesRef]);

    return (
        <Flexbox fullWidth vertical>
            {
                getKnowledgeLoading ? (
                    <Flexbox fullWidth fullHeight align justify>
                        <Loader disableShrink />
                    </Flexbox>
                ) : knowledgeItems.length === 0 ? (
                    <EmptyState
                        icon={<DataScience />}
                        title='General Knowledge'
                        titleSmall={'All your General knowledge will be imported from the link'}
                        buttonItems={buttonItemsEmptyState}
                    />
                ) : (
                    <>
                        <KBGeneralHeader
                            gridApi={gridApi}
                            updateQueryPreference={updateQueryPreference}
                            searchValue={searchValue}
                            emptyState={Array.isArray(knowledgeItems) && knowledgeItems.length === 0}
                        />
                        <Flexbox fullHeight className={classes('kbGeneralTableContainer')}>
                            <AgGridTable
                                data={filteredData}
                                columns={columns}
                                onGridReady={onGridReady}
                                exportFileName={'General Knowledge Base'}
                                onSortChanged={onSortChanged}
                                order={preferencesRef.current.order}
                                orderBy={preferencesRef.current.orderBy}
                                gridStatePreferences={preferencesRef.current.kbGeneral}
                                onGridStateChanged={onGridStateChanged}
                            />
                        </Flexbox>
                    </>
                )
            }
            <AddKnowledgeSourcePopup
                open={openAddSourcePopup}
                handleClosePopup={() => setOpenAddSourcePopup(false)}
                kbType={KBTypes.GENERAL}
                description='General knowledge will be imported from the link.'
            />
        </Flexbox>
    )
}

export default KBGeneral