import EmptyState from 'common/emptyState'
import classNames from 'classnames/bind';
import styles from '../report.module.scss'
import { Flexbox, Loader, RichTextEditor } from 'components'
import TabPanel from 'components/TabPanel'
import { EmptyReportIcon, GenerateIcon } from 'components/icons'
import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { currentReportSummarySelector, updateCurrentReport } from 'store/report-slice';
import { AIStreamingEndCharCode } from 'utils/constants';
import { generateSummary } from '../reports.api';
const classes = classNames.bind(styles)

interface ReportSummaryProps {
    reportId: number,
}

const ReportSummary: FC<ReportSummaryProps> = ({ reportId }) => {
    const dispatch = useDispatch();

    const generatedSummary = useRef('');
    const scrollingContainerRef = useRef<HTMLDivElement>(null);

    const summary = useSelector(currentReportSummarySelector);

    const [summaryLoading, setSummaryLoading] = useState<boolean>(false);

    const onSummaryChange = (value: string) => {
        dispatch(updateCurrentReport({ summary: value }))
    }

    const onGenerateSummary = async () => {
        setSummaryLoading(true)
        dispatch(generateSummary(reportId, (value: string) => {
                setSummaryLoading(false);
                if (value.charCodeAt(0) === AIStreamingEndCharCode) {
                    generatedSummary.current = '';
                    return;
                }
                generatedSummary.current += value;
                onSummaryChange(generatedSummary.current)
            },
            () => setSummaryLoading(false)
        ))
    }

    useEffect(() => {
        if(scrollingContainerRef.current) {
            scrollingContainerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, [summary, scrollingContainerRef.current]);

    return (
        <TabPanel active ref={scrollingContainerRef}>
            <Flexbox vertical fullWidth className={classes('summaryTabPanelBox')} >
                { summary ? (
                        <RichTextEditor
                            value={summary || ''}
                            onChange={onSummaryChange}
                            placeholder='Summary'
                            files={[]}
                            disabled
                            className={classes('summaryEditor')}
                        />
                    ) : summaryLoading ? (
                        <Flexbox fullWidth align justify fullHeight><Loader disableShrink/></Flexbox>
                    ) : (
                        <EmptyState
                            icon={<EmptyReportIcon />}
                            title='There is no summary generated for this report instance.'
                            buttonItems={[
                                {
                                    onClick: onGenerateSummary,
                                    text: 'Generate summary',
                                    icon: <GenerateIcon />,
                                },
                            ]}
                        />
                    )
                }
            </Flexbox>
        </TabPanel>
    )
}

export default ReportSummary;