import { Flexbox, Loader } from 'components'
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLazyGetReportQuery } from 'store/report-api';
import { useWorkspaceId } from 'utils/hooks';
import { reportsSelector } from 'store/report-slice';
import ReportHeader from './components/ReportHeader';
import ReportFilterPanel from './components/ReportFilterPanel';
import ReportSummary from './components/ReportSummary';
import ReportTabs from './components/ReportTabs';
import ReportTabsContent from './components/ReportTabsContent';

const urlTabParamKey = 'tab'

const ReportMain = () => {
    const params = useParams();
    const workspaceId = useWorkspaceId();

    const reportId = params['id'] ? parseInt(params['id']) : undefined;

    const [getReport, { isLoading }] = useLazyGetReportQuery();

    const reports = useSelector(reportsSelector);

    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get(urlTabParamKey);

    useEffect(() => {
        if(reportId){
            getReport({ workspaceId, reportId })
        }
    }, [reportId])

    const tabsList = useMemo(() => {
        if(reports.length){
            const tabsNames = reports[0].data[`${reports[0].type}s`].map(d => d.id + '')
            tabsNames.unshift('summary')
            return tabsNames
        } else {
            return []
        }
    }, [reports])

    useEffect(() => {
        if(tabsList.length > 0 && (!tab || !tabsList.includes(tab))) {
            setSearchParams(`${urlTabParamKey}=${tabsList[0]}`, { replace: true })
        }
    }, [tabsList, tab])

    const selectedTab = tabsList.indexOf(searchParams.get(urlTabParamKey) || '');

    const onTabChange = useCallback((_event: ChangeEvent<{}>, value: number) => {
        setSearchParams(`${urlTabParamKey}=${tabsList[value]}`, { replace: true })
    }, [urlTabParamKey, tabsList]);

    return isLoading ? (
            <Flexbox fullWidth align justify fullHeight><Loader disableShrink/></Flexbox>
        ) : (
            <Flexbox fullWidth vertical>
                {
                    reportId && !!reports.length && (
                        <>
                            <ReportHeader reportId={reportId} />
                            <ReportFilterPanel />
                            <ReportTabs selectedTab={selectedTab} onTabChange={onTabChange} />
                            {selectedTab ? <ReportTabsContent selectedTab={selectedTab} /> : <ReportSummary reportId={reportId} /> }
                        </>
                    )
                }
            </Flexbox>
        )
}

export default ReportMain;