import { ActionsMenu, Flexbox } from 'components'
import { Block, LinkMetadata } from 'utils/types';
import { useState, MouseEvent, useMemo, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import LinkPopover from './linkPopover';
import { useDispatch } from 'react-redux';
import { deleteAction } from 'common/resourcesBlock/index.api';
import { ButtonItem } from 'components/ActionsMenu';
const classes = classNames.bind(styles);

interface LinkItemProps{
    link: Block<LinkMetadata>;
    baseUrl: string;
    isEditable: boolean;
    deleteLinkAction: (id: number) => void;
    editLinkAction: (data: any) => void;
    createLinkAction: (data: Block<LinkMetadata>) => void
}

const LinkItem = ({ link, baseUrl, isEditable, deleteLinkAction, editLinkAction, createLinkAction }: LinkItemProps) => {
    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const refMenuTarget = useRef<HTMLElement | null>(null);

    const deleteLink = () => {
        dispatch(deleteAction(link.id, baseUrl))
        deleteLinkAction(link.id)
    }

    const menuTargetHandler = (e: MouseEvent<HTMLElement | null>) => {
        refMenuTarget.current = e.currentTarget
    }

    const showLinkPopover = () => {
        setAnchorEl(refMenuTarget.current)
    }

    const actionMenuButtons = useMemo(() => {
        const buttons: ButtonItem[] = [];

        if(isEditable){
            buttons.push({ label: 'Edit', action: showLinkPopover, }, { label: 'Delete', action: deleteLink, type: 'red' })
        }
        return buttons
    },[])

    return(
        <Flexbox className={classes('linkContainer')}>
            <a className={classes('linkText')} href={link.metadata.url} target="_blank" rel="noopener">
                {link.metadata?.title || link.metadata.url}
            </a>
            {actionMenuButtons.length > 0 &&
                <ActionsMenu
                    className={classes('linkActionsMenu')}
                    menuTarget={menuTargetHandler}
                    buttonItems={actionMenuButtons}
                />
            }
            <LinkPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} linkData={link} baseUrl={baseUrl} editLinkAction={editLinkAction} createLinkAction={createLinkAction} />
        </Flexbox>
    )
}

export default LinkItem