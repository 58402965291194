const EmptyResources = () => {
    return (
        <svg
            width={197}
            height={157}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse cx={91.632} cy={154.989} rx={62.233} ry={2.013} fill="#D9D9D9" />
            <rect
                x={110.676}
                y={8.779}
                width={91.263}
                height={103.34}
                rx={5}
                transform="rotate(22.937 110.676 8.78)"
                fill="#fff"
                stroke="#EBEBEB"
                strokeWidth={2}
                strokeDasharray="10 4"
            />
            <rect
                x={1.299}
                y={39.615}
                width={91.263}
                height={103.34}
                rx={5}
                transform="rotate(-21.668 1.299 39.615)"
                fill="#fff"
                stroke="#EBEBEB"
                strokeWidth={2}
                strokeDasharray="10 4"
            />
            <rect
                x={41.258}
                y={1}
                width={91.263}
                height={103.34}
                rx={5}
                fill="#fff"
                stroke="#EBEBEB"
                strokeWidth={2}
                strokeDasharray="10 4"
            />
            <path
                d="m68.488 26.836 36.567 36.567M68.488 63.063l36.567-36.568"
                stroke="#EBEBEB"
            />
        </svg>
    )
}

export default EmptyResources;