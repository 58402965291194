const EmptyReport = () => {
    return (
        <svg width="145" height="175" viewBox="0 0 145 175" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M142.307 27.465C133.864 21.0222 114.093 2.57884 113.899 2.40184L109.952 0.773438H17.6638C8.12345 0.773438 0.40625 8.68534 0.40625 18.2433V156.693C0.40625 166.233 8.12345 174.251 17.6638 174.251H127.262C136.802 174.251 144.608 166.251 144.608 156.693V32.0493L142.307 27.465ZM127.262 162.481H17.6638C14.4778 162.481 11.929 159.861 11.929 156.675V18.2433C11.929 15.0573 14.4955 12.5262 17.6638 12.5262H104.057V35.5539L109.952 41.5542H133.085V156.693C133.085 159.861 130.448 162.481 127.262 162.481Z" fill="#EBEBEB"/>
            <path d="M46.5157 65.2038C43.7899 65.2038 41.6305 64.7435 39.3118 62.4249L28.4086 51.5217C26.1607 49.2738 26.1607 45.6099 28.4086 43.362C30.6565 41.1141 34.3204 41.1141 36.5683 43.362L45.9316 52.7253L66.4459 32.2641C68.6938 30.0161 72.3577 30.0161 74.6056 32.2641C76.8535 34.512 76.8535 38.1759 74.6056 40.4238L52.9408 62.0886C52.6222 62.4072 49.7371 65.2038 46.5157 65.2038Z" fill="#EBEBEB"/>
            <path d="M104.183 99.1356H29.1882C26.0022 99.1356 23.418 96.4452 23.418 93.2592C23.418 90.0732 26.0022 87.3828 29.1882 87.3828H104.183C107.369 87.3828 109.953 90.0732 109.953 93.2592C109.953 96.4452 107.369 99.1356 104.183 99.1356Z" fill="#EBEBEB"/>
            <path d="M115.723 139.224H29.1882C26.0022 139.224 23.418 136.657 23.418 133.471C23.418 130.285 26.0022 127.719 29.1882 127.719H115.723C118.909 127.719 121.494 130.285 121.494 133.471C121.494 136.64 118.909 139.224 115.723 139.224Z" fill="#EBEBEB"/>
        </svg>
    )
}

export default EmptyReport;