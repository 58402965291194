import { Dispatch, GetState } from 'store';
import request from 'utils/request';
import { InitiativeMockupCreateModel, InitiativeMockupEditModel } from 'utils/types';

export const getMockups = (initiativeId: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()

    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/initiatives/${initiativeId}/mockups`);

    return res.data
}

export const createMockup = (initiativeId: number, mockup: InitiativeMockupCreateModel) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()

    const res = await request.post(`/workspaces/${storeState.user.workspace.id}/initiatives/${initiativeId}/mockups`, mockup, { timeout: 2 * 60 * 1000 });

    return res.data
}

export const deleteMockup = (initiativeId: number, mockupId: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()

    const res = await request.delete(`/workspaces/${storeState.user.workspace.id}/initiatives/${initiativeId}/mockups/${mockupId}`);

    return res.data
}

export const editMockup = (initiativeId: number, mockupId: number, change: InitiativeMockupEditModel) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()

    const res = await request.put(`/workspaces/${storeState.user.workspace.id}/initiatives/${initiativeId}/mockups/${mockupId}`, change, { timeout: 2 * 60 * 1000 });

    return res.data
}
