import { IconButton } from 'components';
import Loader from 'components/Loader';
import { SendIcon } from 'components/icons/Send';
import { FC, MouseEventHandler } from 'react';

interface CommentSendButtonProps {
    className?: string;
    loading?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

const CommentSendButton: FC<CommentSendButtonProps> = ({ className, loading, onClick }) => {
    if (loading) {
        return <Loader size={13} />;
    }
    return (
        <IconButton className={className} disabled={loading} onClick={onClick} disableRipple>
            <SendIcon />
        </IconButton>
    );
};

export default CommentSendButton;
