
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { Collapse, Flexbox } from 'components';
import { ReactNode, useState } from 'react';
import { ArrowCollapsedIcon, ArrowExpendedIcon } from 'components/icons';
const classes = classNames.bind(styles);

interface CollapseItemProps{
    children: ReactNode;
    title: string;
}

const CollapseItem = ({ children, title }: CollapseItemProps) => {
    const [expand, setExpand] = useState(true);

    const handleExpand = () => {
        setExpand(!expand)
    }

    return(
        <Flexbox vertical className={classes('container')}>
            <Flexbox className={classes('head')}>
                <Flexbox className={classes('title')} onClick={handleExpand}>
                    {title}
                </Flexbox>
                <Flexbox className={classes('iconContainer')} onClick={handleExpand}>
                    {expand ? <ArrowExpendedIcon /> : <ArrowCollapsedIcon /> }
                </Flexbox>
            </Flexbox>
            <Collapse in={expand} timeout="auto" unmountOnExit>
                <Flexbox vertical>
                    {children}
                </Flexbox>
            </Collapse>
        </Flexbox>
    )
}

export default CollapseItem