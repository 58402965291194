import { Box, CommonButton, CustomTyphography, Flexbox } from 'components'
import { CodeIcon, ContentCopyIcon, DownloadIcon, Stars, VisibilityIcon } from 'components/icons'
import { FC, useEffect, useRef, useState } from 'react'
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import html2canvas from 'html2canvas';

const classes = classNames.bind(styles);

interface MockupPanelProps {
    content?: string,
    setAiRefineDialog: (arg: {
        open: boolean,
        field: string,
        type: 'prd' | 'mockup',
        content?: string
    }) => void,
    streaming?: boolean
}

const MockupPanel: FC<MockupPanelProps> = ({ content, setAiRefineDialog, streaming }) => {
    const mockupRef = useRef<HTMLDivElement>(null);
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const [isCopied, setIsCopied] = useState(false);
    const [isPreview, setIsPreview] = useState<boolean>(true);

    const openAiRefineDialog = () => {
        setAiRefineDialog({
            open: true,
            field: 'mockup',
            type: 'mockup',
            content
        })
    }

    const handleCopyCode = async () => {
        if (!!content) {
            try {
                await navigator.clipboard.writeText(content);
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 1000);
            } catch (err) {
                console.error('Failed to copy code: ', err);
            }
        }
    };

    const prepareIframeCanvas = async (content: string): Promise<HTMLCanvasElement> => {
        const offscreenIframe = document.createElement('iframe');
        offscreenIframe.style.position = 'absolute';
        offscreenIframe.style.top = '-9999px';
        offscreenIframe.style.left = '-9999px';
        offscreenIframe.srcdoc = content;
        document.body.appendChild(offscreenIframe);

        await new Promise<void>((resolve, reject) => {
            offscreenIframe.onload = () => resolve();
            offscreenIframe.onerror = () => reject(new Error('Iframe failed to load'));
        });

        const iframeDocument = offscreenIframe.contentDocument || offscreenIframe.contentWindow?.document;

        if (!iframeDocument) {
            document.body.removeChild(offscreenIframe);
            throw new Error('Failed to access the iframe content');
        }

        const zoomControls = iframeDocument.querySelector<HTMLElement>('#zoomControls');
        const flowchart = iframeDocument.querySelector<HTMLElement>('#flowchart');

        if (zoomControls) {
            zoomControls.style.display = 'none';
        }

        if (flowchart) {
            flowchart.style.backgroundColor = '#fff';
        }

        const scale = flowchart ? 4 : 1;

        const canvas = await html2canvas(iframeDocument.body, {
            scale,
        });

        if (zoomControls) {
            zoomControls.style.display = '';
        }

        document.body.removeChild(offscreenIframe);

        return canvas;
    };

    const handleCopyImage = async () => {
        if (content) {
            try {
                const canvas = await prepareIframeCanvas(content);
                const imageURL = canvas.toDataURL('image/png');
                const blob = await fetch(imageURL).then((res) => res.blob());

                await navigator.clipboard.write([
                    new ClipboardItem({
                        'image/png': blob,
                    }),
                ]);

                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 1000)
            } catch (error) {
                console.error('Failed to copy iframe content as image:', error);
            }
        }
    };

    const downloadMockupAsImage = async () => {
        if (content) {
            try {
                const canvas = await prepareIframeCanvas(content);
                const imageURL = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = imageURL;
                link.download = 'mockup.png';
                link.click();
            } catch (error) {
                console.error('Failed to download iframe content as image:', error);
            }
        }
    };

    useEffect(() => {
        if (mockupRef.current && streaming) {
            mockupRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, [streaming, content]);

    return (
        <Box className={classes('sidePanel-mainContent')}>
            {!streaming && <Flexbox wrap className={classes('gap-2', 'mb-2')}>
                <CommonButton
                    buttonType='shadow'
                    data-copy='false'
                    buttonSize='small'
                    className={classes('smallIcon')}
                    onClick={downloadMockupAsImage}
                >
                    <DownloadIcon />
                    Download
                </CommonButton>
                <CommonButton
                    buttonType='shadow'
                    data-copy='false'
                    buttonSize='small'
                    className={classes('smallIcon')}
                    onClick={isPreview ? handleCopyImage : handleCopyCode}
                >
                    <ContentCopyIcon />
                    {isCopied ? 'Copied!' : isPreview ? 'Copy' : 'Copy code'}
                </CommonButton>
                <CommonButton
                    buttonType='shadow'
                    data-copy='false'
                    buttonSize='small'
                    className={classes('smallIcon')}
                    onClick={() => setIsPreview(prev => !prev)}
                >
                    {isPreview ? <Flexbox justify align className={classes('gap-2')}><CodeIcon />Show Code</Flexbox> : <Flexbox justify align className={classes('gap-2')}><VisibilityIcon />Preview</Flexbox>}
                </CommonButton>
            </Flexbox>}
            <Flexbox fullWidth vertical className={classes('gap-4')}>
                {content && (
                    (streaming || !isPreview) ? (
                        <div ref={mockupRef}>
                            {content.includes('<!DOCTYPE html>') ? <pre>{content}</pre> : <code style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>{content}</code>}
                        </div>
                    ) : (
                        <Flexbox vertical className={classes('gap-4')} >
                            <Flexbox align
                                className={classes('cursor-pointer', 'gap-1', 'refine-btn')}
                                onClick={() => openAiRefineDialog()}
                                data-copy='false'
                            >
                                <Stars />
                                <CustomTyphography>AI Refine</CustomTyphography>
                            </Flexbox>
                            <iframe
                                ref={iframeRef}
                                srcDoc={content.replace(/(<a\b[^>]*\bhref=["'])#(["'][^>]*>)/gi, '$1javascript:void(0);$2')}
                                style={{ height: '70vh', border: 'none' }}
                            />
                        </Flexbox>
                    )
                )}
            </Flexbox>
        </Box>
    )
}

export default MockupPanel