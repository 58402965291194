const Expand = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 12L14 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            <path d="M5 5L19 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            <path d="M5 19H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            <path d="M17 9L20 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            <path d="M17 15L20 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

export default Expand;