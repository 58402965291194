import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { Flexbox, HorizontalSeparator } from 'components';
import { Block, LinkMetadata } from 'utils/types';
import LinkItem from './linkItem';
import LinkAdd from './linkAdd';
import CollapseItem from './collapseItem';
const classes = classNames.bind(styles);


interface LinkProps{
    baseUrl: string;
    isEditable?: boolean;
    links: Block<LinkMetadata>[];
    horizontalSeparator?: boolean;
    deleteLinkAction: (id: number) => void;
    editLinkAction: (data: any) => void;
    createLinkAction: (data: Block<LinkMetadata>) => void
}

const LinkResources = ({ baseUrl, isEditable = true, links, horizontalSeparator = true, deleteLinkAction, editLinkAction, createLinkAction } : LinkProps) => {

    return(
        <>
            <CollapseItem title='Link'>
                {links.map(link => <LinkItem link={link} key={link.id} baseUrl={baseUrl} isEditable={isEditable} deleteLinkAction={deleteLinkAction} editLinkAction={editLinkAction} createLinkAction={createLinkAction} />)}
            </CollapseItem>
            <Flexbox>
                <LinkAdd baseUrl={baseUrl} isEditable={isEditable} editLinkAction={editLinkAction} createLinkAction={createLinkAction} />
            </Flexbox>
            {horizontalSeparator && <HorizontalSeparator className={classes('separator')} dashed />}
        </>
    )
}

export default LinkResources