import { FC, useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from '../../../initiative.module.scss';
import InitiativeState from './InitiativeState';
import { Grid } from 'components';
import { useSelector } from 'react-redux';
import { initiativeScoreSelector, overallProgressSelector } from 'store/initiativeGrading-slice';
import { gradingValues } from './constants';

const classes = classNames.bind(styles);

interface InitiativeStatesProps {

}

const InitiativeStates: FC<InitiativeStatesProps> = () => {
    const overallProgress = useSelector(overallProgressSelector);
    const score = useSelector(initiativeScoreSelector)

    const stateData = useMemo(() => {
        const grade = score && gradingValues.find(el => (score >= el.percentage.min && score <= el.percentage.max))?.grade

        return [
            { id: 'overallProgress', label: 'Progress', value: `${overallProgress} %` },
            { id: 'initiativeGrading', label: 'Initiative Grading', value: grade || '' },
            // { id: 'overallRisk', label: 'Overall Risk', value: 'medium' },
        ]
    }, [overallProgress, score])

    return (
        <Grid container spacing={3} mb={3}>
            {
                stateData.map(item => (
                    <InitiativeState
                        key={item.id}
                        label={item.label}
                        value={item.value}
                        type={item.id}
                    />
                ))
            }
        </Grid>
    )
}

export default InitiativeStates;