export const getFromLocalStorage = (key: string) => {
    const stringValue = localStorage.getItem(key);
    if(stringValue) {
        try{
            const parsedValue = JSON.parse(stringValue);
            return parsedValue;
        } catch(e) {
            return stringValue;
        }
    }
    return null;
}

export const setToLocalStorage = (key: string, value: any) => {
    if(typeof value !== 'string') {
        const stringValue = JSON.stringify(value);
        localStorage.setItem(key, stringValue);
    } else {
        localStorage.setItem(key, value);
    }
}

export const removeFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
}