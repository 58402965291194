import { CommonButton, CustomSnackbar, CustomTyphography, Flexbox, Link, Loader } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { FC, useEffect, useState } from 'react';
import { useWorkspaceId } from 'utils/hooks';
import { useParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import CustomDialog from 'components/CustomDialog';
import { useLazyGetSuggestedNodesQuery, useLinkToTasksMutation } from 'store/views-api';
import { useDispatch, useSelector } from 'react-redux';
import { linkToTasksErrorSelector, setLinkToTasksError, suggestedNodesSelector, updateSuggestedNodes } from 'store/views-slice';
import { isValidHttpsUrl } from 'utils';
import { JiraSmallIcon, LanguageIcon } from 'components/icons';
import { LinkNode } from 'utils/types';

const classes = classNames.bind(styles);

interface CreateViewDialogProps {
    open: boolean;
    handleClosePopup: () => void;
    nodeId: number;
    nodeTitle: string;
    linkNodes: LinkNode[];
}

const LinkNodeModal: FC<CreateViewDialogProps> = ({ open, handleClosePopup, nodeId, nodeTitle, linkNodes }) => {
    const workspaceId = useWorkspaceId();
    const params = useParams();
    const dispatch = useDispatch()

    const viewId = params.id

    const [linkToTasks] = useLinkToTasksMutation()
    const [getSuggestedNodes, { isLoading: suggestedNodesLoading }] = useLazyGetSuggestedNodesQuery()
    const [nodeLink, setNodeLink] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [linkingSources, setLinkingSources] = useState<string[]>([])
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)

    const linkToTasksError = useSelector(linkToTasksErrorSelector)
    const suggestedNodes = useSelector(suggestedNodesSelector)

    const resetFields = () => {
        setNodeLink('')
        dispatch(setLinkToTasksError(''))
    }

    const onClosePopup = () => {
        resetFields()
        handleClosePopup()
    }

    const handleLinkNode = async (source?: string) => {
        const link = source || nodeLink
        setLinkingSources(prev => ([...prev, link]));

        try {
            const res = await linkToTasks({
                workspaceId,
                viewId,
                nodeId,
                data: { link }
            }).unwrap()

            if (source) {
                dispatch(updateSuggestedNodes(source))
            } else {
                handleClosePopup()
            }

            resetFields()
            setShowSuccessMessage(true)

            setTimeout(() => setShowSuccessMessage(false), 3000)
        } catch (error) {
            console.error('Failed to Link', error);
        } finally {
            setLinkingSources(prev => prev.filter(el => el !== link))
        }
    }

    useEffect(() => {
        getSuggestedNodes({ workspaceId, nodeId, viewId })
    }, [open])

    useEffect(() => {
        if (!isValidHttpsUrl(nodeLink)) {
            setErrorMessage('Should be a valid url')
        } else if (Array.isArray(linkNodes) && linkNodes.some(node => node.source === nodeLink)) {
            setErrorMessage('This link is already attached')
        } else {
            setErrorMessage('')
        }
    }, [nodeLink, linkNodes]);

    return (
        <CustomDialog
            open={open}
            onClose={onClosePopup}
            title={'Link Item'}
            description={nodeTitle}
            className={classes('linkNodeContainer')}
        >
            <Flexbox vertical className={classes('gap-4', 'pb-4')}>
                <Flexbox align justifyBetween className={classes('linkNode-header')}>
                    <CustomTyphography className={classes('font-500')}>Add Issue Link</CustomTyphography>
                    {/* <Select popupIcon value={'Jira'} options={['Jira']} disabled /> */}
                </Flexbox>

                <Flexbox vertical fullWidth >

                    <Flexbox className={classes('linkNodeInputBox', 'w-full')}>
                        <Flexbox align justify className={classes('linkNodeInputBox__left')}>
                            <LanguageIcon />
                        </Flexbox>
                        <Flexbox align justifyBetween fullWidth className={classes('linkNodeInputBox__right', 'gap-2')}>
                            <TextField
                                value={nodeLink}
                                placeholder='https://example.atlassian.net/browse/PROJ-XXXX'
                                className={classes('linkNodeInput')}
                                onChange={(e) => setNodeLink(e.target.value)}
                            />
                            <CommonButton
                                disabled={!nodeLink || !!errorMessage || linkingSources.includes(nodeLink)}
                                onClick={() => handleLinkNode()}
                                className={classes('linkNode-btn')}
                                loading={linkingSources.includes(nodeLink)}
                            >
                                Link
                            </CommonButton>
                        </Flexbox>
                    </Flexbox>
                    {
                        !!nodeLink && !!errorMessage && <Flexbox className="input-error">
                            {errorMessage}
                        </Flexbox>
                    }
                    <Flexbox vertical className={classes('gap-4', 'mt-4')}>
                        {suggestedNodesLoading ? <Flexbox justify align vertical className={classes('gap-2')}>
                            <CustomTyphography>Looking for suggested nodes</CustomTyphography>
                            <Loader />
                        </Flexbox> : !!suggestedNodes?.length && (
                            <>
                                <CustomTyphography variant='h4' type='primary' className={classes('font-500')}>Suggested Items</CustomTyphography>
                                {suggestedNodes.map((node) => (
                                    <Flexbox align justifyBetween className={classes('linkedNode')} key={node.id}>
                                        <Link to={node.source} target='_blank'>
                                            <Flexbox vertical className={classes('gap-1')}>
                                                <Flexbox align className={classes('gap-2')}>
                                                    <JiraSmallIcon />
                                                    <CustomTyphography>{node.key}</CustomTyphography>
                                                </Flexbox>
                                                <CustomTyphography>{node.title}</CustomTyphography>
                                            </Flexbox>
                                        </Link>
                                        <CommonButton
                                            disabled={linkingSources.includes(node.source)}
                                            onClick={() => handleLinkNode(node.source)}
                                            className={classes('linkNode-btn')}
                                            loading={linkingSources.includes(node.source)}
                                        >
                                            Link
                                        </CommonButton>
                                    </Flexbox>
                                ))}
                            </>
                        )}
                    </Flexbox>
                </Flexbox>
            </Flexbox>

            <CustomSnackbar open={!!linkToTasksError} type='error' title={linkToTasksError} />
            <CustomSnackbar open={!!showSuccessMessage} type='success' title='Source Linked successfully!' />
        </CustomDialog>
    );
};

export default LinkNodeModal;
