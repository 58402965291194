import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Owner, ParentProduct, Product, ProductInitiatives, Team } from 'utils/types'
import { productApi } from './product-api'
import store, { RootState } from 'store';
import { SaveStatus } from 'common/savingStatus';

interface ProductsState {
    currentProduct: Product,
    initiatives: ProductInitiatives,
    savingStatus: SaveStatus,
}

interface EditProductPayload {
    title?: string;
    owner?: Owner;
    parentProduct?: ParentProduct | null;
    teams?: Team[];
}

const initialState: ProductsState = {
    currentProduct: {
        id: 0,
        owner: {} as Owner,
        parentProduct: null,
        teams: [],
        title: 'Untitled Product'
    },
    initiatives: {
        childProductInitiatives: [],
        affectedProductInitiatives: [],
        selfInitiatives: []
    },
    savingStatus: SaveStatus.UNSET,
}

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        editProduct: (state, action: PayloadAction<EditProductPayload>) => {
            state.currentProduct = {
                ...state.currentProduct,
                ...action.payload
            }

            state.savingStatus = SaveStatus.SAVING
        },
        setSavingStatus: (state, action: PayloadAction<SaveStatus>) => {
            state.savingStatus = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            productApi.endpoints.getProduct.matchFulfilled,
            (state, { payload }) => {
                state.currentProduct = payload
            },
        ),
            builder.addMatcher(
                productApi.endpoints.editProduct.matchFulfilled,
                (state) => {
                    state.savingStatus = SaveStatus.SAVED

                    setTimeout(() => {
                        store.dispatch(setSavingStatus(SaveStatus.UNSET));
                    }, 2000);
                },
            ),
            builder.addMatcher(
                productApi.endpoints.getInitiatives.matchFulfilled,
                (state, { payload }) => {
                    state.initiatives = payload
                },
            )
    },
})

export const productSelector = (store: RootState) => store.product.currentProduct;
export const productTitleSelector = (store: RootState) => store.product.currentProduct.title;
export const productOwnerSelector = (store: RootState) => store.product.currentProduct.owner;
export const productBlocksSelector = (store: RootState) => store.product.currentProduct.blocks;
export const productParentProductSelector = (store: RootState) => store.product.currentProduct.parentProduct;
export const productTeamsSelector = (store: RootState) => store.product.currentProduct.teams;
export const savingStatusSelector = (store: RootState) => store.product.savingStatus;
export const initiativesSelector = (store: RootState) => store.product.initiatives;

export const { editProduct, setSavingStatus } = productSlice.actions

export default productSlice
