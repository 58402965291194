import { getJiraProjectFields } from 'common/jira/index.api';
import { ActionsMenu, Button, Flexbox, Loader, Select, Table } from 'components';
import { TableHeader } from 'components/Table';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { JiraIssueType, JiraMapping, JiraMappingObjectTypes, JiraProject, JiraProjectIssueTypes } from 'utils/types';

interface ObjectIssueTypeHeaders extends JiraIssueType {
    prodmapObject: string;
    arrow: string;
    actions: string;
}

const objectIssueTypeHeaders: TableHeader<ObjectIssueTypeHeaders>[] = [
    {
        id: 'prodmapObject',
        text: 'Prodmap object',
    },
    {
        id: 'arrow',
        text: '',
        width: '30px'
    },
    {
        id: 'name',
        text: 'Jira issue type',
    },
    {
        id: 'actions',
        text: '',
        width: '30px'
    }
]


interface ObjectToIssueTypeProps {
    project: JiraProject
    onMappingObjectSelect: (object: JiraMappingObjectTypes, jiraField: number | undefined | null) => void;
    mapping?: JiraMapping;
    startMappingFields: (object: JiraMappingObjectTypes, issueType: JiraIssueType) => void;
    onUnmapObject: (object: JiraMappingObjectTypes) => void;
}

export default ({ project, onMappingObjectSelect, mapping, startMappingFields, onUnmapObject }: ObjectToIssueTypeProps) => {

    const [issueTypes, setIssueTypes] = useState<JiraIssueType[]>([]);
    const [loadingIssueTypes, setLoadingIssueTypes] = useState(false);
    const dispatch = useDispatch()
    useEffect(() => {
        loadIssueTypes()
    }, [project])
    const loadIssueTypes = async () => {
        setLoadingIssueTypes(true);
        const res = await dispatch(getJiraProjectFields(project.key)) as unknown as JiraProjectIssueTypes;
        setIssueTypes(res.issuetypes);
        setLoadingIssueTypes(false)
    }

    const onMappingSelect = (object: JiraMappingObjectTypes, issueType: JiraIssueType | null) => {
        if(issueType) {
            onMappingObjectSelect(object, issueType.id);
        } else {
            onMappingObjectSelect(object, null);
        }
    }

    const setCurrentMappingFields = (object: JiraMappingObjectTypes) => {
        if(object === JiraMappingObjectTypes.Initiative) {
            const issueType = issueTypes.find(type => type.id === mapping?.initiativeMapping?.fieldId)
            if (issueType) {
                startMappingFields(object, issueType);
            }
        } else if(object === JiraMappingObjectTypes.Requirement) {
            const issueType = issueTypes.find(type => type.id === mapping?.requirementMapping?.fieldId)
            if (issueType) {
                startMappingFields(object, issueType);
            }
        }
    }


    return (
        loadingIssueTypes ? <Flexbox fullWidth fullHeight align justify><Loader disableShrink/></Flexbox> :
            <Table
                header={objectIssueTypeHeaders}
                data={[
                    {
                        data: [
                            <Flexbox>Initiative</Flexbox>,
                            <Flexbox>-&gt;</Flexbox>,
                            <Select
                                value={issueTypes.find(issue => issue.id === mapping?.initiativeMapping?.fieldId) }
                                options={issueTypes}
                                onChange={(e, issueType) => {onMappingSelect(JiraMappingObjectTypes.Initiative, issueType)}}
                                getOptionLabel={issueType => issueType.name}
                                placeholder='Select Jira issue type'
                            />,
                            <Flexbox>
                                {mapping?.initiativeMapping?.fieldId && <Button onClick={() => {setCurrentMappingFields(JiraMappingObjectTypes.Initiative)}}>Mappings</Button>}
                                <ActionsMenu
                                    buttonItems={[{ label: 'Unmap', action: () => {onUnmapObject(JiraMappingObjectTypes.Initiative)} }]}
                                />
                            </Flexbox>

                        ],

                    },
                    {

                        data: [
                            <Flexbox>Story</Flexbox>,
                            <Flexbox>-&gt;</Flexbox>,
                            <Select
                                value={issueTypes.find(issue => issue.id === mapping?.requirementMapping?.fieldId)}
                                options={issueTypes}
                                onChange={(e, issueType) => {onMappingSelect(JiraMappingObjectTypes.Requirement, issueType);}}
                                getOptionLabel={issueType => issueType.name}
                                placeholder='Select Jira issue type'
                            />,
                            <Flexbox>
                                {mapping?.requirementMapping?.fieldId && <Button onClick={() => {setCurrentMappingFields(JiraMappingObjectTypes.Requirement);}}>Mappings</Button>}
                                <ActionsMenu
                                    buttonItems={[{ label: 'Unmap', action: () => {onUnmapObject(JiraMappingObjectTypes.Requirement);} }]}
                                />
                            </Flexbox>

                        ],

                    }
                ]}
            // orderBy={orderBy}
            // order={order}
            // sortHandler={sortHandler}
            />
    )
}