import { Flexbox, IconButton, Typography } from 'components'
import { ArrowDropDownIcon, ArrowDropUpIcon } from 'components/icons'
import classNames from 'classnames/bind';
import styles from '../../../initiative.module.scss';
import { FC, useState } from 'react';
import GradingBox from './GradingBox';
import { IrelevantPoint, Insight } from 'utils/types';

const classes = classNames.bind(styles);

type groupDropdownStateType = { aiFeedback: boolean, nonRelevantFeedback: boolean }

interface InitiativeGradingGroupProps {
    data: Insight[] | IrelevantPoint[],
    groupTitle: string,
    groupKey: keyof groupDropdownStateType,
}

const InitiativeGradingGroup: FC<InitiativeGradingGroupProps> = ({ data, groupTitle, groupKey }) => {
    const [groupDropdownState, setGroupDropdownState] = useState<groupDropdownStateType>(
        {
            aiFeedback: true,
            nonRelevantFeedback: true
        }
    )

    const handleToggleGroup = (group: keyof groupDropdownStateType) => {
        setGroupDropdownState(prev => {
            return {
                ...prev,
                [group]: !prev[group]
            }
        })
    }

    return (
        <Flexbox vertical className={classes('gradingGroup')}>
            <Flexbox className={classes('groupContainer', { 'closed': !groupDropdownState[groupKey] })} onClick={() => handleToggleGroup(groupKey)}>
                <Typography variant='h4' className={classes('groupTitle')}>{groupTitle}</Typography>
                <IconButton className={classes('dropdownIcon')}>
                    {groupDropdownState[groupKey] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
            </Flexbox>
            {
                groupDropdownState[groupKey] && data?.map((insight, index) => (
                    <GradingBox
                        {...insight}
                        key={`${insight.title}_${index}`}
                    />
                ))
            }
        </Flexbox>
    )
}

export default InitiativeGradingGroup;