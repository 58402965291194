import { Button, Flexbox, Link, Snackbar } from 'components'
import styles from '../../dependency.module.scss';
import classNames from 'classnames/bind';
import { DependencyStatuses, InitiativeSaveModel } from 'utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { dependencyNotesSelector, dependencyStatusSelector, editDependency } from 'store/dependency-slice';
import { ErrorType, InitiativeWithExtraValue, defaultError } from '.';
import { useWorkspaceId } from 'utils/hooks';
import { userSelector } from 'store/user';
import { createInitiative } from 'pages/Initiatives/initiatives.api';
import { useUpdateDependencyMutation } from 'store/dependency-api';
import { FC, useState } from 'react';
const classes = classNames.bind(styles);

interface StatusActionButtonsProps {
    dependencyId: number,
    error: ErrorType,
    setError: (value: ErrorType) => void,
    selectedInitiative?: InitiativeWithExtraValue,
    setSelectedInitiative: (value: InitiativeWithExtraValue) => void,
}

const StatusActionButtons: FC<StatusActionButtonsProps> = ({
    dependencyId,
    error,
    setError,
    selectedInitiative,
    setSelectedInitiative,
}) => {
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();

    const status = useSelector(dependencyStatusSelector);
    const currentUser = useSelector(userSelector);
    const notes = useSelector(dependencyNotesSelector);

    const [snackbarText, setSnackbarText] = useState('');
    const [initiativeCreateSuccess, setInitiativeCreateSuccess] = useState(false);

    const [updateDependencyMutation] = useUpdateDependencyMutation();

    const acceptAndResolve = async (statusType: DependencyStatuses) => {
        dispatch(editDependency({ status: statusType }));
        if(selectedInitiative && selectedInitiative.extraValue){
            const { title, problem, validation, solution, status, size, priority, type, startDate, endDate, releaseDate, okrs } = selectedInitiative;
            const initiativeSaveData: InitiativeSaveModel = {
                title,
                problem,
                validation,
                solution,
                status,
                size,
                priority,
                type,
                startDate,
                endDate,
                releaseDate,
                okrs,
                owner: currentUser.id,
            }

            const newInitiative: InitiativeWithExtraValue = (await dispatch(createInitiative(initiativeSaveData))) as unknown as InitiativeWithExtraValue

            setSelectedInitiative(newInitiative)
            setInitiativeCreateSuccess(true)

            await updateDependencyMutation({ workspaceId, dependencyId, data: {
                status: statusType,
                initiativeId: newInitiative.id,
                notes,
            } })
        } else {
            await updateDependencyMutation({ workspaceId, dependencyId, data: {
                status: statusType,
                initiativeId: selectedInitiative?.id,
                notes,
            } })
        }
    }

    const accept = async () => {
        setError(defaultError)
        if(selectedInitiative){
            await acceptAndResolve(DependencyStatuses.Accepted)
            if(selectedInitiative.extraValue){
                setSnackbarText('You have created New Initiative to accept Dependency ticket')
            } else {
                setSnackbarText('You have linked an Initiative to accept Dependency ticket')
            }
            setInitiativeCreateSuccess(true)
        } else{
            setError({ ...error, initiativeError: 'Initiative required for accept' })
        }
    }

    const resolve = async () => {
        setError(defaultError)
        if(notes){
            await acceptAndResolve(DependencyStatuses.Resolved)
            if(selectedInitiative) {
                if(selectedInitiative.extraValue) {
                    setSnackbarText('You have linked an Initiative to resolve Dependency ticket')
                } else {
                    setSnackbarText('You have resolved Dependency ticket')
                }
                setInitiativeCreateSuccess(true)
            }
        } else{
            setError({ ...error, noteError: !notes ? 'Notes required for resolve' : '' })
        }
    }

    const reject = async () => {
        setError(defaultError)
        if(notes){
            dispatch(editDependency({ status: DependencyStatuses.Declined }));
            await updateDependencyMutation({ workspaceId, dependencyId,  data: {
                status: DependencyStatuses.Declined,
                notes: notes,
            } })
            setSnackbarText('You have declined Dependency ticket')
            setInitiativeCreateSuccess(true)
        } else{
            setError({ ...error, noteError: 'Notes required for decline' })
        }
    }

    return (
        <Flexbox vertical className={classes('fieldContainer')}>
            <Flexbox justify className={classes('groupButtons')}>
                <Button className={classes('button', { 'selectedGreen': status === DependencyStatuses.Accepted })}
                    onClick={accept}>
                    {status === DependencyStatuses.Accepted ? 'Accepted' : 'Accept'}
                </Button>
                <Button className={classes('button', { 'selectedGreen': status === DependencyStatuses.Resolved })}
                    onClick={resolve}>
                    {status === DependencyStatuses.Resolved ? 'Resolved' : 'Resolve'}
                </Button>
                <Button className={classes('button', { 'selectedRed': status === DependencyStatuses.Declined })}
                    onClick={reject}>
                    {status === DependencyStatuses.Declined ? 'Declined' : 'Decline'}
                </Button>
            </Flexbox>

            <Snackbar
                type='success'
                onClose={() => setInitiativeCreateSuccess(false)}
                open={initiativeCreateSuccess}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                }}
            >
                <Flexbox vertical>
                    <Flexbox>
                        {snackbarText}
                    </Flexbox>
                    {selectedInitiative?.id && (
                        <Link className={classes('snackbarLink')} to={`/initiatives/initiative/${selectedInitiative.id}`}>
                            See Initiative
                        </Link>
                    )}
                </Flexbox>
            </Snackbar>
        </Flexbox>
    )
}

export default StatusActionButtons