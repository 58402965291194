import { Avatar, AvatarGroup, Collapse } from '@mui/material';
import classNames from 'classnames/bind';
import { CommentIcon } from 'components/icons/Comment';
import { FC, useMemo } from 'react';
import { getInitialsFromName } from 'utils';
import { CommentExtended } from 'utils/types';
import { CommentFieldToolbarButton } from './CommentFieldToolbarButton';
import styles from './commentFieldToolbar.module.scss';
import { uniqueFilter } from 'utils/search';
const classes = classNames.bind(styles);

export interface CommentFieldToolbarProps {
    outlined?: boolean;
    comments: CommentExtended[];
    onClick?: () => void;
    onNewComment?: () => void;
}

export const CommentFieldToolbar: FC<CommentFieldToolbarProps> = ({
    outlined,
    comments,
    onClick,
    onNewComment,
}) => {
    const uniqueUserComments = useMemo(
        () => comments.filter(c => !c.parentId).filter(uniqueFilter(c => c.author.id)),
        [comments]
    );
    return (
        <div className={classes('toolbar', { outlined })} data-comment-toolbar-id={comments[0]?.id}>
            <AvatarGroup max={1} className={classes('avatar-group')} onClick={onClick}>
                {uniqueUserComments.map(comment => (
                    <Avatar
                        key={comment.id + '/' + comment.author.id}
                        alt={comment.author.fullName}
                    >
                        {getInitialsFromName(comment.author.fullName)}
                    </Avatar>
                ))}
            </AvatarGroup>
            <div>
                <Collapse in={Boolean(onNewComment)} orientation="horizontal">
                    <CommentFieldToolbarButton onClick={onNewComment}>
                        <CommentIcon />
                    </CommentFieldToolbarButton>
                </Collapse>
            </div>
        </div>
    );
};
