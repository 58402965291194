import { ForwardRefExoticComponent, useEffect, useState } from 'react';
import { Loader, Flexbox, Button, HorizontalSeparator, Link } from 'components';
import { setToLocalStorage } from 'utils/localStorage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { INTEGRATIONS_TYPE, REDIRECT_URL_KEY } from 'utils/constants';
import { disconnectIntegration, getAvailableIntegrations, getWorkspaceIntegrations } from './integrations.api';
import { useDispatch, useSelector } from 'react-redux';
import { availableIntegrationsSelector, workspaceIntegrationSelector } from 'store/integrations';
import { JiraIcon, SlackIcon } from 'components/icons';
import classNames from 'classnames/bind';
import styles from './integrations.module.scss';
import { createQueryParamsFromObject } from 'utils/request';
const classes = classNames.bind(styles);
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
const classesInfo = classNames.bind(stylesInfo);

const IntegrationProps: {[key: string] : {name: string, icon: ForwardRefExoticComponent<any> | (() => JSX.Element), description: string}} = {
    'JIRA': {
        name: 'Jira',
        icon: JiraIcon,
        description: 'This will enable a 2-way sync for your &nbsp;<b>Requirements & User Stories</b>&nbsp; from your &nbsp;<b>Initiatives</b>&nbsp; directly to Jira',
    },
    'SLACK':{
        name: 'Slack',
        icon: SlackIcon,
        description: 'This will enable you to receive important &nbsp;<b>updates & notifications</b>&nbsp; directly in your Slack workspace'
    }
}

const Integrations = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(true);

    const availableIntegrations = useSelector(availableIntegrationsSelector);
    const workspaceIntegrations = useSelector(workspaceIntegrationSelector);

    useEffect(() => {
        const getData = async () => {
            await Promise.all([
                dispatch(getAvailableIntegrations()),
                dispatch(getWorkspaceIntegrations())
            ])
            setIsLoading(false)
        }
        getData()
    }, [])


    const connect = (type: string) => {
        const integration = availableIntegrations.find(integration => integration.name === type);

        if(integration) {
            const props = integration.properties;
            const params = createQueryParamsFromObject({ ...props, state: `${type}_workspace` })
            setToLocalStorage(REDIRECT_URL_KEY, location.pathname);

            const currentIntegration = INTEGRATIONS_TYPE.find(integration => integration.name === type && integration.workspace)
            if(currentIntegration){
                window.location.replace(`${currentIntegration.url}&${params}`);
            }
        }
    }

    const disconnectConnect = async (integrationName: string) => {
        await dispatch(disconnectIntegration(integrationName))
    }

    return isLoading ? (<Flexbox fullWidth align justify><Loader /></Flexbox>) : (
        <Flexbox className={classes('integrationsContainer')} fullWidth vertical>
            <Flexbox vertical className={classesInfo('headerContainer')}>
                <Flexbox className={classesInfo('headerInfoTop')}>
                    <Flexbox className={classesInfo('headerTitle')}>Integrations</Flexbox>
                </Flexbox>
            </Flexbox>
            {availableIntegrations.map((availableIntegration, i) => {
                const integrationOptions = IntegrationProps[availableIntegration.name];
                const isConnected = workspaceIntegrations.some(workspaceIntegration => {
                    return workspaceIntegration.name === availableIntegration.name})
                return integrationOptions && (
                    <>
                        <Flexbox key={i} className={classes('integrationCardContainer')} align>
                            <Flexbox vertical>
                                <Flexbox className={classes('name')} align ><integrationOptions.icon/> {integrationOptions.name}</Flexbox>
                                <Flexbox className={classes('description')} dangerouslySetInnerHTML={{ __html: integrationOptions.description }} />
                                {availableIntegration.name === 'JIRA' && isConnected && <Link to={'jiramapping'} className={classes('jiraMapping')}><Flexbox>Manage &nbsp;<Flexbox className={classes('bold')}>Mappings</Flexbox></Flexbox></Link>}
                            </Flexbox>
                            <Flexbox>
                                {isConnected ?
                                    <Flexbox vertical>
                                        <Button variant='contained' className={classes('integrationDisconnectButton')} onClick={() => disconnectConnect(availableIntegration.name)}>
                                    Disconnect
                                        </Button>
                                    </Flexbox>
                                    :
                                    <Button variant='contained' onClick={() => connect(availableIntegration.name)}>
                                    Connect
                                    </Button>
                                }
                            </Flexbox>
                        </Flexbox>
                        <HorizontalSeparator className={classes('separator')} />
                    </>
                )
            })}
        </Flexbox>
    )
}

export default Integrations;