const GitBranch = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clipPath="url(#clip0_962_705)">
            <path opacity="0.2" d="M14.6875 7.49985C15.8956 7.49985 16.875 6.52047 16.875 5.31235C16.875 4.10422 15.8956 3.12485 14.6875 3.12485C13.4794 3.12485 12.5 4.10422 12.5 5.31235C12.5 6.52047 13.4794 7.49985 14.6875 7.49985Z" fill="white" />
            <path d="M5.31158 12.5V11.875C5.31159 11.3777 5.50914 10.9008 5.86076 10.5492C6.21238 10.1976 6.68928 10.0001 7.18655 10.0001L12.8116 9.99994C13.3089 9.99994 13.7858 9.80241 14.1374 9.45079C14.489 9.09917 14.6866 8.62228 14.6866 8.12501V7.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.31158 7.5V12.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.3125 16.875C6.52062 16.875 7.5 15.8956 7.5 14.6875C7.5 13.4794 6.52062 12.5 5.3125 12.5C4.10438 12.5 3.125 13.4794 3.125 14.6875C3.125 15.8956 4.10438 16.875 5.3125 16.875Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6875 7.49985C15.8956 7.49985 16.875 6.52047 16.875 5.31235C16.875 4.10422 15.8956 3.12485 14.6875 3.12485C13.4794 3.12485 12.5 4.10422 12.5 5.31235C12.5 6.52047 13.4794 7.49985 14.6875 7.49985Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.3125 7.49985C6.52062 7.49985 7.5 6.52047 7.5 5.31235C7.5 4.10422 6.52062 3.12485 5.3125 3.12485C4.10438 3.12485 3.125 4.10422 3.125 5.31235C3.125 6.52047 4.10438 7.49985 5.3125 7.49985Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_962_705">
                <rect width="20" height="20" fill="currentColor" />
            </clipPath>
        </defs>
    </svg>
)

export default GitBranch