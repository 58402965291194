
import { default as MTab, TabProps } from '@mui/material/Tab';
import './tab.scss'

const Tab = ({ className, ...props }: TabProps) => {
    return (
        <MTab
            classes={{
                root: 'root',
                selected: 'selected'
            }}
            className={`tabContainer ${className || ''}`}
            {...props}
        />
    )
}

export default Tab;