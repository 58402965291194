import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { Row } from './components';
// import classNames from 'classnames/bind';
// import styles from './table.module.scss';
// const classes = classNames.bind(styles);

import './table.scss'
import { ArrowDropDownIcon } from 'components/icons';
import { Flexbox } from 'components';

export interface TableHeader<T> {
    id: keyof T;
    text: string;
    sortable?: boolean;
    width?: string;
    endIcon?: JSX.Element;
}

export interface TableRowProps {
    data: any[];
    hasEditableField?: boolean;
    collapsible?: boolean;
    openRenderer?: (data: any[], index: number) => ReactNode;
    autoOpen?: boolean;
    url?: string;
}

interface TableProps<T> {
    header: TableHeader<T>[];
    orderBy?: string;
    order?: 'asc' | 'desc';
    data: TableRowProps[];
    onRowClick?: (index: number) => void;
    sortHandler?: (column: keyof T) => void;
    collapsible?: boolean;
    className?: string;
    nested?: boolean;
    emptyText?: string;
    height?: string;
    stickyHeader?: boolean;
}

const CustomTable = <T extends unknown>({ header, orderBy, order, data, sortHandler, onRowClick, collapsible = false, className, nested, emptyText, height, stickyHeader = true } : TableProps<T>) => {

    const [style, setStyle] = useState<React.CSSProperties | undefined>();
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if(stickyHeader && containerRef.current){
            setStyle({
                height: `calc(100vh - ${containerRef.current.offsetTop}px)`,
                overflow: 'auto'
            })
        }
    }, [containerRef.current?.offsetTop])


    return (
        <Flexbox vertical fullWidth ref={containerRef} style={stickyHeader ? (height ? { height } : style) : undefined}>
            <Table
                stickyHeader={stickyHeader}
                className={`table${nested ? ' nested' : ''} ${className || ''}`}>
                <TableHead classes={{
                    root: 'tableHead'
                }}>
                    <TableRow>
                        {collapsible && (
                            <TableCell
                                key={'collapse'}
                                style={{
                                    width: '10px'
                                }}
                            >
                            </TableCell>
                        )}
                        {
                            header.map(head => {
                                return (
                                    <TableCell
                                        key={head.id as string}
                                        style={{
                                            width: head.width || ''
                                        }}
                                    >
                                        {
                                            head.sortable ? (
                                                <TableSortLabel
                                                    IconComponent={ArrowDropDownIcon}
                                                    active={orderBy === head.id}
                                                    direction={orderBy === head.id ? order : 'asc'}
                                                    onClick={() => {
                                                        if(sortHandler) {
                                                            sortHandler(head.id)
                                                        }
                                                    }}
                                                >
                                                    {head.text}
                                                </TableSortLabel>
                                            ) : (<Flexbox>
                                                {head.text}
                                                {head.endIcon}
                                            </Flexbox>)
                                        }

                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => {
                        return (
                            <Row
                                data={row.data}
                                index={index}
                                key={index}
                                tableCollapsible={collapsible}
                                collapsible={row.collapsible}
                                openRenderer={row.openRenderer}
                                onClick={onRowClick}
                                hasEditableField={row.hasEditableField}
                                autoOpen={row.autoOpen}
                                url={row.url}
                            />
                        )
                    })}
                </TableBody>
            </Table>
            {emptyText &&
                <Flexbox className='emptyState'>
                    {emptyText}
                </Flexbox>
            }
        </Flexbox>
    )
}

export default CustomTable;