import { Box, CustomSnackbar, CustomTyphography, Dialog, Flexbox, Loader, PRDScoreBox, Typography } from 'components'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import EmptyState from 'common/emptyState';
import { DataScience, Flag } from 'components/icons';
import { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Actions, hasPermission } from 'utils/permissions';
import Header from './Header';
import { GridApi } from 'ag-grid-enterprise';
import AgGridTable, { AgColumn, ColumnTypes, GridStatePreferences } from 'components/AgGridTable';
import { useDispatch, useSelector } from 'react-redux';
import { CellClickedEvent, GetDetailRowDataParams, GetRowIdFunc, GetRowIdParams, GridReadyEvent, ICellRendererParams, SortChangedEvent } from 'ag-grid-community';
import { useSearchParams } from 'react-router-dom';
import SidePanelDrawer from 'components/SidePanelDrawer';
import { getRoadmapView, getRoadmapViews, handleChangeViewItemSelection, handleSendEmail } from 'store/viewsRoadmap-api';
import {
    changeViewItemSelectionAction,
    getViewsRoadmapLoadingSelector,
    setSendEmailResultMessage,
    roadmapViewIdSelector,
    roadmapViewSelector,
    sendEmailLoadingSelector,
    sendEmailResultMessageSelector
} from 'store/viewsRoadmap-slice';
import { Preferences, PreferencesKeys, PreferenceValues, RoadmapItem } from 'utils/types';
import AddSourcePopup from './AddSourcePopup';
import Markdown from 'components/Markdown';
import { FilterOption } from 'components/FilterButton';
import { FilterValuesReducerAction } from 'pages/Products/productsList';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import { useDebounce } from 'utils/hooks';
import CommonInput from 'components/CommonInput';
import { emailValidation } from 'utils/validations';

const classes = classNames.bind(styles);

export enum FilterKeys {
    owner = 'owner',
    query = 'query',
    order = 'order',
    orderBy = 'orderBy',
    planningAnalysesGridLayout = 'planningAnalysesGridLayout',
    program = 'program',
    speaker = 'speaker',
    projectLead = 'projectLead',
    team = 'team',
}

const defaultFilterState = {
    [FilterKeys.owner]: [],
    [FilterKeys.program]: [],
    [FilterKeys.speaker]: [],
    [FilterKeys.projectLead]: [],
    [FilterKeys.team]: [],
}

const filterValuesReducer = (state: { [key: string]: FilterOption[] }, action: FilterValuesReducerAction) => {
    switch (action.type) {
        case 'update':
            return { ...state, [action.key]: action.payload }
        case 'reset':
            return defaultFilterState;
        default:
            return state;
    }
}

const PlanningAnalysis = () => {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const roadmapViewId = useSelector(roadmapViewIdSelector)
    const roadmapView = useSelector(roadmapViewSelector)
    const getViewsRoadmapLoading = useSelector(getViewsRoadmapLoadingSelector);
    const sendEmailLoading = useSelector(sendEmailLoadingSelector);
    const sendEmailResultMessage = useSelector(sendEmailResultMessageSelector)

    const [openAddSourcePopup, setOpenAddSourcePopup] = useState(false);
    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);
    const [searchValue, searchDebounceValue, setSearchValue] = useDebounce('');
    const [filteredData, setFilteredData] = useState<RoadmapItem[]>([])
    const [orderBy, setOrderBy] = useState<keyof RoadmapItem | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc' | undefined>();
    const [ownerOptions, setOwnerOptions] = useState<FilterOption[]>([])
    const [programOptions, setProgramOptions] = useState<FilterOption[]>([])
    const [speakerOptions, setSpeakerOptions] = useState<FilterOption[]>([])
    const [projectLeadOptions, setProjectLeadOptions] = useState<FilterOption[]>([])
    const [teamOptions, setTeamOptions] = useState<FilterOption[]>([])

    const [filterValues, setFilterValues] = useReducer(filterValuesReducer, defaultFilterState);
    const [canUpdatePreferences, setCanUpdatePreferences] = useState(false);
    const [gridStatePreferences, setGridStatePreferences] = useState<GridStatePreferences | undefined>({})
    const [loading, setLoading] = useState(false);
    const [sendEmailDialog, setSendEmailDialog] = useState<{ open: boolean, id?: number, email?: string }>({ open: false })

    const buttonItemsEmptyState = useMemo(() => {
        const buttons = []

        if (hasPermission(Actions.create)) {
            buttons.push({
                onClick: () => { setOpenAddSourcePopup(true) },
                text: 'Add Roadmap Planning Source',
            })
        }
        return buttons
    }, [])

    const columns: AgColumn[] = useMemo(() => [
        {
            headerName: 'Number',
            field: 'index',
            minWidth: 120,
            sortable: true,
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell',
            cellRendererSelector: (params: ICellRendererParams) => {
                const hasGrouping = params.api.getRowGroupColumns().length > 0
                if (hasGrouping) {
                    return { component: undefined }
                } else {
                    return { component: 'agGroupCellRenderer' }
                }
            },
        },
        {
            headerName: '',
            field: 'checked',
            cellEditor: 'agCheckboxCellEditor',
            cellRenderer: 'agCheckboxCellRenderer',
            minWidth: 50,
            editable: true,
        },
        {
            headerName: 'Title',
            field: 'title',
            minWidth: 300,
            sortable: true,
            canExpand: true,
            cellClass: 'cursor-pointer',
        },
        {
            headerName: 'Score',
            field: 'score',
            minWidth: 150,
            sortable: true,
            cellClass: 'ag-score-cell',
            canExpand: true,
            cellRenderer: (params: ICellRendererParams) => {
                if (params.node.group) {
                    if (params.node.field !== 'score') {
                        return null
                    } else if (params.node.groupData) {
                        const value = params.node.groupData['ag-Grid-AutoColumn']
                        return value ? <Flexbox align className='h-full'><PRDScoreBox value={params.value} /></Flexbox> : null
                    }
                } else if (params.value === null) {
                    return <Box sx={{ paddingLeft: '8px' }}>N/A</Box>
                } else {
                    return (
                        <Flexbox align className={'h-full'} >
                            <PRDScoreBox value={params.value} />
                        </Flexbox>
                    );
                }
            },
        },
        {
            headerName: 'Feedback / Analysis',
            field: 'feedbackCount',
            minWidth: 150,
            sortable: true,
            valueFormatter: (params: any) => {
                return `${params?.value} Analysis`;
            },
            cellRenderer: (params: ICellRendererParams) => {
                if (params.node.group && params.node.field !== 'feedbackCount') {
                    return null
                } else {
                    return (
                        <Flexbox align className={classes('h-full')}>
                            <Flexbox align className={classes('analysisBox')}>
                                <Flag />
                                <CustomTyphography>{`${params.value} action items`}</CustomTyphography>
                            </Flexbox>
                        </Flexbox>
                    );
                }
            },
            canExpand: true
        },
        {
            headerName: 'Owner',
            field: 'owner',
            minWidth: 150,
            sortable: true,
        },
        {
            headerName: 'Program',
            field: 'program',
            minWidth: 150,
            sortable: true,
        },
        {
            headerName: 'Planning Palooza Speaker',
            field: 'speaker',
            minWidth: 150,
            sortable: true,
        },
        {
            headerName: 'TPX Product Lead',
            field: 'projectLead',
            minWidth: 150,
            sortable: true,
        },
        {
            headerName: 'Submitting Team',
            field: 'team',
            minWidth: 150,
            sortable: true,
        },
        {
            colType: ColumnTypes.Action,
            field: 'actions',
            headerName: '',
            actions: params => {
                return [
                    { label: 'Send Email', action: () => setSendEmailDialog({ open: true, id: params.data.id }) }
                ]
            },
        },
    ], []);

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: {
                headerHeight: 0,
                columnDefs: [
                    {
                        headerName: 'Name',
                        field: 'original_value',
                        minWidth: 200,
                        flex: 1,
                        cellRenderer: (params: ICellRendererParams) => {
                            return <SidePanelDrawer
                                actionElement={
                                    (props: any) => (
                                        <Flexbox align className={classes('h-full', 'w-full', 'cursor-pointer')} {...props}>
                                            {params.data?.analysis?.title || ''}
                                        </Flexbox>
                                    )
                                }
                            >
                                <Flexbox vertical className={classes('analysisItem', 'gap-4')}>
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: 700
                                        }}
                                    >
                                        {params.data.analysis.title || ''}
                                    </Typography>
                                    <Flexbox vertical>
                                        <ul>
                                            {params.value && <li><span className={classes('analysisItem-subtitle')}>Original Value:</span><Markdown text={params.value} /></li>}
                                            <li><span className={classes('analysisItem-subtitle')}>Feedback:</span> <Markdown text={params.data.analysis.feedback} /></li>
                                            <li><span className={classes('analysisItem-subtitle')}>Suggestions:</span><Markdown text={params.data.analysis.suggestions} /></li>
                                            <li>
                                                <CustomTyphography><span className={classes('analysisItem-subtitle')}>Examples:</span></CustomTyphography>
                                                <Markdown text={params.data.analysis.examples} />
                                            </li>
                                        </ul>
                                    </Flexbox>
                                </Flexbox>
                            </SidePanelDrawer>
                        }
                    }
                ] as AgColumn[]
            },
            getDetailRowData: (params: GetDetailRowDataParams) => {
                type DataItem = {
                    analysis: {
                        title?: string;
                        feedback?: string;
                        suggestions?: string;
                        examples?: string;
                        id: number
                    },
                    original_value?: string;
                };

                const data = (Object.values(params.data?.data) as DataItem[])?.filter((item: DataItem) => !!Object.keys(item.analysis).length)
                return params.successCallback(data)
            },
        }
    }, [])

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.planningAnalyses))) as unknown as Preferences<FilterKeys>[];
        if (preferences && preferences.length) {
            const { planningAnalysesGridLayout, order, orderBy, ...filters } = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;

            planningAnalysesGridLayout && setGridStatePreferences(planningAnalysesGridLayout as GridStatePreferences);
            setOrder(order);
            setOrderBy(orderBy)

            if (searchParams.toString().length === 0) {
                setSearchParams(filters, { replace: true })
            }
        }
    }

    useEffect(() => {
        dispatch(getRoadmapViews())
    }, [])

    useEffect(() => {
        if (roadmapViewId) {
            const fetchData = async () => {
                setLoading(true);

                await Promise.all([
                    dispatch(getRoadmapView(roadmapViewId)),
                    loadPreferences()
                ])

                setLoading(false)
            }

            fetchData();
        }
    }, [roadmapViewId])

    useEffect(() => {
        if (roadmapView.length) {
            const uniqueOptions = <K extends keyof RoadmapItem>(key: K) =>
                Array.from(new Set(roadmapView.map(item => item[key]).filter(item => item !== undefined && item !== null && item !== '')))
                    .map((item, index) => ({ id: index, title: `${item}` }));

            setOwnerOptions(uniqueOptions('owner'));
            setProgramOptions(uniqueOptions('program'));
            setSpeakerOptions(uniqueOptions('speaker'));
            setProjectLeadOptions(uniqueOptions('projectLead'));
            setTeamOptions(uniqueOptions('team'));
        }
    }, [roadmapView]);

    // Update Data on filtering/search
    useEffect(() => {
        if (!!roadmapView.length) {
            let data = [...roadmapView];

            if (searchDebounceValue) {
                data = data.filter((item: RoadmapItem) => item.title?.toLocaleUpperCase().includes(searchDebounceValue.toLocaleUpperCase()))
            }

            data = data.filter(item => {
                if (!filterValues[FilterKeys.owner].length || filterValues[FilterKeys.owner].some(filter => filter.title === item.owner)) {
                    return true;
                }
                return false;
            })

            data = data.filter(item => {
                if (!filterValues[FilterKeys.program].length || filterValues[FilterKeys.program].some(filter => filter.title === item.program)) {
                    return true;
                }
                return false;
            })

            data = data.filter(item => {
                if (!filterValues[FilterKeys.speaker].length || filterValues[FilterKeys.speaker].some(filter => filter.title === item.speaker)) {
                    return true;
                }
                return false;
            })

            data = data.filter(item => {
                if (!filterValues[FilterKeys.projectLead].length || filterValues[FilterKeys.projectLead].some(filter => filter.title === item.projectLead)) {
                    return true;
                }
                return false;
            })

            data = data.filter(item => {
                if (!filterValues[FilterKeys.team].length || filterValues[FilterKeys.team].some(filter => filter.title === item.team)) {
                    return true;
                }
                return false;
            })

            setFilteredData(data)
        }
    }, [roadmapView, searchDebounceValue, filterValues]);

    // Update Preferences and routing
    useEffect(() => {
        if (canUpdatePreferences) {
            const owners = filterValues[FilterKeys.owner].map(filter => filter.title);
            const programs = filterValues[FilterKeys.program].map(filter => filter.title);
            const speakers = filterValues[FilterKeys.speaker].map(filter => filter.title);
            const projectLeads = filterValues[FilterKeys.projectLead].map(filter => filter.title);
            const teams = filterValues[FilterKeys.team].map(filter => filter.title);

            const filterKeys: PreferenceValues<FilterKeys> = {};

            if (owners.length) {
                filterKeys[FilterKeys.owner] = owners.join(',');
            }

            if (programs.length) {
                filterKeys[FilterKeys.program] = programs.join(',');
            }

            if (speakers.length) {
                filterKeys[FilterKeys.speaker] = speakers.join(',');
            }

            if (projectLeads.length) {
                filterKeys[FilterKeys.projectLead] = projectLeads.join(',');
            }

            if (teams.length) {
                filterKeys[FilterKeys.team] = teams.join(',');
            }

            if (searchDebounceValue.length) {
                filterKeys[FilterKeys.query] = searchDebounceValue;
            }

            if (order && orderBy) {
                filterKeys[FilterKeys.order] = order;
                filterKeys[FilterKeys.orderBy] = orderBy;
            }

            dispatch(updatePreferences(filterKeys, PreferencesKeys.planningAnalyses));

            setSearchParams(filterKeys, { replace: true });
        }
    }, [filterValues, searchDebounceValue, order, orderBy]);

    // Set Filter Values
    useEffect(() => {
        if (!getViewsRoadmapLoading && !loading) {
            const ownersString = searchParams.get(FilterKeys.owner);
            if (ownersString && !!ownerOptions.length) {
                const owners = ownersString.split(',').map(owner => owner.trim());
                setFilterValues({ type: 'update', key: FilterKeys.owner, payload: ownerOptions.filter(option => owners.includes(option.title)) })
            }

            const programsString = searchParams.get(FilterKeys.program);
            if (programsString && !!programOptions.length) {
                const programs = programsString.split(',').map(program => program.trim());
                setFilterValues({ type: 'update', key: FilterKeys.program, payload: programOptions.filter(option => programs.includes(option.title)) })
            }

            const speakersString = searchParams.get(FilterKeys.speaker);
            if (speakersString && !!speakerOptions.length) {
                const speakers = speakersString.split(',').map(speaker => speaker.trim());
                setFilterValues({ type: 'update', key: FilterKeys.speaker, payload: speakerOptions.filter(option => speakers.includes(option.title)) })
            }

            const projectLeadsString = searchParams.get(FilterKeys.projectLead);
            if (projectLeadsString && !!projectLeadOptions.length) {
                const projectLeads = projectLeadsString.split(',').map(projectLead => projectLead.trim());
                setFilterValues({ type: 'update', key: FilterKeys.projectLead, payload: projectLeadOptions.filter(option => projectLeads.includes(option.title)) })
            }

            const teamsString = searchParams.get(FilterKeys.team);
            if (teamsString && !!teamOptions.length) {
                const teams = teamsString.split(',').map(team => team.trim());
                setFilterValues({ type: 'update', key: FilterKeys.team, payload: teamOptions.filter(option => teams.includes(option.title)) })
            }

            const queryString = searchParams.get(FilterKeys.query);
            if (queryString) {
                setSearchValue(queryString);
            }
        }
    }, [getViewsRoadmapLoading, loading, ownerOptions, programOptions, speakerOptions, projectLeadOptions, teamOptions])

    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setCanUpdatePreferences(true);
    }

    const onSearchClear = () => {
        setSearchValue('');
        setCanUpdatePreferences(true);
    }

    const onFilterValueChange = (targetName: string, value: FilterOption[]) => {
        setFilterValues({ type: 'update', key: targetName, payload: value });
        setCanUpdatePreferences(true)
    }

    const onFilterReset = (filterName: string) => {
        setFilterValues({ type: 'update', key: filterName, payload: [] });
        setCanUpdatePreferences(true)
    }

    const resetAllFilter = () => {
        setSearchValue('')
        setOrder(undefined);
        setOrderBy(undefined);
        setFilterValues({ type: 'reset' });
        setCanUpdatePreferences(true)
    }

    const onSortChanged = useCallback((e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        if (order !== value?.sort || orderBy !== value?.colId) {
            modifiedSearchParamsObject.planningAnalysesGridLayout = gridStatePreferences
            dispatch(updatePreferences(modifiedSearchParamsObject, PreferencesKeys.planningAnalyses));
        }
    }, [searchParams])

    const onCellClicked = (params: CellClickedEvent) => {
        const colId = params.column.getColId()

        if (colId === 'feedbackCount' || colId === 'title' || colId === 'score') {
            params.node.setExpanded(!params.node.expanded);
        }
    };

    const onGridStateChanged = (data: GridStatePreferences) => {
        setGridStatePreferences(data)

        const updatedPreferences: any = {};

        searchParams.forEach((value, key) => {
            updatedPreferences[key] = value;
        });

        updatedPreferences.planningAnalysesGridLayout = data
        dispatch(updatePreferences(updatedPreferences, PreferencesKeys.planningAnalyses));
    }

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    const onChangeViewItemSelection = async (item: RoadmapItem) => {
        if (roadmapViewId) {
            const res: any = await dispatch(handleChangeViewItemSelection({
                roadmapViewId,
                roadmapItemId: item.id,
                checked: item.checked,
            }));

            dispatch(changeViewItemSelectionAction({ id: item.id, checked: item.checked }))

            return !res.error
        }
    }

    const getRowId = useMemo<GetRowIdFunc>(() => {
        return (params: GetRowIdParams) => {
            if (params.context && params.context.nested) {
                return params.data.analysis.id
            } else {
                return params.data.id
            }
        };
    }, []);

    const onSendEmail = async () => {
        if (roadmapViewId && sendEmailDialog.id && sendEmailDialog.email) {
            try {
                await dispatch(handleSendEmail({
                    roadmapViewId,
                    roadmapItemId: sendEmailDialog.id,
                    email: sendEmailDialog.email,
                }))

                setSendEmailDialog({ open: false })

            } catch (err) {
                setSendEmailResultMessage({ type: 'error', message: 'Failed to send email. Please try again.' })
            }

            setTimeout(() => {
                dispatch(setSendEmailResultMessage())
            }, 2000)
        }
    }

    const onCancelSendEmail = () => {
        setSendEmailDialog({ open: false })
    }

    return (
        <Flexbox fullWidth vertical>
            {
                (getViewsRoadmapLoading || loading) ? (
                    <Flexbox fullWidth fullHeight align justify>
                        <Loader disableShrink />
                    </Flexbox>
                ) : roadmapView.length === 0 ? (
                    <EmptyState
                        icon={<DataScience />}
                        title='Planning Analysis'
                        titleSmall={buttonItemsEmptyState.length ? 'Start creating yours by adding items' : undefined}
                        buttonItems={buttonItemsEmptyState}
                    />
                ) : (
                    <>
                        <Header
                            gridApi={gridApi}
                            searchValue={searchValue}
                            emptyState={Array.isArray(roadmapView) && roadmapView.length === 0}
                            totalRows={roadmapView.length}
                            pendingRows={roadmapView.filter(item => item.feedbackCount > 0).length}
                            roadmapViewId={roadmapViewId}
                            ownerOptions={ownerOptions}
                            filterValues={filterValues}
                            onSearchValueChange={onSearchValueChange}
                            onSearchClear={onSearchClear}
                            onFilterValueChange={onFilterValueChange}
                            onFilterReset={onFilterReset}
                            resetAllFilter={resetAllFilter}
                            programOptions={programOptions}
                            speakerOptions={speakerOptions}
                            projectLeadOptions={projectLeadOptions}
                            teamOptions={teamOptions}
                        />
                        <Flexbox fullHeight className={classes('')}>
                            <AgGridTable
                                data={filteredData}
                                columns={columns}
                                onGridReady={onGridReady}
                                exportFileName={'Planning Analysis'}
                                onSortChanged={onSortChanged}
                                order={order}
                                orderBy={orderBy}
                                masterDetail
                                detailCellRendererParams={detailCellRendererParams}
                                isRowMaster={(params: RoadmapItem) => params.data !== null}
                                onCellClicked={onCellClicked}
                                gridStatePreferences={gridStatePreferences}
                                onGridStateChanged={onGridStateChanged}
                                readOnlyEdit={true}
                                updateRowCallback={onChangeViewItemSelection}
                                getRowId={getRowId}
                            />
                        </Flexbox>
                    </>
                )
            }
            <AddSourcePopup open={openAddSourcePopup} handleClosePopup={() => setOpenAddSourcePopup(false)} />

            <Dialog
                open={sendEmailDialog.open}
                onClose={onCancelSendEmail}
                onConfirm={onSendEmail}
                title='Send Email'
                disabled={!sendEmailDialog.email || (!!sendEmailDialog.email && !emailValidation(sendEmailDialog.email.trim()))}
                confirmButtonLabel={'Send'}
                confirmButton
                cancelButton
                PaperProps={{
                    sx: {
                        width: '350px'
                    }
                }}
                confirmButtonLoading={sendEmailLoading}
            >
                <Flexbox>
                    <CommonInput
                        placeholder={'Email'}
                        inputType="filled"
                        value={sendEmailDialog.email}
                        onChange={(e) => { setSendEmailDialog(prev => ({ ...prev, email: e.target.value })) }}
                        error={!!sendEmailDialog.email && !emailValidation(sendEmailDialog.email?.trim())}
                        helperText={sendEmailDialog.email && !emailValidation(sendEmailDialog.email?.trim()) && 'Invalid email'}
                    />
                </Flexbox>
            </Dialog>

            {sendEmailResultMessage && <CustomSnackbar open={!!sendEmailResultMessage} type={sendEmailResultMessage?.type} title={sendEmailResultMessage?.message} />}
        </Flexbox>
    )
}

export default PlanningAnalysis