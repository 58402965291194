import { Flexbox, Loader, TabPanel } from 'components'
import { useSelector } from 'react-redux';
import ResourcesBlock from 'common/resourcesBlock';
import { productBlocksSelector } from 'store/product-slice';

interface ResourcesTabProps{
    active: boolean,
    productId?: number;
    isEditable?: boolean;
}

const ResourcesTab = ({ active, productId, isEditable = true } : ResourcesTabProps) => {
    const blocksData = useSelector(productBlocksSelector)

    const baseUrl = `/products/${productId}/blocks/`

    return(
        <TabPanel active={active}>
            { !blocksData ? <Flexbox fullWidth align justify><Loader disableShrink/></Flexbox>
                :
                <ResourcesBlock blocks={blocksData} baseUrl={baseUrl} isEditable={isEditable} />
            }
        </TabPanel>
    )
}

export default ResourcesTab