import { ActionsMenu, ConfirmationDialog, Flexbox } from 'components'
import styles from '../dependency.module.scss';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import classNames from 'classnames/bind';
import DependencyStatusIcon from './dependencyStatusIcon';
import { Actions, hasPermission } from 'utils/permissions';
import { FC, useState } from 'react';
import { getEnumKeyByEnumValue } from 'utils';
import { DependencyStatuses } from 'utils/types';
import { useSelector } from 'react-redux';
import { dependencyOwnerSelector, dependencyStatusSelector } from 'store/dependency-slice';
import { useNavigate } from 'react-router-dom';
import { useDeleteDependencyMutation } from 'store/dependency-api';
import { useWorkspaceId } from 'utils/hooks';
const classes = classNames.bind(styles);
const classesInfo = classNames.bind(stylesInfo);

interface DependencyHeadProps {
    assigned: boolean,
    dependencyId: number
}

const DependencyHead: FC<DependencyHeadProps> = ({ assigned, dependencyId }) => {
    const navigate = useNavigate();
    const workspaceId = useWorkspaceId();

    const status = useSelector(dependencyStatusSelector);
    const ownerId = useSelector(dependencyOwnerSelector)?.id;

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

    const [deleteDependency] = useDeleteDependencyMutation()

    const showDeleteConfirmation = () => {
        setOpenDeleteConfirmation(true)
    }

    const onCancelDelete = () => {
        setOpenDeleteConfirmation(false)
    }

    const onDeleteDependency = async () => {
        try {
            await deleteDependency({ workspaceId, dependencyId })
        } catch (error) {
            console.error('Failed to delete Dependency', error);
        }

        onCancelDelete()
        navigate('/dependencies');
    }

    return(
        <Flexbox vertical className={classesInfo('headerContainer')}>
            <Flexbox className={classesInfo('headerInfoTop')}>
                <Flexbox className={classesInfo('headerTitle')}>Dependency ticket</Flexbox>
                <Flexbox>
                    <Flexbox className={classes('statusItem')}>
                        <Flexbox className={classes('statusItemText')}>
                            {status && getEnumKeyByEnumValue(DependencyStatuses, status)}
                        </Flexbox>
                        <DependencyStatusIcon status={status} />
                    </Flexbox>
                    {hasPermission(Actions.delete, { owner: { id: ownerId } }) && !assigned &&
                        <ActionsMenu className={classes('actionsMenu')}
                            buttonItems={[{ label: 'Delete', action: showDeleteConfirmation, type: 'red' }]}
                        />
                    }
                </Flexbox>
            </Flexbox>
            <ConfirmationDialog
                    open={openDeleteConfirmation}
                    onClose={onCancelDelete}
                    onConfirm={onDeleteDependency}
                    confirmButtonStyle='danger'
                    title='Delete this Dependency ticket?'
                >
                    <Flexbox>
                        You're about to permanently delete this Dependency ticket, and all connected data will be lost.
                    </Flexbox>
            </ConfirmationDialog>
        </Flexbox>
    )
}

export default DependencyHead