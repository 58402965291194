import Flexbox from 'components/Flexbox'
import classNames from 'classnames/bind';
import styles from './styles.module.scss'
import CustomTyphography from 'components/CustomTyphography';
import { Box, Link, Loader } from 'components';
import { DescriptionIcon } from 'components/icons';
import { FC } from 'react';
import { KBSource } from 'utils/types';
import { snakeToTitleCase } from 'utils/string';

const classes = classNames.bind(styles);

const getFormattedDate = (value: number) => {
    const date = new Date(value);

    if (!isNaN(date.getTime())) {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    }
}

interface KBGenericSidebarProps {
    title: string
    source?: KBSource
    createdDate?: number
    lastModifiedDate?: number
    fields?: { key: string, value: string }[]
    complexFields?: { [key: string]: string | number }[]
    loading?: boolean
}

const KBGenericSidebar: FC<KBGenericSidebarProps> = ({
    title,
    source,
    createdDate,
    lastModifiedDate,
    fields,
    complexFields,
    loading
}) => {
    return (
        <Flexbox vertical fullWidth className={classes('kbSidebarContainer', 'gap-6')}>
            <Flexbox fullWidth align justifyBetween className={classes('pb-4', 'kbSidebar-titleContainer')}>
                <CustomTyphography type='primary' className={classes('kbSidebar-title')}>{title}</CustomTyphography>
            </Flexbox>
            {
                loading ? <Flexbox align justify><Loader /></Flexbox> : <Flexbox vertical fullWidth className={classes('gap-6', 'kbSidebar-mainContent')}>
                    {/* _____________________GROUP1_____________________ */}
                    <Flexbox vertical className={classes('gap-6')}>
                        <Flexbox vertical fullWidth className={classes('gap-5', 'kbSidebar-group')}>
                            {
                                !!complexFields?.length && complexFields.map((entry, entryIndex) => {
                                    return <Box key={entryIndex} sx={{
                                        borderBottom: '1px solid #e1dcdc'
                                    }}>
                                        {Object.entries(entry).filter(entry => !!entry[1] && !entry[0].includes('id')).map(entry => {
                                            if (entry[0] === 'createdDate') {
                                                return <Flexbox key={entry[0]} vertical align className={classes('p-4', 'gap-5')}>
                                                    <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem', 'pb-4')}>
                                                        <CustomTyphography type='primary'>Created Date</CustomTyphography>
                                                        <CustomTyphography>{getFormattedDate(entry[1] as number)}</CustomTyphography>
                                                    </Flexbox>
                                                </Flexbox>
                                            } else if (entry[0] === 'lastModifiedDate') {
                                                return <Flexbox key={entry[0]} vertical align className={classes('p-4', 'gap-5')}>
                                                    <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem', 'pb-4')}>
                                                        <CustomTyphography type='primary'>Updated Date</CustomTyphography>
                                                        <CustomTyphography>{getFormattedDate(entry[1] as number)}</CustomTyphography>
                                                    </Flexbox>
                                                </Flexbox>
                                            } else {
                                                return <Flexbox key={entry[0]} vertical align className={classes('p-4', 'gap-5')}>
                                                    <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem', 'pb-4', 'gap-4')}>
                                                        <CustomTyphography type='primary'>{snakeToTitleCase(entry[0])}</CustomTyphography>
                                                        <CustomTyphography className={classes('text-end')}>{entry[1]}</CustomTyphography>
                                                    </Flexbox>
                                                </Flexbox>
                                            }
                                        })}
                                    </Box>
                                })
                            }
                            {!!fields?.length && fields.map(field => (
                                <Flexbox key={field.key} vertical align className={classes('p-4', 'gap-5')}>
                                    <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem', 'pb-4', 'gap-4')}>
                                        <CustomTyphography type='primary'>{field.key}</CustomTyphography>
                                        <CustomTyphography className={classes('text-end')}>{field.value}</CustomTyphography>
                                    </Flexbox>
                                </Flexbox>
                            ))}
                            {!!createdDate && <Flexbox vertical align className={classes('p-4', 'gap-5')}>
                                <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem', 'pb-4')}>
                                    <CustomTyphography type='primary'>Created Date</CustomTyphography>
                                    <CustomTyphography>{getFormattedDate(createdDate)}</CustomTyphography>
                                </Flexbox>
                            </Flexbox>}
                            {!!lastModifiedDate && <Flexbox vertical align className={classes('p-4', 'gap-5')}>
                                <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem', 'pb-4')}>
                                    <CustomTyphography type='primary'>Updated Date</CustomTyphography>
                                    <CustomTyphography>{getFormattedDate(lastModifiedDate)}</CustomTyphography>
                                </Flexbox>
                            </Flexbox>}
                            {
                                !!source && <Flexbox vertical align className={classes('p-4', 'gap-5')}>
                                    <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem', 'last')}>
                                        <CustomTyphography type='primary'>Source</CustomTyphography>
                                        <Flexbox align justifyBetween className={classes('kbSidebar-link')}>
                                            <DescriptionIcon />
                                            <Link to={source.source} target='_blank'>
                                                <CustomTyphography>{source.name}</CustomTyphography>
                                            </Link>
                                        </Flexbox>
                                    </Flexbox>
                                </Flexbox>
                            }
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            }
        </Flexbox>
    )
}

export default KBGenericSidebar