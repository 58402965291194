import { Flexbox } from 'components'

import classNames from 'classnames/bind';
import styles from './content.module.scss';

const classes = classNames.bind(styles);

interface ContentProps {
    children: JSX.Element
}

const Content = ({ children }:ContentProps) => {

    return (
        <Flexbox className={classes('contentContainer')} >
            {children}

        </Flexbox>
    )
}

export default Content