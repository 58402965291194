import MPopover, { PopoverProps as MPopoverProps } from '@mui/material/Popover';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
const classes = classNames.bind(styles);


interface PopoverProps extends MPopoverProps{
    className?: string;
}

const Popover = ({ className, children, ...props }: PopoverProps) => {

    return (
        <MPopover
            className={classes('popover', className)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            {...props}
        >
            {children}
        </MPopover>

    )
}

export default Popover;