import { Flexbox, Input, Select, AddButton, TabPanel, ActionsMenu, LinearProgress, ConfirmationDialog, TimeAgo } from 'components';
import { ChangeEvent, useEffect, useState } from 'react';
import { Metric } from 'utils/types';
import classNames from 'classnames/bind';
import styles from '../../okr.module.scss';
import Table, { TableHeader } from 'components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { toPrecision, trimSpaces } from 'utils';
import { createFilterOptions } from 'components/Select';
import { uomList } from 'utils/constants';
import { SaveStatus } from 'common/savingStatus';
import { deleteKr, editKr, okrKeyResultsSelector, okrOwnerSelector, setKrs, setSavingStatus } from 'store/okr-slice';
import { useCreateKeyResultMutation, useDeleteKeyResultMutation, useEditKeyResultMutation, useLazyGetOkrByIdQuery } from 'store/okr-api';
import { useWorkspaceId } from 'utils/hooks';
import { Actions, hasPermission } from 'utils/permissions';
import { useCreateMetricMutation, useLazyGetMetricsQuery, usePushMetricDataMutation } from 'store/metrics-api';
import { metricsSelector } from 'store/metrics-slice';
const classes = classNames.bind(styles);

interface KeyResultTableHeader {
    name: string;
    uom: string;
    startValue: number;
    targetValue: number;
    currentValue: number;
    progress: number;
    lastModifiedDate: number,
    actions: string;
}

const krHeaders: TableHeader<KeyResultTableHeader>[] = [
    {
        id: 'name',
        text: 'Metric',
        width: '300px',
        sortable: true,
    },
    {
        id: 'uom',
        text: 'Unit',
        width: '200px',
        sortable: true,
    },
    {
        id: 'startValue',
        text: 'Start',
        width: '200px',
        sortable: true,
    },
    {
        id: 'targetValue',
        text: 'Target',
        width: '200px',
        sortable: true,
    },
    {
        id: 'currentValue',
        text: 'Current',
        width: '200px',
        sortable: true,
    },
    {
        id: 'progress',
        text: 'Progress',
        sortable: true,
    },
    {
        id: 'lastModifiedDate',
        text: 'Last Updated',
        width: '200px',
        sortable: true,
    },
    {
        id: 'actions',
        text: '',
        width: '30px',
    },
]

interface KeyResultsTabProps {
    active: boolean;
    okrId: number | undefined;
}

interface MetricWithExtraValue extends Metric {
    extraValue?: string
}

const filter = createFilterOptions<MetricWithExtraValue>();

const KeyResultsTab = ({
    active,
    okrId,
}: KeyResultsTabProps) => {
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();

    const [createMetric] = useCreateMetricMutation();
    const [pushMetricData] = usePushMetricDataMutation();
    const [getMetrics] = useLazyGetMetricsQuery();

    const metrics = useSelector(metricsSelector);
    const keyResults = useSelector(okrKeyResultsSelector);
    const ownerId = useSelector(okrOwnerSelector)

    const isEditable = hasPermission(Actions.edit, { owner: { id: ownerId } })

    const [editKrMutation] = useEditKeyResultMutation();
    const [createKrMutation] = useCreateKeyResultMutation();
    const [deleteKrMutation] = useDeleteKeyResultMutation()
    const [getOkrById] = useLazyGetOkrByIdQuery()

    const [newKRMetric, setNewKRMetric] = useState<Metric | null>(null);
    const [newKRMetricError, setNewKRMetricError] = useState('');

    const [newKRUOM, setNewKRUOM] = useState<string | null>(null);
    const [newKRUOMError, setNewKRUOMError] = useState('');

    const [newKRStartValue, setNewKRStartValue] = useState(0);
    const [newKRTargetValue, setNewKRTargetValue] = useState(0);
    const [newKRCurrentValue, setNewKRCurrentValue] = useState(0);

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [krActionId, setKrActionId] = useState<number | undefined>();

    const [orderBy, setOrderBy] = useState<keyof KeyResultTableHeader | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        getMetrics({ workspaceId })
    }, [])

    useEffect(() => {
        if (newKRMetric && newKRMetric.id !== 0) {
            setNewKRUOM(newKRMetric.uom || '');
            setNewKRStartValue(newKRMetric.currentValue || 0);
            setNewKRCurrentValue(newKRMetric.currentValue || 0);
        } else {
            setNewKRUOM('');
            setNewKRStartValue(0);
            setNewKRCurrentValue(0);
            setNewKRTargetValue(0);
        }
    }, [newKRMetric])

    const onNewKrUomChange = (_e: ChangeEvent<{}>, value: string | null) => {
        setNewKRUOM(value)
        if (newKRUOMError) {
            setNewKRUOMError('')
        }
    }
    const onNewKrStartValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = toPrecision(+e.target.value, 2)

        setNewKRStartValue(value);

        if (newKRMetric?.id === 0) {
            setNewKRCurrentValue(value)
        }
    }

    const onNewKrTargetValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = toPrecision(+e.target.value, 2);
        setNewKRTargetValue(value);
    }

    useEffect(() => {
        let data = [...keyResults];

        if (orderBy && order && orderBy !== 'actions') {
            data.sort((d1, d2) => {
                if (orderBy === 'currentValue' || orderBy === 'lastModifiedDate') {
                    const value1 = d1.metric[orderBy];
                    const value2 = d2.metric[orderBy];
                    if (value1 > value2) {
                        return order === 'asc' ? 1 : -1
                    } else {
                        return order === 'asc' ? -1 : 1
                    }
                } else if (orderBy === 'uom' || orderBy === 'name') {
                    const value1 = d1.metric[orderBy] || '';
                    const value2 = d2.metric[orderBy] || '';
                    if (value1.toUpperCase() > value2.toUpperCase()) {
                        return order === 'asc' ? 1 : -1
                    } else {
                        return order === 'asc' ? -1 : 1
                    }
                } else {
                    const value1 = d1[orderBy] || 0;
                    const value2 = d2[orderBy] || 0;
                    if (value1 > value2) {
                        return order === 'asc' ? 1 : -1
                    } else {
                        return order === 'asc' ? -1 : 1
                    }
                }
            })
        }

        dispatch(setKrs(data))
    }, [orderBy, order]);

    const onCreateKeyResult = async () => {
        if (!newKRMetric?.name || !newKRUOM) {
            if (!newKRMetric?.name) {
                setNewKRMetricError('Metric is required')
            }
            if (!newKRUOM) {
                setNewKRUOMError('Unit is required')
            }
        } else if (okrId && newKRMetric) {
            let metricId = newKRMetric.id;
            if (newKRMetric.id === 0) {
                const newMetric = {
                    description: '',
                    formula: '',
                    level: 0,
                    name: trimSpaces(newKRMetric.name),
                    uom: newKRUOM || '',
                }

                const res: any = await createMetric({ workspaceId, data: newMetric }).unwrap();

                metricId = res.id
            }

            try {
                await createKrMutation({
                    workspaceId,
                    okrId,
                    model: {
                        metric: metricId,
                        startValue: newKRStartValue,
                        targetValue: newKRTargetValue
                    }
                }).unwrap()
            } catch (error) {
                console.error('Failed to create Key Result', error);
            }

            setNewKRMetric(null);

            if (newKRMetricError) {
                setNewKRMetricError('')
            }
            if (newKRUOMError) {
                setNewKRUOMError('')
            }

        }
    }

    const onKrFieldBlur = async (krId: number) => {
        const result = keyResults.find(kr => kr.id === krId);
        if (result && okrId) {
            dispatch(setSavingStatus(SaveStatus.SAVING));

            try {
                await editKrMutation({
                    workspaceId,
                    okrId,
                    krId,
                    model: {
                        metric: result.metric.id,
                        targetValue: result.targetValue,
                        startValue: result.startValue,
                    }
                })
            } catch (error) {
                console.error('Failed to edit Key Result', error);
            }
        }
    }

    const onMetricCurrentValueChange = (metricId: number, value: string) => {
        const kr = keyResults.find(kr => kr.metric.id === metricId)
        if (kr) {
            dispatch(editKr({
                krId: kr.id, metric: {
                    ...kr.metric,
                    currentValue: toPrecision(+value, 2)
                }
            }))
        }
    }

    const onMetricCurrentValueBlur = async (krId: number) => {
        const result = keyResults.find(kr => kr.id === krId);
        if (result) {
            dispatch(setSavingStatus(SaveStatus.SAVING));
            await pushMetricData({ workspaceId, metricId: result.metric.id, value: result.metric.currentValue });
            getOkrById({ okrId, workspaceId });
            dispatch(setSavingStatus(SaveStatus.SAVED));
            setTimeout(() => { dispatch(setSavingStatus(SaveStatus.UNSET)) }, 2000)
        }
    }

    const keyResultSortHandler = (header: keyof KeyResultTableHeader) => {
        setOrderBy(header);
        setOrder(oldOrder => {
            return oldOrder === 'asc' ? 'desc' : 'asc'
        })
    }

    const onKrStartValueChange = (newValue: string, krId: number) => {
        dispatch(editKr({ krId, startValue: toPrecision(+newValue, 2) }))
    }

    const onKrTargetValueChange = (newValue: string, krId: number) => {
        dispatch(editKr({ krId, targetValue: toPrecision(+newValue, 2) }))
    }

    const deleteKrHandler = async () => {
        if (okrId && krActionId) {
            try {
                await deleteKrMutation({ workspaceId, okrId, krId: krActionId })
            } catch (error) {
                console.error('Failed to delete Key Result', error);
            }
            onCancelDelete();
            dispatch(deleteKr(krActionId))
        }
    }

    const showDeleteConfirmation = (idKr: number) => {
        setKrActionId(idKr)
        setOpenConfirmation(true)
    }

    const onCancelDelete = () => {
        setOpenConfirmation(false)
    }

    const krTableData = keyResults.map(kr => {
        return {

            data: [
                kr.metric.name,
                kr.metric.uom,
                <Input
                    variant='filled'
                    value={kr.startValue + ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => { onKrStartValueChange(e.target.value, kr.id) }}
                    onBlur={() => { onKrFieldBlur(kr.id) }}
                    type='number'
                    placeholder='Target value'
                    editable={isEditable}
                />, ,
                <Input
                    variant='filled'
                    value={kr.targetValue + ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => { onKrTargetValueChange(e.target.value, kr.id) }}
                    onBlur={() => { onKrFieldBlur(kr.id) }}
                    type='number'
                    placeholder='Target value'
                    editable={isEditable}
                />,
                <Input
                    variant='filled'
                    value={kr.metric.currentValue + ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => { onMetricCurrentValueChange(kr.metric.id, e.target.value) }}
                    onBlur={() => { onMetricCurrentValueBlur(kr.id) }}
                    type='number'
                    placeholder='Current value'
                    editable={isEditable}
                />,
                <Flexbox align className={classes('progressContainer')}>
                    <Flexbox className={classes('progress')}>
                        <LinearProgress value={kr.progress || 0} variant="determinate" />
                    </Flexbox>
                    <Flexbox>{Math.round(kr.progress || 0)}% </Flexbox>
                </Flexbox>,
                <Flexbox>
                    <TimeAgo datetime={kr.metric.lastModifiedDate} live={false} />
                </Flexbox>,
                <Flexbox>
                    {isEditable && <ActionsMenu
                        buttonItems={[
                            {
                                label: 'Delete',
                                type: 'red',
                                action: () => {
                                    showDeleteConfirmation(kr.id);
                                }
                            }
                        ]}
                    />}
                </Flexbox>
            ]
        }
    })

    return (
        <TabPanel active={active} vertical>
            <Flexbox className={classes('contentContainer')} vertical>
                <Flexbox className={classes('tableContainer')}>
                    <Table
                        header={krHeaders}
                        data={isEditable ? [{
                            data: [
                                <Select
                                    variant='filled'
                                    placeholder='Select metric'
                                    value={newKRMetric}
                                    fullWidth
                                    onChange={(e: ChangeEvent<{}>, value: MetricWithExtraValue | null) => {
                                        if (value && value.extraValue) {
                                            const newData = { ...value, name: value.extraValue }
                                            setNewKRMetric(newData)
                                        } else {
                                            setNewKRMetric(value);
                                        }

                                        if (newKRMetricError) {
                                            setNewKRMetricError('')
                                        }

                                    }}
                                    options={metrics}
                                    getOptionLabel={option => option.name}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => trimSpaces(inputValue) === trimSpaces(option.name));
                                        if (hasPermission(Actions.createMetric) && inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                id: 0,
                                                description: '',
                                                formula: '',
                                                name: `Create "${inputValue}"`,
                                                type: 'number',
                                                level: 0,
                                                creator: null,
                                                currentValue: 0,
                                                lastModifiedDate: new Date().getTime(),
                                                extraValue: inputValue
                                            });
                                        }

                                        return filtered;
                                    }}
                                    errorText={newKRMetricError}
                                />,
                                <Select
                                    placeholder='Select Unit'
                                    variant='filled'
                                    value={newKRUOM}
                                    onChange={onNewKrUomChange}
                                    options={uomList}
                                    disabled={!!newKRMetric && newKRMetric.id !== 0}
                                    className={classes('selectUnitValue')}
                                    errorText={newKRUOMError}
                                />,
                                <Input
                                    placeholder='Start value'
                                    variant='filled'
                                    value={!!newKRStartValue && newKRStartValue || ''}
                                    onChange={onNewKrStartValueChange}
                                    alwaysEditable
                                    type='number'
                                />,
                                <Input
                                    placeholder='Target value'
                                    variant='filled'
                                    value={!!newKRTargetValue && newKRTargetValue || ''}
                                    onChange={onNewKrTargetValueChange}
                                    alwaysEditable
                                    type='number'
                                />,
                                <Flexbox className={classes('newKrValue')}>
                                    {!!newKRCurrentValue && newKRCurrentValue}
                                </Flexbox>,
                                '',
                                '',
                                <AddButton
                                    onClick={onCreateKeyResult}
                                    active={!!newKRMetric && !!newKRUOM}
                                />,
                            ],
                            hasEditableField: true
                        }, ...krTableData] : krTableData}
                        orderBy={orderBy}
                        order={order}
                        sortHandler={keyResultSortHandler}
                        emptyText={krTableData.length === 0 ? 'There are no KRs yet' : undefined}
                    />
                </Flexbox>
            </Flexbox>

            <ConfirmationDialog
                open={openConfirmation}
                onClose={onCancelDelete}
                onConfirm={deleteKrHandler}
                confirmButtonStyle='danger'
                title='Delete this Kr?'
            >
                <Flexbox>You're about to permanently delete this Kr.</Flexbox>
            </ConfirmationDialog>

        </TabPanel>
    )
}

export default KeyResultsTab