import { TabPanel } from 'components'
import StoryList from 'common/Story';


interface RequirementsTabProps{
    active: boolean;
    productId?: number;
}

const RequirementsTab = ({ active, productId } : RequirementsTabProps) => {

    const baseUrl = `products/${productId}/requirements`
    const integrationUrl = `products/${productId}/integration-options`


    return(
        <TabPanel vertical active={active}>
            {productId && <StoryList baseUrl={baseUrl} integrationUrl={integrationUrl} tableEditMode={false} fromProduct />}
        </TabPanel>
    )
}

export default RequirementsTab

