import { Flexbox, Select, TabPanel } from 'components';
import { InitiativePriority, InitiativeSize, InitiativeType } from 'utils/types';
import classNames from 'classnames/bind';
import styles from '../../initiative.module.scss';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { EditorRef } from 'components/RichTextEditor';
import { useSelector } from 'react-redux';
import { userSelector } from 'store/user';
import { CommentField } from 'components/Comments/CommentField/CommentField';
import { CommentFieldEditor } from 'components/Comments/CommentField/CommentFieldEditor';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useWorkspaceId } from 'utils/hooks';
const classes = classNames.bind(styles);

interface OverviewTabProps {
    active: boolean;
    priority: InitiativePriority;
    setPriority: (value: InitiativePriority) => void;
    size: string;
    setSize: (value: string) => void;
    type: InitiativeType;
    setType: (value: InitiativeType) => void;
    problem: string;
    setProblem: React.Dispatch<React.SetStateAction<string>>;
    validation: string;
    setValidation: React.Dispatch<React.SetStateAction<string>>;
    solution: string;
    setSolution: React.Dispatch<React.SetStateAction<string>>;
    isEditable?: boolean;
    generatingInitiative: boolean;
    initiativeId?: number;
    ownerId?: number;
    commentStatus?: QueryStatus;
    fillingFieldName?: string;
}

const OverviewTab = ({
    active,
    priority,
    setPriority,
    size,
    setSize,
    type,
    setType,
    problem,
    setProblem,
    validation,
    setValidation,
    solution,
    setSolution,
    isEditable = true,
    generatingInitiative,
    initiativeId,
    ownerId,
    commentStatus,
    fillingFieldName,
}: OverviewTabProps) => {
    const currentUser = useSelector(userSelector);
    const workspaceId = useWorkspaceId();

    const initiativePriorityEntries = Object.keys(InitiativePriority);
    const initiativeSizeEntries = Object.keys(InitiativeSize);

    const initiativeTypeEntries = Object.keys(InitiativeType);

    const problemFieldRef = useRef<EditorRef | null>(null);
    const validationFieldRef = useRef<EditorRef | null>(null);
    const solutionFieldRef = useRef<EditorRef | null>(null);


    // ___________Scrolling
    const problemRef = useRef<HTMLDivElement>(null)
    const validationRef = useRef<HTMLDivElement>(null)
    const solutionRef = useRef<HTMLDivElement>(null)

    const onProblemChange = (value: string) => {
        setProblem(value);
    };

    const onSolutionChange = (value: string) => {
        setSolution(value);
    };

    const onValidationChange = (value: string) => {
        setValidation(value);
    };

    const onTypeChange = (_e: ChangeEvent<{}>, value: string) => {
        if (value) {
            setType(InitiativeType[value as keyof typeof InitiativeType]);
        }
    };

    const onPriorityChange = (_e: ChangeEvent<{}>, value: string) => {
        if (value) {
            setPriority(InitiativePriority[value as keyof typeof InitiativePriority]);
        }
    };

    const onSizeChange = (_e: ChangeEvent<{}>, value: string) => {
        if (value) {
            setSize(value);
        }
    };

    if (initiativeId === undefined) {
        return null;
    }


    const scrollToViewHandler = (ref: any) => {
        const boundingClientRect = ref.current.getBoundingClientRect()
        window.scrollTo({
            top: boundingClientRect.top + boundingClientRect.height,
            left: 0,
        });
    }

    useEffect(() => {
        if (generatingInitiative && problemRef.current && fillingFieldName?.includes('[problem]')) {
            scrollToViewHandler(problemRef)
        }
    }, [problem, generatingInitiative, fillingFieldName]);

    useEffect(() => {
        if (generatingInitiative && validationRef.current && fillingFieldName?.includes('[validation]')) {
            scrollToViewHandler(validationRef)
        }
    }, [validation, generatingInitiative, fillingFieldName]);

    useEffect(() => {
        if (generatingInitiative && solutionRef.current && fillingFieldName?.includes('[solution]')) {
            scrollToViewHandler(solutionRef)
        }
    }, [solution, generatingInitiative, fillingFieldName]);

    return (
        <TabPanel vertical active={active}>
            <Flexbox
                vertical
                fullWidth
                className={classes('tabContainer')}
            >
                <div className={classes('comment-field-container')}>
                    <Flexbox fullWidth className={classes('tabColSpacing')}>
                        <CommentField
                            workspaceId={workspaceId}
                            initiativeId={initiativeId}
                            objectKey="initiatives"
                            objectId={initiativeId}
                            fieldKey="overview.type"
                        >
                            <Select
                                onChange={onTypeChange}
                                options={initiativeTypeEntries}
                                value={initiativeTypeEntries.find(
                                    entry =>
                                        InitiativeType[entry as keyof typeof InitiativeType] ===
                                        type
                                )}
                                disableClearable
                                // getOptionLabel={option => InitiativeType[option as keyof typeof InitiativeType]}
                                label="Type"
                                disabled={!isEditable}
                            />
                        </CommentField>
                        <CommentField
                            workspaceId={workspaceId}
                            initiativeId={initiativeId}
                            objectKey="initiatives"
                            objectId={initiativeId}
                            fieldKey="overview.priority"
                        >
                            <Select
                                onChange={onPriorityChange}
                                value={initiativePriorityEntries.find(entry => entry === priority)}
                                options={initiativePriorityEntries}
                                disableClearable
                                getOptionLabel={option =>
                                    InitiativePriority[option as keyof typeof InitiativePriority]
                                }
                                label="Priority"
                                disabled={!isEditable}
                            />
                        </CommentField>
                        <CommentField
                            workspaceId={workspaceId}
                            initiativeId={initiativeId}
                            objectKey="initiatives"
                            objectId={initiativeId}
                            fieldKey="overview.size"
                        >
                            <Select
                                onChange={onSizeChange}
                                value={initiativeSizeEntries.find(entry => entry === size)}
                                options={initiativeSizeEntries}
                                disableClearable
                                getOptionLabel={option =>
                                    InitiativeSize[option as keyof typeof InitiativeSize]
                                }
                                label="Size"
                                disabled={!isEditable}
                            />
                        </CommentField>
                    </Flexbox>
                </div>
                <div className={classes('comment-field-container')} ref={problemRef}>
                    <CommentFieldEditor
                        workspaceId={workspaceId}
                        initiativeId={initiativeId}
                        objectKey="initiatives"
                        objectId={initiativeId}
                        fieldKey="overview.problem"
                        flexClassname={classes('tabRow')}
                        value={problem}
                        readOnly={!isEditable}
                        onChange={onProblemChange}
                        placeholder="Problem description"
                        label="Problem"
                        dataForAiGenerate={{
                            name: 'generate_prd_component',
                            initiativeId: initiativeId,
                            component: 'problem',
                        }}
                        hasAiDialog
                        files={[]}
                        ref={problemFieldRef}
                        user={currentUser}
                        ownerId={ownerId}
                        commentsUrl={`initiatives/${initiativeId}/problem/comments`}
                    />
                </div>
                <div className={classes('comment-field-container')} ref={validationRef}>
                    <CommentFieldEditor
                        workspaceId={workspaceId}
                        initiativeId={initiativeId}
                        objectKey="initiatives"
                        objectId={initiativeId}
                        fieldKey="overview.validation"
                        flexClassname={classes('tabRow')}
                        value={validation}
                        readOnly={!isEditable}
                        onChange={onValidationChange}
                        placeholder="Validation description"
                        label="Validation"
                        files={[]}
                        dataForAiGenerate={{
                            name: 'generate_prd_component',
                            initiativeId: initiativeId,
                            component: 'validation',
                        }}
                        hasAiDialog
                        ref={validationFieldRef}
                        user={currentUser}
                        ownerId={ownerId}
                        commentsUrl={`initiatives/${initiativeId}/validation/comments`}
                    />
                </div>
                <div className={classes('comment-field-container')} ref={solutionRef}>
                    <CommentFieldEditor
                        workspaceId={workspaceId}
                        initiativeId={initiativeId}
                        objectKey="initiatives"
                        objectId={initiativeId}
                        fieldKey="overview.solution"
                        flexClassname={classes('tabRow')}
                        value={solution}
                        readOnly={!isEditable}
                        onChange={onSolutionChange}
                        placeholder="High level solution"
                        label="High level solution"
                        files={[]}
                        dataForAiGenerate={{
                            name: 'generate_prd_component',
                            initiativeId: initiativeId,
                            component: 'solution',
                        }}
                        hasAiDialog
                        ref={solutionFieldRef}
                        user={currentUser}
                        ownerId={ownerId}
                        commentsUrl={`initiatives/${initiativeId}/solution/comments`}
                    />
                </div>
            </Flexbox>
        </TabPanel>
    );
};

export default OverviewTab;
