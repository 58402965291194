export const CommentResolvedIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="10" fill="#025B62" />
            <path
                d="M17.7 9.125L10.625 16.2L6.5 12.05L7.625 10.925L10.625 13.925L16.55 8L17.7 9.125Z"
                fill="white"
            />
        </svg>
    );
};
