import { ActionsMenu, IconButton, Stack } from 'components';
import { ButtonItem } from 'components/ActionsMenu';
import { CommentNotResolvedIcon } from 'components/icons/CommentNotResolved';
import { CommentResolvedIcon } from 'components/icons/CommentResolved';
import { FC, MouseEventHandler } from 'react';
import styles from './comments.module.scss';
import classNames from 'classnames/bind';
const classes = classNames.bind(styles);

interface CommentActionsProps {
    hovered?: boolean;
    canDelete?: boolean;
    onDelete?: () => Promise<void>;
    canEdit?: boolean;
    onEdit?: () => void;
    resolved?: boolean;
    onResolve?: MouseEventHandler<HTMLButtonElement>;
}

export const CommentActions: FC<CommentActionsProps> = ({
    hovered = true,
    canDelete = true,
    onDelete,
    canEdit = true,
    onEdit,
    resolved,
    onResolve,
}) => {
    const items: ButtonItem[] = [];
    if (onDelete && canDelete) {
        items.push({ label: 'Delete', action: onDelete, type: 'red' });
    }
    if (onEdit && canEdit) {
        items.push({ label: 'Edit', action: onEdit });
    }
    if (hovered) {
        return (
            <Stack direction="row" spacing={2}>
                {items.length > 0 && <ActionsMenu buttonItems={items} />}
                {onResolve && (
                    <IconButton
                        className={classes('comment-resolved-button')}
                        onClick={onResolve}
                        size="small"
                        disableRipple
                    >
                        {resolved ? <CommentResolvedIcon /> : <CommentNotResolvedIcon />}
                    </IconButton>
                )}
            </Stack>
        );
    } else if (resolved && onResolve) {
        return (
            <IconButton
                className={classes('comment-resolved-button')}
                onClick={onResolve}
                size="small"
                disableRipple
            >
                {resolved ? <CommentResolvedIcon /> : <CommentNotResolvedIcon />}
            </IconButton>
        )
    } else {
        return null;
    }
};
