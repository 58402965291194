import { Dispatch, GetState } from 'store';
import request, { emit } from 'utils/request';
import { DependencySaveModel, Story, StorySaveModel } from 'utils/types';
import { reorderDataType } from '.';
import { ACTION_SET_GLOBAL_ERROR } from 'store/globalError';


export const getStory = (url: string) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/${url}`)

    return res.data
}

export const createStory = (url: string, story: StorySaveModel) => async (dispatch: Dispatch, getState: GetState) => {
    try {
        const storeState = getState();
        const res = await request.post(`/workspaces/${storeState.user.workspace.id}/${url}`, story)
        return res.data as Story;

    } catch (error) {
        console.log(error);
        return { error }
    }
}

export const editStory = (url: string, storyId: number, story: StorySaveModel) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    const res = await request.put(`/workspaces/${storeState.user.workspace.id}/${url}/${storyId}`, story)
}

export const deleteStory = (url: string, storyId: number) => async (dispatch: Dispatch, getState: GetState) => {
    try {
        const storeState = getState();
        const res = await request.delete(`/workspaces/${storeState.user.workspace.id}/${url}/${storyId}`)
        return res.data;

    } catch (error) {
        console.log(error);
        return { error }
    }
}

export const reorderStory = (initiativeId: number, storyId: number, reorderData: reorderDataType) => async (dispatch: Dispatch, getState: GetState) => {

    try {
        const storeState = getState();
        const res = await request.put(`/workspaces/${storeState.user.workspace.id}/initiatives/${initiativeId}/stories/${storyId}/rank`, reorderData)
        return res.data as reorderDataType;

    } catch (e: any) {
        if (e.data.error) {
            dispatch({ type: ACTION_SET_GLOBAL_ERROR, payload: e.data.error });
        }
    }
}

export const duplicateStory = (storyId: number) => async (dispatch: Dispatch, getState: GetState) => {
    try {
        const storeState = getState();
        const res = await request.post(`/workspaces/${storeState.user.workspace.id}/stories/${storyId}/duplicate`)
        return res.data as Story;
    } catch (error) {
        console.log(error);
        return { error }
    }
}

export const importJiraData = (baseUrl: string, url: string) => async (dispatch: Dispatch, getState: GetState) => {

    const storeState = getState();
    const res = await request.post(`/workspaces/${storeState.user.workspace.id}/${baseUrl}/import`, {
        type: 'JIRA',
        issueTrackingUrl: url
    })

    return res.data;
}


export const exportToJira = (baseUrl: string, storyId: number, fields: any, withStories = false) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    const res = await request.post(`/workspaces/${storeState.user.workspace.id}/${baseUrl}/export`, {
        type: 'JIRA',
        fields,
        // withStories
    })

    return res.data;
}



export const createDependencyTicket = (storyId: number, dependency: DependencySaveModel) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    try {
        const res = await request.post(`/workspaces/${storeState.user.workspace.id}/stories/${storyId}/dependency-tickets`, dependency)
        return res.data;

    } catch (error) {
        console.log(error);
        return { error }
    }
}
