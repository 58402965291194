import { Flexbox, Loader } from 'components'
import AgGridTable, { AgColumn } from 'components/AgGridTable'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import styles from './initiativeScores.module.scss';
import classNames from 'classnames/bind';
import InitiativeScoresHeader from './components/InitiativeScoresHeader';
import { GridApi } from 'ag-grid-enterprise';
import { GetDetailRowDataParams, GetRowIdFunc, GetRowIdParams, GridReadyEvent, ICellRendererParams, SortChangedEvent } from 'ag-grid-community';
import { useDispatch, useSelector } from 'react-redux';
import { initiativeScoresSelector, updateScoreAction } from 'store/initiativeScores-slice';
import { IInitiativeScore } from 'utils/types';
import { useSearchParams } from 'react-router-dom';
import { useGetInitiativeScoresQuery } from 'store/initiativeScores-api';
import { useWorkspaceId } from 'utils/hooks';

const classes = classNames.bind(styles);

interface InitiativeScoresProps { }

interface IPreferencesData {
    query?: string,
    order?: 'asc' | 'desc',
    orderBy?: keyof IInitiativeScore,
}

const columns: AgColumn[] = [
    {
        headerName: 'Title',
        field: 'title',
        sortable: true,
        enableRowGroup: false,
        enablePivot: false,
        suppressMovable: true,
        resizable: false,
        cellRendererSelector: (params: ICellRendererParams) => {
            const hasGrouping = params.api.getRowGroupColumns().length > 0
            if (hasGrouping) {
                return { component: undefined }
            } else {
                return { component: 'agGroupCellRenderer' }
            }
        },
    },
    {
        headerName: 'Percentage %',
        field: 'percentage',
        sortable: true,
        editable: true,
        cellDataType: 'number',
        enableRowGroup: false,
        enablePivot: false,
        suppressMovable: true,
        resizable: false,
        valueFormatter: (params) => `${params.value}%`
    },
]

const detailCellRendererParams = {
    detailGridOptions: {
        columnDefs: [
            {
                headerName: 'Title',
                field: 'title',
                flex: 1,
                resizable: false,
            },
            {
                headerName: 'Percentage %',
                field: 'percentage',
                cellDataType: 'number',
                flex: 1,
                resizable: false,
                valueFormatter: (params) => `${params.value}%`,
                editable: true,
            },
        ] as AgColumn[]
    },
    getDetailRowData: (params: GetDetailRowDataParams) => params.successCallback([params.data?.childElement])
}

const InitiativeScores: FC<InitiativeScoresProps> = () => {
    const preferencesRef = useRef<IPreferencesData>({});
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();

    const { isLoading } = useGetInitiativeScoresQuery({ workspaceId });

    const initiativeScores = useSelector(initiativeScoresSelector);

    const [searchParams, setSearchParams] = useSearchParams();
    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    const onSortChanged = useCallback((e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });
    }, [preferencesRef, searchParams])

    const updateScore = useCallback(async (scoreData: IInitiativeScore) => {
        dispatch(updateScoreAction(scoreData));

        return true
    }, [])

    const getRowId = useMemo<GetRowIdFunc>(() => {
        return (params: GetRowIdParams) => params.data.id;
    }, []);

    return (
        <>
            {isLoading ? <Flexbox fullWidth align justify><Loader /></Flexbox> :
                <Flexbox vertical fullWidth className={classes('mainContainer')}>
                    <InitiativeScoresHeader gridApi={gridApi} />
                    <Flexbox className={classes('tableContainer')}>
                        <AgGridTable
                            columns={columns}
                            data={initiativeScores}
                            onGridReady={onGridReady}
                            order={preferencesRef.current.order}
                            orderBy={preferencesRef.current.orderBy}
                            onSortChanged={onSortChanged}
                            exportFileName='Initiative Scores'
                            readOnlyEdit={true}
                            updateRowCallback={updateScore}
                            pivotPanelShow={'never'}
                            rowGroupPanelShow='never'
                            sideBar={false}
                            autoSizeStrategy={{ type: 'fitGridWidth' }}
                            masterDetail
                            isRowMaster={(params) => params.id === 'aiTotal'}
                            detailCellRendererParams={detailCellRendererParams}
                            getRowId={getRowId}
                        />
                    </Flexbox>
                </Flexbox>
            }
        </>
    )
}

export default InitiativeScores;