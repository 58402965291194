import { Flexbox, RichTextEditor, TabPanel } from 'components'
import { InitiativeReportTable, OkrReportTable } from './reportTables'
import classNames from 'classnames/bind';
import styles from '../report.module.scss'
import { FC } from 'react'
import { Report, ReportData, ReportOKR, ReportSaveModel, ReportVariantTypes } from 'utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { currentReportSelector, updateCurrentReport } from 'store/report-slice';
import { useUpdateReportMutation } from 'store/report-api';
import { useWorkspaceId } from 'utils/hooks';
const classes = classNames.bind(styles)

interface ReportMainContentProps {
    report: ReportData,
    okrs: ReportOKR[],
    isActive: boolean,
}

const ReportMainContent: FC<ReportMainContentProps> = ({ isActive, report, okrs }) => {
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();

    const currentReport = useSelector(currentReportSelector) as Report;

    const [updateReport] = useUpdateReportMutation();

    const handleNotesChange = (newValue: string) => {
        dispatch(updateCurrentReport({
            data: {
                [`${currentReport.type}s`]: currentReport.data[`${currentReport.type}s`].map(el => {
                    if(el.id === report.id) {
                        return {
                            ...el,
                            note: newValue
                        }
                    } else {
                        return el
                    }
                })
            }
        }))
    }

    const handleNotesBlur = () => {
        let data: ReportSaveModel = { note: report.note }

        if(currentReport.type === ReportVariantTypes['By Team']){
            data.teamId = report.id
        }

        if(currentReport.type === ReportVariantTypes['By Okr']){
            data.okrId = report.id
        }

        updateReport({ workspaceId, reportTemplateId: currentReport.templateId, reportId: currentReport.id, data })
    }

    return (
        <TabPanel active={isActive}>
            <Flexbox vertical fullWidth className={classes('reportTabPanelBox')}>
                <Flexbox className={classes('reportSection')}>
                    <RichTextEditor
                        value={report.note}
                        onChange={handleNotesChange}
                        onBlur={handleNotesBlur}
                        label='Notes'
                    />
                </Flexbox>

                <OkrReportTable
                    okrs={okrs}
                    reportId={currentReport.id}
                    templateId={currentReport.templateId}
                    teamId={report.id}
                    reportType={currentReport.type}
                />

                <InitiativeReportTable initiatives={report.initiatives || []} />
            </Flexbox>
        </TabPanel>
    )
}

export default ReportMainContent;