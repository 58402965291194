import { ConfirmationDialog, Flexbox } from 'components';
import * as go from 'gojs';
import { ReactDiagram, ReactOverview } from 'gojs-react';
import { useEffect, useRef, useState } from 'react';
import { OKR, User } from 'utils/types';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { useDispatch } from 'react-redux';
import { defaultTitle } from 'pages/Okrs/Okr';
import { createOkr, deleteOkr, editOkr } from 'pages/Okrs/okrs.api';
import { useNavigate } from 'react-router-dom';
import { Actions, hasPermission } from 'utils/permissions';
const classes = classNames.bind(styles);
import dotsSvg from 'assets/common/dots.svg'

interface OkrTreeViewProps{
    data: OKR[],
    searchValue?: string;
    user: User;
}

interface OkrTreeViewData extends Omit <OKR, 'startDate' | 'keyResults' | 'id' | 'initiatives' | 'progress' | 'endDate'> {
    name?: string;
    parent?: number;
    key: number;
    endDate: string
}

const OkrTreeView = ({ data, searchValue, user } : OkrTreeViewProps) => {
    const diagramRef = useRef<ReactDiagram>(null)

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [nodeDataArray, setNodeDataArray] = useState<OkrTreeViewData[]>([])
    const [openConfirmationOkr, setOpenConfirmationOkr] = useState(false);
    const [okrDeleteId, setOkrDeleteId] = useState<number | undefined>();

    useEffect(() => {

        diagramRef.current?.clear();

        const okrs = [...data]

        const okrsData = okrs.map((okr) => {
            return({
                key: okr.id,
                name: okr.owner?.fullName,
                allKrProgress: okr.allKrProgress && Math.round(okr.allKrProgress),
                objective: okr.objective,
                startDate: okr.startDate ? new Date(okr.startDate)?.toLocaleDateString() : '',
                endDate: okr.endDate ? new Date(okr.endDate)?.toLocaleDateString() : '',
                parent: okr.parentOkr?.id,
            })
        })

        setNodeDataArray(okrsData)

    }, [data])

    useEffect(() => {
        const diagram = diagramRef.current?.getDiagram();
        if (!diagram) {return;}
        // diagram.focus();

        if (diagram instanceof go.Diagram) {
            diagram.startTransaction('highlight search');

            if (searchValue) {
            // search four different data properties for the string, any of which may match for success
            // create a case insensitive RegExp from what the user typed
                let safe = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                let regex = new RegExp(safe, 'i');
                let results = diagram.findNodesByExample(
                    { objective: regex },
                    { name: regex }
                );
                diagram.highlightCollection(results);
                // try to center the diagram at the first node that was found
                const first = results.first();
                if (first) {
                    diagram.centerRect(first.actualBounds);
                }
            } else {  // empty string only clears highlighteds collection
                diagram.clearHighlighteds();
            }
            diagram.commitTransaction('highlight search');
        }
    }, [searchValue])

    const onCancelDeleteOkr = () => {
        setOpenConfirmationOkr(false)
    }

    const onDeleteConfirmationOkr = async () => {
        if(okrDeleteId){
            await dispatch(deleteOkr(okrDeleteId));
            onCancelDeleteOkr();
        }
    }

    const changeOkrParent = async (okrId: number, newParentId: number) => {
        const okr = data.find(okr => okr.id === okrId);
        if(okr){
            await dispatch(editOkr(okrId, {
                owner: okr.owner?.id,
                endDate: okr?.endDate,
                startDate: okr?.startDate,
                objective: okr?.objective,
                parentOkr: newParentId,
                teams: okr.teams?.map(t => t.id)
            }))
        }
    }

    const $ = go.GraphObject.make;  // for conciseness in defining templates

    function initDiagram() {
        // Since 2.2 you can also author concise templates with method chaining instead of GraphObject.make
        // For details, see https://gojs.net/latest/intro/buildingObjects.html


        const myDiagram =
        $(go.Diagram,
            {
                allowCopy: false,
                allowDelete: false,
                isModelReadOnly: false,
                initialDocumentSpot: go.Spot.Top,
                initialViewportSpot: go.Spot.Top,
                // initialAutoScale: go.Diagram.Uniform,
                minScale: 0.5,
                maxScale: 2,
                allowMove: true,
                maxSelectionCount: 1, // users can select only one part at a time
                validCycle: go.Diagram.CycleDestinationTree, // make sure users can only create trees
                // 'clickCreatingTool.archetypeNodeData': { // allow double-click in background to create a new node
                //     name: '(new person)',
                //     title: '',
                //     comments: ''
                // },
                'clickCreatingTool.insertPart': function (loc: any) {  // override to scroll to the new node
                    const node = go.ClickCreatingTool.prototype.insertPart.call(this, loc);
                    if (node !== null) {
                        myDiagram.select(node);
                        myDiagram.commandHandler.scrollToPart(node);
                        myDiagram.commandHandler.editTextBlock(node.findObject('NAMETB') as go.TextBlock);
                    }
                    return node;
                },
                layout:
          $(go.TreeLayout,
              {
                  treeStyle: go.TreeLayout.StyleLastParents,
                  arrangement: go.TreeLayout.ArrangementHorizontal,
                  // properties for most of the tree:
                  angle: 90,
                  layerSpacing: 35,
                  // properties for the "last parents":
                  alternateAngle: 90,
                  alternateLayerSpacing: 35,
                  alternateAlignment: go.TreeLayout.AlignmentCenterChildren,
                  alternateNodeSpacing: 20,
                  alternateCompaction: go.TreeLayout.CompactionNone
              }),
                'undoManager.isEnabled': true // enable undo & redo
            });

        // // when the document is modified, add a "*" to the title and enable the "Save" button
        // myDiagram.addDiagramListener('Modified', e => {
        //     const button = document.getElementById('SaveButton');
        //     if (button) {button.disabled = !myDiagram.isModified;}
        //     const idx = document.title.indexOf('*');
        //     if (myDiagram.isModified) {
        //         if (idx < 0) {document.title += '*';}
        //     } else {
        //         if (idx >= 0) {document.title = document.title.slice(0, idx);}
        //     }
        // });

        // const levelColors = ['#AC193D', '#2672EC', '#8C0095', '#5133AB',
        //     '#008299', '#D24726', '#008A00', '#094AB2'];



        // this is used to determine feedback during drags
        function mayWorkFor(node1: any, node2: any) {
            if (!(node1 instanceof go.Node)) {return false;}  // must be a Node
            if (node1 === node2) {return false;}  // cannot work for yourself
            if (node2.isInTreeOf(node1)) {return false;}  // cannot work for someone who works for you
            return true;
        }

        // This function provides a common style for most of the TextBlocks.
        // Some of these values may be overridden in a particular TextBlock.
        function textStyle() {
            return { stroke: 'black', };
        }
        // zoom on scroll
        // myDiagram.toolManager.mouseWheelBehavior = go.ToolManager.WheelZoom;

        async function addOkr(node: go.Part | null) {

            if (!node) {return;}
            const okrData = node.data;
            myDiagram.startTransaction('add okr');

            const okr: OKR = (await dispatch(createOkr({
                owner: user.id,
                objective: defaultTitle,
                startDate: new Date(okrData.startDate).getTime(),
                endDate: new Date(okrData.endDate).getTime(),
                parentOkr: okrData.key,
            }))) as unknown as OKR

            const endDate = okr.endDate ? new Date(okr.endDate) : null;

            const newOkr = {
                name: okr.owner?.fullName,
                objective: okr.objective,
                parent: okr.parentOkr?.id,
                endDate: endDate?.toLocaleDateString(),
                allKrProgress: okr.allKrProgress ? Math.round(okr.allKrProgress) : 0,
                key: okr.id
            };

            myDiagram.model.addNodeData(newOkr);

            const newNode: go.Node | null = myDiagram.findNodeForData(newOkr);
            if (newNode) {
                newNode.location = node.location;
            }
            myDiagram.commitTransaction('add okr');
            if(newNode){
                myDiagram.commandHandler.scrollToPart(newNode);
            }
        }

        function showDeleteOkrDialog(node: go.Part | null) {
            if (!node) {return;}
            const okrId = node.data.key
            setOkrDeleteId(okrId)

            setOpenConfirmationOkr(true)
        }

        // define the Node template
        myDiagram.nodeTemplate =
        $(go.Node, 'Spot',
            {
                selectionObjectName: 'BODY',
                // @ts-ignore
                mouseEnter: (e, node) => node.findObject('BUTTON').opacity = 1,
                // @ts-ignore
                mouseLeave: (e, node) => node.findObject('BUTTON').opacity = 0,

                doubleClick: (e, node) => {
                    if(myDiagram.commandHandler.canCollapseTree(node as go.Node)) {
                        myDiagram.commandHandler.collapseTree(node as go.Node)
                    } else if(myDiagram.commandHandler.canExpandTree(node as go.Node)) {
                        myDiagram.commandHandler.expandTree(node as go.Node)
                    }
                },

                // handle dragging a Node onto a Node to (maybe) change the reporting relationship
                mouseDragEnter: (e, node, prev) => {
                    const diagram = node.diagram;
                    // @ts-ignore
                    const selnode = diagram.selection.first();
                    if (!mayWorkFor(selnode, node)) {return;}
                    // @ts-ignore
                    const shape = node.findObject('SHAPE');
                    if (shape) {
                        shape._prevFill = shape.fill;  // remember the original brush
                        shape.fill = '#E6EFEF';
                    }
                },
                mouseDragLeave: (e, node, next) => {
                    // @ts-ignore
                    const shape = node.findObject('SHAPE');
                    if (shape && shape._prevFill) {
                        shape.fill = shape._prevFill;  // restore the original brush
                    }
                },
                mouseDrop: (e, node) => {

                    const diagram = node.diagram;
                    // @ts-ignore
                    const selnode = diagram.selection.first();  // assume just one Node in selection
                    if (mayWorkFor(selnode, node)) {
                        // @ts-ignore
                        changeOkrParent(selnode.data.key, node.data.key)
                        // find any existing link into the selected node
                        // @ts-ignore
                        const link = selnode.findTreeParentLink();
                        if (link !== null) {  // reconnect any existing link
                            link.fromNode = node;
                        } else {  // else create a new link
                            // @ts-ignore
                            diagram.toolManager.linkingTool.insertLink(node, node.port, selnode, selnode.port);
                        }
                    }
                },
            },
            {
                selectionAdornmentTemplate:
                  $(go.Adornment, 'Auto',)
            },
            // for sorting, have the Node.text be the data.name
            new go.Binding('text', 'name'),
            // bind the Part.layerName to control the Node's layer depending on whether it isSelected
            new go.Binding('layerName', 'isSelected', sel => sel ? 'Foreground' : '').ofObject(),
            $(go.Panel, 'Auto',
                { name: 'BODY' },
                // define the node's outer shape
                $(go.Shape, 'RoundedRectangle',
                    { name: 'SHAPE', stroke: '#C4C4C4', strokeWidth: 1, portId: '', width: 240, height: 108 },
                    // lightGreen if highlighted, white otherwise
                    new go.Binding('fill', 'isHighlighted', h => h ? '#FFEA8D' : '#ffffff').ofObject()
                ),
                $(go.Panel, 'Vertical', { alignment: go.Spot.TopLeft, padding: new go.Margin(4) },
                    $(go.Panel, 'Horizontal', { alignment: go.Spot.TopLeft },
                        $(go.TextBlock, textStyle(),  // the name
                            {
                                name: 'OBJECTIVE',
                                width: 195,
                                height: 40,
                                maxSize: new go.Size(200, 40),
                                margin: new go.Margin(4),
                                font: '600 14px Quicksand',
                                spacingBelow: 4,
                                stroke: '#003138',
                                isMultiline: true,
                                maxLines: 2,
                                overflow: go.TextBlock.OverflowEllipsis,
                                alignment: go.Spot.TopLeft,
                                cursor: 'pointer',
                                toolTip:  // define a tooltip for each node that displays the color as text
                            $('ToolTip',
                                $(go.TextBlock, { margin: 4, width: 160, alignment: go.Spot.Center },
                                    new go.Binding('text', 'objective'))
                            ),  // end of Adornment
                                // spacingBelow: 4,
                                click: function (_e: go.InputEvent, obj: go.GraphObject) {
                                    if(obj.part){
                                        navigate(`/okrs/okr/${obj.part.data.key}`)
                                    }
                                },
                                mouseEnter: function (_e: go.InputEvent, obj: go.GraphObject) {
                                    if(obj.part){
                                        const objective = obj.part.findObject('OBJECTIVE') as go.TextBlock;
                                        if (objective){
                                            objective.isUnderline = true
                                        }
                                    }
                                },
                                mouseLeave: function (_e: go.InputEvent, obj: go.GraphObject){
                                    if(obj.part){
                                        const objective = obj.part.findObject('OBJECTIVE') as go.TextBlock;
                                        if (objective){
                                            objective.isUnderline = false
                                        }
                                    }
                                }
                            },
                            new go.Binding('text', 'objective')
                        ),

                        hasPermission(Actions.delete) ? $(go.Picture,
                            {
                                cursor: 'pointer',
                                source: dotsSvg,
                                alignment: go.Spot.TopRight,
                                contextMenu: $(
                                    'ContextMenu', 'Spot',
                                    $(go.Placeholder, { padding: 5, }),
                                    $('ContextMenuButton',{ 'ButtonBorder.fill': '#FFF','_buttonFillOver': '#F9F9F9',
                                        'ButtonBorder.strokeWidth': 1,
                                        'ButtonBorder.stroke': '#EBEBEB',
                                        '_buttonStrokeOver': '#EBEBEB',
                                        'ButtonBorder.figure': 'RoundedRectangle',
                                        width: 140,
                                    },
                                    $(go.TextBlock, 'Delete', {
                                        stroke: '#ED4337',
                                        margin: new go.Margin(6, 4),
                                        alignment: go.Spot.Left,
                                    }),
                                    {
                                        click: (_e, node) => showDeleteOkrDialog(node.part),
                                        alignment: go.Spot.BottomLeft, alignmentFocus: go.Spot.Top,
                                    },),
                                ),
                            },
                            {
                                click: (e:  go.InputEvent, node: go.GraphObject) => e.diagram.commandHandler.showContextMenu(node),
                            },
                        ) : '',
                    ),

                    $(go.TextBlock, textStyle(),
                        {
                            width: 200,
                            margin: new go.Margin(4),
                            maxSize: new go.Size(200, 20),
                            font: '14px Quicksand',
                            stroke: '#191919',
                            isMultiline: false,
                            overflow: go.TextBlock.OverflowEllipsis,
                            alignment: go.Spot.TopLeft,
                            // spacingBelow: 4,
                        },
                        new go.Binding('text', 'endDate')),
                    $(go.Shape, 'MinusLine',
                        {
                            strokeDashArray: [2],
                            margin: new go.Margin(1),
                            stroke: '#025B62',
                            width: 220,
                            height: 1,
                        }),

                    $(go.Panel, 'Horizontal', {
                        alignment: go.Spot.TopLeft,
                        width: 220,
                    },

                    $(go.TextBlock, textStyle(),
                        {
                            width: 115,
                            height: 18,
                            margin: new go.Margin(4),
                            font: '10px Quicksand',
                            isMultiline: false,
                            overflow: go.TextBlock.OverflowEllipsis,
                            verticalAlignment: go.Spot.Center,
                            toolTip:  // define a tooltip for each node that displays the color as text
                            $('ToolTip',
                                $(go.TextBlock, { margin: 4 },
                                    new go.Binding('text', 'name'))
                            )  // end of Adornment
                        },
                        new go.Binding('text', 'name')),
                    $(go.Panel, 'Horizontal', {
                        width: 60,
                        background: '#F5F5F5'
                    },
                    $(go.Shape, 'RoundedRectangle', {
                        fill: '#025B62',
                        height: 10,
                        strokeWidth: 0,
                    },
                    new go.Binding('width', 'allKrProgress', (allKrProgress: number) =>  Math.floor(allKrProgress * 60 / 100)))
                    ),
                    $(go.TextBlock, textStyle(),
                        {
                            width: 47,
                            margin: new go.Margin(4),
                            maxSize: new go.Size(47, 18),
                            font: '14px Quicksand',
                            isMultiline: false,
                            overflow: go.TextBlock.OverflowEllipsis,
                            verticalAlignment: go.Spot.Center,
                            toolTip:  // define a tooltip for each node that displays the color as text
                            $('ToolTip',
                                $(go.TextBlock, { margin: 4, },
                                    new go.Binding('text', 'allKrProgress', (allKrProgress: number) =>  allKrProgress + '%'))
                            )  // end of Adornment
                            // spacingBelow: 4,
                        },
                        new go.Binding('text', 'allKrProgress', (allKrProgress: number) =>  allKrProgress + '%')),

                    ),
                ) // end of Vertical panel

            ), // end Auto Panel

            hasPermission(Actions.create) ? $('Button',
                $(go.Shape, 'PlusLine', { width: 10, height: 10, stroke: '#025B62', strokeWidth: 2  }),
                {
                    name: 'BUTTON', alignment: go.Spot.Bottom, opacity: 0,   // initially not visible
                    click: (e, button) => addOkr(button.part)
                },
                // button is visible either when node is selected or on mouse-over
                // new go.Binding('opacity', 'isSelected', s => s ? 1 : 0).ofObject()
            ) : '',

            // new go.Binding('isTreeExpanded').makeTwoWay(),
            // $('TreeExpanderButton',
            //     {
            //         name: 'BUTTONX', alignment: go.Spot.Right, opacity: 0,  // initially not visible
            //         '_treeExpandedFigure': 'TriangleUp',
            //         '_treeCollapsedFigure': 'TriangleDown'
            //     },
            //     // button is visible either when node is selected or on mouse-over
            //     new go.Binding('opacity', 'isSelected', s => s ? 1 : 0).ofObject()
            // )
        );  // end Node, a Spot Panel

        myDiagram.linkTemplate =
    $(go.Link, go.Link.Orthogonal,
        { layerName: 'Background', corner: 6 },
        $(go.Shape, { strokeWidth: 1, stroke: '#C4C4C4' }));  // the link shape

        myDiagram.model = go.Model.fromJson(`{ "class": "go.TreeModel",
    "nodeDataArray": ${JSON.stringify(nodeDataArray)}
  }`);
        // make sure new data keys are unique positive integers
        let lastkey = 1;
        myDiagram.model.makeUniqueKeyFunction = (model, data) => {
            let k = data.key || lastkey;
            while (model.findNodeDataForKey(k)) {k++;}
            data.key = lastkey = k;
            return k;
        };

        return myDiagram;
    }

    const initOverview = () => {
        const overview: any = $(go.Overview, { contentAlignment: go.Spot.Center, });

        overview.box.elt(0).stroke = '#E4B12E';

        return overview;
    };

    return (
        <Flexbox vertical className={classes('wrapper')}>
            <ReactDiagram
                divClassName={classes('diagram')}
                initDiagram={initDiagram}
                nodeDataArray={nodeDataArray}
                ref={diagramRef}
            />
            <ReactOverview
                initOverview={initOverview}
                divClassName={classes('overview')}
                observedDiagram={diagramRef.current?.getDiagram() || null}
            />

            <ConfirmationDialog
                open={openConfirmationOkr}
                onClose={onCancelDeleteOkr}
                onConfirm={onDeleteConfirmationOkr}
                confirmButtonStyle='danger'
                title='Delete this OKR?'
            >
                <Flexbox>
                    You're about to permanently delete this OKR, and all own KRs will be deleted too.
                </Flexbox>
            </ConfirmationDialog>

        </Flexbox>
    )
}

export default OkrTreeView


