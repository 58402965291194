export const SendIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
        >
            <path
                d="M0.199219 13.1999V0.399902L15.3992 6.7999L0.199219 13.1999ZM1.39922 11.3399L12.2792 6.7999L1.39922 2.1999V5.5599L6.23922 6.7999L1.39922 7.9999V11.3399ZM1.39922 11.3399V2.1999V7.9999V11.3399Z"
                fill="#5A5A5A"
            />
        </svg>
    );
};
