import { Dispatch, GetState } from 'store';
import { ACTION_DELETE_DEPENDENCY, ACTION_GET_DEPENDENCIES, ACTION_SET_DEPENDENCIES } from 'store/dependencies';
import request from 'utils/request';

export const getDependencies = () => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    dispatch({ type: ACTION_GET_DEPENDENCIES, payload: null })

    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/dependency-tickets`);

    dispatch({ type: ACTION_SET_DEPENDENCIES, payload: res.data })

}

export const deleteDependency = (dependencyId: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()

    await request.delete(`/workspaces/${storeState.user.workspace.id}/dependency-tickets/${dependencyId}`,);

    dispatch({ type: ACTION_DELETE_DEPENDENCY, payload: dependencyId });
}
