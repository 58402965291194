import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { Button } from 'components';
import Flexbox from 'components/Flexbox';
const classes = classNames.bind(styles);


interface AddButtonProps {
    onClick: () => void;
    active?: boolean;
    disabled?: boolean
}

const AddButton = ({ onClick, active, disabled }: AddButtonProps) => {
    return (
        <Flexbox align className={classes('addButtonContainer')}>
            <Button
                className={classes('addButton', { active })}
                size="small"
                disableRipple
                onClick={onClick}
                disabled={disabled}
            >
                ADD
            </Button>
        </Flexbox>
    )
}

export default AddButton