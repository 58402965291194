import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const reportApi = createApi({
    reducerPath: 'report.api',
    baseQuery: rtkBaseQueryWithReauth,
    tagTypes: ['report'],
    endpoints: builder => ({
        getReport: builder.query({
            query: ({ workspaceId, reportId }) => `/workspaces/${workspaceId}/reports/templates/${reportId}/revisions`,
        }),
        generateReport: builder.mutation({
            query: ({ workspaceId, reportId, data }) => {
                return {
                    url: `/workspaces/${workspaceId}/reports/templates/${reportId}/revisions`,
                    method: 'POST',
                    body: { notes: data }
                };
            },
        }),
        updateReport: builder.mutation({
            query: ({ workspaceId, reportId, reportTemplateId, data }) => {
                return {
                    url: `/workspaces/${workspaceId}/reports/templates/${reportTemplateId}/revisions/${reportId}`,
                    method: 'PUT',
                    body: data
                };
            },
        }),
    })
});

export const {
    useGenerateReportMutation,
    useGetReportQuery,
    useLazyGetReportQuery,
    useUpdateReportMutation,
} = reportApi