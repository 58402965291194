import { TabPanel } from 'components'
import { memo } from 'react'
import { Block } from 'utils/types';
import ResourcesBlock from 'common/resourcesBlock';
import classNames from 'classnames/bind';
import styles from '../../initiative.module.scss';
const classes = classNames.bind(styles);


interface ResourcesTabProps {
    active: boolean,
    initiativeId?: number;
    blocks: Block[],
    isEditable: boolean;
}

const ResourcesTab = ({ active, initiativeId, blocks, isEditable = true } : ResourcesTabProps) => {
    const baseUrl = `/initiatives/${initiativeId}/blocks/`

    return(
        <TabPanel active={active}>
            <ResourcesBlock blocks={blocks} baseUrl={baseUrl} isEditable={isEditable} />
        </TabPanel>
    )
}

export default memo(ResourcesTab)