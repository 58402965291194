import { CustomTyphography, Flexbox } from 'components'
import { FC } from 'react'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { LinearProgress } from '@mui/material';

const classes = classNames.bind(styles);

interface ProgressBarProps {
    value: number
}

const progressBarConfig = [
    {
        interval: { min: 0, max: 40 },
        className: 'accentError',
    },
    {
        interval: { min: 41, max: 80 },
        className: 'accentCaution',
    },
    {
        interval: { min: 81, max: 100 },
        className: 'accentSuccess',
    }
]

const ProgressBar: FC<ProgressBarProps> = ({ value }) => {
    const progressBarClassName = progressBarConfig.find(el => (value >= el.interval.min && value <= el.interval.max))?.className

    return (
        <Flexbox align className={classes('progressBar')}>
            <Flexbox className={classes('progressBar-linearProgress')}>
                <LinearProgress
                    value={+value.toFixed(2)}
                    variant="determinate"
                    classes={{
                        bar: `${progressBarClassName}-bgColor`
                    }}
                />
            </Flexbox>
            <CustomTyphography>{+value.toFixed(2)}%</CustomTyphography>
        </Flexbox>
    )
}

export default ProgressBar