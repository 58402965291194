import { OKR } from 'utils/types';
import { RootState } from './index';

interface OKRsReducerState {
    loading: boolean;
    okrs: OKR[];
}

const initialState: OKRsReducerState = {
    loading: false,
    okrs: [],
}

export const ACTION_GET_OKRS = 'getOkrs';
export const ACTION_SET_OKRS = 'setOkrs';
export const ACTION_ADD_OKR = 'addOkr';
export const ACTION_DELETE_OKR = 'deleteOkr';

const okrs = (state: OKRsReducerState = initialState, action: {type: string, payload: any}): OKRsReducerState => {
    switch (action.type) {
    case ACTION_GET_OKRS:
        return { ...state, loading: true }
    case ACTION_SET_OKRS:
        return { ...state, loading: false, okrs: [...action.payload] }
    case ACTION_ADD_OKR:
        return { ...state, okrs: [...state.okrs, action.payload] }
    case ACTION_DELETE_OKR:
        return { ...state, okrs: state.okrs.filter(okr => okr.id !== action.payload) }

    default:
        return state
    }
}
export default okrs;

export const okrsSelector = (store: RootState) => store.okrs.okrs;
export const okrsLoadingSelector = (store: RootState) => store.okrs.loading;