import { createDependencyTicket } from 'common/Story/index.api';
import { DatePicker, Dialog, Flexbox, RichTextEditor, Select } from 'components'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { teamsSelector } from 'store/teams-slice';
import { Dependency, DependencyStatuses, Story, Team, UserStatuses } from 'utils/types';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { activeUsersSelector } from 'store/users-slice';
const classes = classNames.bind(styles);


interface DependencyDialogProps{
    currentStory?: Story;
    onCreate?: (dependency: Dependency) => void,
    isOpen: boolean;
    onClose: () => void;
    initiativeTitle?: string;
    ownerId?: number
}


const DependencyDialog = ({ currentStory, isOpen, onClose, initiativeTitle, ownerId, onCreate }: DependencyDialogProps) => {

    const dispatch = useDispatch();
    const teams = useSelector(teamsSelector);
    const users = useSelector(activeUsersSelector);

    const [description, setDescription] = useState('');
    const [selectedTeamId, setSelectedTeamId] = useState<number | undefined>();
    const [dueDate, setDueDate] = useState<Date | null>(null);

    const ownerUser = users.find(user => user.id === ownerId);

    useEffect(() => {
        setDescription('')
        setSelectedTeamId(undefined)
        setDueDate(null)
    }, [isOpen])

    const closeDependencyDialog = () => {
        onClose()
    }

    const onDescriptionChange = (value: string) => {
        setDescription(value)
    }

    const onTeamChange = (_e: ChangeEvent<{}>, value: Team | null) => {
        if(value){
            setSelectedTeamId(value.id)
        }
    }

    const onDueDateChange = (date: Date | null) => {
        setDueDate(date);
    };

    const onCreateDependency = async () => {
        if(currentStory?.id && selectedTeamId && dueDate){
            const newDependencyTicket = (await dispatch(createDependencyTicket(currentStory.id,
                {
                    dueDate: dueDate.getTime(),
                    description: description,
                    teamId: selectedTeamId,
                    status: DependencyStatuses.Open
                }
            ))) as unknown as Dependency

            if(onCreate){
                onCreate(newDependencyTicket)
            }
        }
    }

    return(
        <Dialog
            title='Open Dependency ticket'
            onClose={closeDependencyDialog}
            open={isOpen}
            confirmButton
            confirmButtonLabel='Submit'
            onConfirm={onCreateDependency}
            cancelButton
        >
            <Flexbox vertical className={classes('container')}>
                <Flexbox className={classes('fieldLabel')}>
                    Owner
                </Flexbox>
                <Flexbox className={classes('fieldTitleSmall')}>
                    <Flexbox className={classes('fieldTitle')}>
                        {ownerUser?.fullName}
                    </Flexbox>
                </Flexbox>
                <Flexbox className={classes('fieldLabel')}>
                    Initiative
                </Flexbox>
                <Flexbox className={classes('fieldTitle')}>
                    {initiativeTitle}
                </Flexbox>
                <Flexbox className={classes('fieldLabel')}>
                    Main Requirement
                </Flexbox>
                <Flexbox className={classes('fieldTitle', 'notEditableTitle')}>
                    {currentStory?.title}
                </Flexbox>
                <Flexbox className={classes('fieldTitle', 'notEditableDescription')}>
                    <Flexbox vertical
                        dangerouslySetInnerHTML={{ __html: currentStory?.description || '' }}
                    />
                </Flexbox>
                <Flexbox className={classes('textarea')}>
                    <RichTextEditor
                        value={description}
                        onChange={onDescriptionChange}
                        label='Description'
                        placeholder='Description'
                        files={[]}
                    />
                </Flexbox>
                <Flexbox className={classes('twoColBox')}>
                    <Select
                        label='Team/lead'
                        placeholder='Team/lead'
                        onChange={onTeamChange}
                        options={teams.filter(team => users.find(user => user.id === team.lead?.id)?.status === UserStatuses.ACTIVE)}
                        getOptionLabel={option => option.name + ' / ' + (option.lead ? option.lead?.fullName : '-')}
                        required
                    />
                    <DatePicker
                        label='Due date'
                        placeholderText='Due date'
                        onChange={onDueDateChange}
                        selected={dueDate}
                        isClearable
                        fullWidth
                        required
                    />
                </Flexbox>
            </Flexbox>
        </Dialog>
    )
}

export default DependencyDialog