import React, { ReactNode } from 'react';
import { Dialog } from 'components';


export interface ConfirmationDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    confirmButtonLabel?: string;
    confirmButtonStyle?: 'normal' | 'danger';
    title: string;
    children?: ReactNode;
    confirmButtonLoading?: boolean
}
const DeleteConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    onClose,
    onConfirm,
    title,
    confirmButtonLabel = 'Delete',
    confirmButtonStyle,
    confirmButtonLoading,
    ...props
}: ConfirmationDialogProps) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            cancelButton
            title={title}
            confirmButton
            confirmButtonLabel={confirmButtonLabel}
            confirmButtonStyle={confirmButtonStyle}
            onConfirm={onConfirm}
            size='small'
            confirmButtonLoading={confirmButtonLoading}
            {...props}
        />
    )
}

export default DeleteConfirmationDialog