import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './link.module.scss';
const classes = classNames.bind(styles);


const CustomLink = ({ className, ...props }: LinkProps) => {

    return (
        <Link className={classes('link', className)} {...props}/>
    )
}

export default CustomLink;