import Linkify from 'react-linkify';
import { Link } from 'components';
import { FC } from 'react';

interface TextWithLinksProps {
    text: string
}

const TextWithLinks: FC<TextWithLinksProps> = ({ text }) => {
    const componentDecorator = (href: string, text: string, key: number) => {
        return (
            <Link to={href} key={key} target="_blank">
                {text}
            </Link>
        )
    };

    return (
        <div>
            <Linkify componentDecorator={componentDecorator}>
                {text}
            </Linkify>
        </div>
    );
};

export default TextWithLinks