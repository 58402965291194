const SlackIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 48 48"
        >
            <path
                fill="#33d375"
                d="M33 8a4 4 0 00-8 0v11a4 4 0 008 0V8zM43 19a4 4 0 01-4 4h-4v-4a4 4 0 018 0z"
            ></path>
            <path
                fill="#40c4ff"
                d="M8 14a4 4 0 000 8h11a4 4 0 000-8H8zM19 4a4 4 0 014 4v4h-4a4 4 0 010-8z"
            ></path>
            <path
                fill="#e91e63"
                d="M14 39.006C14 41.212 15.791 43 18 43s4-1.788 4-3.994V28.022c0-2.206-1.791-3.994-4-3.994s-4 1.788-4 3.994v10.984zM4 28.022a3.997 3.997 0 014-3.994h4v3.994c0 2.206-1.791 3.994-4 3.994s-4-1.788-4-3.994z"
            ></path>
            <path
                fill="#ffc107"
                d="M39 33a4 4 0 000-8H28a4 4 0 000 8h11zM28 43a4 4 0 01-4-4v-4h4a4 4 0 010 8z"
            ></path>
        </svg>
    )
}

export default SlackIcon;