import { Dependency } from 'utils/types';
import { RootState } from './index';


interface DependenciesReducerState {
    loading: boolean;
    dependencies: Dependency[];
}

const initialState: DependenciesReducerState = {
    loading: false,
    dependencies: [],
}

export const ACTION_GET_DEPENDENCIES = 'getDependencies';
export const ACTION_SET_DEPENDENCIES = 'setDependencies';
export const ACTION_DELETE_DEPENDENCY = 'deleteDependency';

const dependencies = (state: DependenciesReducerState = initialState, action: {type: string, payload: any}): DependenciesReducerState => {
    switch (action.type) {
    case ACTION_GET_DEPENDENCIES:
        return { ...state, dependencies: [], loading: true }
    case ACTION_SET_DEPENDENCIES:
        return { ...state, loading: false, dependencies: [...action.payload] }
    case ACTION_DELETE_DEPENDENCY:
        return { ...state, dependencies: state.dependencies.filter(dependency => dependency.id !== action.payload) }

    default:
        return state
    }
}
export default dependencies;

export const  dependenciesSelector = (store: RootState) => store.dependencies.dependencies;
export const dependenciesLoadingSelector = (store: RootState) => store.dependencies.loading;