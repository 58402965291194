import classNames from 'classnames/bind';
import styles from '../report.module.scss'
import { ChangeEvent, FC } from 'react'
import { Flexbox, Tab, Tabs } from 'components';
import { useSelector } from 'react-redux';
import { currentReportDataSelector, currentReportTypeSelector } from 'store/report-slice';
import { ReportData, ReportVariantTypes } from 'utils/types';
const classes = classNames.bind(styles)

interface ReportTabsProps {
    selectedTab: number;
    onTabChange: (_event: ChangeEvent<{}>, value: number) => void
}

const ReportTabs: FC<ReportTabsProps> = ({ selectedTab, onTabChange }) => {
    const currentReportData = useSelector(currentReportDataSelector) as {
        teams: ReportData[],
        okrs: ReportData[],
    };
    const type = useSelector(currentReportTypeSelector) as ReportVariantTypes;

    return (
        <Tabs
            value={selectedTab !== -1 ? selectedTab : false}
            onChange={onTabChange}
            className={classes('reportTabs')}
            variant='scrollable'
        >
            <Tab className={classes('summaryTab')} label='Summary' id='0' />
            {currentReportData[`${type}s`].map((d, index) => {
                return (
                    <Tab
                        key={index}
                        id={`${index + 1}`}
                        label={
                            <Flexbox className={classes('reportTab')}>
                                {d.keyName}
                            </Flexbox>
                        }
                        title={d.keyName}
                    />
                )
            })}
        </Tabs>
    )
}

export default ReportTabs;