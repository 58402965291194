const PriorityHightIcon = () => {
    return (
        <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7671 8.62809C17.409 9.06357 16.7664 9.12558 16.3319 8.7666L7.67812 1.61661L8.97514 0.039587L17.6289 7.18957C18.0634 7.54856 18.1253 8.1926 17.7671 8.62809Z" fill="#FF5050"/>
            <path d="M0.238552 8.62557C-0.12339 8.19324 -0.0671253 7.54868 0.36422 7.18591L8.90847 0L10.2192 1.56562L1.67493 8.75153C1.24358 9.1143 0.600494 9.05791 0.238552 8.62557Z" fill="#FF5050"/>
        </svg>

    )
}

export default PriorityHightIcon;