import classNames from 'classnames/bind';
import styles from './jiraActions.module.scss';
import { Flexbox, Snackbar } from 'components';
import { useEffect, useState } from 'react';
import { Initiative } from 'utils/types';
import { ExportIcon, JiraIcon } from 'components/icons';
import JiraExportSnackbar from 'common/Story/components/jiraSnackbar/jiraExportSnackbar';
import { useDispatch, useSelector } from 'react-redux';
import { availableIntegrationsSelector, userIntegrationsSelector, workspaceIntegrationSelector } from 'store/integrations';
import { getAvailableIntegrations, getWorkspaceIntegrations } from 'pages/Integrations/integrations.api';
import ActionsButton from 'components/ActionsButton';
import { setToLocalStorage } from 'utils/localStorage';
import { JIRA_AUTH_BASE_URL, REDIRECT_URL_KEY } from 'utils/constants';
import { createQueryParamsFromObject } from 'utils/request';
import InitiativeExportPopup from 'common/Story/components/initiativeExportPopup';
import { getJiraUrl } from 'utils/jiraFunctions';
const classes = classNames.bind(styles);

interface JiraActionsProps {
    currentInitiative?: Initiative;
    isEditable: boolean;
}

const JiraActions = ({ currentInitiative, isEditable }: JiraActionsProps) => {
    const dispatch = useDispatch();
    const workspaceIntegrations = useSelector(workspaceIntegrationSelector);
    const userIntegrations = useSelector(userIntegrationsSelector);
    const availableIntegrations = useSelector(availableIntegrationsSelector);

    const [initiative, setInitiative] = useState<Initiative | undefined>();

    const [showIntegrationUnavailable, setIntegrationUnavailable] = useState(false);

    const baseUrl = `initiatives/${initiative?.id}`

    const [jiraExportPopupOpen, setJiraExportPopupOpen] = useState(false);
    const [showExportSuccess, setShowExportSuccess] = useState(false);


    useEffect(() => {
        if (currentInitiative) {
            setInitiative(currentInitiative)
        }

    }, [currentInitiative])

    useEffect(() => {
        dispatch(getWorkspaceIntegrations());
        dispatch(getAvailableIntegrations());
    }, [])

    const closeExportPopup = () => {
        setJiraExportPopupOpen(false);
    }

    const openExportSuccess = () => {
        setShowExportSuccess(true);
    }

    const closeExportSuccess = () => {
        setShowExportSuccess(false);
    }

    const onJiraExport = (newInitiative: Initiative) => {
        setInitiative(newInitiative);
        closeExportPopup();
        openExportSuccess();
    }

    const connectJira = (callback: () => void) => {
        if (!workspaceIntegrations.find(integration => integration.name === 'JIRA')) {
            setIntegrationUnavailable(true);
            return;
        }
        if (!userIntegrations.find(integration => integration.name === 'JIRA')) {
            const jiraIntegration = availableIntegrations.find(
                integration => integration.name === 'JIRA'
            );
            if (jiraIntegration) {
                setToLocalStorage(REDIRECT_URL_KEY, location.pathname);
                const props = jiraIntegration.properties;
                const params = createQueryParamsFromObject({ ...props, state: 'JIRA' });

                window.location.replace(`${JIRA_AUTH_BASE_URL}&${params}`);
            }
        } else {
            callback();
        }
    };

    const exportToJira = () => {
        connectJira(async () => {
            setJiraExportPopupOpen(true);
        });
    }

    return (
        <Flexbox className={classes('jiraActions')} align>
            {initiative?.key ? (
                <a target="_blank" className={classes('jiraUrl')} href={getJiraUrl(initiative.key)}>
                    {initiative.key}
                </a>
            ) : (
                initiative && isEditable && (
                    <>
                        <ActionsButton
                            buttonItems={[
                                { label: 'Export as Jira Epic', action: exportToJira, icon: <ExportIcon /> },
                            ]}
                            className={classes('actionMenu', 'jiraActions-btn')}
                            buttonText="Jira actions"
                            variant="outlined"
                            buttonIcon={<JiraIcon />}
                        />

                        <InitiativeExportPopup
                            open={jiraExportPopupOpen}
                            onClose={closeExportPopup}
                            baseUrl={baseUrl}
                            exportedData={initiative}
                            onExport={onJiraExport}
                        />
                    </>
                )
            )}

            <JiraExportSnackbar
                closeExportSuccess={closeExportSuccess}
                showExportSuccess={showExportSuccess}
            />

            <Snackbar
                open={showIntegrationUnavailable}
                type="warning"
                onClose={() => setIntegrationUnavailable(false)}
            >
                <Flexbox vertical>
                    <Flexbox>Jira is not enabled for the workspace you are in.</Flexbox>
                    <Flexbox>It should be added as integration from administrator at first.</Flexbox>
                </Flexbox>
            </Snackbar>


        </Flexbox>
    );
}

export default JiraActions