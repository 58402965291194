import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DependencyInitiative, DependencyStatuses, Owner } from 'utils/types'
import { RootState } from 'store';
import { dependencyApi } from './dependency-api';

interface DependencyState {
    dependency: {
        teamId: number,
        dueDate: Date | null,
        description: string,
        title?: string,
        notes?: string,
        status: DependencyStatuses,
        requirement: string,
        initiative: DependencyInitiative;
        resolvingInitiative?: DependencyInitiative;
        owner: Owner
    },
}

const initialState: DependencyState = {
    dependency: {
        teamId: NaN,
        dueDate: null,
        description: '',
        notes: '',
        status: DependencyStatuses.Open,
        requirement: '',
        initiative: {} as DependencyInitiative,
        owner: {} as Owner
    },
}

const dependencySlice = createSlice({
    name: 'dependency',
    initialState,
    reducers: {
        editDependency: (state, action: PayloadAction<any>) => {
            state.dependency = {
                ...state.dependency,
                ...action.payload
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            dependencyApi.endpoints.getDependency.matchFulfilled,
            (state, { payload }) => {
                state.dependency = {
                    ...payload,
                    teamId: payload.team?.id,
                    dueDate: payload.dueDate ? new Date(payload.dueDate) : null,
                }
            },
        )
    },
})

export const dependencySelector = (store: RootState) => store.dependency.dependency;
export const dependencyStatusSelector = (store: RootState) => store.dependency.dependency.status;
export const dependencyOwnerSelector = (store: RootState) => store.dependency.dependency.owner;
export const dependencyTeamSelector = (store: RootState) => store.dependency.dependency.teamId;
export const dependencyDueDateSelector = (store: RootState) => store.dependency.dependency.dueDate;
export const dependencyInitiativeSelector = (store: RootState) => store.dependency.dependency.initiative;
export const dependencyTitleSelector = (store: RootState) => store.dependency.dependency.title;
export const dependencyRequirementSelector = (store: RootState) => store.dependency.dependency.requirement;
export const dependencyDescriptionSelector = (store: RootState) => store.dependency.dependency.description;
export const dependencyNotesSelector = (store: RootState) => store.dependency.dependency.notes;
export const dependencyResolvingInitiativeSelector = (store: RootState) => store.dependency.dependency.resolvingInitiative;

export const { editDependency } = dependencySlice.actions

export default dependencySlice
