import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import { Button, ButtonProps } from 'components';
import { FC } from 'react';

interface CommentButtonProps extends ButtonProps {
    checked: boolean;
}

export const CommentsToggleButton: FC<CommentButtonProps> = ({ checked, ...buttonProps }) => {
    const icon = checked ? <VisibilityOutlined /> : <VisibilityOffOutlined />;
    return (
        <Button {...buttonProps} size="large" variant="text" endIcon={icon}>
            Comments
        </Button>
    );
};

export default CommentsToggleButton;
