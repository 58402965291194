
import { forwardRef, memo, useImperativeHandle, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Select } from 'components';

const SelectEditor = memo(forwardRef(({
    value,
    api,
    colDef: {
        field,
        cellEditorParams: {
            selectorName,
            multiple,
            options,
            placeholder,
            defaultValue = '',
            disableClearable
        },
        minWidth
    },
}: ICellEditorParams, ref) => {

    const [selectValue, setSelectValue] = useState(value);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectValue
            },

            isCancelBeforeStart() {
                return false;
            },
        };
    });

    return (
        <Select
            sx={{
                minWidth: minWidth,
                width: api.getColumnState().find(el => el.colId === field)?.width || '100%'
            }}
            className='agGrid-custom-select'
            disableClearable={disableClearable}
            multiple={multiple}
            value={selectValue || defaultValue}
            options={options}
            getOptionLabel={(option) => selectorName ? option[selectorName] || '' : option}
            onChange={(e, value) => setSelectValue(value)}
            placeholder={placeholder}
            variant='filled'
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
}))

export default SelectEditor