import TextField, { TextFieldProps } from '@mui/material/TextField';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput'
import Flexbox from 'components/Flexbox';

import './textarea.scss';

export interface TextAreaProps extends OutlinedInputProps {
    asInput?: boolean;
    errorText?: string;
    required?: boolean
};

const TextArea = ({ label, fullWidth, disabled, asInput,className, errorText, required, ...props }: TextAreaProps) => {
    return <Flexbox vertical fullWidth={fullWidth} className={'textAreaContainer'}>
        {label && <Flexbox className='label'>{label}
            {required && <Flexbox className='required'>*</Flexbox>}
        </Flexbox>}
        {disabled ? (<Flexbox className={`disabled${asInput ? ' asInput' : ''}${className ? ` ${className}` : ''}${props.value ? '' : ' placeholder'}`}>{props.value as string || props.placeholder}</Flexbox>) : (<OutlinedInput
            multiline
            fullWidth={fullWidth}
            classes={{
                root: `root${asInput ? ' asInput' : ''}${errorText ? ' errorField' : ''}`,
                focused: 'focused',
            }}
            className={className}
            {...props}

        />)}
        {
            errorText && <Flexbox className="error">
                {errorText}
            </Flexbox>
        }
    </Flexbox>
}

export default TextArea;