import { CustomTyphography, Flexbox, Loader, Tooltip } from 'components'
import { FC } from 'react'
import { StatusTypes } from 'utils/types';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Fire, Flag, HandWaving } from 'components/icons';

const classes = classNames.bind(styles);

interface RiskStatusBoxProps {
    category: keyof typeof StatusTypes,
    alertsCount?: number,
    withoutAlerts?: boolean,
    loading?: boolean
}

const riskState = {
    Low: {
        className: 'accentSuccess',
        icon: HandWaving
    },
    Medium: {
        className: 'accentCaution',
        icon: Flag
    },
    High: {
        className: 'accentError',
        icon: Fire
    },
    Critical: {
        className: 'accentCritical',
        icon: Fire
    },
}

const RiskStatusBox: FC<RiskStatusBoxProps> = ({ category, alertsCount, withoutAlerts, loading }) => {

    if (loading && !category) {
        return (
            <Flexbox align className={classes('riskStatusBox', 'accentDisabled')}>
                <Loader />
                <CustomTyphography>Evaluating...</CustomTyphography>
            </Flexbox>
        )
    } else if (category) {
        const Icon = riskState[category].icon
        return (
            <Tooltip title={category}>
                <Flexbox align className={classes('riskStatusBox', {
                    [riskState[category].className]: !loading,
                    'accentDisabled': loading,
                    'cursor-pointer': !!alertsCount && !loading
                })}>
                    {loading ? <Loader /> : <Icon />}
                    <CustomTyphography>{withoutAlerts ? category : !!alertsCount ? `${alertsCount} ${alertsCount === 1 ? 'Insight' : 'Insights'} ` : 'On Track'}</CustomTyphography>
                </Flexbox>
            </Tooltip>
        )
    } else {
        return null
    }
}

export default RiskStatusBox