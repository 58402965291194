import { CommonButton, Dialog, Flexbox, TextField } from 'components'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { FC, useRef, useState } from 'react';
import { UploadIcon } from 'components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { addViewsRoadmap } from 'store/viewsRoadmap-api';
import { addViewsRoadmapLoadingSelector } from 'store/viewsRoadmap-slice';
import CommonInput from 'components/CommonInput';

const classes = classNames.bind(styles);

interface AddSourcePopupProps {
    open: boolean;
    handleClosePopup: () => void;
}

const AddSourcePopup: FC<AddSourcePopupProps> = ({ open, handleClosePopup }) => {
    const dispatch = useDispatch();

    const [file, setFile] = useState<File | null>(null);
    const [name, setName] = useState<string>('');

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const addViewsRoadmapLoading = useSelector(addViewsRoadmapLoadingSelector)

    const handleFileInputClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onClosePopup = () => {
        handleClosePopup();
        setFile(null)
        setName('')
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    };

    const onConfirm = async () => {
        if (file && name) {
            try {
                await dispatch(addViewsRoadmap({ source: file, name }))
                onClosePopup()
            } catch (err) {
                console.log(err, 'error');
            }
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClosePopup}
            title={'Add Roadmap Planning Source'}
            className={classes('gap-4')}
        >
            <Flexbox fullWidth vertical className={classes('gap-6', 'addSourceContainer')}>
                <CommonInput
                    value={name}
                    placeholder="Name"
                    onChange={e => setName(e.target.value)}
                    disabled={addViewsRoadmapLoading}
                />
                <Flexbox fullWidth align className={classes('commonInput')}>
                    <TextField
                        fullWidth
                        onClick={handleFileInputClick}
                        className={classes('commonInput-field', 'upload', 'outline')}
                        type="text"
                        placeholder={'Upload Planning Source'}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <UploadIcon />
                        }}
                        value={file ? file.name : ''}
                        disabled={addViewsRoadmapLoading}
                    />
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept={'.csv, .pdf, .xlsx, .txt'}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        disabled={addViewsRoadmapLoading}
                    />
                </Flexbox>
                <CommonButton
                    fullWidth
                    buttonSize="big"
                    buttonType="primary"
                    loading={addViewsRoadmapLoading}
                    onClick={onConfirm}
                    disabled={!name || !file || addViewsRoadmapLoading}
                >
                    Add Roadmap Planning Source
                </CommonButton>
            </Flexbox>
        </Dialog>
    )
}

export default AddSourcePopup