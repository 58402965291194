const PortalIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13274_41878)">
                <path fill="currentColor" d="M15.9482 6.06505C16.0015 6.05462 16.0569 6.0647 16.1028 6.09256C16.1813 6.12469 16.2367 6.20108 16.237 6.29046L16.2826 12.5426C16.2862 12.6193 16.2484 12.6955 16.1772 12.7376L13.599 14.2606C13.5626 14.2879 13.517 14.304 13.468 14.304C13.4561 14.304 13.4442 14.3033 13.4328 14.3013L6.74234 13.5645C6.62725 13.5589 6.53562 13.4644 6.53562 13.3489V6.99114H6.53597C6.53338 6.90603 6.58221 6.82383 6.66573 6.7876L10.0219 5.32643L10.0221 5.32677C10.0571 5.31139 10.0968 5.30524 10.1375 5.31037L15.9482 6.06505ZM11.2512 1C13.8031 1 16.1176 2.02846 17.7942 3.68206C19.4707 5.34249 20.5023 7.6279 20.5023 10.1621C20.5023 12.0107 19.9456 13.7385 18.9955 15.1768L23 19.4993L20.2376 22L16.3758 17.792C14.9096 18.76 13.1448 19.3244 11.2514 19.3244C8.69929 19.3244 6.38481 18.296 4.70843 16.6425C3.03171 14.9818 2 12.6964 2 10.1621C2 7.63457 3.03861 5.34249 4.70826 3.68206C6.38463 2.02179 8.69239 1 11.2512 1ZM17.0612 4.40803C15.5748 2.93592 13.5182 2.02162 11.2512 2.02162C8.98418 2.02162 6.92767 2.93592 5.44127 4.40803C3.95488 5.88014 3.03171 7.91706 3.03171 10.1621C3.03171 12.4074 3.95471 14.4441 5.4411 15.9164C6.92749 17.3883 8.98418 18.3026 11.251 18.3026C13.5179 18.3026 15.5746 17.3883 17.061 15.9164C18.5474 14.4441 19.4706 12.4074 19.4706 10.1621C19.4707 7.91706 18.5476 5.88031 17.0612 4.40803ZM13.7638 7.89142V13.5543L15.7699 12.4315L15.7278 6.72249L13.7638 7.89142ZM13.0941 13.6163V7.95158L10.3971 7.6684L10.3035 10.3689L9.30168 9.69319L8.29983 10.2539L8.50655 7.42932L7.12714 7.23535V13.0021L13.0941 13.6163ZM12.799 6.08966L10.7727 7.19553L13.51 7.54143L15.3504 6.42103L12.799 6.08966ZM8.85252 6.95286L10.815 5.83194L10.1422 5.74462L7.62427 6.79751L8.85252 6.95286Z" />
            </g>
            <defs>
                <clipPath id="clip0_13274_41878">
                    <rect width="24" height="24"  fill="currentColor"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default PortalIcon;