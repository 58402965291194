import { ActionsMenu, ConfirmationDialog, Flexbox, MultilineInput } from 'components'
import styles from '../products.module.scss';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Actions, hasPermission } from 'utils/permissions';
import { defaultTitle, updateProductBody } from '../product';
import SavingStatus from 'common/savingStatus';
import { useDeleteProductMutation, useEditProductMutation } from 'store/product-api';
import { productOwnerSelector, productTitleSelector, editProduct, savingStatusSelector } from 'store/product-slice';
import { useWorkspaceId } from 'utils/hooks';
import { usersSelector } from 'store/users-slice';
import { useGetUsersQuery } from 'store/users-api';

const classes = classNames.bind(styles);

interface productHeadProps {
    productId: number;
    isEditable?: boolean,
}

const ProductHead = ({ productId, isEditable = true }: productHeadProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const workspaceId = useWorkspaceId()
    useGetUsersQuery({ workspaceId });

    const users = useSelector(usersSelector);
    const title = useSelector(productTitleSelector);
    const ownerId = useSelector(productOwnerSelector)?.id;
    const savingStatus = useSelector(savingStatusSelector)

    const [openConfirmation, setOpenConfirmation] = useState(false);

    const [editProductMutation] = useEditProductMutation()
    const [deleteProductMutation] = useDeleteProductMutation()

    const timeoutId = useRef<NodeJS.Timeout | null>(null);

    const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newTitle = e.target.value;
        dispatch(editProduct({ title: newTitle }));

        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }

        timeoutId.current = setTimeout(async () => {
            try {
                await editProductMutation({
                    workspaceId,
                    productId,
                    data: updateProductBody(),
                });
            } catch (error) {
                console.error('Failed to update product title:', error);
            }
        }, 500);
    };

    const onDeleteProduct = async () => {
        try {
            deleteProductMutation({ workspaceId, productId })
        } catch (error) {
            console.error('Failed to delete product', error);
        }

        onCancelDelete()
        navigate('/catalog');
    }

    const showDeleteConfirmation = () => {
        setOpenConfirmation(true)
    }

    const onCancelDelete = () => {
        setOpenConfirmation(false)
    }

    const ownerUser = users.find(user => user.id === ownerId);

    return (
        <Flexbox className={classes('productHead')} vertical>
            <Flexbox className={classes('infoTopBox')}>
                <Flexbox className={classes('infoTitleBox')} fullWidth>
                    <MultilineInput className={classes('infoTitleInput')}
                        value={title === defaultTitle ? '' : title}
                        onChange={onTitleChange}
                        placeholder='Create title'
                        disabled={!isEditable}
                        fullWidth
                    />
                </Flexbox>
                <Flexbox>
                    <SavingStatus savingStatus={savingStatus} />
                    {hasPermission(Actions.delete, { owner: ownerUser }) && <ActionsMenu
                        buttonItems={[{ label: 'Delete', action: showDeleteConfirmation, type: 'red' }]}
                        className={classes('actionMenu')} />}
                </Flexbox>
            </Flexbox>
            <ConfirmationDialog
                open={openConfirmation}
                onClose={onCancelDelete}
                onConfirm={onDeleteProduct}
                confirmButtonStyle='danger'
                title='Delete this Product?'
            >
                <Flexbox>
                    You're about to permanently delete this Product, and all connected data will be lost.
                </Flexbox>
            </ConfirmationDialog>
        </Flexbox>
    )
}

export default ProductHead
