import { CustomTyphography, Flexbox } from 'components'
import { FC } from 'react'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Stars } from 'components/icons';

const classes = classNames.bind(styles);

interface PRDScoreBoxProps {
    value: number
}

const scoresConfig = [
    {
        interval: { min: 0, max: 40 },
        className: 'accentError',
    },
    {
        interval: { min: 41, max: 80 },
        className: 'accentCaution',
    },
    {
        interval: { min: 81, max: 100 },
        className: 'accentSuccess',
    }
]

const PRDScoreBox: FC<PRDScoreBoxProps> = ({ value }) => {
    const scoreClassName = scoresConfig.find(el => (value >= el.interval.min && value <= el.interval.max))?.className

    return (
        <Flexbox align justifyBetween className={classes('prdScoreBox', scoreClassName)}>
            <CustomTyphography>{value}%</CustomTyphography>
            <Flexbox align justify className={classes('prdScore-iconBox', `${scoreClassName}-bgColor`)}><Stars /></Flexbox>
        </Flexbox>
    )
}

export default PRDScoreBox