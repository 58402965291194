import classNames from 'classnames/bind';
import { FC, SyntheticEvent, useMemo, useState } from 'react';
import styles from './alertsSidePanel.module.scss';
import { StatusTypes } from 'utils/types';
import { TabPanel, Flexbox, Box, CustomTyphography, RiskStatusBox } from 'components';
import { Tab, Tabs } from '@mui/material';
import TextWithLinks from 'common/TextWithLinks';
import showdown from 'showdown';
import { formatDateTime } from 'utils/date';
const classes = classNames.bind(styles);

const tabs = [
    {
        label: 'Summary',
        value: 'summary',
    },
    {
        label: 'AI Insights',
        value: 'aiInsights',
    },
    {
        label: 'Insights',
        value: 'alerts',
    },
]

interface AlertsSidePanelProps {
    title: string,
    reasons: { title: string, description: string }[],
    format?: 'markdown' | 'text'
    lastEvaluationDate: number
}

const AlertsSidePanel: FC<AlertsSidePanelProps> = ({
    title,
    reasons,
    format = 'text',
    lastEvaluationDate
}) => {
    const [tabValue, setTabValue] = useState(2);

    const converter = useMemo(() => {
        return new showdown.Converter({ tables: true });
    }, []);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Box className={classes('alertsSidePanel')}>
            <Flexbox vertical className={classes('gap-6', 'pb-4')}>
                <Flexbox vertical className={classes('gap-4')}>
                    <Flexbox align justifyBetween>
                        {/* <CustomTyphography variant='caption' className={classes('alertsSidePanel-keyNumber')}>{headerNumber}</CustomTyphography> */}
                        <Flexbox align className={classes('gap-2')}>
                            {/* <RiskStatusBox value={status} /> */}
                            {/* <Flexbox>{category}</Flexbox> */}
                        </Flexbox>
                    </Flexbox>
                    <CustomTyphography type='primary' className={classes('alertsSidePanel-title')}>{title}</CustomTyphography>
                </Flexbox>
                {/* <Flexbox vertical justify align className={classes('alertsSidePanel-tabsContainer')}>
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        className={classes('alertsSidePanel-tabs')}
                    >
                        {
                            tabs.map(tab => <Tab className={classes('alertsSidePanel-tab')} key={tab.value} label={tab.label} />)
                        }
                    </Tabs>
                </Flexbox> */}
            </Flexbox>

            {/* <TabPanel active={tabValue === 2} vertical> */}
            <Flexbox vertical className={classes('gap-5', 'pt-6', 'pb-6')}>
                {/* <Flexbox className={classes('alertsSidePanel-tabTitle')}>
                    🚨 Alerts
                    ⏰ Reasons
                </Flexbox>
                <Flexbox className={classes('alertsSidePanel-description')}>
                    There are 3 tasks assigned to "John Smith" and all of them are in progress. This puts the delivery of this initiatives at risk.
                </Flexbox> */}
                <Flexbox justifyBetween align>
                    <Flexbox className={classes('alertsSidePanel-tabTitle')}>
                        ⏰ Insights
                    </Flexbox>
                    {!!lastEvaluationDate && <Flexbox className={classes('alertsSidePanel-description')}>Last evaluation date: {formatDateTime(+lastEvaluationDate)}</Flexbox>}
                </Flexbox>
                <Flexbox vertical className={classes('alertsSidePanel-tasks', 'gap-2')}>
                    {reasons.map((reason, reasonIndex) => (
                        <Flexbox vertical key={reasonIndex} className={classes('alertsSidePanel-task', 'gap-2', 'p-3')}>
                            <CustomTyphography className={classes('alertsSidePanel-taskTitle')} type='primary'>{reason.title}</CustomTyphography>
                            {format === 'markdown' ? <Flexbox
                                fullWidth
                                vertical
                                dangerouslySetInnerHTML={{
                                    __html: converter.makeHtml(reason.description),
                                }}
                            /> : <CustomTyphography>
                                <TextWithLinks text={reason.description} />
                            </CustomTyphography>}
                        </Flexbox>
                    ))}
                </Flexbox>
            </Flexbox>
            {/* </TabPanel> */}
        </Box>
    );
};

export default AlertsSidePanel