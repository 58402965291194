import { AttachmentMetadata, KnowledgeBase } from 'utils/types';
import { RootState } from './index';

interface KnowledgeBaseReducerState {
    loading: boolean;
    knowledgeBaseList: KnowledgeBase[];
    knowledgeBaseCurrent: KnowledgeBase;
    retryUploadUuId: string | null
}

const initialState: KnowledgeBaseReducerState = {
    loading: false,
    knowledgeBaseList: [],
    knowledgeBaseCurrent: {} as KnowledgeBase,
    retryUploadUuId: null
}

export const ACTION_GET_KB = 'getKB';
export const ACTION_SET_KB = 'setKB';
export const ACTION_DELETE_KB = 'deleteKB';

export const ACTION_SET_CURRENT_KB = 'setCurrentKb';
export const ACTION_CREATE_KB = 'createKBRR';

export const UPDATE_TITLE = 'updateTitle';
export const UPDATE_STATUS = 'updateStatus';
export const UPDATE_CONTENT = 'updateContent';
export const ACTION_ADD_BLOCK_ITEM = 'addBlockItem'
export const ACTION_DELETE_BLOCK_ITEM = 'deleteBlockItem'
export const ACTION_UPDATE_BLOCK_ITEM = 'updateBlockItem'
export const ACTION_UPDATE_ATTACHMENT = 'updateAttachment'
export const ACTION_REPLACE_ATTACHMENT = 'replaceAttachment'
export const ACTION_CANCEL_UPLOAD = 'cancelUpload'
export const ACTION_SET_RETRY_UPLOAD_UUID = 'setRetryUploadUuId'


const knowledgeBase = (state: KnowledgeBaseReducerState = initialState, action: {type: string, payload: any}): KnowledgeBaseReducerState => {
    switch (action.type) {
    case ACTION_GET_KB:
        return { ...state, knowledgeBaseList: [], loading: true }

    case ACTION_SET_KB:
        return { ...state, loading: false, knowledgeBaseList: [...action.payload] }

    case ACTION_DELETE_KB:
        return { ...state, knowledgeBaseList: state.knowledgeBaseList.filter(kb => kb.id !== action.payload) }

    case ACTION_SET_CURRENT_KB:
        return { ...state, knowledgeBaseCurrent: action.payload }

    case ACTION_CREATE_KB:
        return { ...state, knowledgeBaseCurrent: action.payload }

    case UPDATE_TITLE:
        return {
            ...state,
            knowledgeBaseCurrent: {
                ...state.knowledgeBaseCurrent,
                title: action.payload
            },
        };

    case UPDATE_STATUS:
        return {
            ...state,
            knowledgeBaseCurrent: {
                ...state.knowledgeBaseCurrent,
                status: action.payload
            },
        };

    case UPDATE_CONTENT:
        return {
            ...state,
            knowledgeBaseCurrent: {
                ...state.knowledgeBaseCurrent,
                content: action.payload
            },
        };

    case ACTION_ADD_BLOCK_ITEM:
        return {
            ...state,
            knowledgeBaseCurrent: {
                ...state.knowledgeBaseCurrent,
                blocks: [...state.knowledgeBaseCurrent.blocks, action.payload],
            },
        };

    case ACTION_DELETE_BLOCK_ITEM:
        return {
            ...state,
            knowledgeBaseCurrent: {
                ...state.knowledgeBaseCurrent,
                blocks: state.knowledgeBaseCurrent.blocks.filter(b => b.id !== action.payload)
            },
        };

    case ACTION_UPDATE_BLOCK_ITEM:
        return{
            ...state,
            knowledgeBaseCurrent: {
                ...state.knowledgeBaseCurrent,
                blocks: state.knowledgeBaseCurrent.blocks.map((block) =>
                    block.id === action.payload.id
                        ? { ...block, metadata: { title: action.payload.title, url: action.payload.url } }
                        : block
                )
            },
        };

    case ACTION_UPDATE_ATTACHMENT:
        return {
            ...state,
            knowledgeBaseCurrent: {
                ...state.knowledgeBaseCurrent,
                blocks: state.knowledgeBaseCurrent.blocks.map((block) => {
                    if ((block.metadata as AttachmentMetadata).fileName === action.payload.fileName) {
                        return { ...block, metadata: { ...block.metadata, ...action.payload } };
                    } else {
                        return block;
                    }
                })
            }
        };

    case ACTION_REPLACE_ATTACHMENT:
        return {
            ...state,
            knowledgeBaseCurrent: {
                ...state.knowledgeBaseCurrent,
                blocks: state.knowledgeBaseCurrent.blocks.map((block) => {
                    if ((block.metadata as AttachmentMetadata).fileName === action.payload.metadata.fileName) {
                        return action.payload
                    } else {
                        return block;
                    }
                })
            }
        };
    case ACTION_CANCEL_UPLOAD:
        return {
            ...state,
            knowledgeBaseCurrent: {
                ...state.knowledgeBaseCurrent,
                blocks: state.knowledgeBaseCurrent.blocks.filter(b => b.uuId !== action.payload)
            }
        };
    case ACTION_SET_RETRY_UPLOAD_UUID:
        return {
            ...state,
            retryUploadUuId: action.payload
        };
    default:
        return state
    }
}

export default knowledgeBase;

export const knowledgeBaseListSelector = (store: RootState) => store.knowledgeBase.knowledgeBaseList
export const knowledgeBaseLoadingSelector = (store: RootState) => store.knowledgeBase.loading

export const knowledgeBaseCurrentSelector = (store: RootState) => store.knowledgeBase.knowledgeBaseCurrent
export const knowledgeBaseCurrentId = (store: RootState) => store.knowledgeBase.knowledgeBaseCurrent.id

export const currentKbTitleSelector = (store: RootState) => store.knowledgeBase.knowledgeBaseCurrent.title
export const currentKbOwnerSelector = (store: RootState) => store.knowledgeBase.knowledgeBaseCurrent.owner
export const currentKbModifiedDateSelector = (store: RootState) => store.knowledgeBase.knowledgeBaseCurrent.lastModifiedDate
export const currentKbStatusSelector = (store: RootState) => store.knowledgeBase.knowledgeBaseCurrent.status

export const knowledgeBaseCurrentBlockSelector = (store: RootState) => store.knowledgeBase.knowledgeBaseCurrent.blocks
export const currentKbContentSelector = (store: RootState) => store.knowledgeBase.knowledgeBaseCurrent.content

export const retryUploadUuIdSelector = (store: RootState) => store.knowledgeBase.retryUploadUuId