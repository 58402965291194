import { ReportListData } from 'pages/Reports/reportsList';
import { RootState } from './index';


interface ReportsReducerState {
    loading: boolean;
    reports: ReportListData[];
}

const initialState: ReportsReducerState = {
    loading: false,
    reports: [],
}

export const ACTION_GET_REPORTS = 'getReports';
export const ACTION_SET_REPORTS = 'setReports';
export const ACTION_ADD_REPORT = 'addReport';
export const ACTION_DELETE_REPORT = 'deleteReport';

const reports = (state: ReportsReducerState = initialState, action: {type: string, payload: any}): ReportsReducerState => {
    switch (action.type) {
    case ACTION_GET_REPORTS:
        return { ...state, reports: [], loading: true }
    case ACTION_SET_REPORTS:
        return { ...state, loading: false, reports: [...action.payload] }
    case ACTION_ADD_REPORT:
        return { ...state, reports: [...state.reports, action.payload] }
    case ACTION_DELETE_REPORT:
        return { ...state, reports: state.reports.filter(report => report.id !== action.payload) }

    default:
        return state
    }
}
export default reports;

export const  reportsSelector = (store: RootState) => store.reports.reports;
export const reportsLoadingSelector = (store: RootState) => store.reports.loading;