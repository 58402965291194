import { IconButton, Stack } from 'components';
import { AtSignIcon } from 'components/icons/AtSign';
import { PaperClipIcon } from 'components/icons/Paperclip';
import { SmileIcon } from 'components/icons/Smile';
import { FC, ReactNode } from 'react';
import styles from './mention-editor.module.scss';
import classNames from 'classnames/bind';
const classes = classNames.bind(styles);

export interface MentionEditorToolsProps {
    actions?: ReactNode;
}

export const MentionEditorTools: FC<MentionEditorToolsProps> = ({
    actions,
}) => {
    return (
        <Stack direction="row" justifyContent="space-between" padding={1}>
            <div />
            {/* <Stack direction="row" spacing={1} alignItems="center">
                <IconButton disableRipple size="small">
                    <SmileIcon />
                </IconButton>
                <IconButton disableRipple size="small">
                    <AtSignIcon />
                </IconButton>
                <IconButton disableRipple size="small">
                    <PaperClipIcon />
                </IconButton>
            </Stack> */}
            {actions}
        </Stack>
    );
};
