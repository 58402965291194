import { useCallback, FC, RefObject } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const classes = classNames.bind(styles);

interface ResizableDividerProps {
  setLeftWidth: (width: number) => void;
  containerRef: RefObject<HTMLDivElement>;
}

const ResizableDivider: FC<ResizableDividerProps> = ({ setLeftWidth, containerRef }) => {

  const startResizing = useCallback(
    (pointerDownEvent: React.PointerEvent<HTMLDivElement>) => {
      pointerDownEvent.preventDefault();

      const target = pointerDownEvent.currentTarget;
      target.setPointerCapture(pointerDownEvent.pointerId);

      const onPointerMove = (pointerMoveEvent: PointerEvent) => {
        if (containerRef.current) {
          const gridWidth = containerRef.current.offsetWidth;
          const offsetX = pointerMoveEvent.clientX - (window.innerWidth - gridWidth);
          const newLeftWidth = (offsetX / gridWidth) * 100;

          setLeftWidth(Math.min(80, Math.max(20, newLeftWidth)));
        }
      };

      const onPointerUp = () => {
        target.releasePointerCapture(pointerDownEvent.pointerId);

        target.removeEventListener('pointermove', onPointerMove);
        target.removeEventListener('pointerup', onPointerUp);
      };

      target.addEventListener('pointermove', onPointerMove);
      target.addEventListener('pointerup', onPointerUp);
    },
    [setLeftWidth, containerRef]
  );

  return (
    <div
      className={classes('divider')}
      onPointerDown={startResizing}
    ></div>
  );
};

export default ResizableDivider;