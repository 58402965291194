import classNames from 'classnames/bind';
import { User } from 'utils/types';
import styles from './commentReplyInput.module.scss';
import MentionEditor, { MentionEditorProps } from 'components/MentionEditor/MentionEditor';
import { useGetWorkspaceMentions } from 'store/comments-api';
import CommentSendButton from '../CommentSendButton';
const classes = classNames.bind(styles);

interface CommentReplyInputProp
    extends Pick<MentionEditorProps, 'editorState' | 'onEditorStateChange'> {
    author: User;
    selected?: boolean;
    disabled?: boolean;
    onReply?: () => void;
}

export const CommentReplyInput = ({
    author,
    selected = false,
    disabled = false,
    editorState,
    onEditorStateChange,
    onReply,
}: CommentReplyInputProp) => {
    const { mentions = [] } = useGetWorkspaceMentions();

    return (
        <div className={classes('comment-reply')}>
            <MentionEditor
                autoFocus
                readonly={disabled}
                className={classes('comment-reply-input', { selected })}
                placeholder="Reply"
                mentions={mentions}
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                onEnter={onReply}
            />
            <CommentSendButton onClick={onReply} />
        </div>
    );
};
