import { Flexbox, Typography } from 'components'
import { FC, useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../../../initiative.module.scss';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import AgGridTable, { AgColumn } from 'components/AgGridTable';
import { GridApi } from 'ag-grid-enterprise';
import { GridReadyEvent } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import { storiesSelector } from 'store/initiativeGrading-slice';

const classes = classNames.bind(styles);

interface StoriesProgressTableProps {
}

const columns: AgColumn[] = [
    {
        headerName: 'Name',
        field: 'name',
        minWidth: 200,
        sortable: true,
        enableRowGroup: false,
        enablePivot: false,
        suppressMovable: true,
    },
    {
        headerName: 'Status',
        field: 'status',
        minWidth: 200,
        sortable: true,
        enableRowGroup: false,
        enablePivot: false,
        suppressMovable: true,
    },
]

const StoriesProgressTable: FC<StoriesProgressTableProps> = () => {
    const stories = useSelector(storiesSelector);

    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    return (
        <Flexbox fullWidth vertical className={classes('storiesProgressContainer')}>
            <Flexbox className={classes('headerContainer')}>
                <Typography variant='h4' className={classes('headerTitle')}>Non-Exported Requirements</Typography>
                <Flexbox>
                    {gridApi && <ExportButton api={gridApi} />}
                </Flexbox>
            </Flexbox>
            <AgGridTable
                data={stories}
                columns={columns}
                onGridReady={onGridReady}
                exportFileName={'Non-Exported Requirements'}
                domLayout='autoHeight'
                showSidebar={false}
                rowGroupPanelShow={'never'}
            />
        </Flexbox>
    )
}

export default StoriesProgressTable;