import { getMenuItemsArray } from 'common/mainLayout/Sidebar/menuItems';
import { useEffect, useRef, useState } from 'react';
import store from 'store';
import { SidebarMenuItem } from './types';
import posthog from 'posthog-js';

export const useDebounce = <T extends unknown>(
    initialValue: T,
    timeout: number = 500
): [T, T, (value: T) => void] => {
    const [value, setValue] = useState<T>(initialValue);
    const [debounceValue, setDebounceValue] = useState<T>(initialValue);
    const timer = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            setDebounceValue(value);
        }, timeout);

        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, [value]);
    return [value, debounceValue, setValue];
};

export const useWorkspaceId = () => store.getState().user.workspace.id

export const useFirstAvailableMenuItem = () => {
    const menuItems = getMenuItemsArray();
    const distinctId = posthog.get_distinct_id()

    const [menuItem, setMenuItem] = useState<SidebarMenuItem>()

    useEffect(() => {
        if (distinctId) {
            const menuItemsWithFlagStatus = menuItems.map(item => ({
                ...item,
                isEnabled: item.featureFlag ? posthog.isFeatureEnabled(item.featureFlag) : true
            }));

            const enabledMenuItem = menuItemsWithFlagStatus.find(el => el.isEnabled);

            if (enabledMenuItem && enabledMenuItem.url === '' && enabledMenuItem.sections && enabledMenuItem.sections.length > 0) {
                setMenuItem(enabledMenuItem.sections[0]);
            }

            setMenuItem(enabledMenuItem)
        }
    }, [distinctId])

    return menuItem as SidebarMenuItem;
}