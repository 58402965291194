import { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Button, ConfirmationDialog, FilterButton, Flexbox, Loader, SearchField } from 'components';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import styles from './dependency.module.scss';
import classNames from 'classnames/bind';
import { Dependency, DependencyStatuses, Preferences, PreferencesKeys, PreferenceValues } from 'utils/types';
import { Actions, hasPermission } from 'utils/permissions';
import { EmptyDependencyIcon } from 'components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { dependenciesSelector } from 'store/dependencies';
import { deleteDependency, getDependencies } from './dependencies.api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getEnumKeyByEnumValue } from 'utils';
import { FilterOption } from 'components/FilterButton';
import { useDebounce, useWorkspaceId } from 'utils/hooks';
import { FilterValuesReducerAction } from 'pages/Products/productsList';
import { userSelector } from 'store/user';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import AgGridTable, { AgColumn, ColumnTypes, GridStatePreferences, getLinkedColumnContextMenuItems } from 'components/AgGridTable';
import { GridApi, GridReadyEvent, ICellRendererParams, SortChangedEvent } from 'ag-grid-community';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import EmptyState from 'common/emptyState';
import { useLazyGetUsersQuery } from 'store/users-api';
import { activeUsersSelector } from 'store/users-slice';
import { useLazyGetTeamsQuery } from 'store/teams-api';
import { teamsSelector } from 'store/teams-slice';
const classesInfo = classNames.bind(stylesInfo);
const classes = classNames.bind(styles);

export enum FilterKeys {
    owner = 'owner',
    team = 'team',
    status = 'status',
    query = 'query',
    order = 'order',
    orderBy = 'orderBy',
    dependencyTicketGridLayout = 'dependencyTicketGridLayout'
}

const defaultFilterState = {
    [FilterKeys.owner]: [],
    [FilterKeys.team]: [],
    [FilterKeys.status]: [],
}

const filterValuesReducer = (state: { [key: string]: FilterOption[] }, action: FilterValuesReducerAction) => {
    switch (action.type) {
        case 'update':
            return { ...state, [action.key]: action.payload }
        case 'reset':
            return defaultFilterState;
        default:
            return state;
    }
}

const DependenciesList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const workspaceId = useWorkspaceId()

    const dependencies = useSelector(dependenciesSelector)
    const [getUsers] = useLazyGetUsersQuery();
    const [getTeams] = useLazyGetTeamsQuery();
    const users = useSelector(activeUsersSelector);
    const teams = useSelector(teamsSelector);
    const user = useSelector(userSelector);

    const [searchValue, searchDebounceValue, setSearchValue] = useDebounce('');
    const [filterValues, setFilterValues] = useReducer(filterValuesReducer, defaultFilterState);
    const [searchParams, setSearchParams] = useSearchParams();
    const [gridStatePreferences, setGridStatePreferences] = useState<GridStatePreferences | undefined>({})

    const [userOptions, setUserOptions] = useState<FilterOption[]>([]);
    const [teamOptions, setTeamOptions] = useState<FilterOption[]>([]);
    const [statusOptions] = useState<FilterOption[]>(Object.keys(DependencyStatuses).map((key, index) => ({ id: index, title: key })));

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
    const [dependencyId, setDependencyId] = useState<number | undefined>()

    const [data, setData] = useState<Dependency[]>([])
    const [loading, setLoading] = useState(true);
    const [canUpdatePreferences, setCanUpdatePreferences] = useState(false);

    const [orderBy, setOrderBy] = useState<keyof Dependency | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc' | undefined>();

    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null)

    const columns: AgColumn[] = useMemo(() => [
        {
            colType: ColumnTypes.Linked,
            headerName: 'ID',
            field: 'id',
            minWidth: 60,
            sortable: false,
            link: '/dependencies/dependency',
            linkParam: 'id',
            enableRowGroup: false
        },
        {
            colType: ColumnTypes.SimpleSelect,
            headerName: 'Requested By',
            field: 'owner',
            minWidth: 200,
            sortable: true,
            valueGetter: (params) => {
                return params.data ? params.data.owner?.fullName : '';
            },
            valueFormatter: (params) => {
                return params?.value?.fullName;
            },
        },
        {
            headerName: 'Dependant Initiative',
            field: 'initiative',
            minWidth: 250,
            sortable: true,
            valueFormatter: params => params?.value?.title,
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <Flexbox wrap className={classes('ag-gap-5')}>
                        {params.valueFormatted}
                        {params.data?.initiative.deleted && <Flexbox className={classes('ag-deletedItemText')}>(Deleted)</Flexbox>}
                    </Flexbox>
                )
            },
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell ag-title',
        },
        {
            headerName: 'Resolving Team',
            field: 'team',
            minWidth: 150,
            sortable: true,
            valueGetter: params => params.data?.team?.name,
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell',
        },
        {
            colType: ColumnTypes.DependencyStatus,
            headerName: 'Status',
            field: 'status',
            minWidth: 100,
            sortable: true,
        },
        {
            headerName: 'Resolving Initiative',
            field: 'resolvingInitiative',
            minWidth: 200,
            sortable: true,
            valueGetter: params => params?.data?.resolvingInitiative?.title,
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell',
        },
        {
            colType: ColumnTypes.Date,
            headerName: 'Due Date',
            field: 'dueDate',
            minWidth: 130,
            sortable: true,
        },
        {
            colType: ColumnTypes.Action,
            field: 'actions',
            headerName: '',
            actions: params => {
                return [
                    {
                        label: 'Open',
                        action: () => {
                            navigate(`/dependencies/dependency/${params?.data?.id}`)
                        }
                    },
                    ...(hasPermission(Actions.delete, params.data) && !(teams.find(team => team.id === params.data?.team?.id)?.lead?.id === user.id) ? [
                        { label: 'Delete', action: () => showDeleteConfirmation(params.data.id), type: 'red' }
                    ] : [])
                ]
            },
        },
    ], [])

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                dispatch(getDependencies()),
                getUsers({ workspaceId }),
                getTeams({ workspaceId }),
                loadPreferences()
            ])
            setLoading(false)
        }
        setLoading(true);
        fetchData();
    }, []);

    useEffect(() => {
        const options: FilterOption[] = users.map(u => ({ id: u.id, title: `${u.fullName}${u.id === user.id ? ' (Me)' : ''}`, tooltip: u.email }))
        const currentUserIndex = options.findIndex((o) => o.id === user.id)
        if (currentUserIndex >= 0) {
            const currentUserOption = options.splice(currentUserIndex, 1)[0]
            options.splice(0, 0, currentUserOption)
        }
        setUserOptions(options)

        setTeamOptions(teams.map(team => ({ id: team.id, title: team.name || '' })))

    }, [users, user, teams])

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.dependencyTicket))) as unknown as Preferences<FilterKeys>[];
        if (preferences && preferences.length) {
            const { dependencyTicketGridLayout, order, orderBy, ...filters } = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;

            dependencyTicketGridLayout && setGridStatePreferences(dependencyTicketGridLayout as GridStatePreferences);
            setOrder(order);
            setOrderBy(orderBy)

            if (searchParams.toString().length === 0) {
                setSearchParams(filters, { replace: true })
            }
        }
    }

    useEffect(() => {
        let data = [...dependencies];

        if (searchDebounceValue) {
            data = data.filter(d => d.initiative.title && d.initiative.title.toLowerCase().includes(searchDebounceValue.toLowerCase())
                || d.resolvingInitiative?.title && d.resolvingInitiative.title.toLowerCase().includes(searchDebounceValue.toLowerCase()))
        }

        data = data.filter(d => {
            if (!filterValues[FilterKeys.owner].length || filterValues[FilterKeys.owner].some(filter => filter.id === d.owner.id)) {
                return true;
            }
            return false;
        })

        data = data.filter(d => {
            if (!filterValues[FilterKeys.team].length || filterValues[FilterKeys.team].some(filter => filter.title === d.team.name)) {
                return true;
            }
            return false;
        })

        data = data.filter(d => {
            const dependencyStatus = getEnumKeyByEnumValue(DependencyStatuses, d.status);
            if (!filterValues[FilterKeys.status].length || filterValues[FilterKeys.status].some(filter => filter.title === dependencyStatus)) {
                return true;
            }
            return false
        })

        setData(data)

    }, [dependencies, searchDebounceValue, filterValues]);

    useEffect(() => {
        if (canUpdatePreferences) {
            const ownerIds = filterValues[FilterKeys.owner].map(filter => filter.id);
            const teamIds = filterValues[FilterKeys.team].map(filter => filter.id);
            const statuses = filterValues[FilterKeys.status].map(filter => filter.title);

            const filterKeys: PreferenceValues<FilterKeys> = {};

            if (ownerIds.length) {
                filterKeys[FilterKeys.owner] = ownerIds.join(',');
            }

            if (teamIds.length) {
                filterKeys[FilterKeys.team] = teamIds.join(',');
            }

            if (statuses.length) {
                filterKeys[FilterKeys.status] = statuses.join(',');
            }

            if (searchDebounceValue.length) {
                filterKeys[FilterKeys.query] = searchDebounceValue;
            }

            if (order && orderBy) {
                filterKeys[FilterKeys.order] = order;
                filterKeys[FilterKeys.orderBy] = orderBy;
            }

            dispatch(updatePreferences(filterKeys, PreferencesKeys.dependencyTicket));

            setSearchParams(filterKeys, { replace: true });
        }
    }, [filterValues, searchDebounceValue, order, orderBy]);

    useEffect(() => {

        if (!loading) {
            const ownerIdsString = searchParams.get(FilterKeys.owner);
            if (ownerIdsString) {
                const ownerIds = ownerIdsString.split(',').map(id => parseInt(id));
                setFilterValues({ type: 'update', key: FilterKeys.owner, payload: userOptions.filter(option => ownerIds.includes(option.id)) })
            }

            const teamIdsString = searchParams.get(FilterKeys.team);
            if (teamIdsString) {
                const teamIds = teamIdsString.split(',').map(id => parseInt(id));
                setFilterValues({ type: 'update', key: FilterKeys.team, payload: teamOptions.filter(option => teamIds.includes(option.id)) })
            }

            const statusesString = searchParams.get(FilterKeys.status);
            if (statusesString) {
                const statuses = statusesString.split(',');
                setFilterValues({ type: 'update', key: FilterKeys.status, payload: statusOptions.filter(option => statuses.includes(option.title)) })
            }

            const queryString = searchParams.get(FilterKeys.query);
            if (queryString) {
                setSearchValue(queryString);
            }
        }
    }, [loading])

    const showDeleteConfirmation = (id: number) => {
        setDependencyId(id)
        setOpenDeleteConfirmation(true)
    }

    const onCancelDelete = () => {
        setOpenDeleteConfirmation(false)
    }

    const onDeleteDependency = () => {
        if (dependencyId) {
            dispatch(deleteDependency(dependencyId))
            onCancelDelete()
        }
    }

    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setCanUpdatePreferences(true);
    }

    const onSearchClear = () => {
        setSearchValue('');
        setCanUpdatePreferences(true);
    }

    const onFilterValueChange = (targetName: string, value: FilterOption[]) => {
        setFilterValues({ type: 'update', key: targetName, payload: value });
        setCanUpdatePreferences(true)
    }

    const onFilterReset = (filterName: string) => {
        setFilterValues({ type: 'update', key: filterName, payload: [] });
        setCanUpdatePreferences(true)
    }

    const resetAllFilter = () => {
        setSearchValue('')
        setOrder(undefined);
        setOrderBy(undefined);
        setFilterValues({ type: 'reset' });
        setCanUpdatePreferences(true)
    }

    const onSortChanged = (e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        if (order !== value?.sort || orderBy !== value?.colId) {
            modifiedSearchParamsObject.dependencyTicketGridLayout = gridStatePreferences
            dispatch(updatePreferences(modifiedSearchParamsObject, PreferencesKeys.dependencyTicket));
        }
    }

    const onGridStateChanged = (data: GridStatePreferences) => {
        setGridStatePreferences(data)

        const updatedPreferences: any = {};

        searchParams.forEach((value, key) => {
            updatedPreferences[key] = value;
        });

        updatedPreferences.dependencyTicketGridLayout = data
        dispatch(updatePreferences(updatedPreferences, PreferencesKeys.dependencyTicket));
    }

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    return (
        <Flexbox fullWidth vertical>
            <Flexbox vertical className={classesInfo('headerContainer')}>
                <Flexbox className={classesInfo('headerInfoTop')}>
                    <Flexbox className={classesInfo('headerTitle')}>
                        Dependency tickets
                    </Flexbox>
                    <Flexbox>
                        {gridApi && (
                            <ExportButton
                                api={gridApi}
                                formatExportedCellValue={
                                    (colId: string, value: any, formattedValue: any) => {
                                        if (colId === 'dueDate') {
                                            return value ? new Date(value).toLocaleDateString() : '';
                                        } else if (colId === 'status') {
                                            return getEnumKeyByEnumValue(DependencyStatuses, value)
                                        } else {
                                            return formattedValue
                                        }
                                    }
                                }
                            />
                        )}
                    </Flexbox>
                </Flexbox>
                {dependencies.length > 0 &&
                    <Flexbox className={classesInfo('headerInfo')}>
                        <Flexbox>
                            <SearchField
                                value={searchValue}
                                onChange={onSearchValueChange}
                                onClear={onSearchClear}
                                placeholder='Search Dependency'
                                className={classesInfo('searchInput')}
                            />
                            <FilterButton
                                options={userOptions}
                                value={filterValues[FilterKeys.owner]}
                                onChange={(value) => onFilterValueChange(FilterKeys.owner, value)}
                                onFilterReset={() => onFilterReset(FilterKeys.owner)}
                                multiple
                                label={'Owner'}
                                className={classesInfo('filterButton')}
                                keepFirstOption
                            />
                            <FilterButton
                                options={teamOptions}
                                value={filterValues[FilterKeys.team]}
                                onChange={(value) => onFilterValueChange(FilterKeys.team, value)}
                                onFilterReset={() => onFilterReset(FilterKeys.team)}
                                multiple
                                label={'Team'}
                                className={classesInfo('filterButton')}
                            />
                            <FilterButton
                                options={statusOptions}
                                value={filterValues[FilterKeys.status]}
                                onChange={(value) => onFilterValueChange(FilterKeys.status, value)}
                                onFilterReset={() => onFilterReset(FilterKeys.status)}
                                multiple
                                sortAlphabetically={false}
                                label={'Status'}
                                className={classesInfo('filterButton')}
                            />
                            {Object.values(filterValues).some(v => v.length !== 0) &&
                                <Flexbox className={classesInfo('resetButtonContainer')}>
                                    <Button className={classesInfo('resetButton')} onClick={resetAllFilter}>
                                        Reset
                                    </Button>
                                </Flexbox>
                            }
                        </Flexbox>
                    </Flexbox>
                }
            </Flexbox>
            <Flexbox fullHeight>
                {loading ? (
                    <Flexbox fullWidth fullHeight align justify><Loader disableShrink /></Flexbox>
                ) : dependencies.length > 0 ? (
                    <AgGridTable
                        data={data}
                        columns={columns}
                        getContextMenuItems={params => {
                            if (params.defaultItems) {
                                const rowData = params?.node?.data;
                                const url = `/dependencies/dependency/${rowData.id}`;

                                const linkItems = getLinkedColumnContextMenuItems(params, url, 'id')
                                const menuItems = [
                                    ...params.defaultItems,
                                    ...linkItems,
                                ]

                                return menuItems
                            } else {
                                return []
                            }
                        }}
                        order={order}
                        orderBy={orderBy}
                        onSortChanged={onSortChanged}
                        onGridStateChanged={onGridStateChanged}
                        gridStatePreferences={gridStatePreferences}
                        onGridReady={onGridReady}
                        exportFileName='Dependency tickets'
                    />
                ) : (
                    <EmptyState
                        icon={<EmptyDependencyIcon />}
                        title='There are no Dependency tickets yet'
                    />
                )}
            </Flexbox>

            <ConfirmationDialog
                open={openDeleteConfirmation}
                onClose={onCancelDelete}
                onConfirm={onDeleteDependency}
                confirmButtonStyle='danger'
                title='Delete this Dependency ticket?'
            >
                <Flexbox>
                    You're about to permanently delete this Dependency ticket, and all connected data will be lost.
                </Flexbox>
            </ConfirmationDialog>

        </Flexbox>
    )
}

export default DependenciesList