import { Flexbox, SearchField } from 'components'
import { ChangeEvent, FC, useState } from 'react';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import classNames from 'classnames/bind';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import { GridApi } from 'ag-grid-community';
import { useSearchParams } from 'react-router-dom';
import * as timeago from 'timeago.js';
import AddKnowledgeSourcePopup from 'pages/KnowledgeBase/components/AddKnowledgeSourcePopup';
import { KBTypes } from 'utils/types';
import KnowledgeSourcePopup from 'pages/KnowledgeBase/components/KnowledgeSourcePopup';

const classesInfo = classNames.bind(stylesInfo);

interface KBGoalsHeaderProps {
    gridApi: GridApi<any> | null,
    updateQueryPreference: (value: string) => void,
    searchValue: string,
    emptyState: boolean
}

const KBGoalsHeader: FC<KBGoalsHeaderProps> = ({
    gridApi,
    updateQueryPreference,
    searchValue,
    emptyState
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [openAddSourcePopup, setOpenAddSourcePopup] = useState(false);

    const updateQueryParam = (value: string) => {
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (!!value) {
            modifiedSearchParams.set('query', value)
        } else {
            modifiedSearchParams.delete('query')
        }

        setSearchParams(modifiedSearchParams, { replace: true });
        updateQueryPreference(value);
    }

    const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateQueryParam(e.target.value)
    }

    const handleSearchClear = () => {
        updateQueryParam('')
    }

    const handleOpenAddSourcePopup = () => {
        setOpenAddSourcePopup(true)
    }

    return (
        <Flexbox vertical className={classesInfo('headerContainer')}>
            <Flexbox wrap className={classesInfo('headerInfoTop')}>
                <Flexbox className={classesInfo('headerTitle')}>Goals</Flexbox>
                <Flexbox align wrap className={'gap-2'}>
                    {gridApi && (
                        <ExportButton
                            api={gridApi}
                            formatExportedCellValue={
                                (colId: string, value: any, formattedValue: any) => {
                                    if (colId === 'lastModifiedDate') {
                                        return value ? timeago.format(value) : '';
                                    } if ((colId === 'createdDate')) {
                                        return value ? new Date(value).toLocaleDateString() : '';
                                    } else if (colId === 'datasource') {
                                        return value ? value.source : ''
                                    } else {
                                        return formattedValue
                                    }
                                }
                            }
                        />
                    )}
                    <KnowledgeSourcePopup kbType={KBTypes.GOALS} onOpenAddSourcePopup={handleOpenAddSourcePopup} />
                </Flexbox>
            </Flexbox>

            {!emptyState && (
                <Flexbox className={classesInfo('headerInfo')}>
                    <Flexbox>
                        <SearchField
                            value={searchValue}
                            onChange={handleSearchValueChange}
                            onClear={handleSearchClear}
                            placeholder='Search Goal'
                            className={classesInfo('searchInput')}
                        />
                    </Flexbox>
                </Flexbox>
            )}

            <AddKnowledgeSourcePopup
                open={openAddSourcePopup}
                handleClosePopup={() => setOpenAddSourcePopup(false)}
                description='Goal knowledge will be imported from the link.'
                kbType={KBTypes.GOALS}
            />
        </Flexbox>
    )
}

export default KBGoalsHeader;