import classNames from 'classnames/bind';
import { Button, Stack } from 'components';
import { MentionEditorProps } from 'components/MentionEditor/MentionEditor';
import { FC, Fragment, MouseEventHandler, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uniqueFilter } from 'utils/search';
import { highlightComment, selectComment, useCommentSidebarScroll } from '../../../store/comments-slice';
import { CommentExtended } from '../../../utils/types';
import { CommentActions } from '../CommentActions';
import { CommentAvatar } from '../CommentAvatar';
import { CommentAvatars } from '../CommentAvatars';
import { CommentItem } from '../CommentItem/CommentItem';
import { CommentItemControlled } from '../CommentItem/CommentItemControlled';
import { CommentReplyInput } from '../CommentReplyInput/CommentReplyInput';
import styles from './commentThread.module.scss';
import { useCommentCurrentHilghLight, useCommentDelete, useCommentEdit, useCommentEditorHilghLight, useCommentResolve } from 'store/comments-api';
import { useCurrentUser } from 'store/user';
const classes = classNames.bind(styles);

const AVATAR_SQUASH_THRESHOLD = 5;

interface CommentThreadProps
    extends Pick<MentionEditorProps, 'editorState' | 'onEditorStateChange'> {
    dot?: boolean;
    highlighted?: boolean;
    selected?: boolean;
    source: string;
    collapsed?: boolean;
    comment: CommentExtended;
    onClick?: () => void;
    onReply?: () => void;
    canResolve?: boolean;
}

export const CommentThread: FC<CommentThreadProps> = ({
    dot,
    highlighted,
    selected,
    comment,
    source,
    onClick,
    onReply,
    editorState,
    onEditorStateChange,
    canResolve = false,
}) => {
    const currentUser = useCurrentUser();
    const dispatch = useDispatch();
    const [isHovering, setIsHovering] = useState(false);
    const isCollapsed = !selected;

    const handleMouseEnter = () => {
        handleEditorHilghLight()
        handleCurrentHilghLight()
        setIsHovering(true);
        dispatch(highlightComment({ comment }));
    };

    const handleMouseLeave = () => {
        handleDeleteCurrentHilghLight()
        setIsHovering(false);
        dispatch(highlightComment({ comment: undefined }));
    };

    const handleCollapse = () => {
        dispatch(selectComment({ comment: undefined }));
    };

    const comments = [comment, ...(comment?.replies ?? [])];
    const uniqueUsers = comments.map(c => c.author).filter(uniqueFilter(u => u.id));
    const extraComments = uniqueUsers.length - AVATAR_SQUASH_THRESHOLD;
    const isSquashed = extraComments > 0;

    useCommentSidebarScroll(comment);

    const [firstComment, ...otherComments] = comments;
    const { handleEdit, ...commentEditProps } = useCommentEdit(firstComment);
    const { handleDelete, ...commentDeleteProps } = useCommentDelete(firstComment);
    const { isResolvingComment, handleResolve } = useCommentResolve(firstComment);
    const { handleCurrentHilghLight, handleDeleteCurrentHilghLight } = useCommentCurrentHilghLight(firstComment)
    const { handleEditorHilghLight } = useCommentEditorHilghLight(firstComment)

    const handleEditWithCollapse = () => {
        handleEdit();
        if (selected) {
            return;
        }
        dispatch(selectComment({ comment: firstComment }));
    };

    const handleResolveNoPropagation: MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        handleResolve();
    };

    const canEditOrDelete = currentUser.id === firstComment.author.id;

    const actions = (
        <CommentActions
            hovered={isHovering}
            canDelete={canEditOrDelete}
            onDelete={handleDelete}
            canEdit={canEditOrDelete}
            onEdit={handleEditWithCollapse}
            resolved={comment.resolved}
            onResolve={canResolve ? handleResolveNoPropagation : undefined}
        />
    );

    const isDisabled =
        commentDeleteProps.isDeletingComment ||
        commentEditProps.isUpdatingComment ||
        isResolvingComment;

    return (
        <div data-thread-id={comment.id}>
            <Stack
                className={classes('comment-thread-container', { highlighted, selected })}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={isCollapsed ? onClick : undefined}
            >
                {isCollapsed ? (
                    <Fragment>
                        <Stack
                            width="100%"
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Stack direction="row" spacing={1}>
                                <CommentAvatars
                                    dot={dot}
                                    users={uniqueUsers.slice(0, AVATAR_SQUASH_THRESHOLD)}
                                />
                                {isSquashed && (
                                    <CommentAvatar>{'+' + String(extraComments)}</CommentAvatar>
                                )}
                            </Stack>
                            {actions}
                        </Stack>
                        <Stack className={classes('comment-source-container')} marginTop={1} marginBottom={0.5}>
                            {source}
                        </Stack>
                        <CommentItem comment={comments[0]} />
                        <Stack direction="row" marginTop={1}>
                            {comments.length > 1 && (
                                <Button
                                    className={classes('comment-replies-button')}
                                    onClick={handleCollapse}
                                    size="small"
                                >
                                    {comments.length - 1} replies
                                </Button>
                            )}
                        </Stack>
                    </Fragment>
                ) : (
                    <Stack spacing={2}>
                        <Stack
                            width="100%"
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Stack className={classes('comment-source-container')}>{source}</Stack>
                            {actions}
                        </Stack>
                        <Stack spacing={2.5}>
                            <CommentItemControlled
                                avatar
                                comment={firstComment}
                                {...commentEditProps}
                                {...commentDeleteProps}
                            />
                            {otherComments.map((comment) => (
                                <CommentItem avatar actions key={comment.id} comment={comment} />
                            ))}
                            <CommentReplyInput
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                                selected={selected}
                                author={comment.author}
                                onReply={onReply}
                                disabled={isDisabled}
                            />
                        </Stack>
                        <Button
                            className={classes('comment-show-less-button')}
                            variant="text"
                            onClick={onClick}
                        >
                            Show less
                        </Button>
                    </Stack>
                )}
            </Stack>
        </div>
    );
};
