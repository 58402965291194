import { Dialog, Flexbox, TextArea } from 'components'
import { FC } from 'react'
import classNames from 'classnames/bind';
import styles from '../assistant.module.scss';
import { AMAUserFeedback } from 'utils/types';

const classes = classNames.bind(styles);

interface FeedbackDialogProps {
    pendingStatus: boolean
    handleSubmitFeedback: () => Promise<void>
    feedback: AMAUserFeedback | null
    handleChangeFeedback: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

const FeedbackDialog: FC<FeedbackDialogProps> = ({ pendingStatus, handleSubmitFeedback, feedback, handleChangeFeedback }) => {
    return (
        <Dialog
            open={pendingStatus}
            onClose={handleSubmitFeedback}
            onConfirm={handleSubmitFeedback}
            title='Feedback'
            description='Please provide details: (optional)'
            PaperProps={{
                sx: {
                    width: '450px'
                }
            }}
            confirmButton
            confirmButtonLabel='Send Feedback'
        >
            <Flexbox>
                <TextArea
                    value={feedback?.content}
                    onChange={handleChangeFeedback}
                    placeholder={feedback?.type === 'positive' ? 'What was satisfying about this response?' : 'What can be improved?'}
                    className={classes('textArea')}
                    fullWidth
                    rows={3}
                />
            </Flexbox>
        </Dialog>
    )
}

export default FeedbackDialog