import { Dispatch, GetState } from 'store';
import { ACTION_ADD_PRODUCT, ACTION_DELETE_PRODUCT, ACTION_EDIT_PRODUCT, ACTION_GET_PRODUCTS, ACTION_SET_PRODUCTS } from 'store/products';
import request from 'utils/request';
import { ProductSaveModel } from 'utils/types';

export const getProducts = () => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    dispatch({ type: ACTION_GET_PRODUCTS, payload: null })
    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/products`);

    dispatch({ type: ACTION_SET_PRODUCTS, payload: res.data })

}


export const createProduct = (product: ProductSaveModel) => async (dispatch: Dispatch, getState: GetState) => {
    try{
        const storeState = getState();
        const res = await request.post(`/workspaces/${storeState.user.workspace.id}/products`, product)
        if(res.data) {
            dispatch({ type: ACTION_ADD_PRODUCT, payload: res.data })
        }
        return res.data;
    }
    catch(err: any) {
        console.log(err);
        return { error: err.message }
    }
}

export const editProduct = (id: number, model: ProductSaveModel) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    const res = await request.put(`/workspaces/${storeState.user.workspace.id}/products/${id}`, model)
    dispatch({ type: ACTION_EDIT_PRODUCT, payload: res.data })
    return res.data
}

export const deleteProduct = (id: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    const res = await request.delete(`/workspaces/${storeState.user.workspace.id}/products/${id}`)

    dispatch({ type: ACTION_DELETE_PRODUCT, payload: id })

    return res.data;
}