import { ActionsMenu, ConfirmationDialog, Flexbox, LinearProgress, MultilineInput } from 'components'
import classNames from 'classnames/bind';
import styles from '../okr.module.scss';
import SavingStatus from 'common/savingStatus';
import { defaultTitle, updateOkrBody } from '../Okr';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editOkr, okrOwnerSelector, okrProgressSelector, okrTitleSelector, savingStatusSelector } from 'store/okr-slice';
import { Actions, hasPermission } from 'utils/permissions';
import { useWorkspaceId } from 'utils/hooks';
import { useDeleteOkrMutation, useEditOkrMutation } from 'store/okr-api';
import { useNavigate } from 'react-router-dom';
const classes = classNames.bind(styles);

let timeoutId: NodeJS.Timeout;

interface OkrHeadProps {
  okrId: number,
}

const OkrHead: FC<OkrHeadProps> = ({ okrId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspaceId = useWorkspaceId();

  const title = useSelector(okrTitleSelector)
  const savingStatus = useSelector(savingStatusSelector)
  const okrProgress = useSelector(okrProgressSelector)
  const ownerId = useSelector(okrOwnerSelector)

  const [debouncedTitle, setDebouncedTitle] = useState<null | string>(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [editOkrMutation] = useEditOkrMutation()
  const [deleteOkrMutation] = useDeleteOkrMutation()

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(editOkr({ title: e.target.value }));
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setDebouncedTitle(e.target.value);
    }, 500);
  }

  const showDeleteConfirmation = () => {
    setOpenConfirmation(true)
  }

  const onCancelDelete = () => {
    setOpenConfirmation(false)
  }

  const onDeleteConfirmation = async () => {
    try {
      await deleteOkrMutation({ workspaceId, okrId })
    } catch (error) {
      console.error('Failed to delete okr', error);
    }
    onCancelDelete();
    navigate('/okrs');
  }

  useEffect(() => {
      const saveOkrTitle = async () => {
        try {
          await editOkrMutation({ workspaceId, okrId,  model: updateOkrBody() })
        } catch (error) {
          console.error('Failed to update title', error);
        }
      };

      if (debouncedTitle !== null) {
        saveOkrTitle();
      }

      return () => clearTimeout(timeoutId);
  }, [debouncedTitle]);

  return (
    <Flexbox className={classes('infoContainer')} vertical>
      <Flexbox className={classes('infoTopBox')}>
          <Flexbox fullWidth align className={classes('infoTitleBox')}>
              <MultilineInput
                value={title === defaultTitle ? '' : title}
                onChange={onTitleChange}
                className={classes('titleInput')}
                disabled={!hasPermission(Actions.edit, { owner: { id: ownerId } })}
                placeholder='Create Objective'
                fullWidth
              />
          </Flexbox>
          <Flexbox className={classes('infoRight')}>
              <SavingStatus savingStatus={savingStatus} />
              <Flexbox align className={classes('progressContainer')}>
                  <Flexbox className={classes('progress')}>
                      <LinearProgress value={okrProgress} variant="determinate" />
                  </Flexbox>
                  <Flexbox className={classes('title')}>{Math.round(okrProgress)}% </Flexbox>
              </Flexbox>
              { hasPermission(Actions.delete, { owner: { id: ownerId } }) && (
                <ActionsMenu
                  buttonItems={[{ label: 'Delete', action: showDeleteConfirmation, type: 'red' }]}
                  className={classes('actionMenu')}
                />
              )}
          </Flexbox>
      </Flexbox>
      <ConfirmationDialog
        open={openConfirmation}
        onClose={onCancelDelete}
        onConfirm={onDeleteConfirmation}
        confirmButtonStyle='danger'
        title='Delete this OKR?'
      >
        <Flexbox>
            You're about to permanently delete this OKR, and all own KRs will be deleted too.
        </Flexbox>
      </ConfirmationDialog>
    </Flexbox>
  )
}

export default OkrHead