import { Button, Flexbox, Grid, Loader, Typography } from 'components'
import { FC, useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from '../../../initiative.module.scss';
import InitiativeGradingGroup from './InitiativeGradingGroup';
import { useWorkspaceId } from 'utils/hooks';
import { useParams } from 'react-router-dom';
import { useLazyReEvaluateScoreQuery, useGetAllIrrelevantPointsQuery } from 'store/initiativeGrading-api';
import { useSelector } from 'react-redux';
import { allIrrelevantPointsSelector, initiativeInsightsSelector, initiativeScoreSelector, missingScoresSelector } from 'store/initiativeGrading-slice';
import GradingBox from './GradingBox';
import { InitiativeScoreKey } from 'utils/types';
import { RegenerateIcon } from 'components/icons';

const classes = classNames.bind(styles);

interface InitiativeGradingProps {

}

const InitiativeGrading: FC<InitiativeGradingProps> = () => {
    const workspaceId = useWorkspaceId();
    const params = useParams();

    const { isLoading: isIrrelevantPointsLoading } = useGetAllIrrelevantPointsQuery({ workspaceId, id: params.id });
    const [reEvaluateScore, { isLoading: isReEvaluateScoreLoading }] = useLazyReEvaluateScoreQuery()

    const initiativeScore = useSelector(initiativeScoreSelector)
    const allIrrelevantPoints = useSelector(allIrrelevantPointsSelector);
    const aiInsights = useSelector(initiativeInsightsSelector);
    const missingScores = useSelector(missingScoresSelector);

    const missingScoresArray = useMemo(() => Object.entries(missingScores).map(([title, score]) => ({
        title: InitiativeScoreKey[title as keyof typeof InitiativeScoreKey],
        score: Number(score),
    })), [missingScores]);

    const handleReEvaluateScore = async () => {
        try {
            await reEvaluateScore({ workspaceId, id: params.id })
        } catch (err) {
            console.log(err, 'err');
        }
    }

    return (
        <Flexbox vertical className={classes('grading')}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4} sx={{
                    marginLeft: 'auto'
                }}>
                    <Flexbox className={classes('titleContainer')}>
                        <Typography className={classes('gradingTitle')}>Overall PRD Score:</Typography>
                        <Typography className={classes('gradingValue')}>{initiativeScore} %</Typography>
                    </Flexbox>
                </Grid>

                <Grid item xs={4}>
                    <Flexbox justifyEnd align>
                        {isReEvaluateScoreLoading && <Loader size={22} />}
                        <Button
                            variant='outlined'
                            onClick={handleReEvaluateScore}
                            disabled={isReEvaluateScoreLoading}
                            className={classes('reEvaluateBtn')}
                        >
                            Re-evaluate PRD Score
                            <RegenerateIcon />
                        </Button>
                    </Flexbox>
                </Grid>
            </Grid>

            {
                !!missingScoresArray.length && missingScoresArray?.map((point, index) => (
                    <GradingBox
                        {...point}
                        key={`${point.title}_${index}`}
                    />
                ))
            }

            {!!aiInsights?.length && (
                <InitiativeGradingGroup
                    data={aiInsights}
                    groupTitle='AI Feedback'
                    groupKey='aiFeedback'
                />
            )}

            {
                isIrrelevantPointsLoading ?
                    <Flexbox fullWidth fullHeight align justify><Loader disableShrink /></Flexbox>
                    : !!allIrrelevantPoints?.length && (
                        <InitiativeGradingGroup
                            data={allIrrelevantPoints}
                            groupTitle='Non-Relevant Feedbacks'
                            groupKey='nonRelevantFeedback'
                        />
                    )
            }
        </Flexbox>
    )
}

export default InitiativeGrading;