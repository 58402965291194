import request from 'utils/request';
import { Dispatch, GetState } from 'store';

export const deleteFile = (blockId: number, baseUrl: string) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()

    const url = `/workspaces/${storeState.user.workspace.id}/${baseUrl}/${blockId}`.replaceAll('//', '/')
    const res = await request.delete(url);

    return res.data
}
