import { GridApi } from 'ag-grid-enterprise';
import classNames from 'classnames/bind';
import styles from 'common/infoHeader/infoHeader.module.scss';
import SavingStatus from 'common/savingStatus';
import { Flexbox, SearchField } from 'components';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import { ChangeEvent, FC } from 'react'
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { metricsSavingStatusSelector } from 'store/metrics-slice';

const classes = classNames.bind(styles);

interface MetricsHeaderProps {
    gridApi: GridApi<any> | null,
    updateQueryPreference: (value: string) => void,
    searchValue: string
}

const MetricsHeader: FC<MetricsHeaderProps> = ({ gridApi, searchValue, updateQueryPreference }) => {
    const savingStatus = useSelector(metricsSavingStatusSelector);

    const [searchParams, setSearchParams] = useSearchParams();

    const updateQueryParam = (value: string) => {
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if(!!value) {
            modifiedSearchParams.set('query', value)
        } else {
            modifiedSearchParams.delete('query')
        }

        setSearchParams(modifiedSearchParams, { replace: true });
        updateQueryPreference(value);
    }


    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateQueryParam(e.target.value);
    }

    const onSearchClear = () => {
        updateQueryParam('');
    }

    return (
        <Flexbox vertical className={classes('headerContainer')}>
        <Flexbox className={classes('headerInfoTop')}>
            <Flexbox className={classes('headerTitle')}>Metrics</Flexbox>
            <Flexbox>
                { gridApi && <ExportButton api={gridApi}/> }
                <SavingStatus savingStatus={savingStatus} />
            </Flexbox>
        </Flexbox>
        <Flexbox className={classes('headerInfo')}>
            <SearchField
                value={searchValue}
                onChange={onSearchValueChange}
                onClear={onSearchClear}
                placeholder='Search Metric'
            />
        </Flexbox>
    </Flexbox>
    )
}

export default MetricsHeader;