import Flexbox from 'components/Flexbox';
import { Initiative } from 'utils/types';
import classNames from 'classnames/bind';
import styles from './roadMap.module.scss';
import { LinearProgress, Link, Tooltip } from 'components';
import InitiativeCard from './components/initiativeCard';
import EmptyCard from './components/emptyCard';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import EmptyState from 'common/emptyState';
import { EmptyRoadmapIcon } from 'components/icons';
import { useNavigate } from 'react-router-dom';
import { Actions, hasPermission } from 'utils/permissions';
const classes = classNames.bind(styles);
const cardHeight = parseInt(styles.roadMapCardHeight)
const cardGap = parseInt(styles.roadMapCardGap)

export interface RoadMapRangeRelease {
    title: string;
    startDate: Date;
    endDate: Date;
    containsNow?: boolean;
    count?: number;
}
export interface RoadMapRangeKanban {
    title: string;
    status: string;
    containsNow?: boolean;
    count?: number;
}

export type RoadMapRange = RoadMapRangeRelease | RoadMapRangeKanban;

export function isKanbanRange(range: RoadMapRange): range is RoadMapRangeKanban {
    return (range as RoadMapRangeKanban).status !== undefined;
}

export interface RoadMapGroupItem {
    id: number;
    title: string;
    maxCount: number;
    url?: string;
    progress?: number;
}

export interface RoadMapGroup {
    item: RoadMapGroupItem;
    columns: {
        [key: string]: Initiative[]
    };
}

export interface GroupedRoadMap {
    mainColumnTitle: string;
    secondColumnTitle: string;
    groups: RoadMapGroup[]
}

interface RoadMapProps {
    roadMap: GroupedRoadMap;
    ranges: RoadMapRange[];
    isKanbanVew?: boolean;
    allInitiatives: Initiative[]
}

export default ({ roadMap, ranges, isKanbanVew = false, allInitiatives }: RoadMapProps) => {

    const [style, setStyle] = useState<React.CSSProperties | undefined>();
    const containerRef = useRef<HTMLDivElement>(null)

    const navigate = useNavigate();

    useEffect(() => {
        if(containerRef.current && allInitiatives.length) {

            setStyle({
                height: `calc(100vh - ${containerRef.current.offsetTop}px - 30px)`
            })
        }

    }, [containerRef])

    const createNewInitiative = () => {
        navigate('/initiatives/initiative');
    }

    const buttonItemsEmptyState = useMemo(() => {
        const buttons = []

        if(hasPermission(Actions.create)){
            buttons.push({
                onClick: createNewInitiative,
                text: 'Create initiative',
            },)
        }

        return buttons
    },[])

    return (
        <Flexbox className={classes('roadMapContainer', { roadMapContainerEmptyState: allInitiatives.length === 0 })} vertical ref={containerRef} style={style}>
            <Flexbox className={classes('titlesContainer')}>
                <Flexbox className={classes('mainColumnTitle')}>{roadMap.mainColumnTitle}</Flexbox>
                <Flexbox className={classes('secondColumnTitle')} vertical>
                    <Flexbox className={classes('header')}>{roadMap.secondColumnTitle}</Flexbox>
                    {allInitiatives.length > 0 &&
                    <Flexbox className={classes('rangesContainer')}>
                        {ranges.map((range, rIdx) => {
                            return (
                                <Flexbox className={classes('rangeTitle')} key={`${range.title}-${rIdx}`}>
                                    <Flexbox className={classes({ now: range.containsNow })}>
                                        {range.title} ({range.count})
                                    </Flexbox>
                                </Flexbox>
                            )
                        })}
                    </Flexbox>
                    }
                </Flexbox>
            </Flexbox>
            {allInitiatives.length > 0 ?
                <Flexbox className={classes('dataContainer')} vertical>
                    {
                        roadMap.groups.map(group => {
                            const item = group.item;
                            const mainCardHeight = (item.maxCount * cardHeight) + ((item.maxCount - 1) * cardGap)
                            return (
                                <Flexbox key={item.id} className={classes('row')}>
                                    <Flexbox className={classes('mainColumnContainer')}>
                                        <div className={classes('itemContainer', { unassignedInitiative: item.id === -1 })} style={{ height: `${mainCardHeight}px` }}>
                                            <div className={classes('itemContent')} style={{ width: `${mainCardHeight}px` }}>

                                                <div className={classes('title')}>{
                                                    item.url ? <Link to={`${item.url}/${item.id}`}><Tooltip placement="top" title={item.title}><Flexbox className={classes('text')}>{item.title}</Flexbox></Tooltip></Link>
                                                        : <Tooltip placement="top" title={item.title}><Flexbox className={classes('text')}>{item.title}</Flexbox></Tooltip>
                                                }</div>

                                                <div className={classes('progressContainer')}>{item.progress !== undefined ? (
                                                    <Flexbox align>
                                                        <Flexbox className={classes('progress')} fullWidth>
                                                            <LinearProgress value={item.progress} variant="determinate" />
                                                        </Flexbox>
                                                        <Flexbox className={classes('progressTitle')}>{Math.round(item.progress)}% </Flexbox>
                                                    </Flexbox>) : ''}</div>
                                            </div>
                                        </div>
                                    </Flexbox>
                                    <Flexbox className={classes('cardsContainer')}>
                                        {
                                            ranges.map((range, rIdx) => {
                                                return (
                                                    <Flexbox key={`${item.id}-${rIdx}`} className={classes('subColumnContainer')} vertical fullWidth align>
                                                        {
                                                            Array.from({ length: group.item.maxCount }, (_, i) => {
                                                                const key = `${range.title}_${i}`;
                                                                if(group.columns[range.title] && group.columns[range.title][i]) {
                                                                    const initiative = group.columns[range.title][i]
                                                                    return (
                                                                        <InitiativeCard key={key} initiative={initiative} isKanbanVew={isKanbanVew} groupItemId={group.item.id}/>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <EmptyCard key={key} />
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </Flexbox>
                                                )
                                            })
                                        }
                                    </Flexbox>
                                </Flexbox>
                            )
                        })
                    }
                </Flexbox>
                :
                <EmptyState
                    className={classes('roadMap')}
                    icon={<EmptyRoadmapIcon />}
                    title='There are no Initiatives yet'
                    titleSmall={buttonItemsEmptyState.length ? 'Press the button and start to' : undefined}
                    buttonItems={buttonItemsEmptyState}
                />

            }
        </Flexbox>
    )
}