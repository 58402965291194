import MTooltip, { TooltipProps } from '@mui/material/Tooltip';
import './tooltip.scss';
const Tooltip = (props: TooltipProps) => {
    return (
        <MTooltip
            classes={{
                popper: 'tooltipContainer',
                tooltip: 'tooltip'
            }}
            {...props}
        />
    )
}

export default Tooltip;