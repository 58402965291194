import { RootState } from './index';
import { Product } from 'utils/types';

interface ProductsReducerState {
    products: Product[];
    loading: boolean;
}

export const ACTION_GET_PRODUCTS = 'getProducts';
export const ACTION_SET_PRODUCTS = 'setProducts';
export const ACTION_DELETE_PRODUCT = 'deleteProduct';
export const ACTION_ADD_PRODUCT = 'addProduct';
export const ACTION_EDIT_PRODUCT = 'editProduct';

const initialState: ProductsReducerState = {
    loading: false,
    products: [],
}


const products = (state: ProductsReducerState = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {
    case ACTION_GET_PRODUCTS:
        return { ...state, products: [], loading: true }
    case ACTION_SET_PRODUCTS:
        return { ...state, loading: false, products: [...action.payload] }

    case ACTION_DELETE_PRODUCT:
        return { ...state, products: state.products.filter(product => product.id !== action.payload) }

    case ACTION_ADD_PRODUCT:
        return { ...state, products: [...state.products, action.payload] }
    case ACTION_EDIT_PRODUCT:
        const productIndex = state.products.findIndex(product => product.id === action.payload.id);
        if(productIndex >= 0) {
            state.products.splice(productIndex, 1, action.payload);
        }
        return { ...state, ideas: [...state.products] }

    default:
        return state
    }
}
export default products;

export const productsSelector = (store: RootState) => store.products.products as Product[];
export const productsLoadingSelector = (store: RootState) => store.products.loading;