import { Dialog, Flexbox, Input, RichTextEditor, Select, TextArea } from 'components'
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productsSelector } from 'store/products';
import { Idea, IdeaSaveModel, InitiativePriority, Product } from 'utils/types';

import classNames from 'classnames/bind';
import styles from './editDialog.module.scss';
import { createIdea, editIdea } from 'pages/Ideas/ideas.api';
const classes = classNames.bind(styles);



interface EditDialogProps{
    isOpen: boolean;
    onClose: () => void;
    idea?: Idea | null;
}
export default ({ isOpen, onClose, idea }: EditDialogProps) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [priority, setPriority] = useState(InitiativePriority.Medium);
    const [selectedProduct, setSelectedProduct] = useState<Product>();

    const dispatch = useDispatch();

    const products = useSelector(productsSelector);

    const initiativePriorityEntries = useMemo(() => {return Object.keys(InitiativePriority);}, [])

    useEffect(() => {
        if(idea) {
            setTitle(idea.title);
            setDescription(idea.description);
            setPriority(idea.priority);
            setSelectedProduct(idea.product);
        }
    }, [idea]);

    useEffect(() => {
        if(!isOpen) {
            setTitle('');
            setDescription('');
            setPriority(InitiativePriority.Medium);
            setSelectedProduct(undefined);
        }
    }, [isOpen])

    const onPriorityChange = (_e: ChangeEvent<{}>, value: string) => {
        if(value) {
            setPriority(InitiativePriority[value as keyof typeof InitiativePriority]);
        }
    }

    const onProductChange = (_e: ChangeEvent<{}>, value: Product | null) => {
        if(value) {
            setSelectedProduct(value)
        }
    }

    const onCreateIdea = async () => {
        if(selectedProduct) {

            const ideaModel: IdeaSaveModel = {
                title,
                description,
                productId: selectedProduct?.id,
                priority
            }

            if(idea) {
                await dispatch(editIdea(idea.id, ideaModel))
            } else {
                await dispatch(createIdea(ideaModel));
            }

            onClose();
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            title={idea ? 'Edit Idea' : 'New Idea'}
            confirmButton
            confirmButtonLabel={idea ? 'Save' : 'Create'}
            onConfirm={onCreateIdea}
        >
            <Flexbox vertical justify className={classes('container')}>
                <Flexbox className={classes('row')}>
                    <TextArea placeholder='Idea' label='Idea' fullWidth  value={title} onChange={(e) => {setTitle(e.target.value)}}/>
                </Flexbox>

                <Flexbox className={classes('row')}>
                    <RichTextEditor placeholder='Description' label='Description' value={description} onChange={value => {setDescription(value)}} />
                </Flexbox>
                <Flexbox className={classes('row')}>
                    <Select
                        onChange={onPriorityChange}
                        value={initiativePriorityEntries.find(entry => entry === priority)}
                        options={initiativePriorityEntries}
                        disableClearable
                        getOptionLabel={option => InitiativePriority[option as keyof typeof InitiativePriority]}
                        label='Priority'
                    />
                </Flexbox>
                <Flexbox className={classes('row')}>
                    <Select
                        onChange={onProductChange}
                        value={selectedProduct}
                        key={selectedProduct?.id}
                        disableClearable
                        options={products}
                        getOptionLabel={option => option.title}
                        label='Product'
                        placeholder='Product'
                    />
                </Flexbox>
            </Flexbox>
        </Dialog>
    )
}