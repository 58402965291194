import request from 'utils/request';
import { Dispatch, GetState } from 'store';

import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from 'utils/localStorage';

import store from 'store';
import { ACTION_LOGIN, ACTION_LOGOUT } from 'store/user';
import { AxiosResponse } from 'axios';

export const getCompanies = async () => {

    request.get('/companies').then(res => {
        console.log(res);
    }).catch(err => {
        console.log(err);
    })
}

export const logout = async () => {
    request.post('users/signout', {
        'accessToken': getFromLocalStorage('accessToken')
    }).then(res => {
        console.log(res);
    }).catch(err => {
        console.log(err);
    }).finally(() => {
        store.dispatch({ type: ACTION_LOGOUT, payload: null })
        removeFromLocalStorage('idToken');
        removeFromLocalStorage('accessToken');
        removeFromLocalStorage('refreshToken');
        location.replace('/login')
    })
}

let refreshTokenPromise: Promise<AxiosResponse<any, any>> | null = null

export const refreshToken = async () => {

    if (!refreshTokenPromise) {
        refreshTokenPromise = request.post('users/refresh-token', {
            refreshToken: getFromLocalStorage('refreshToken'),
            idToken: getFromLocalStorage('idToken')
        })
    }

    const res = await refreshTokenPromise;
    refreshTokenPromise = null;
    const token = res.data.idToken;
    const accessToken = res.data.accessToken;
    setToLocalStorage('idToken', token);
    setToLocalStorage('accessToken', accessToken);

    await store.dispatch({ type: ACTION_LOGIN, payload: { token, accessToken } });
    return res;
}

export const sendJiraCode = async (code: string) => {
    request.post('/integrations', {
        name: 'JIRA',
        code,
    }).then(res => {
        console.log(res);

    }).catch(res => {
        console.log('error');

        console.log(res);

    })
}