import React from 'react';
import classNames from 'classnames/bind';

import styles from './errorMessage.module.scss';
const classes = classNames.bind(styles);

const ErrorMessage = ({ className, ...props }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>) => {
    return (
        <p className={classes('errorMessage', className)} {...props}/>
    )
}

export default ErrorMessage