import { getJiraStatuses } from 'common/jira/index.api';
import { ActionsMenu, Flexbox, Select, Table } from 'components'
import { TableHeader } from 'components/Table';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDateTypes, getPriorityTypes, getStringTypes, getTeamTypes, getUserTypes } from 'utils/jiraFunctions';
import { JiraMapping, JiraIssueType, JiraField, InitiativePriority, JiraFieldMapping, JiraFieldAllowedValue, JiraMappingObjectTypes, Initiative, Story, JiraStatus, InitiativeStatus } from 'utils/types';


interface FieldMappingsHeader {
    field: string;
    arrow: string;
    jiraField: string;
    actions: string;
}

const fieldMappingsHeader: TableHeader<FieldMappingsHeader>[] = [
    {
        id: 'field',
        text: 'Field',
        sortable: false,
    },

    {
        id: 'arrow',
        text: '',
    },

    {
        id: 'jiraField',
        text: 'Jira Field',
    },

    {
        id: 'actions',
        text: '',
        width: '30px'
    }
];

interface FieldMappingProps {
    mapping?: JiraMapping;
    issueType: JiraIssueType;
    object: JiraMappingObjectTypes;
    onMappingField: (fieldMapping: JiraFieldMapping) => void;
    onUnmapingField: (prodmapField: string) => void;
    projectId: string;
}

interface JiraMappingField<T> {
    id: keyof T;
    name: string;
    filter: (jiraFields: JiraField[]) => JiraField[];
    values?: {
        id: string;
        name: string;
    }[]
}

const initiativeFields: JiraMappingField<Initiative>[]  = [
    {
        id: 'title',
        name: 'Title',
        filter: getStringTypes,
    },
    {
        id: 'priority',
        name: 'Priority',
        filter: getPriorityTypes,
        values: Object.entries(InitiativePriority).map(entry => ({ id: entry[0], name: entry[1] }))
    },
    {
        id: 'problem',
        name: 'Problem',
        filter: getStringTypes,
    },
    {
        id: 'validation',
        name: 'Validation',
        filter: getStringTypes,
    },
    {
        id: 'solution',
        name: 'Solution',
        filter: getStringTypes,
    },
    {
        id: 'owner',
        name: 'Owner',
        filter: getUserTypes,
    },
    {
        id: 'startDate',
        name: 'Start Date',
        filter: getDateTypes,
    },
    {
        id: 'endDate',
        name: 'End Date',
        filter: getDateTypes,
    },
    {
        id: 'releaseDate',
        name: 'Live Date',
        filter: getDateTypes,
    },
    {
        id: 'teams',
        name: 'Teams',
        filter: getTeamTypes,
    }
];

const storyFields: JiraMappingField<Story>[] = [
    {
        id: 'title',
        name: 'Title',
        filter: getStringTypes,
    },
    {
        id: 'description',
        name: 'Description',
        filter: getStringTypes,
    },
]

export default ({ mapping, issueType, object, onMappingField, onUnmapingField, projectId }: FieldMappingProps) => {

    const [fields, setFields] = useState<JiraField[]>([]);
    const [allFields, setAllFields] = useState<(JiraMappingField<Initiative> | JiraMappingField<Story>)[]>([]);
    const [jiraStatuses, setJiraStatuses] = useState<JiraFieldAllowedValue[]>([]);

    const dispatch = useDispatch();

    useEffect(() => {

        if(object === JiraMappingObjectTypes.Initiative) {
            setAllFields(initiativeFields);
        } else {

            setAllFields(storyFields)
        }
    }, [object])


    useEffect(() => {
        const load = async () => {
            const allJiraStatuses = (await dispatch(getJiraStatuses())) as unknown as JiraStatus[];

            const jiraStatuses: JiraStatus[] = [];

            allJiraStatuses.forEach(status => {
                if(status.usages.some(usage => {return usage.project.id === projectId && usage.issueTypes.includes(issueType.id + '')})) {
                    jiraStatuses.push(status)
                }
            })

            // allJiraStatuses.filter(status => status.scope.project.id === projectId)
            if(mapping) {

                const map = mapping[object === JiraMappingObjectTypes.Initiative ? 'initiativeMapping' : 'requirementMapping'];
                if(map && !map.fields.some(m => m.prodmapField === 'status')) {
                    onMappingField({
                        prodmapField: 'status',
                        jiraField: 'status',
                    })
                }
            }

            setAllFields(prevValue => [...prevValue, {
                id: 'status',
                name: 'Status',
                filter: () => [],
                values: Object.entries(InitiativeStatus).map(entry => ({ id: entry[1], name: entry[0] }))
                // values: jiraStatuses.map(jiraStatus => ({ id: jiraStatus.id, name: jiraStatus.name }))
            }]);
            setJiraStatuses(jiraStatuses as unknown as JiraFieldAllowedValue[]);
        }
        if(object === JiraMappingObjectTypes.Initiative) {
            load();
        }
    }, [object]);

    useEffect(() => {
        setFields(Object.values(issueType.fields))
    }, [issueType])

    const onFieldValueChange = (field: JiraFieldMapping, prodmapValueField: {
        id: string;
        name: string;
    }, allowedValue?: JiraFieldAllowedValue | null) => {
        if(!field.values) {
            field.values = [];
        }
        field.values = field.values.filter(v => v.prodmapValue !== prodmapValueField.id);

        if(allowedValue) {

            field.values.push({
                prodmapValue: prodmapValueField.id,
                jiraValue: allowedValue.id
            })
        }

        onMappingField({ ...field })
    }

    const fieldValueRenderer = (data: any, index: number) => {
        const prodmapField = allFields[index];
        const map = mapping && mapping[object === JiraMappingObjectTypes.Initiative ? 'initiativeMapping' : 'requirementMapping'];
        if(prodmapField.values && map) {
            const field = map.fields.find(m => m.prodmapField === prodmapField.id);
            if(field) {
                let allowedValues: JiraFieldAllowedValue[] | undefined | null;
                if(prodmapField.id === 'status') {
                    allowedValues = jiraStatuses;
                } else {
                    const jiraField = fields.find(f => f.key === field.jiraField);
                    allowedValues = jiraField?.allowedValues;
                }

                if(allowedValues) {
                    return (
                        <Table
                            header={fieldMappingsHeader}
                            nested
                            stickyHeader={false}
                            data={
                                prodmapField.values.map(value => {
                                    let selectValue: JiraFieldAllowedValue | undefined = undefined
                                    if(allowedValues) {
                                        const allowedValueId = field.values?.find(v => v.prodmapValue === value.id)?.jiraValue;

                                        selectValue = allowedValues.find(allowedValue => allowedValue.id === allowedValueId);
                                    }
                                    return {
                                        data: [
                                            <Flexbox>{value.name}</Flexbox>,
                                            <Flexbox>-&gt;</Flexbox>,
                                            <Flexbox>
                                                <Select
                                                    value={selectValue}
                                                    key={selectValue?.id}
                                                    options={allowedValues || []}
                                                    onChange={(e, allowedValue) => {
                                                        onFieldValueChange(field, value, allowedValue)
                                                    }}
                                                    getOptionLabel={value => value.name}
                                                    placeholder='Select Jira Field'
                                                />
                                            </Flexbox>,
                                            <Flexbox>
                                                <ActionsMenu
                                                    buttonItems={[{ label: 'Unmap', action: () => {onFieldValueChange(field, value)} }]}
                                                />
                                            </Flexbox>
                                        ]
                                    }})
                            }
                        />
                    )
                }
            }

        }
    }

    return (
        <Table
            header={fieldMappingsHeader}
            collapsible
            data={allFields.map(prodmapField => {
                const map = mapping && mapping[object === JiraMappingObjectTypes.Initiative ? 'initiativeMapping' : 'requirementMapping'];
                let selectedValue: JiraField | undefined = undefined

                if(map) {
                    const field = map.fields.find(f => f.prodmapField === prodmapField.id);

                    if(field) {
                        selectedValue = fields.find(f => f.key === field.jiraField);
                    }
                }

                return {
                    collapsible: !!prodmapField.values,
                    openRenderer: fieldValueRenderer,
                    data: [
                        <Flexbox>{prodmapField.name}</Flexbox>,
                        <Flexbox>-&gt;</Flexbox>,
                        <Flexbox>
                            {prodmapField.id === 'status' ? <Flexbox>Status</Flexbox> : <Select
                                key={selectedValue?.key}
                                value={selectedValue}
                                options={prodmapField.filter(fields)}
                                onChange={(e, issueType) => {
                                    if (issueType) {
                                        onMappingField({ prodmapField: prodmapField.id, jiraField: issueType.key })
                                    } else {
                                        onUnmapingField(prodmapField.id)
                                    }
                                }}
                                getOptionLabel={issueType => issueType.name}
                                placeholder='Select Jira Field'
                            />}
                        </Flexbox>,
                        <Flexbox>
                            <ActionsMenu
                                buttonItems={[{ label: 'Unmap', action: () => {onUnmapingField(prodmapField.id)} }]}
                            />
                        </Flexbox>
                    ]
                }
            })}
        />
    )
}

