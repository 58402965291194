import { Dispatch, GetState } from 'store';
import { ACTION_DELETE_OKR, ACTION_GET_OKRS, ACTION_SET_OKRS } from 'store/okrs';
import { calculateOkrProgress } from 'utils';
import request from 'utils/request';
import { OKR, OKRSaveModel } from 'utils/types';


export const getOkrs = () => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    dispatch({ type: ACTION_GET_OKRS, payload: null })
    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/okrs`);

    const data = res.data as OKR[]

    data.forEach(okr => {
        okr.allKrProgress = calculateOkrProgress(okr.keyResults?.map(el => el.progress))
    })

    dispatch({ type: ACTION_SET_OKRS, payload: data })

}

export const createOkr = (model: OKRSaveModel) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    const res = await request.post(`/workspaces/${storeState.user.workspace.id}/okrs`, model);

    return res.data as OKR

}

export const editOkr = (id: number, model: OKRSaveModel) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    const res = await request.put(`/workspaces/${storeState.user.workspace.id}/okrs/${id}`, model);

}

export const deleteOkr = (id: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    const res = await request.delete(`/workspaces/${storeState.user.workspace.id}/okrs/${id}`,);
    dispatch({ type: ACTION_DELETE_OKR, payload: id });
    return res;
}

export const getOkrById = (id: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/okrs/${id}`);

    return res.data as OKR;
}

export const deleteKeyResult = (okrId: number, id: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    await request.delete(`/workspaces/${storeState.user.workspace.id}/okrs/${okrId}/key-results/${id}`);
}