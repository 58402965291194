import { Flexbox, Button } from 'components'
import { FC } from 'react';
import classNames from 'classnames/bind';
import styles from 'common/infoHeader/infoHeader.module.scss';
import { RegenerateIcon } from 'components/icons';
import { useGenerateReportMutation } from 'store/report-api';
import { useSelector } from 'react-redux';
import { currentReportTitleSelector } from 'store/report-slice';
import { useWorkspaceId } from 'utils/hooks';

const classes = classNames.bind(styles);

interface ReportHeaderProps {
    reportId: number
}

const ReportHeader: FC<ReportHeaderProps> = ({ reportId }) => {
    const workspaceId = useWorkspaceId();

    const title = useSelector(currentReportTitleSelector);

    const [generateReport, { isLoading }] = useGenerateReportMutation();

    const handleReportRegenerate = async () => {
        await generateReport({ workspaceId, reportId })
    }

    return (
        <Flexbox vertical className={classes('headerContainer')}>
            <Flexbox className={classes('headerInfoTop')}>
                <Flexbox className={classes('headerTitle')}>
                    {title}
                </Flexbox>
                <Flexbox>
                    <Button
                        className={classes('createButton')}
                        variant='contained'
                        onClick={handleReportRegenerate}
                        disabled={isLoading}
                    >
                        Regenerate
                        <RegenerateIcon />
                    </Button>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    )
}

export default ReportHeader;