import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const okrApi = createApi({
    reducerPath: 'okr.api',
    baseQuery: rtkBaseQueryWithReauth,
    tagTypes: ['okr'],
    endpoints: builder => ({
      // ______________Okrs_________________
      getOkrById: builder.query({
        query: ({ workspaceId, okrId }) => `/workspaces/${workspaceId}/okrs/${okrId}`,
      }),
      createOkr: builder.mutation({
          query: ({ workspaceId, model }) => {
            return {
              url: `/workspaces/${workspaceId}/okrs`,
              method: 'POST',
              body: model,
            };
          },
      }),
      editOkr: builder.mutation({
          query: ({ workspaceId, okrId, model }) => {
            return {
              url: `/workspaces/${workspaceId}/okrs/${okrId}`,
              method: 'PUT',
              body: model
            };
          },
      }),
      deleteOkr: builder.mutation({
          query: ({ workspaceId, okrId }) => {
            return {
              url: `/workspaces/${workspaceId}/okrs/${okrId}`,
              method: 'DELETE',
            };
          },
      }),
      // __________________Krs________________
      createKeyResult: builder.mutation({
          query: ({ workspaceId, okrId, model }) => {
            return {
              url: `/workspaces/${workspaceId}/okrs/${okrId}/key-results`,
              method: 'POST',
              body: model,
            };
          },
      }),
      deleteKeyResult: builder.mutation({
          query: ({ workspaceId, okrId, krId }) => {
            return {
              url: `/workspaces/${workspaceId}/okrs/${okrId}/key-results/${krId}`,
              method: 'DELETE',
            };
          },
      }),
      editKeyResult: builder.mutation({
          query: ({ workspaceId, okrId, krId, model }) => {
            return {
              url: `/workspaces/${workspaceId}/okrs/${okrId}/key-results/${krId}`,
              method: 'PUT',
              body: model
            };
          },
      }),
    })
});

export const {
    useCreateKeyResultMutation,
    useCreateOkrMutation,
    useDeleteKeyResultMutation,
    useDeleteOkrMutation,
    useEditKeyResultMutation,
    useEditOkrMutation,
    useGetOkrByIdQuery,
    useLazyGetOkrByIdQuery,
} = okrApi