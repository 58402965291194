import { RiskLevel } from 'utils/types';

export const riskLevel: {
    [key: string]: {
        label: string;
        color: string;
    }
} = {
    medium: { label: RiskLevel.Medium, color: 'mediumOrange' },
    high: { label: RiskLevel.High, color: 'darkRed' },
    critical: { label: RiskLevel.Critical, color: 'darkestRed' }
}

export const gradingValues = [
    { grade: 'A', percentage: { min: 93, max: 100 }, color: 'mediumGreen', contrastColor: 'secondaryColor' },
    { grade: 'A-', percentage: { min: 90, max: 92 }, color: 'darkGreen', contrastColor: 'secondaryColor' },
    { grade: 'B+', percentage: { min: 87, max: 89 }, color: 'lightYellow', contrastColor: 'secondaryColor' },
    { grade: 'B', percentage: { min: 83, max: 86 }, color: 'mediumYellow', contrastColor: 'secondaryColor' },
    { grade: 'B-', percentage: { min: 80, max: 82 }, color: 'darkYellow', contrastColor: 'secondaryColor' },
    { grade: 'C+', percentage: { min: 77, max: 79 }, color: 'lightOrange', contrastColor: 'secondaryColor' },
    { grade: 'C', percentage: { min: 73, max: 76 }, color: 'mediumOrange', contrastColor: 'secondaryColor' },
    { grade: 'C-', percentage: { min: 70, max: 72 }, color: 'darkOrange', contrastColor: 'secondaryColor' },
    { grade: 'D+', percentage: { min: 67, max: 69 }, color: 'lightRed', contrastColor: 'secondaryColor' },
    { grade: 'D', percentage: { min: 63, max: 66 }, color: 'mediumRed', contrastColor: 'white' },
    { grade: 'D-', percentage: { min: 60, max: 62 }, color: 'darkRed', contrastColor: 'white' },
    { grade: 'F', percentage: { min: 0, max: 59 }, color: 'darkestRed', contrastColor: 'white' },
]

export const scoresConfig = [
    { percentage: { min: 76, max: 100 }, color: 'scoreGreen', contrastColor: 'secondaryColor' },
    { percentage: { min: 51, max: 75 }, color: 'lightYellow', contrastColor: 'secondaryColor' },
    { percentage: { min: 26, max: 50 }, color: 'lightRed', contrastColor: 'secondaryColor' },
    { percentage: { min: 0, max: 25 }, color: 'scoreRed', contrastColor: 'white' },
]

export const feedbackPercentage = [
    { label: RiskLevel.Low, bgColor: 'bgLightRed', percentage: [0, 9] },
    { label: RiskLevel.Medium, bgColor: 'bgMediumRed', percentage: [10, 19] },
    { label: RiskLevel.High, bgColor: 'bgDarkRed', percentage: [20, 100] },
]