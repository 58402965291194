
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store';
import { getUserLatestSession, getUserSession, getUserSessions } from 'pages/Assistant/assistant.api';
import { AIAnswerTypes, ChatMessage, AMAReferenceTypes, Message, AMAReference } from 'utils/types';

interface AssistantState {
    currentSession: {
        session_id: string | null,
        next_page_token: string | null,
        messages: Message[]
    },
    sessions: {
        sessions: {
            session_id: string,
            latest_interaction_timestamp: string
            title: string
        }[],
        next_page_token: string
    },
    latestSessionLoading: boolean,
    latestSessionError?: string
    currentSessionLoading: boolean,
    currentSessionError?: string
    sessionsLoading: boolean,
    sessionsError?: string
}

const initialState: AssistantState = {
    currentSession: {
        session_id: null,
        next_page_token: null,
        messages: []
    },
    sessions: { sessions: [], next_page_token: '' },
    latestSessionLoading: false,
    currentSessionLoading: false,
    sessionsLoading: false,
}

export function getRelevantReferences(references: AMAReference[]) {
    if (Array.isArray(references)) {
        return references.map(ref => {
            if (ref.type === AMAReferenceTypes.KNOWLEDGE) {
                return (
                    {
                        ...ref,
                        items: Array.isArray(ref.items) ? ref.items.filter(item => item.distance_score <= 0.55) : []
                    }
                )
            } else {
                return ref
            }
        }).filter(ref => ref.type === AMAReferenceTypes.WEBPAGE || !!ref.items.length)
    } else {
        return []
    }
}

const assistantSlice = createSlice({
    name: 'assistant',
    initialState,
    reducers: {
        setSessionId: (state, action: PayloadAction<string>) => {
            state.currentSession.session_id = action.payload;
        },
        updateMessages: (state, action: PayloadAction<Message[]>) => {
            const previousMessages = state.currentSession.messages;
            // To avoid PRD/MOCKUP preview duplication, we need to replace the previous one with new generated
            if ((action.payload[0]?.from === 'assistant' &&
                previousMessages[0]?.from === 'assistant') &&
                (
                    (action.payload[0].text.type === AIAnswerTypes.PRD_PREVIEW &&
                        previousMessages[0].text.type === AIAnswerTypes.PRD_PREVIEW_STREAM) ||
                    (action.payload[0].text.type === AIAnswerTypes.MOCKUP &&
                        previousMessages[0].text.type === AIAnswerTypes.MOCKUP)
                )
            ) {
                state.currentSession.messages = [...action.payload, ...previousMessages.slice(1)]
            } else {
                state.currentSession.messages = [...action.payload, ...previousMessages];
            }
        },
        replaceMessages: (state, action: PayloadAction<Message[]>) => {
            state.currentSession.messages = action.payload;
        },
        setNextPageToken: (state, action: PayloadAction<string | null>) => {
            state.currentSession.next_page_token = action.payload;
        },
        deleteSession: (state, action: PayloadAction<string | null>) => {
            state.sessions = {
                ...state.sessions,
                sessions: state.sessions.sessions.filter(session => session.session_id !== action.payload)
            }
        },
        renameChat: (state, action: PayloadAction<{ id: string, title: string }>) => {
            state.sessions = {
                ...state.sessions,
                sessions: state.sessions.sessions.map(session => {
                    if (session.session_id === action.payload.id) {
                        return {
                            ...session,
                            title: action.payload.title
                        }
                    } else {
                        return session
                    }
                })
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserLatestSession.pending, (state) => {
                state.latestSessionLoading = true;
            })
            .addCase(getUserLatestSession.fulfilled, (state, action) => {
                state.latestSessionLoading = false;
                state.currentSession = {
                    session_id: action.payload.session_id,
                    next_page_token: action.payload.next_page_token,
                    messages: action.payload.messages.map((message: ChatMessage) => ({
                        id: message.id,
                        text: message.message.text,
                        from: message.from,
                        feedback: message.message.feedback,
                        references: getRelevantReferences(message.references)
                    }))
                };
            })
            .addCase(getUserLatestSession.rejected, (state, action) => {
                state.latestSessionLoading = false;
                state.latestSessionError = action.error.message;
            })
            .addCase(getUserSession.pending, (state) => {
                state.currentSessionLoading = true;
            })
            .addCase(getUserSession.fulfilled, (state, action) => {
                state.currentSessionLoading = false;
                state.currentSession = {
                    session_id: action.payload.session_id,
                    next_page_token: action.payload.next_page_token,
                    messages: action.payload.messages.map((message: ChatMessage) => ({
                        id: message.id,
                        text: message.message.text,
                        from: message.from,
                        feedback: message.message.feedback,
                        references: getRelevantReferences(message.references)
                    }))
                };
            })
            .addCase(getUserSession.rejected, (state, action) => {
                state.currentSessionLoading = false;
                state.currentSessionError = action.error.message;
            })
            .addCase(getUserSessions.pending, (state) => {
                state.sessionsLoading = true;
            })
            .addCase(getUserSessions.fulfilled, (state, action) => {
                state.sessionsLoading = false;
                state.sessions = action.payload;
            })
            .addCase(getUserSessions.rejected, (state, action) => {
                state.sessionsLoading = false;
                state.sessionsError = action.error.message;
            });
    },
})

export const sessionIdSelector = (store: RootState) => store.assistant.currentSession.session_id;
export const messagesSelector = (store: RootState) => store.assistant.currentSession.messages;
export const latestSessionLoadingSelector = (store: RootState) => store.assistant.latestSessionLoading;
export const latestSessionErrorSelector = (store: RootState) => store.assistant.latestSessionError;
export const currentSessionSelector = (store: RootState) => store.assistant.currentSession;
export const currentSessionLoadingSelector = (store: RootState) => store.assistant.currentSessionLoading;
export const currentSessionErrorSelector = (store: RootState) => store.assistant.currentSessionError;
export const sessionsSelector = (store: RootState) => store.assistant.sessions.sessions;
export const sessionsLoadingSelector = (store: RootState) => store.assistant.sessionsLoading;
export const sessionsErrorSelector = (store: RootState) => store.assistant.sessionsError;

export const {
    setSessionId,
    updateMessages,
    setNextPageToken,
    deleteSession,
    renameChat,
    replaceMessages
} = assistantSlice.actions;

export default assistantSlice;
