import { useSelector } from 'react-redux';
import ReportMainContent from './ReportMainContent';
import { currentReportDataSelector, currentReportTypeSelector } from 'store/report-slice';
import { ReportData, ReportOKR, ReportVariantTypes } from 'utils/types';
import { FC } from 'react';

interface ReportTabsContentProps {
    selectedTab: number
}

const ReportTabsContent: FC<ReportTabsContentProps> = ({ selectedTab }) => {
    const currentReportData = useSelector(currentReportDataSelector) as {
        teams: ReportData[],
        okrs: ReportData[],
    };
    const type = useSelector(currentReportTypeSelector) as ReportVariantTypes;

    return (
        <>
            {currentReportData[`${type}s`].map((current, index) => {
                let okrs: ReportOKR[] = [];

                if(type === ReportVariantTypes['By Okr']){
                    const { keyName: objective, initiatives, ...byOkrData } = current;
                    const data: any = { ...byOkrData, objective }
                    okrs.push(data)
                } else {
                    okrs = current.okrs.map(el => ({ ...el }))
                }

                return (
                    <ReportMainContent
                        isActive={selectedTab === index + 1}
                        report={current}
                        key={index}
                        okrs={okrs}
                    />
                )
            })}
        </>
    )
}

export default ReportTabsContent;