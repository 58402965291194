import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw'
import { FC } from 'react';

interface MarkdownProps {
    text: string
}

const Markdown: FC<MarkdownProps> = ({ text }) => {
    return (
        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
            {typeof text === 'string' ? text : ''}
        </ReactMarkdown>
    )
}

export default Markdown