import { JiraField } from './types'
import store from 'store';


export const getStringTypes = (fields: JiraField[]) => {
    return fields.filter(field => field.schema.type === 'string')
}

export const getPriorityTypes = (fields: JiraField[]) => {
    return fields.filter(field => field.schema.type === 'priority')
}

export const getDateTypes = (fields: JiraField[]) => {
    return fields.filter(field => field.schema.type === 'datetime' || field.schema.type === 'date')
}

export const getUserTypes = (fields: JiraField[]) => {
    return fields.filter(field => field.schema.type === 'user')
}

export const getTeamTypes = (fields: JiraField[]) => {
    return fields.filter(field => field.schema.type === 'team')
}

export const getJiraUrl = (jiraId: string) => {
    const workspaceIntegrations = store.getState().integrations.workspaceIntegrations;

    const integration = workspaceIntegrations.find(integration => integration.name === 'JIRA');
    if (integration) {
        const baseUrl = integration.integrationProperties.url;
        return `${baseUrl}/browse/${jiraId}`;
    }

    return '#'
}