const Planet = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_763_11319)">
                <path opacity="0.2" d="M10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875Z" fill="#303030" />
                <path d="M10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875Z" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.3884 4.7077C16.557 4.11759 18.1811 4.17011 18.6602 5C19.5232 6.49466 16.3454 9.94491 11.5625 12.7063C6.77953 15.4678 2.20265 16.4947 1.3397 15C0.86065 14.1703 1.62685 12.7379 3.22163 11.1552" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_763_11319">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Planet