import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { CheckIcon } from 'components/icons';
import { Flexbox } from 'components';
const classes = classNames.bind(styles);


interface SavingStatusProps {
    savingStatus: SaveStatus;
}

export enum SaveStatus {
    UNSET,
    SAVING,
    SAVED,
}

const SavingStatus = ({ savingStatus }: SavingStatusProps) => {

    return (
        <Flexbox align className={classes('container')}>
            {savingStatus === SaveStatus.SAVING && <Flexbox className={classes('saving')}>Saving...</Flexbox>}
            {savingStatus === SaveStatus.SAVED && <Flexbox className={classes('saved')} align>Saved <CheckIcon className={classes('savedIcon')} /></Flexbox>}
        </Flexbox>
    )
}

export default SavingStatus