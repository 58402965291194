import Select, { SelectProps } from '@mui/material/Select/Select';
import { Flexbox } from 'components';
import { SyntheticEvent, useState } from 'react';
import './selectStandard.scss'


const SelectStandard: (<T>(props: SelectProps<T>) => JSX.Element) = ({ className, fullWidth, onOpen, onClose,  ...props }) => {

    const [containerClass, setContainerClass] = useState('')

    const close = (e: SyntheticEvent) => {
        if(onClose){
            onClose(e)
        }
        setContainerClass('closeFocus')
    }

    const open = (e: SyntheticEvent) => {
        if(onOpen){
            onOpen(e)
        }
        setContainerClass('')
    }

    return (
        <Flexbox vertical fullWidth={fullWidth} className={`selectStandard ${containerClass}`}>

            <Select
                classes={{
                    outlined: 'outlined',
                    select: 'select',
                    iconOpen: 'iconOpen',
                }}
                className={`selectBase ${className || ''}`}
                onOpen={open}
                onClose={close}
                displayEmpty
                {...props}
            />

        </Flexbox>
    )
}

export type { SelectChangeEvent } from  '@mui/material/Select/Select';

export default SelectStandard