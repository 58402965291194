import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const metricsApi = createApi({
    reducerPath: 'metrics.api',
    baseQuery: rtkBaseQueryWithReauth,
    tagTypes: ['metrics'],
    endpoints: builder => ({
        getMetrics: builder.query({
            query: ({ workspaceId }) => `/workspaces/${workspaceId}/metrics`,
        }),
        createMetric: builder.mutation({
            query: ({ workspaceId, data }) => {
              return {
                url: `/workspaces/${workspaceId}/metrics`,
                method: 'POST',
                body: data,
              };
            },
        }),
        editMetric: builder.mutation({
            query: ({ workspaceId, metricId, data }) => {
                return {
                    url: `/workspaces/${workspaceId}/metrics/${metricId}`,
                    method: 'PUT',
                    body: data
                };
            },
        }),
        deleteMetric: builder.mutation({
            query: ({ workspaceId, metricId }) => {
              return {
                url: `/workspaces/${workspaceId}/metrics/${metricId}`,
                method: 'DELETE',
              };
            },
        }),
        pushMetricData: builder.mutation({
            query: ({ workspaceId, metricId, value }) => {
              return {
                url: `/workspaces/${workspaceId}/metrics/${metricId}/data`,
                method: 'POST',
                body: { timestamp: Date.now(), value },
              };
            },
        }),
    })
});

export const {
    useCreateMetricMutation,
    useDeleteMetricMutation,
    useEditMetricMutation,
    useGetMetricsQuery,
    useLazyGetMetricsQuery,
    usePushMetricDataMutation,
} = metricsApi;