import { Flexbox } from 'components';

import classNames from 'classnames/bind';
import styles from './switchFilter.module.scss';
const classes = classNames.bind(styles);

export interface SwitchOption {
    id: number;
    title: string;
}

interface SwitchProps {
    options: SwitchOption[];
    value: SwitchOption;
    onChange: (value: SwitchOption) => void;
}

export default ({ options, value, onChange }: SwitchProps) => {

    return (
        <Flexbox className={classes('switchContainer')}>
            {options.map(option => (
                <Flexbox
                    key={option.id}
                    className={classes('switchItem', { selected: value.id === option.id })}
                    onClick={() => onChange(option)}
                >
                    {option.title}
                </Flexbox>
            ))}
        </Flexbox>
    )
}