import React from 'react';
const JiraIcon = React.forwardRef<HTMLDivElement, React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>((props, ref) => {
    return (
        <div {...props} ref={ref}>
            <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2876_8044)">
                    <path d="M21.7307 11.3362L11.8257 1.13149L10.8659 0.14267L3.40962 7.82452L4.12237e-05 11.3362C-0.170265 11.5126 -0.265869 11.7512 -0.265869 12C-0.265869 12.2488 -0.170265 12.4874 4.12237e-05 12.6638L6.812 19.6818L10.8659 23.8573L18.3211 16.1755L18.4372 16.0569L21.7307 12.6691C21.8161 12.5818 21.8838 12.478 21.93 12.3636C21.9762 12.2492 22 12.1265 22 12.0026C22 11.8787 21.9762 11.7561 21.93 11.6417C21.8838 11.5273 21.8161 11.4235 21.7307 11.3362V11.3362ZM10.8659 15.5064L7.46247 12L10.8659 8.49362L14.2683 12L10.8659 15.5064Z" fill="#2684FF"/>
                    <path d="M10.8657 8.49363C9.79536 7.39113 9.19196 5.89703 9.18734 4.33769C9.18272 2.77834 9.77725 1.28048 10.8411 0.171265L3.39404 7.84041L7.4469 12.0159L10.8657 8.49363Z" fill="url(#paint0_linear_2876_8044)"/>
                    <path d="M14.2776 11.9905L10.866 15.5064C11.9404 16.6136 12.544 18.1152 12.544 19.6808C12.544 21.2464 11.9404 22.748 10.866 23.8552L18.3335 16.166L14.2776 11.9905Z" fill="url(#paint1_linear_2876_8044)"/>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_2876_8044" x1="10.2564" y1="4.94596" x2="5.36212" y2="9.69544" gradientUnits="userSpaceOnUse">
                        <stop offset="0.18" stopColor="#0052CC"/>
                        <stop offset="1" stopColor="#2684FF"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_2876_8044" x1="11.5154" y1="19.0117" x2="16.4001" y2="14.2715" gradientUnits="userSpaceOnUse">
                        <stop offset="0.18" stopColor="#0052CC"/>
                        <stop offset="1" stopColor="#2684FF"/>
                    </linearGradient>
                    <clipPath id="clip0_2876_8044">
                        <rect width="22" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </div>

    )
})

export default JiraIcon;