export const emailValidation = (email: string) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email)
}

export const passwordValidation  = (password: string) => {
    const isValid =
  password.length >= 8 &&
  /[0-9]/.test(password) &&
  /[^a-zA-Z0-9\s]/.test(password) &&
  /[A-Z]/.test(password) &&
  /[a-z]/.test(password);

    return isValid;
}

export const urlValidation = (str: string) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

