import { Button, DatePicker, Flexbox } from 'components'
import { ArrowExpendedIcon } from 'components/icons'
import { ReactDatePickerProps } from 'react-datepicker';

import styles from './filterCalendar.module.scss';
const classes = classNames.bind(styles);
import classNames from 'classnames/bind';

export interface FilterCalendarProps extends ReactDatePickerProps<string, boolean>{
    label: string;
}

export default ({ label, ...props }: FilterCalendarProps) => {

    const { startDate, endDate } = props

    return (
        <Flexbox>

            <DatePicker

                customInput={
                    <Button
                        variant="contained"
                        endIcon={<ArrowExpendedIcon />}
                        className={classes('button', { active: startDate && endDate })}
                    >
                        {label}

                    </Button>
                }
                {...props}
            />
        </Flexbox>
    )
}
