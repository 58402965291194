import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { EditorState } from 'draft-js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    createCustomEvent,
    getMentionsFromContentState,
    getMessageFromContentState,
    scrollToThreadElement,
    scrollToToolbar,
} from 'utils/comments';
import { useDebounce } from 'utils/hooks';
import {
    CommentEventHighlightDetail,
    CommentEventSelectDetail,
    CommentExtended,
    CommentFieldKey,
    CommentFieldOptions,
    CommentObjectOptions,
} from 'utils/types';
import { CommentFilterOption } from '../components/Comments/CommentFilter';
import {
    GetCommentsOptions,
    useCreateCommentMutation,
    useGetCommentsFiltered,
    useGetCommentsQuery,
} from './comments-api';
import { RootState } from './index';
import { userSelector } from './user';
import { COMMENT_FILTER_KEY } from 'utils/constants';
import { useSearchParams } from 'react-router-dom';

export type CommentMode = 'off' | 'view' | 'new' | 'reply';

export interface CommentsState {
    searchValue: string;
    commentsFilter: CommentFilterOption;
    selectedComment?: CommentExtended;
    highlightedComment?: CommentExtended;

    scrollSidebarToComment?: CommentExtended;
    scrollPageToComment?: CommentExtended;
    scrollToNewComment: boolean;

    mode: CommentMode;
    creationOptions?: CommentStartCreationPayload;
    options?: CommentObjectOptions;
    currentCommentHighLighting?: boolean
}

const initialState: CommentsState = {
    searchValue: '',
    commentsFilter:
        (window.localStorage.getItem(COMMENT_FILTER_KEY) as CommentFilterOption) || 'open',
    scrollToNewComment: false,
    mode: 'off',
    currentCommentHighLighting: false
};

export interface CommentStartCreationPayload extends CommentFieldOptions {
    temporaryId: string;
    title?: string;
}

interface CommentSelectPayload {
    comment: CommentExtended | undefined;
    scrollPage?: boolean;
}

interface CommentHighlightPayload {
    comment: CommentExtended | undefined;
    scroll?: boolean;
}

export const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        selectComment: (state, action: PayloadAction<CommentSelectPayload>) => {
            if (action.payload && action.payload.comment?.id !== state.selectedComment?.id) {
                state.mode = 'reply';
                state.selectedComment = action.payload.comment;
            } else {
                state.mode = 'view';
                state.selectedComment = undefined;
            }
            if (action.payload.scrollPage) {
                state.scrollPageToComment = action.payload.comment;
            }
        },
        highlightComment: (state, action: PayloadAction<CommentHighlightPayload>) => {
            state.highlightedComment = action.payload.comment;
            if (action.payload.scroll) {
                state.scrollSidebarToComment = action.payload.comment;
                state.mode = 'view';
            }
        },
        setSearchValue: (state, action: PayloadAction<string>) => {
            state.searchValue = action.payload;
        },
        startCommentCreation: (state, action: PayloadAction<CommentStartCreationPayload>) => {
            state.creationOptions = action.payload;
            state.selectedComment = undefined;
            state.scrollToNewComment = true;
            state.mode = 'new';
        },
        stopCommentCreation: state => {
            if (state.mode !== 'reply') {
                state.creationOptions = undefined;
                state.selectedComment = undefined;
                if (state.mode !== 'off') {
                    state.mode = 'view';
                }
            }
        },
        setCommentsMode: (state, action: PayloadAction<CommentMode>) => {
            state.mode = action.payload;
        },
        setCommentsFilter: (state, action: PayloadAction<CommentFilterOption>) => {
            state.commentsFilter = action.payload;
            window.localStorage.setItem(COMMENT_FILTER_KEY, action.payload);
        },
        resetSidebarCommentToScroll: (
            state,
            action: PayloadAction<CommentExtended | undefined>
        ) => {
            state.scrollSidebarToComment = action.payload;
        },
        resetPageCommentToScroll: (state, action: PayloadAction<CommentExtended | undefined>) => {
            state.scrollPageToComment = action.payload;
        },
        selectFieldToolbar: (state, action: PayloadAction<{ comment: CommentExtended }>) => {
            state.scrollSidebarToComment = action.payload.comment;
            state.highlightedComment = action.payload.comment;
            state.mode = 'view';
        },
        openComment: (state, action: PayloadAction<CommentExtended>) => {
            state.scrollPageToComment = action.payload;
            state.scrollSidebarToComment = action.payload;
            state.highlightedComment = action.payload;
            state.mode = 'view';
        },
        initComments: (state, action: PayloadAction<CommentObjectOptions>) => {
            state.options = action.payload;
        },
        scrollToThread: (state, action: PayloadAction<CommentExtended>) => {
            state.scrollPageToComment = action.payload;
        },
        scrollToNewComment: (state, action: PayloadAction<boolean | undefined>) => {
            state.mode = 'new';
            state.scrollToNewComment = action?.payload ?? true;
        },
    },
    selectors: {
        modeSelector: state => state.mode,
        selectedCommentSelector: state => state.selectedComment,
        highlightedCommentSelector: state => state.highlightedComment,
        optionsSelector: state => state.options,
        creationOptionsSelector: state => state.creationOptions,
        searchValueSelector: state => state.searchValue,
        commentsFilterSelector: state => state.commentsFilter,
    },
});

export const {
    highlightComment,
    selectComment,
    startCommentCreation,
    stopCommentCreation,
    setSearchValue,
    setCommentsMode,
    setCommentsFilter,
    selectFieldToolbar,
    openComment,
    resetSidebarCommentToScroll,
    resetPageCommentToScroll,
    initComments,
    scrollToThread,
    scrollToNewComment,
} = commentsSlice.actions;
export const {
    modeSelector,
    selectedCommentSelector,
    highlightedCommentSelector,
    optionsSelector,
    creationOptionsSelector,
    searchValueSelector,
    commentsFilterSelector,
} = commentsSlice.selectors;
export default commentsSlice.reducer;

export const useCommentFilter = () => {
    const dispatch = useDispatch();
    const filter = useSelector(({ comments }: RootState) => comments.commentsFilter);
    const setFilter = (filter: CommentFilterOption) => {
        dispatch(setCommentsFilter(filter));
    };
    return [filter, setFilter] as const;
};

export const useCommentSearch = () => {
    const dispatch = useDispatch();
    const searchValue = useSelector((state: RootState) => state.comments.searchValue);
    const [value, debounceValue, setValue] = useDebounce<string>(searchValue);
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
    useEffect(() => {
        dispatch(setSearchValue(debounceValue));
    }, [debounceValue]);
    return [value, onChange] as const;
};

export const useHighlightedComment = () => {
    return useSelector((state: RootState) => state.comments.highlightedComment);
};

export const useIsCommentsActive = () => {
    const mode = useSelector((state: RootState) => state.comments.mode);
    return mode !== 'off';
};

export const useSelectedComment = () => {
    return useSelector((state: RootState) => state.comments.selectedComment);
};

export const useIsCreatingComment = (options: CommentFieldOptions) => {
    return useSelector(
        (state: RootState) =>
            state.comments.mode === 'new' &&
            state.comments.creationOptions !== undefined &&
            state.comments.creationOptions.objectId === options.objectId &&
            state.comments.creationOptions.objectKey === options.objectKey &&
            state.comments.creationOptions.fieldKey === options.fieldKey
    );
};

export const useIsCreatingCommentForField = (fieldKey: CommentFieldKey) => {
    return useSelector(
        (state: RootState) =>
            state.comments.creationOptions?.fieldKey === fieldKey && state.comments.mode === 'new'
    );
};

interface UseCommentsOptions extends Omit<GetCommentsOptions, 'objectId'> {
    objectId?: number;
    onCommentSelect?: (comment: CommentExtended) => void;
}

export const useComments = ({
    workspaceId,
    objectKey,
    objectId,
    onCommentSelect,
}: UseCommentsOptions) => {
    const [newCommentEditorState, setEditorStateNewComment] = useState<EditorState>(() =>
        EditorState.createEmpty()
    );
    const currentUser = useSelector(userSelector);
    const dispatch = useDispatch();
    const [createComment, { isLoading: isCreatingComment }] = useCreateCommentMutation();
    const isCommentsOn = useSelector(modeSelector) !== 'off';
    const isCreatingNewComment = useSelector(modeSelector) === 'new';
    const selectedComment = useSelector(selectedCommentSelector);
    const highlightedComment = useSelector(highlightedCommentSelector);
    const options = useSelector(optionsSelector);
    const searchValue = useSelector(searchValueSelector);
    const commentsFilter = useSelector(commentsFilterSelector);
    const creationOptions = useSelector(creationOptionsSelector);

    if (!options && objectId) {
        dispatch(initComments({ workspaceId, objectKey, objectId }));
    }

    const clearNewCommentEditorState = () => {
        setEditorStateNewComment(() => EditorState.createEmpty());
    };

    const { comments, isLoading: isLoadingComments } = useGetCommentsFiltered(
        {
            workspaceId,
            objectKey,
            objectId: objectId!,
        },
        currentUser,
        commentsFilter,
        searchValue,
        Boolean(objectId)
    );

    useCommentSearchParam({ workspaceId, objectKey, objectId: objectId! });

    const toggleComments = () => {
        if (isCommentsOn) {
            dispatch(setCommentsMode('off'));
            setTimeout(() => handleCommentCreationCancel(), 10)
        } else {
            dispatch(setCommentsMode('view'));
        }
    };

    const startCommentCreation = () => {
        clearNewCommentEditorState();
        dispatch(scrollToNewComment());
    };

    const executeCommentCreation = async () => {
        let commentFieldOptions: CommentFieldOptions | null = null;
        if (creationOptions) {
            commentFieldOptions = {
                workspaceId,
                objectKey: creationOptions.objectKey,
                objectId: creationOptions.objectId,
                fieldKey: creationOptions.fieldKey,
            };
        } else if (selectedComment) {
            commentFieldOptions = {
                workspaceId,
                objectKey: selectedComment.objectKey,
                objectId: selectedComment.objectId,
                fieldKey: selectedComment.fieldKey,
            };
        } else {
            return;
        }

        const content = newCommentEditorState.getCurrentContent();
        const message = getMessageFromContentState(content);

        try {
            if (!newCommentEditorState.getCurrentContent().hasText()) {
                return;
            }

            dispatch(stopCommentCreation());
            clearNewCommentEditorState();

            // TODO: validation
            const newComment = await createComment({
                ...commentFieldOptions,
                message,
                mentions: getMentionsFromContentState(content).map(m => m.id),
                seen: true,
                resolved: false,
                parentId: selectedComment?.id,
            }).unwrap();

            if (creationOptions?.temporaryId) {
                document.dispatchEvent(
                    createCustomEvent('comment-create-success', {
                        detail: {
                            temporaryId: creationOptions.temporaryId,
                            commentId: newComment.id,
                        },
                    })
                );
            }
        } catch (e) {
            console.error('Failed to create comment');
            throw e;
        }
    };

    const handleCommentCreationCancel = () => {
        if (creationOptions?.temporaryId) {
            document.dispatchEvent(
                createCustomEvent('comment-create-cancel', {
                    detail: {
                        temporaryId: creationOptions.temporaryId,
                    },
                })
            );
            dispatch(stopCommentCreation());
            clearNewCommentEditorState();
        }
    };

    const commentSelectHandler = (comment: CommentExtended) => () => {
        clearNewCommentEditorState();
        dispatch(selectComment({ comment, scrollPage: true }));
        onCommentSelect?.(comment);
    };

    useEffect(() => {
        document.addEventListener('comment-create-start', clearNewCommentEditorState);

        const commentHighlightHandler = (e: CustomEvent<CommentEventHighlightDetail>) => {
            const highlightedCommentId = e.detail.commentId;
            if (!highlightedCommentId) {
                dispatch(highlightComment({ comment: undefined }));
                return;
            }
            const highlightedComment = comments?.find(comment => comment.id === e.detail.commentId);
            dispatch(highlightComment({ comment: highlightedComment, scroll: e.detail.scroll }));
        };
        document.addEventListener('comment-highlight', commentHighlightHandler);

        const commentSelectHandler = (e: CustomEvent<CommentEventSelectDetail>) => {
            const selectedCommentId = e.detail.commentId;
            if (!selectedCommentId) {
                dispatch(selectComment({ comment: undefined }));
                return;
            }
            const selectedComment = comments?.find(comment => comment.id === selectedCommentId);
            dispatch(selectComment({ comment: selectedComment }));
            if (selectedComment) {
                dispatch(scrollToThread(selectedComment));
            }
        };
        document.addEventListener('comment-select', commentSelectHandler);

        const commentHighlightClearHandler = () => {
            dispatch(highlightComment({ comment: undefined }));
        };
        document.addEventListener('comment-highlight-clear', commentHighlightClearHandler);
        return () => {
            document.removeEventListener('comment-create-start', clearNewCommentEditorState);
            document.removeEventListener('comment-select', commentSelectHandler);
            document.removeEventListener('comment-highlight', commentHighlightHandler);
            document.removeEventListener('comment-highlight-clear', commentHighlightClearHandler);
        };
    }, [comments]);

    const editorNewCommentProps = {
        editorState: newCommentEditorState,
        onEditorStateChange: setEditorStateNewComment,
    };

    return {
        editorNewCommentProps,
        comments,
        isCreatingComment,
        isLoadingComments,
        toggleComments,
        startCommentCreation,
        cancelCommentCreation: handleCommentCreationCancel,
        commentHighlightHandler: commentSelectHandler,
        executeCommentCreation,
        isCommentsOn,
        isCreatingNewComment,
        selectedComment,
        highlightedComment,
    };
};

export const useCommentSidebarScroll = (comment: CommentExtended | undefined) => {
    const dispatch = useDispatch();
    const commentToScroll = useSelector(
        (state: RootState) => state.comments.scrollSidebarToComment
    );

    useEffect(() => {
        if (commentToScroll) {
            scrollToThreadElement(commentToScroll);
            dispatch(resetSidebarCommentToScroll());
        }
    }, [commentToScroll]);
};

export const useCommentPageScroll = (
    comments: CommentExtended[] | undefined,
    shouldScroll: (comment: CommentExtended) => boolean
) => {
    const dispatch = useDispatch();
    const commentToScroll = useSelector((state: RootState) => state.comments.scrollPageToComment);

    useEffect(() => {
        if (commentToScroll && shouldScroll(commentToScroll) && comments) {
            scrollToToolbar(commentToScroll);
            dispatch(resetPageCommentToScroll());
        }
    }, [commentToScroll, comments]);
};

export const useCommentSearchParam = (options: GetCommentsOptions) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const hasCommentId = searchParams.has('commentId');

    const { comment } = useGetCommentsQuery(options, {
        skip: !hasCommentId,
        selectFromResult: result => {
            const commentId = Number(searchParams.get('commentId'));
            let comment = result.data?.find(c => c.id === commentId);
            // we want to scroll to the thread which has the id of the parent comment
            if (comment && comment.parentId) {
                comment = result.data?.find(c => c.id === comment!.parentId);
            }
            return {
                ...result,
                comment,
            };
        },
    });

    if (hasCommentId && comment) {
        dispatch(openComment(comment));
        setSearchParams(
            prev => {
                prev.delete('commentId');
                return prev;
            },
            { replace: true }
        );
    }
};

export const useShouldScrollToNewComment = () =>
    useSelector((state: RootState) => state.comments.scrollToNewComment);
