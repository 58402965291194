import { FC, useEffect, useRef } from 'react';
import { Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import classNames from 'classnames/bind';
import { IconButton, Loader, Stack } from 'components';
import MentionEditor, { MentionEditorProps } from 'components/MentionEditor/MentionEditor';
import { SendIcon } from 'components/icons/Send';
import { useGetWorkspaceMentions } from 'store/comments-api';
import { User } from 'utils/types';
import { CommentHeader } from '../CommentHeader/CommentHeader';
import styles from './commentNew.module.scss';
import { scrollToNewComment, useShouldScrollToNewComment } from 'store/comments-slice';
import { useDispatch } from 'react-redux';
const classes = classNames.bind(styles);

interface CommentNewProps extends Pick<MentionEditorProps, 'editorState' | 'onEditorStateChange'> {
    author: User;
    value?: string;
    sending?: boolean;
    disabled?: boolean;
    onChange?: (value: string) => void;
    onClose?: () => void;
    onSend?: () => void;
}

export const CommentNew: FC<CommentNewProps> = ({
    author,
    sending,
    disabled,
    onClose,
    onSend,
    editorState,
    onEditorStateChange,
}) => {
    const dispatch = useDispatch();
    const containerRef = useRef<HTMLDivElement>(null);
    const shouldScrollToNewComment = useShouldScrollToNewComment();
    const { mentions = [] } = useGetWorkspaceMentions();

    useEffect(() => {
        if (shouldScrollToNewComment && containerRef.current) {
            /** https://github.com/facebook/react/issues/23396#issuecomment-1376887787 */
            const timeoutId = setTimeout(() => {
                containerRef.current?.scrollIntoView();
                dispatch(scrollToNewComment(false));
            }, 0);
            return () => clearTimeout(timeoutId);
        }
    }, [shouldScrollToNewComment, containerRef.current]);

    return (
        <div ref={containerRef} className={classes('comment-new')} id="comment-new">
            <Stack direction="row" justifyContent="space-between">
                <CommentHeader avatar>{author.fullName}</CommentHeader>
                <IconButton disableRipple size="small" onClick={onClose}>
                    <Close />
                </IconButton>
            </Stack>
            <div className={classes('input-container')}>
                <MentionEditor
                    autoFocus
                    disabled={disabled}
                    mentions={mentions}
                    placeholder="Add a comment"
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    onEnter={onSend}
                />
                <div className={classes('footer-container')}>
                    <Stack direction="row" spacing={1} alignItems="center" />
                    <Stack direction="row" spacing={1} alignItems="center">
                        {sending ? (
                            <Box padding={0.5}>
                                <Loader size={14} />
                            </Box>
                        ) : (
                            <IconButton
                                disableRipple
                                size="small"
                                disabled={disabled}
                                onClick={onSend}
                            >
                                <SendIcon />
                            </IconButton>
                        )}
                    </Stack>
                </div>
            </div>
        </div>
    );
};
