import LinearProgress, { LinearProgressProps as MLinearProgressProps } from '@mui/material/LinearProgress';
import Flexbox from 'components/Flexbox';

// import classNames from 'classnames/bind';
// import styles from './linearProgress.module.scss';

// const classes = classNames.bind(styles);

import './linearProgress.scss';


interface LinearProgressProps extends MLinearProgressProps {
    className?: string;
}

export default ({ className, ...props } : LinearProgressProps) => {
    return (
        <Flexbox className={`linearProgressContainer ${className || ''}`}>
            <LinearProgress
                classes={{
                    root: 'root',
                    bar: 'bar'
                }}
                {...props}
            />
        </Flexbox>
    )
}