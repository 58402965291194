import Viewer from 'react-viewer';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import './index.scss'

export interface ImageViewerModel extends ImageDecorator {};

interface ImageViewerProps{
    imageViewerVisible: boolean;
    onClose: () => void;
    viewerImages: ImageViewerModel[];
    imageActiveIndex: number;
    closeOnClickAway?: boolean;
}


const ImageViewer = ({ imageViewerVisible, onClose, viewerImages, imageActiveIndex, closeOnClickAway = true }: ImageViewerProps) => {
    const onMaskClick = () => {
        if(closeOnClickAway) {
            onClose();
        }
    }
    return(
        <Viewer
            visible={imageViewerVisible}
            onClose={onClose}
            images={viewerImages}
            activeIndex={imageActiveIndex}
            onMaskClick={onMaskClick}
            drag={false}
            scalable={false}
            showTotal={false}
            noImgDetails
            className='imageViewer'
            downloadable
        />
    )
}

export default ImageViewer