import  { ErrorBoundary as ReactErrorBoundary }  from 'react-error-boundary'

interface ContentProps {
    children: JSX.Element
}

const ErrorBoundary = ({ children }:ContentProps) => {
    return(
        <ReactErrorBoundary fallback={<div>Something went wrong</div>}>
            {children}
        </ReactErrorBoundary>
    )
}

export default ErrorBoundary