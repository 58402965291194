import classNames from 'classnames/bind';
import { Flexbox, Typography } from 'components'
import { FC } from 'react'
import { CloseIcon } from 'components/icons';
import styles from './styles.module.scss'

const classes = classNames.bind(styles);

interface MessageSidePanelProps {
    onClose: () => void,
    title: string,
    children: JSX.Element
}

const MessageSidePanel: FC<MessageSidePanelProps> = ({ onClose, title, children }) => {
    return (
        <Flexbox vertical className={classes('messageSidePanel')} id='copy_content'>
            <Flexbox justifyBetween className={classes('prdPreview-header')}>
                <Typography className={classes('prdPreview-title')}>{title}</Typography>
                <Flexbox data-copy='false'>
                    <CloseIcon className={classes('closeIcon')} onClick={onClose} />
                </Flexbox>
            </Flexbox>
            {children}
        </Flexbox>
    )
}

export default MessageSidePanel