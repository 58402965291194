import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const initiativeScoresApi = createApi({
    reducerPath: 'initiativeScores.api',
    baseQuery: rtkBaseQueryWithReauth,
    tagTypes: ['initiativeScores'],
    endpoints: builder => ({
      getInitiativeScores: builder.query({
        query: ({ workspaceId }) => `/workspaces/${workspaceId}/initiative-scores`,
      }),
      editInitiativeScores: builder.mutation({
          query: ({ workspaceId, data }) => {
            return {
              url: `/workspaces/${workspaceId}/initiative-scores`,
              method: 'PUT',
              body: data
            };
          },
      })
    })
});

export const {
    useEditInitiativeScoresMutation,
    useGetInitiativeScoresQuery,
    useLazyGetInitiativeScoresQuery
} = initiativeScoresApi