import { Dialog, Flexbox, TextArea } from 'components'
import { FC } from 'react'
import classNames from 'classnames/bind';
import styles from '../assistant.module.scss';

const classes = classNames.bind(styles);

interface AIRefineDialogProps {
    open: boolean
    onClose: () => void,
    onConfirm: () => void,
    title: string,
    refinedContent: string,
    setRefinedContent: (e: string) => void
}

const AIRefineDialog: FC<AIRefineDialogProps> = ({ open, onClose, onConfirm, title, refinedContent, setRefinedContent }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            title={title}
            PaperProps={{
                sx: {
                    width: '550px'
                }
            }}
            confirmButton
            confirmButtonLabel='Confirm'
            disabled={!refinedContent}
        >
            <Flexbox>
                <TextArea
                    value={refinedContent}
                    onChange={e => {
                        setRefinedContent(e.target.value)
                    }}
                    placeholder={'What would you like to change?'}
                    className={classes('textArea')}
                    fullWidth
                    rows={3}
                />
            </Flexbox>
        </Dialog>
    )
}

export default AIRefineDialog