import { ConfirmationDialog, Flexbox } from 'components';
import * as go from 'gojs';
import { ReactDiagram, ReactOverview } from 'gojs-react';
import { useEffect, useRef, useState } from 'react';
import { Product, Team, User } from 'utils/types';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Actions, hasPermission } from 'utils/permissions';
const classes = classNames.bind(styles);
import dotsSvg from 'assets/common/dots.svg'
import { createProduct, deleteProduct, editProduct } from 'pages/Products/products.api';
import { defaultTitle } from 'pages/Products/product';
import products from 'store/products';

interface TreeViewProps{
    data: Product[],
    searchValue?: string;
    user: User;
}

interface TreeViewData{
    name?: string;
    parent?: number;
    key: number;
    productTitle: string;
    teams: Team[];
}

const ProductsTreeView = ({ data, searchValue, user } : TreeViewProps) => {
    const diagramRef = useRef<ReactDiagram>(null)

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [nodeDataArray, setNodeDataArray] = useState<TreeViewData[]>([])
    const [openDialog, setOpenDialog] = useState(false);
    const [idToDelete, setIdToDelete] = useState<number | undefined>();

    useEffect(() => {
        const products = [...data]

        const productsData = products.map((product) => {
            return({
                key: product.id,
                name: product.owner?.fullName,
                productTitle: product.title,
                parent: product.parentProduct?.id,
                teams: product.teams,
            })
        })

        setNodeDataArray(productsData)

    }, [data])

    useEffect(() => {
        const diagram = diagramRef.current?.getDiagram();
        if (!diagram) {return;}
        // diagram.focus();

        if (diagram instanceof go.Diagram) {
            diagram.startTransaction('highlight search');

            if (searchValue) {
            // search four different data properties for the string, any of which may match for success
            // create a case insensitive RegExp from what the user typed
                let safe = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                let regex = new RegExp(safe, 'i');
                let results = diagram.findNodesByExample(
                    { productTitle: regex },
                    { name: regex }
                );
                diagram.highlightCollection(results);
                // try to center the diagram at the first node that was found
                const first = results.first();
                if (first) {
                    diagram.centerRect(first.actualBounds);
                }
            } else {  // empty string only clears highlighteds collection
                diagram.clearHighlighteds();
            }
            diagram.commitTransaction('highlight search');
        }
    }, [searchValue])


    const onCloseDialog = () => {
        setOpenDialog(false)
    }

    const onConfirmDialog = async () => {
        if(idToDelete){
            await dispatch(deleteProduct(idToDelete));
            onCloseDialog();
        }
    }

    const changeProductParent = async (productId: number, newParentId: number) => {
        const product = data.find(p => p.id === productId);
        if(product) {
            dispatch(editProduct(product.id, {
                owner: product.owner.id,
                parentProduct: newParentId,
                teams: product.teams.map(team => team.id),
                title: product.title,
            }))
        }
    }

    const $ = go.GraphObject.make;  // for conciseness in defining templates

    function initDiagram() {
        // Since 2.2 you can also author concise templates with method chaining instead of GraphObject.make
        // For details, see https://gojs.net/latest/intro/buildingObjects.html

        const myDiagram =
        $(go.Diagram,
            {
                allowCopy: false,
                allowDelete: false,
                // isModelReadOnly: true,
                initialDocumentSpot: go.Spot.Top,
                initialViewportSpot: go.Spot.Top,
                // initialAutoScale: go.Diagram.Uniform,
                minScale: 0.5,
                maxScale: 2,
                // allowMove: false,
                maxSelectionCount: 1, // users can select only one part at a time
                validCycle: go.Diagram.CycleDestinationTree, // make sure users can only create trees
                'clickCreatingTool.insertPart': function (loc: any) {  // override to scroll to the new node
                    const node = go.ClickCreatingTool.prototype.insertPart.call(this, loc);
                    if (node !== null) {
                        myDiagram.select(node);
                        myDiagram.commandHandler.scrollToPart(node);
                        myDiagram.commandHandler.editTextBlock(node.findObject('NAMETB') as go.TextBlock);
                    }
                    return node;
                },
                layout:
          $(go.TreeLayout,
              {
                  treeStyle: go.TreeLayout.StyleLastParents,
                  arrangement: go.TreeLayout.ArrangementHorizontal,
                  angle: 90,
                  layerSpacing: 35,
                  alternateAngle: 90,
                  alternateLayerSpacing: 35,
                  alternateAlignment: go.TreeLayout.AlignmentStart,
                  alternateNodeSpacing: 20
              }),
                'undoManager.isEnabled': true // enable undo & redo
            });

        // this is used to determine feedback during drags
        function mayWorkFor(node1: any, node2: any) {
            if (!(node1 instanceof go.Node)) {return false;}  // must be a Node
            if (node1 === node2) {return false;}  // cannot work for yourself
            if (node2.isInTreeOf(node1)) {return false;}  // cannot work for someone who works for you
            return true;
        }

        // This function provides a common style for most of the TextBlocks.
        // Some of these values may be overridden in a particular TextBlock.
        function textStyle() {
            return { stroke: 'black', };
        }
        // zoom on scroll
        // myDiagram.toolManager.mouseWheelBehavior = go.ToolManager.WheelZoom;

        async function addProduct(node: go.Part | null) {

            if (!node) {return;}
            const productData = node.data;
            myDiagram.startTransaction('add product');

            const product: Product = (await dispatch(createProduct({
                title: defaultTitle,
                parentProduct: productData.key,
                owner: user.id,
                teams: [],
            }))) as unknown as Product

            const newProduct = {
                name: product.owner?.fullName,
                productTitle: product.title,
                parent: product.parentProduct?.id,
                key: product.id
            };

            myDiagram.model.addNodeData(newProduct);

            const newNode: go.Node | null = myDiagram.findNodeForData(newProduct);
            if (newNode) {
                newNode.location = node.location;
            }
            myDiagram.commitTransaction('add product');
            if(newNode){
                myDiagram.commandHandler.scrollToPart(newNode);
            }

        }

        function showDeleteItemDialog(node: go.Part | null) {
            if (!node) {return;}
            const productId = node.data.key
            setIdToDelete(productId)

            setOpenDialog(true)
        }

        // define the Node template
        myDiagram.nodeTemplate =
        $(go.Node, 'Spot',
            {
                selectionObjectName: 'BODY',
                // @ts-ignore
                mouseEnter: (e, node) => node.findObject('BUTTON').opacity = 1,
                // @ts-ignore
                mouseLeave: (e, node) => node.findObject('BUTTON').opacity = 0,

                doubleClick: (e, node) => {
                    if(myDiagram.commandHandler.canCollapseTree(node as go.Node)) {
                        myDiagram.commandHandler.collapseTree(node as go.Node)
                    } else if(myDiagram.commandHandler.canExpandTree(node as go.Node)) {
                        myDiagram.commandHandler.expandTree(node as go.Node)
                    }
                },

                // handle dragging a Node onto a Node to (maybe) change the reporting relationship
                mouseDragEnter: (e, node, prev) => {
                    console.log('drag start');

                    const diagram = node.diagram;
                    // @ts-ignore
                    const selnode = diagram.selection.first();
                    if (!mayWorkFor(selnode, node)) {return;}
                    // @ts-ignore
                    const shape = node.findObject('SHAPE');
                    if (shape) {
                        shape._prevFill = shape.fill;  // remember the original brush
                        shape.fill = '#E6EFEF';
                    }
                },
                mouseDragLeave: (e, node, next) => {
                    // @ts-ignore
                    const shape = node.findObject('SHAPE');
                    if (shape && shape._prevFill) {
                        shape.fill = shape._prevFill;  // restore the original brush
                    }
                },
                mouseDrop: (e, node) => {

                    const diagram = node.diagram;
                    // @ts-ignore
                    const selnode = diagram.selection.first();  // assume just one Node in selection


                    if (mayWorkFor(selnode, node)) {
                        // console.log(selnode?.data, node.data);
                        // @ts-ignore
                        changeProductParent(selnode.data.key, node.data.key)
                        // find any existing link into the selected node
                        // @ts-ignore
                        const link = selnode.findTreeParentLink();
                        if (link !== null) {  // reconnect any existing link
                            link.fromNode = node;
                        } else {  // else create a new link
                            // @ts-ignore
                            diagram.toolManager.linkingTool.insertLink(node, node.port, selnode, selnode.port);
                        }
                    }
                },
            },
            {
                selectionAdornmentTemplate:
                  $(go.Adornment, 'Auto',

                  )  // end Adornment
            },
            // for sorting, have the Node.text be the data.name
            new go.Binding('text', 'name'),
            // bind the Part.layerName to control the Node's layer depending on whether it isSelected
            new go.Binding('layerName', 'isSelected', sel => sel ? 'Foreground' : '').ofObject(),
            $(go.Panel, 'Auto',
                { name: 'BODY' },
                // define the node's outer shape
                $(go.Shape, 'RoundedRectangle',
                    { name: 'SHAPE', stroke: '#C4C4C4', strokeWidth: 1, portId: '', width: 240, height: 108 },
                    // lightGreen if highlighted, white otherwise
                    new go.Binding('fill', 'isHighlighted', h => h ? '#FFEA8D' : '#ffffff').ofObject()
                ),
                $(go.Panel, 'Vertical', { alignment: go.Spot.TopLeft, padding: new go.Margin(4) },
                    $(go.Panel, 'Horizontal', { alignment: go.Spot.TopLeft },
                        $(go.TextBlock, textStyle(),  // the name
                            {
                                name: 'TITLE',
                                width: 195,
                                height: 40,
                                maxSize: new go.Size(200, 40),
                                margin: new go.Margin(4),
                                font: '600 14px Quicksand',
                                spacingBelow: 4,
                                stroke: '#003138',
                                isMultiline: true,
                                maxLines: 2,
                                overflow: go.TextBlock.OverflowEllipsis,
                                alignment: go.Spot.TopLeft,
                                cursor: 'pointer',
                                toolTip:  // define a tooltip for each node that displays the color as text
                            $('ToolTip',
                                $(go.TextBlock, { margin: 4, width: 160, alignment: go.Spot.Center },
                                    new go.Binding('text', 'productTitle'))
                            ),  // end of Adornment
                                click: function (_e: go.InputEvent, obj: go.GraphObject) {
                                    if(obj.part){
                                        navigate(`/catalog/product/${obj.part.data.key}`)
                                    }
                                },
                                mouseEnter: function (_e: go.InputEvent, obj: go.GraphObject) {
                                    if(obj.part){
                                        const product = obj.part.findObject('TITLE') as go.TextBlock;
                                        if (product){
                                            product.isUnderline = true
                                        }
                                    }
                                },
                                mouseLeave: function (_e: go.InputEvent, obj: go.GraphObject){
                                    if(obj.part){
                                        const product = obj.part.findObject('TITLE') as go.TextBlock;
                                        if (product){
                                            product.isUnderline = false
                                        }
                                    }
                                }
                            },
                            new go.Binding('text', 'productTitle')
                        ),

                        hasPermission(Actions.delete) ? $(go.Picture,
                            {
                                cursor: 'pointer',
                                source: dotsSvg,
                                alignment: go.Spot.TopRight,
                                contextMenu: $(
                                    'ContextMenu', 'Spot',
                                    $(go.Placeholder, { padding: 5, }),
                                    $('ContextMenuButton',{ 'ButtonBorder.fill': '#FFF','_buttonFillOver': '#F9F9F9',
                                        'ButtonBorder.strokeWidth': 1,
                                        'ButtonBorder.stroke': '#EBEBEB',
                                        '_buttonStrokeOver': '#EBEBEB',
                                        'ButtonBorder.figure': 'RoundedRectangle',
                                        width: 140,
                                    },
                                    $(go.TextBlock, 'Delete', {
                                        stroke: '#ED4337',
                                        margin: new go.Margin(6, 4),
                                        alignment: go.Spot.Left,
                                    }),
                                    {
                                        click: (_e, node) => showDeleteItemDialog(node.part),
                                        alignment: go.Spot.BottomLeft, alignmentFocus: go.Spot.Top,
                                    },),
                                ),
                            },
                            {
                                click: (e:  go.InputEvent, node: go.GraphObject) => e.diagram.commandHandler.showContextMenu(node),
                            },
                        ) : '',
                    ),

                    $(go.Panel, 'Horizontal', { alignment: go.Spot.TopLeft, height: 27, },
                        new go.Binding('itemArray', 'teams'),
                        { itemTemplate:
                        $(go.Panel, 'Auto',
                            {
                                margin: 2,
                                height: 16,
                                width: 16,
                                cursor: 'pointer',
                                toolTip: $('ToolTip',
                                    $(go.TextBlock, { margin: 4, width: 160, alignment: go.Spot.Center },
                                        new go.Binding('text', 'name'))
                                ),
                            },
                            $(go.Shape, 'Ellipse',{ fill: '#025B62', strokeWidth: 0 }),
                            $(go.TextBlock, new go.Binding('text', 'name', (name: string) =>  name.slice(0, 1).toUpperCase()),
                                {
                                    // margin: 2,
                                    stroke: '#fff',
                                    font: '600 12px Quicksand',
                                })
                        )
                        }),

                    $(go.Shape, 'MinusLine',
                        {
                            strokeDashArray: [2],
                            margin: new go.Margin(1),
                            stroke: '#025B62',
                            width: 220,
                            height: 1,
                            alignment: go.Spot.TopLeft,
                        }),

                    $(go.Panel, 'Horizontal', {
                        alignment: go.Spot.TopLeft,
                        width: 220,
                    },

                    $(go.TextBlock, textStyle(),
                        {
                            width: 115,
                            height: 16,
                            margin: new go.Margin(2),
                            font: '10px Quicksand',
                            isMultiline: false,
                            overflow: go.TextBlock.OverflowEllipsis,
                            verticalAlignment: go.Spot.Center,
                            toolTip:  // define a tooltip for each node that displays the color as text
                            $('ToolTip',
                                $(go.TextBlock, { margin: 4 },
                                    new go.Binding('text', 'name'))
                            )  // end of Adornment
                        },
                        new go.Binding('text', 'name')),
                    ),
                ) // end of Vertical panel

            ), // end Auto Panel

            hasPermission(Actions.create) ? $('Button',
                $(go.Shape, 'PlusLine', { width: 10, height: 10, stroke: '#025B62', strokeWidth: 2  }),
                {
                    name: 'BUTTON', alignment: go.Spot.Bottom, opacity: 0,   // initially not visible
                    click: (e, button) => addProduct(button.part)
                },
            ) : '',

        );  // end Node, a Spot Panel

        myDiagram.linkTemplate =
    $(go.Link, go.Link.Orthogonal,
        { layerName: 'Background', corner: 6 },
        $(go.Shape, { strokeWidth: 1, stroke: '#C4C4C4' }));  // the link shape

        myDiagram.model = go.Model.fromJson(`{ "class": "go.TreeModel",
    "nodeDataArray": ${JSON.stringify(nodeDataArray)}
  }`);
        // make sure new data keys are unique positive integers
        let lastkey = 1;
        myDiagram.model.makeUniqueKeyFunction = (model, data) => {
            let k = data.key || lastkey;
            while (model.findNodeDataForKey(k)) {k++;}
            data.key = lastkey = k;
            return k;
        };

        return myDiagram;
    }

    const initOverview = () => {
        const overview: any = $(go.Overview, { contentAlignment: go.Spot.Center, });

        overview.box.elt(0).stroke = '#E4B12E';

        return overview;
    };

    return (
        <Flexbox vertical className={classes('wrapper')}>
            <ReactDiagram
                divClassName={classes('diagram')}
                initDiagram={initDiagram}
                nodeDataArray={nodeDataArray}
                ref={diagramRef}
            />
            <ReactOverview
                initOverview={initOverview}
                divClassName={classes('overview')}
                observedDiagram={diagramRef.current?.getDiagram() || null}
            />

            <ConfirmationDialog
                open={openDialog}
                onClose={onCloseDialog}
                onConfirm={onConfirmDialog}
                confirmButtonStyle='danger'
                title='Delete this Product?'
            >
                <Flexbox>
                    You're about to permanently delete this Product, and all connected data will be lost.
                </Flexbox>
            </ConfirmationDialog>

        </Flexbox>
    )
}

export default ProductsTreeView


