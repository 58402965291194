import { NewDot } from 'components/icons/NewDot';
import { FC } from 'react';
import { CommentAvatar } from './CommentAvatar/CommentAvatar';
import { Stack, StackProps } from 'components';
import { uniqueFilter } from 'utils/search';
import { User } from 'utils/types';

export interface CommentAvatarsProps extends StackProps {
    dot?: boolean;
    users: User[];
}

export const CommentAvatars: FC<CommentAvatarsProps> = ({ dot, users, ...stackProps }) => {
    return (
        <Stack direction="row" alignItems="center" gap={1} {...stackProps}>
            {dot && <NewDot />}
            {users.map((u, i) => (
                <CommentAvatar key={i} initials filled>{u.fullName}</CommentAvatar>
            ))}
        </Stack>
    );
};
