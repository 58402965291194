import { Dispatch, GetState } from 'store';
import request from 'utils/request';
import { JiraAssignableUserType, JiraProject, JiraProjectIssueTypes, JiraStatus, JiraTeam } from 'utils/types';

export const getJiraProjects = () => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/integrations/jira-projects`)
    if (res.data) {
        return res.data as JiraProject[];
    }

}

export const getJiraProjectFields = (projectKey: string) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/integrations/jira-fields?projectKey=${projectKey}`)
    if (res.data) {
        return res.data as JiraProjectIssueTypes;
    }

}


export const getJiraAutocomplete = (uri: string, query: string) => async (dispatch: Dispatch, getState: GetState) => {

    const storeState = getState();
    const token = storeState.integrations.userIntegrations.find(integration => integration.name === 'JIRA')?.integrationProperties.access_token;
    const res = await request.get(`${uri}/${query}`.replaceAll('//', '/'), {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
    console.log(res);

    return res.data as any[] || [];

}

export const getJiraStatuses = () => async (dispatch: Dispatch, getState: GetState) => {

    const storeState = getState();
    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/integrations/jira-statuses`);
    if (res.data) {
        return res.data as JiraStatus[];
    }
}

export const getJiraAssignableUsers = (projectKey: string) => async (dispatch: Dispatch, getState: GetState) => {
    try {
        const storeState = getState();

        const res = await request.get(`/workspaces/${storeState.user.workspace.id}/integrations/jira-assignable-users?projectKey=${projectKey}`)
        if (res.data) {
            return res.data as JiraAssignableUserType[]
        }
    } catch (error: any) {
        console.log(error, 'Error: getJiraAssignableUsers');
    }
}

export const getJiraTeams = () => async (dispatch: Dispatch, getState: GetState) => {
    try {
        const storeState = getState();

        const res = await request.get(`/workspaces/${storeState.user.workspace.id}/integrations/jira-teams`)
        if (res.data) {
            return res.data as JiraTeam[]
        }
    } catch (error: any) {
        console.log(error, 'Error: getJiraAssignableUsers');
    }
}