import { RootState } from './index';
import { Initiative } from 'utils/types';

interface InitiativesReducerState {
    initiatives: Initiative[];
    loading: boolean;
}

export const ACTION_GET_INITIATIVES = 'getInitiatives';
export const ACTION_SET_INITIATIVES = 'setInitiatives';
export const ACTION_DELETE_INITIATIVE = 'deleteInitiative';
export const ACTION_ADD_INITIATIVES = 'addInitiatives';


const initialState: InitiativesReducerState = {
    loading: false,
    initiatives: [],
}

const initiatives = (state: InitiativesReducerState = initialState, action: {type: string, payload: any}): InitiativesReducerState => {
    switch (action.type) {
    case ACTION_GET_INITIATIVES:
        return { ...state, initiatives: [], loading: true }
    case ACTION_SET_INITIATIVES:
        return { ...state, loading: false, initiatives: [...action.payload] }
    case ACTION_DELETE_INITIATIVE:
        return { ...state, initiatives: state.initiatives.filter(initiative => initiative.id !== action.payload) }
    case ACTION_ADD_INITIATIVES:
        return { ...state, initiatives: [...state.initiatives, action.payload] }
    default:
        return state
    }
}
export default initiatives;

export const initiativesSelector = (store: RootState) => store.initiatives.initiatives;
export const initiativesLoadingSelector = (store: RootState) => store.initiatives.loading;