import { DatePicker, Flexbox, Input, RichTextEditor, Select } from 'components';
import { JiraAssignableUserType, JiraFieldAllowedValue, JiraIssueType, JiraMapping, JiraProject, JiraTeam } from 'utils/types';
import styles from '../index.module.scss';
import classNames from 'classnames/bind';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJiraAssignableUsers, getJiraAutocomplete, getJiraTeams } from 'common/jira/index.api';
import { JiraFieldError } from '..';
import { activeUsersSelector } from 'store/users-slice';
const classes = classNames.bind(styles);


interface FieldsProps {
    selectedIssueType: JiraIssueType;
    setSelectedIssueType: (v: JiraIssueType) => void;
    mappings: JiraMapping[];
    selectedProject?: JiraProject;
    jiraFields: any;
    setJiraFields: any;
    exportError: JiraFieldError
}

let optionsTimer: NodeJS.Timeout | null = null;

const jiraFieldsToOmit = [
    'issuetype',
    'project',
    'reporter',
    'issuelinks',
    'parent',
    'labels',
]

const Fields = ({ selectedIssueType, setSelectedIssueType, mappings, selectedProject, jiraFields, setJiraFields, exportError }: FieldsProps) => {
    const dispatch = useDispatch();
    const [jiraTeams, setJiraTeams] = useState<JiraTeam[]>([])

    const activeUsers = useSelector(activeUsersSelector);

    const [assignableUsers, setAssignableUsers] = useState<JiraAssignableUserType[]>([])


    const onFieldSelect = (name: string, value: JiraFieldAllowedValue | JiraFieldAllowedValue[] | null) => {
        if (value) {
            setJiraFields({ type: 'update', key: name, payload: value })
        }
    }

    const getAssignableUsersOptions = async () => {
        if (selectedProject) {
            const users = (await dispatch(getJiraAssignableUsers(selectedProject.key))) as unknown as JiraAssignableUserType[];
            setAssignableUsers(users)
            if (jiraFields.assignee) {
                const user = activeUsers.find(u => u.id === jiraFields.assignee.id);
                if (user) {
                    const email: string = user.email;
                    const jiraUser = users.find(u => u.emailAddress === email);
                    setJiraFields({ type: 'update', key: 'assignee', payload: jiraUser });
                }
            }
        }
    }

    const getTeamOptions = async () => {
        const teams = (await dispatch(getJiraTeams())) as unknown as JiraTeam[];
        setJiraTeams(teams);
    }

    useEffect(() => {
        getAssignableUsersOptions();
        getTeamOptions()
    }, [selectedProject])

    const onDescriptionChange = (targetName: string, value: string) => {
        setJiraFields({ type: 'update', key: targetName, payload: value })
    }

    const onFieldValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setJiraFields({ type: 'update', key: name, payload: value })
    }

    const getOptions = async (url: string, fieldId: string, value: string) => {
        if (selectedIssueType) {
            if (optionsTimer) {
                clearTimeout(optionsTimer);
            }
            optionsTimer = setTimeout(async () => {
                const data = await dispatch(getJiraAutocomplete(url, value)) as unknown as any[];
                selectedIssueType.fields[fieldId].options = data;
                setSelectedIssueType({ ...selectedIssueType })
            }, 500)

        }
    }

    return (<>
        {Object.values(selectedIssueType.fields).filter(field => !jiraFieldsToOmit.includes(field.key)).map((field, fieldIdx) => {
            const currentMap = mappings.find(mapping => mapping.projectId === selectedProject?.id)

            const valueInMapping = currentMap?.initiativeMapping?.fields.some(f => f.jiraField === field.key)

            if (field.required || valueInMapping) {
                if (!field.allowedValues && !field.autoCompleteUrl && field.schema.type !== 'array' && field.schema.type !== 'datetime' && field.schema.type !== 'date') {
                    return (
                        <Flexbox key={`${field.key}-${fieldIdx}`} className={classes('rowContainer')}>
                            {field.schema.type === 'string' && field.key !== 'summary' ? (
                                <RichTextEditor
                                    value={jiraFields[field.key] || ''}
                                    placeholder={field.name}
                                    label={field.name}
                                    required={field.required}
                                    errorText={exportError[field.key]}
                                    onChange={(value) => onDescriptionChange(field.key, value)}
                                    disabled={valueInMapping}
                                />
                            ) : (
                                <Input
                                    value={jiraFields[field.key] || ''}
                                    fullWidth
                                    alwaysEditable
                                    label={field.name}
                                    required={field.required}
                                    placeholder={field.name}
                                    name={field.key}
                                    onChange={onFieldValueChange}
                                    errorText={exportError[field.key]}
                                    disabled={valueInMapping}
                                    className={classes('rowInput')}
                                />
                            )}
                        </Flexbox>
                    )
                } else if (field.allowedValues) {
                    return (
                        <Flexbox key={`${field.key}-${fieldIdx}`} className={classes('rowContainer')}>
                            <Select
                                value={field.allowedValues.find(allowedValue => jiraFields[field.key]?.id === allowedValue?.id) as any}
                                options={field.allowedValues}
                                name={field.key}
                                label={field.name}
                                required={field.required}
                                errorText={exportError[field.key]}
                                multiple={field.schema.type === 'array'}
                                getOptionLabel={(option) => option?.name || option?.value}
                                onChange={(e: ChangeEvent<{}>, value: JiraFieldAllowedValue | JiraFieldAllowedValue[] | null) => onFieldSelect(field.key, value)}
                                disableClearable={field.required}
                                key={field.key}
                                disabled={valueInMapping}
                            />
                        </Flexbox>
                    )
                } else if (field.key === 'assignee') {
                    return (
                        <Flexbox className={classes('rowContainer')}>
                            <Select
                                value={jiraFields[field.key] || ''}
                                options={assignableUsers}
                                name={field.key}
                                label={field.name}
                                required={field.required}
                                errorText={exportError[field.key]}
                                getOptionLabel={option => option.displayName || ''}
                                onChange={(e: ChangeEvent<{}>, value: any) => onFieldSelect(field.key, value)}
                            />
                        </Flexbox>
                    )
                } else if (field.key === 'assignee') {
                    return (
                        <Flexbox key={`${field.key}-${fieldIdx}`} className={classes('rowContainer')}>
                            <Select
                                value={jiraFields[field.key] || ''}
                                options={assignableUsers}
                                name={field.key}
                                label={field.name}
                                required={field.required}
                                errorText={exportError[field.key]}
                                getOptionLabel={option => option.displayName || ''}
                                onChange={(e: ChangeEvent<{}>, value: any) => onFieldSelect(field.key, value)}
                            />
                        </Flexbox>
                    )
                } else if (field.schema.type === 'team') {
                    return (
                        <Flexbox key={`${field.key}-${fieldIdx}`} className={classes('rowContainer')}>
                            <Select
                                value={jiraFields[field.key] || ''}
                                options={jiraTeams}
                                name={field.key}
                                label={field.name}
                                required={field.required}
                                errorText={exportError[field.key]}
                                getOptionLabel={option => option.displayName || ''}
                                onChange={(e: ChangeEvent<{}>, value: any) => onFieldSelect(field.key, value)}
                            />
                        </Flexbox>
                    )
                } else if (field.autoCompleteUrl) {
                    return (
                        <Flexbox key={`${field.key}-${fieldIdx}`} className={classes('rowContainer')}>
                            <Select
                                name={field.key}
                                label={field.name}
                                required={field.required}
                                errorText={exportError[field.key]}
                                getOptionLabel={option => option.displayName}
                                onChange={(e: ChangeEvent<{}>, value: any) => onFieldSelect(field.key, value)}
                                onInputChange={(e: ChangeEvent<{}>, value: string) => { getOptions(field.autoCompleteUrl || '', field.key, value) }}
                                options={field.options || []}
                            // disabled={valueInMapping}
                            />
                        </Flexbox>
                    )
                } else if (field.schema.type === 'datetime' || field.schema.type === 'date') {
                    return (
                        <DatePicker
                            key={`${field.key}-${fieldIdx}`}
                            name={field.key}
                            selected={new Date(jiraFields[field.key])}
                            onChange={() => { }}
                            disabled={valueInMapping}
                            fullWidth
                            label={field.name}
                            errorText={exportError[field.key]}
                            required={field.required}
                        />
                    )
                }
            }
            return (<Flexbox key={fieldIdx}></Flexbox>)
        })
        }
    </>)
}

export default Fields