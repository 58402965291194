

import './select.scss'
import Autocomplete, { AutocompleteProps as DefaultAutocompleteProps, AutocompleteRenderInputParams, createFilterOptions as defaultCreateFilterOptions } from '@mui/material/Autocomplete';
import Input from 'components/Input';
import { Flexbox, Tooltip } from 'components';
import { AutocompleteFreeSoloValueMapping } from '@mui/material';
import { ReactNode } from 'react';


interface AutocompleteProps<
T,
Multiple extends boolean | undefined = undefined,
DisableClearable extends boolean | undefined = undefined,
FreeSolo extends boolean | undefined = undefined
> extends Omit<DefaultAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
    renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
    label?: string;
    variant?: 'outlined' | 'filled' | 'standard';
    placeholder?: string;
    name?: string;
    errorText?: string;
    required?: boolean;
    className?: string;
    getToolTipText?: (option: T | AutocompleteFreeSoloValueMapping<FreeSolo>) => string;
}

export default function Select<
T,
Multiple extends boolean | undefined = undefined,
DisableClearable extends boolean | undefined = undefined,
FreeSolo extends boolean | undefined = undefined
>({
    label,
    variant,
    placeholder,
    name,
    errorText,
    required,
    disabled,
    multiple,
    getOptionLabel,
    value,
    className,
    getToolTipText,
    ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {


    const getDisabledValue = () => {
        if(multiple && Array.isArray(value) && value.length) {
            return value.map(option => {
                if(getOptionLabel) {
                    return <Flexbox className='chip'>{getOptionLabel(option)}</Flexbox>
                }
            })
        } else {
            if(getOptionLabel) {
                return getOptionLabel(value as T);
            } else {
                return value;
            }
        }
    }

    return (
        <Flexbox vertical fullWidth className={`selectContainer ${className || ''}`}>
            {label &&  <Flexbox className='label'>{label}
                {required && <Flexbox className='required'>*</Flexbox>}
            </Flexbox>}
            {disabled ? (
                <Flexbox className= {`disabled${!value ? ' placeholder' : ''}`}>
                    {value && getDisabledValue() as ReactNode || placeholder}
                </Flexbox>
            ) : (
                <Autocomplete
                    classes={{
                        root: 'autocomplete',
                        inputRoot: 'inputRoot',
                        listbox: 'listboxes'
                    }}
                    fullWidth
                    filterSelectedOptions
                    disableCloseOnSelect={props.disableCloseOnSelect === undefined ? multiple : props.disableCloseOnSelect}
                    multiple={multiple}
                    getOptionLabel={getOptionLabel}
                    value={value}
                    renderOption={(props, option) => {
                        const optionLabel = getOptionLabel ? getOptionLabel(option) : option;
                        return (
                            <li {...props} key={props.id}>
                                <Tooltip title={getToolTipText ? getToolTipText(option) : ''}>
                                    <span>{optionLabel as ReactNode}</span>
                                </Tooltip>
                            </li>
                        )
                    }}
                    renderInput={(params) => <Input
                        errorText={errorText}
                        name={name}
                        variant={variant}
                        placeholder={multiple && Array.isArray(value) && value.length >= 1 ? '' : placeholder}
                        alwaysEditable
                        {...params}
                    />}
                    {...props}
                />
            )}


        </Flexbox>
    )
}

export const createFilterOptions = defaultCreateFilterOptions;