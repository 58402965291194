import { Button, Flexbox, Typography } from 'components'
import { Point } from 'utils/types'
import { FC } from 'react';
import classNames from 'classnames/bind';
import styles from '../../../initiative.module.scss';
import { useMarkPointAsIrrelevantMutation } from 'store/initiativeGrading-api';
import { useWorkspaceId } from 'utils/hooks';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { insightIdSelector, updateIrrelevantPoints } from 'store/initiativeGrading-slice';

const classes = classNames.bind(styles);

interface PointItemProps {
    point: Point,
}

const PointItem: FC<PointItemProps> = ({ point }) => {
    const workspaceId = useWorkspaceId();
    const params = useParams();
    const dispatch = useDispatch();

    const [markPointAsIrrelevant, { isLoading }] = useMarkPointAsIrrelevantMutation();

    const insightId = useSelector(insightIdSelector);

    const handleMarkPointAsIrrelevant = async () => {
        try {
            const res: any = await markPointAsIrrelevant({ workspaceId, id: params.id, insightId, pointId: point.id })
            dispatch(updateIrrelevantPoints({ id: point.id, title: point.title, content: point.content, irrelevanceId: res?.data?.irrelevanceId }))
        } catch (err) {
            console.log(err, 'err');
        }
    }

    return (
        <Flexbox className={classes('points')}>
            <Flexbox vertical className={classes('pointsContainer')}>
                <Flexbox vertical className={classes('gradTitleContainer')}>
                    <Typography variant='h6' className={classes('feedbackTitle')}>{point.title}</Typography>
                    {point.content && <Typography className={classes('feedbackDescription')}>{point.content}</Typography>}
                </Flexbox>

                {point.recommendation && (
                    <Flexbox vertical className={classes('gradTitleContainer', 'pl-10')}>
                        <Typography className={classes('feedbackTitle')} variant='h6'>Suggestion</Typography>
                        <Typography className={classes('feedbackDescription')}>{point.recommendation}</Typography>
                    </Flexbox>
                )}
            </Flexbox>

            <Flexbox className={classes('actionButtonContainer')}>
                <Button
                    className={classes('actionButton')}
                    onClick={handleMarkPointAsIrrelevant}
                    variant='contained'
                    disabled={isLoading}
                >
                    Mark as Non-Relevant
                </Button>
            </Flexbox>
        </Flexbox>
    )
}

export default PointItem;