import { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Button, ConfirmationDialog, FilterButton, Flexbox, Loader, SearchField } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { productsSelector } from 'store/products';
import { Preferences, PreferencesKeys, PreferenceValues, Product } from 'utils/types';
import { deleteProduct, getProducts } from './products.api';
import styles from './products.module.scss';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import classNames from 'classnames/bind';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Actions, hasPermission } from 'utils/permissions';
import { EmptyProductIcon, PlusIcon } from 'components/icons';
import { FilterOption } from 'components/FilterButton';
import { userSelector } from 'store/user';
import { useDebounce, useWorkspaceId } from 'utils/hooks';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import GroupFilter, { GroupFilterOption } from 'components/GroupFilter';
import ProductsTreeView from './components/treeView/productsTreeView';
import AgGridTable, { AgColumn, ColumnTypes, GridStatePreferences, getLinkedColumnContextMenuItems } from 'components/AgGridTable';
import { GetContextMenuItemsParams, GridApi, GridReadyEvent, SortChangedEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import EmptyState from 'common/emptyState';
import { activeUsersSelector } from 'store/users-slice';
import { teamsSelector } from 'store/teams-slice';
import { useLazyGetUsersQuery } from 'store/users-api';
import { useLazyGetTeamsQuery } from 'store/teams-api';
import KnowledgeSourcePopup from 'pages/KnowledgeBase/components/KnowledgeSourcePopup';
const classes = classNames.bind(styles);
const classesInfo = classNames.bind(stylesInfo);


interface ProductTableHeaders extends Pick<Product, 'title' | 'owner' | 'parentProduct' | 'teams'> {
    actions?: string;
}

export enum FilterKeys {
    owner = 'owner',
    product = 'product',
    team = 'team',
    query = 'query',
    order = 'order',
    orderBy = 'orderBy',
    view = 'view',
    productGridLayout = 'productGridLayout'
}


export type FilterValuesReducerAction = {
    type: 'update';
    key: string;
    payload: FilterOption[];
} | { type: 'reset' };

const defaultFilterState = {
    [FilterKeys.owner]: [],
    [FilterKeys.product]: [],
    [FilterKeys.team]: []
}

const filterValuesReducer = (state: { [key: string]: FilterOption[] }, action: FilterValuesReducerAction) => {
    switch (action.type) {
        case 'update':
            return { ...state, [action.key]: action.payload }
        case 'reset':
            return defaultFilterState;
        default:
            return state;
    }
}

const viewTypes: GroupFilterOption[] = [
    { id: 0, title: 'Table' },
    { id: 1, title: 'Tree' },
]

const ProductsList = () => {
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();
    const navigate = useNavigate();

    const [getUsers] = useLazyGetUsersQuery();
    const [getTeams] = useLazyGetTeamsQuery();

    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, searchDebounceValue, setSearchValue] = useDebounce('');

    const products = useSelector(productsSelector)
    const user = useSelector(userSelector);
    const users = useSelector(activeUsersSelector);
    const teams = useSelector(teamsSelector);

    const [orderBy, setOrderBy] = useState<keyof ProductTableHeaders | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc' | undefined>();
    const [data, setData] = useState<Product[]>([]);
    const [loading, setLoading] = useState(true);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [productId, setProductId] = useState<number | undefined>()

    const [filterValues, setFilterValues] = useReducer(filterValuesReducer, defaultFilterState);
    const [gridStatePreferences, setGridStatePreferences] = useState<GridStatePreferences | undefined>({})

    const [userOptions, setUserOptions] = useState<FilterOption[]>([]);
    const [productOptions, setProductOptions] = useState<FilterOption[]>([]);
    const [teamOptions, setTeamOptions] = useState<FilterOption[]>([]);

    const [viewValue, setViewValue] = useState<GroupFilterOption>(viewTypes[0]);
    const [canUpdatePreferences, setCanUpdatePreferences] = useState(false);

    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null)

    const columns: AgColumn[] = useMemo(() => [
        {
            colType: ColumnTypes.Linked,
            headerName: 'Product',
            field: 'title',
            minWidth: 200,
            sortable: true,
            link: '/catalog/product',
            linkParam: 'id',
            wrapText: true,
            autoHeight: true,
        },
        {
            colType: ColumnTypes.SimpleSelect,
            headerName: 'Owner',
            field: 'owner',
            minWidth: 200,
            sortable: true,
            valueGetter: (params) => {
                return params.data ? params.data.owner?.fullName : '';
            },
            valueFormatter: (params) => {
                return params?.value?.fullName;
            },
        },
        {
            colType: ColumnTypes.SimpleSelect,
            headerName: 'Parent',
            field: 'parentProduct',
            minWidth: 120,
            sortable: true,
            valueFormatter: (params: ValueFormatterParams) => {
                return params?.value?.title;
            },
            valueGetter: (params: ValueGetterParams) => {
                return params.data ? params.data.parentProduct?.title : '';
            },
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell',
        },
        {
            colType: ColumnTypes.Circle,
            headerName: 'Teams',
            field: 'teams',
            sortable: true,
        },
        {
            colType: ColumnTypes.Action,
            field: 'actions',
            headerName: '',
            actions: params => {
                return [
                    {
                        label: 'Open',
                        action: () => {
                            navigate(`/catalog/product/${params?.node?.data?.id}`)
                        }
                    },
                    ...(hasPermission(Actions.delete, params.data) ? [
                        { label: 'Delete', action: () => showDeleteConfirmation(params.data.id), type: 'red' }
                    ] : [])
                ]
            },
        },
    ], [])

    const onViewTypeChange = (value: GroupFilterOption) => {
        setViewValue(value);
        setCanUpdatePreferences(true)
    }

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            await Promise.all([
                dispatch(getProducts()),
                getUsers({ workspaceId }),
                getTeams({ workspaceId }),
                loadPreferences()
            ])
            setLoading(false)
        }
        loadData()
    }, [])

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.product))) as unknown as Preferences<FilterKeys>[];
        if (preferences && preferences.length) {
            const { productGridLayout, order, orderBy, ...filters } = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;

            productGridLayout && setGridStatePreferences(productGridLayout as GridStatePreferences);
            setOrder(order);
            setOrderBy(orderBy)

            if (searchParams.toString().length === 0) {
                setSearchParams(filters, { replace: true })
            }
        }
    }

    useEffect(() => {
        const options: FilterOption[] = users.map(u => ({ id: u.id, title: `${u.fullName}${u.id === user.id ? ' (Me)' : ''}`, tooltip: u.email }))
        const currentUserIndex = options.findIndex((o) => o.id === user.id)
        if (currentUserIndex >= 0) {
            const currentUserOption = options.splice(currentUserIndex, 1)[0]
            options.splice(0, 0, currentUserOption)
        }
        setUserOptions(options)

        setProductOptions(products.map(product => ({ id: product.id, title: product.title || '' })))
        setTeamOptions(teams.map(team => ({ id: team.id, title: team.name || '' })))

    }, [users, user, products, teams])

    useEffect(() => {
        let data = [...products];
        if (orderBy && order) {
            data.sort((d1, d2) => {

                if (orderBy !== 'actions') {

                    if (orderBy === 'owner') {
                        if (d1[orderBy].fullName.toUpperCase() > d2[orderBy].fullName.toUpperCase()) {
                            return order === 'asc' ? 1 : -1
                        } else {
                            return order === 'asc' ? -1 : 1
                        }
                    } else if (orderBy === 'parentProduct') {
                        const product1 = d1[orderBy]?.title || ''
                        const product2 = d2[orderBy]?.title || ''
                        if (product1.toUpperCase() > product2.toUpperCase()) {
                            return order === 'asc' ? 1 : -1
                        }
                        else {
                            return order === 'asc' ? -1 : 1
                        }
                    } else if (orderBy === 'title') {
                        if (d1[orderBy].toUpperCase() > d1[orderBy].toUpperCase()) {
                            return order === 'asc' ? 1 : -1
                        }
                        else {
                            return order === 'asc' ? -1 : 1
                        }
                    }
                    else {
                        const value1 = d1[orderBy];
                        const value2 = d2[orderBy]
                        if (value1 && value2 && value1 > value2) {
                            return order === 'asc' ? 1 : -1
                        } else {
                            return order === 'asc' ? -1 : 1
                        }
                    }


                }
                return 0


            })
        }

        if (searchDebounceValue) {
            data = data.filter(product => product.title && product.title.toLowerCase().includes(searchDebounceValue.toLowerCase()))
        }

        data = data.filter(product => {
            if (!filterValues[FilterKeys.owner].length || filterValues[FilterKeys.owner].some(filter => filter.id === product.owner.id)) {
                return true;
            }
            return false;
        })

        data = data.filter(product => {
            if (!filterValues[FilterKeys.product].length || filterValues[FilterKeys.product].some(filter => product?.parentProduct && filter.id === product.parentProduct.id)) {
                return true;
            }
            return false;
        })

        data = data.filter(product => {
            const productTeams = product.teams.map(team => team.id);
            if (!filterValues[FilterKeys.team].length || filterValues[FilterKeys.team].some(filter => productTeams.includes(filter.id))) {
                return true;
            }
            return false;
        })

        setData(data)
    }, [order, orderBy, products, searchDebounceValue, filterValues]);


    useEffect(() => {
        if (canUpdatePreferences) {

            const ownerIds = filterValues[FilterKeys.owner].map(filter => filter.id);
            const productIds = filterValues[FilterKeys.product].map(filter => filter.id);
            const teamIds = filterValues[FilterKeys.team].map(filter => filter.id);

            const filterKeys: PreferenceValues<FilterKeys> = {
                [FilterKeys.view]: viewValue.id.toString(),
            };

            if (ownerIds.length) {
                filterKeys[FilterKeys.owner] = ownerIds.join(',');
            }

            if (productIds.length) {
                filterKeys[FilterKeys.product] = productIds.join(',');
            }

            if (teamIds.length) {
                filterKeys[FilterKeys.team] = teamIds.join(',');
            }

            if (searchDebounceValue.length) {
                filterKeys[FilterKeys.query] = searchDebounceValue;
            }

            if (order && orderBy) {
                filterKeys[FilterKeys.order] = order;
                filterKeys[FilterKeys.orderBy] = orderBy;
            }

            dispatch(updatePreferences(filterKeys, PreferencesKeys.product));

            setSearchParams(filterKeys, { replace: true });
        }
    }, [filterValues, searchDebounceValue, order, orderBy, viewValue]);

    useEffect(() => {
        if (!loading) {
            const ownerIdsString = searchParams.get(FilterKeys.owner);

            if (ownerIdsString) {
                const ownerIds = ownerIdsString.split(',').map(id => parseInt(id));
                setFilterValues({ type: 'update', key: FilterKeys.owner, payload: userOptions.filter(option => ownerIds.includes(option.id)) })
            }

            const productIdsString = searchParams.get(FilterKeys.product);
            if (productIdsString) {
                const productIds = productIdsString.split(',').map(id => parseInt(id));
                setFilterValues({ type: 'update', key: FilterKeys.product, payload: productOptions.filter(option => productIds.includes(option.id)) })
            }

            const teamIdsString = searchParams.get(FilterKeys.team);
            if (teamIdsString) {
                const teamIds = teamIdsString.split(',').map(id => parseInt(id));
                setFilterValues({ type: 'update', key: FilterKeys.team, payload: teamOptions.filter(option => teamIds.includes(option.id)) })
            }

            const queryString = searchParams.get(FilterKeys.query);
            if (queryString) {
                setSearchValue(queryString);
            }

            const viewType = searchParams.get(FilterKeys.view);
            if (viewType) {
                const viewId = parseInt(viewType);
                const view = viewTypes.find(view => view.id === viewId);
                if (view) {
                    setViewValue(view)
                }
            }

        }
    }, [loading])

    const onCancelDelete = () => {
        setOpenConfirmation(false)
    }

    const onDeleteProduct = async () => {
        if (productId) {
            await dispatch(deleteProduct(productId));
            onCancelDelete();
            const filtered = data.filter(d => d.id !== productId)
            setData(filtered)
        }
    }

    const showDeleteConfirmation = (idProductItem: number) => {
        setProductId(idProductItem)
        setOpenConfirmation(true)
    }

    const createNewProduct = () => {
        navigate('/catalog/product');
    }

    const onFilterValueChange = (targetName: string, value: FilterOption[]) => {
        setFilterValues({ type: 'update', key: targetName, payload: value });
        setCanUpdatePreferences(true)
    }

    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setCanUpdatePreferences(true);
    }

    const onSearchClear = () => {
        setSearchValue('');
        setCanUpdatePreferences(true);
    }

    const resetAllFilter = () => {
        setSearchValue('')
        setOrder(undefined);
        setOrderBy(undefined);
        setFilterValues({ type: 'reset' });
        setCanUpdatePreferences(true)
    }

    const onFilterReset = (filterName: string) => {
        setFilterValues({ type: 'update', key: filterName, payload: [] });
        setCanUpdatePreferences(true)
    }


    const buttonItemsEmptyState = useMemo(() => {
        const buttons = []

        if (hasPermission(Actions.create)) {
            buttons.push({
                onClick: createNewProduct,
                text: 'Create',
            })
        }

        return buttons

    }, [])

    const onSortChanged = (e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        if (order !== value?.sort || orderBy !== value?.colId) {
            modifiedSearchParamsObject.productGridLayout = gridStatePreferences
            dispatch(updatePreferences(modifiedSearchParamsObject, PreferencesKeys.product));
        }
    }

    const onGridStateChanged = (data: GridStatePreferences) => {
        setGridStatePreferences(data)
        const updatedPreferences: any = {};

        searchParams.forEach((value, key) => {
            updatedPreferences[key] = value;
        });

        updatedPreferences.productGridLayout = data
        dispatch(updatePreferences(updatedPreferences, PreferencesKeys.product));
    }

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    const getList: any = () => {
        if (viewValue === viewTypes[0]) {
            return (
                <AgGridTable
                    data={data}
                    columns={columns}
                    getContextMenuItems={params => {
                        const rowData = params?.node?.data;
                        const url = `/catalog/product/${rowData.id}`;

                        if (params.defaultItems) {
                            const linkItems = getLinkedColumnContextMenuItems(params, url, 'title')
                            const menuItems = [
                                ...params.defaultItems,
                                ...linkItems,
                            ]

                            return menuItems
                        } else {
                            return []
                        }
                    }}
                    order={order}
                    orderBy={orderBy}
                    onSortChanged={onSortChanged}
                    onGridStateChanged={onGridStateChanged}
                    gridStatePreferences={gridStatePreferences}
                    onGridReady={onGridReady}
                    exportFileName='Product Catalog'
                />
            )
        }
        if (viewValue === viewTypes[1]) {
            return (
                <ProductsTreeView
                    searchValue={searchDebounceValue}
                    data={products}
                    user={user}
                />
            )
        }
    }

    return (
        <Flexbox fullWidth vertical>
            <Flexbox vertical className={classesInfo('headerContainer')}>
                <Flexbox className={classesInfo('headerInfoTop')}>
                    <Flexbox className={classesInfo('headerTitle')}>Product Catalog</Flexbox>
                    <Flexbox>
                        {gridApi && <ExportButton api={gridApi} />}
                        {products.length > 0 && hasPermission(Actions.create) &&
                            <Button className={classesInfo('createButton')} variant='contained'
                                onClick={createNewProduct}>Create<PlusIcon /></Button>
                        }
                        <Flexbox>
                            <GroupFilter
                                title='Setup your view'
                                groups={[
                                    {
                                        options: viewTypes,
                                        value: viewValue,
                                        onChange: onViewTypeChange,
                                        multiple: false,
                                        title: '',
                                    },
                                ]}
                            />
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
                {products.length > 0 &&
                    <Flexbox className={classesInfo('headerInfo')}>
                        <Flexbox>
                            <SearchField
                                value={searchValue}
                                onChange={onSearchValueChange}
                                onClear={onSearchClear}
                                placeholder='Search Product'
                                className={classesInfo('searchInput')}
                            />
                            {viewValue === viewTypes[0] &&
                                <>
                                    <FilterButton
                                        options={userOptions}
                                        value={filterValues[FilterKeys.owner]}
                                        onChange={(value) => onFilterValueChange(FilterKeys.owner, value)}
                                        onFilterReset={() => onFilterReset(FilterKeys.owner)}
                                        multiple
                                        label={'Owner'}
                                        className={classesInfo('filterButton')}
                                        keepFirstOption
                                    />
                                    <FilterButton
                                        options={productOptions}
                                        value={filterValues[FilterKeys.product]}
                                        onChange={(value) => onFilterValueChange(FilterKeys.product, value)}
                                        onFilterReset={() => onFilterReset(FilterKeys.product)}
                                        multiple
                                        label={'Parent'}
                                        className={classesInfo('filterButton')}
                                    />
                                    <FilterButton
                                        options={teamOptions}
                                        value={filterValues[FilterKeys.team]}
                                        onChange={(value) => onFilterValueChange(FilterKeys.team, value)}
                                        onFilterReset={() => onFilterReset(FilterKeys.team)}
                                        multiple
                                        label={'Teams'}
                                        className={classesInfo('filterButton')}
                                    />
                                    {Object.values(filterValues).some(v => v.length !== 0) &&
                                        <Flexbox className={classesInfo('resetButtonContainer')}>
                                            <Button className={classesInfo('resetButton')} onClick={resetAllFilter}>
                                                Reset
                                            </Button>
                                        </Flexbox>
                                    }
                                </>
                            }
                        </Flexbox>
                    </Flexbox>
                }
            </Flexbox>
            <Flexbox className={classes('productList')}>
                {loading ? <Flexbox fullWidth fullHeight align justify><Loader disableShrink /></Flexbox>
                    :
                    products.length > 0 ?
                        getList()
                        :
                        <EmptyState
                            icon={<EmptyProductIcon />}
                            title='There are no Products yet'
                            titleSmall={buttonItemsEmptyState.length ? 'Press the button and start to' : undefined}
                            buttonItems={buttonItemsEmptyState}
                        />
                }
            </Flexbox>
            <ConfirmationDialog
                open={openConfirmation}
                onClose={onCancelDelete}
                onConfirm={onDeleteProduct}
                confirmButtonStyle='danger'
                title='Delete this Product?'
            >
                <Flexbox>
                    You're about to permanently delete this Product, and all connected data will be lost.
                </Flexbox>
            </ConfirmationDialog>
        </Flexbox>
    )
}

export default ProductsList