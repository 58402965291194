import { Dialog, FilterButton, Flexbox, RichTextEditor, Select, Table } from 'components'
import { TableHeader } from 'components/Table';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getChangeLog } from './changeLog.api';
import classNames from 'classnames/bind';
import styles from './changeLog.module.scss';
import { FilterOption } from 'components/FilterButton';
const classes = classNames.bind(styles);

export interface ChangeLogDialogProps {
    open: boolean;
    onClose: () => void;
    initiativeId: number;
}

type Fields = 'Owner' | 'Priority' | 'Problem' | 'Live Date' | 'Size' | 'Solution' | 'Status' | 'Success Definition' | 'Title' | 'Type' | 'Validation' | 'Description';

interface Revision {
    type: 'Edit' | 'Edit Requirement' | 'Create Requirement' | 'Delete Requirement',
    field: Fields,
    prevValue: string,
    nextValue: string,
    author: string,
    date: Date,
    id: number,
}

enum RevisionActions {
    create = 0,
    edit = 1,
    delete = 2,
}

const tableHeader: TableHeader<Revision>[] = [
    {
        id: 'id',
        text: 'Id'
    },
    {
        id: 'type',
        text: 'Type',
    },
    {
        id: 'field',
        text: 'Field',
    },
    {
        id: 'prevValue',
        text: 'From',
    },
    {
        id: 'nextValue',
        text: 'To',
    },
    {
        id: 'author',
        text: 'Author',
    },
    {
        id: 'date',
        text: 'Date',
    },
];


const filterOptions: FilterOption[] = [
    { id: 0, title: 'Owner' },
    { id: 1, title: 'Priority' },
    { id: 2, title: 'Problem' },
    { id: 3, title:'Live Date' },
    { id: 4, title: 'Size' },
    { id: 5, title: 'Solution' },
    { id: 6, title: 'Status' },
    { id: 7, title: 'Success Definition' },
    { id: 8, title: 'Title' },
    { id: 9, title: 'Type' },
    { id: 10, title: 'Validation' },
    { id: 11, title: 'Requirements' },
]

const groupChange = (initialData: Revision[]) => {
    const grouped: Revision[] = [];
    const groupingInterval = 1000 * 60 * 10; // 10min
    for(let i = 0; i < initialData.length; i++) {
        const data = initialData[i];
        if(data.field === 'Title' && data.type === 'Edit') {
            const groupData = { ...data };
            let j = i;
            while(initialData[j] && initialData[j].field === 'Title' && initialData[j].type === 'Edit' && initialData[j].date.getTime() - data.date.getTime() < groupingInterval) {
                groupData.nextValue = initialData[j].nextValue;
                j++;
            }
            grouped.push(groupData);
            i = j - 1;
        } else {
            grouped.push(data);
        }
    }

    const res = grouped.filter((rev) => rev.type === 'Create Requirement' || rev.nextValue !== rev.prevValue)

    return res.sort((rev1, rev2) => rev1.date > rev2.date ? 1 : -1);
}

export default ({ open, onClose, initiativeId }: ChangeLogDialogProps) => {
    const [revisions, setRevisions] = useState<Revision[]>([]);
    const [data, setData] = useState<Revision[]>([]);

    const [selectedFilter, setSelectedFilter] = useState<FilterOption[]>([]);

    const dispatch = useDispatch();
    useEffect(() => {
        if(open) {
            loadData()
        }
    }, [open])

    const loadData = async () => {
        const res: any = await dispatch(getChangeLog(initiativeId));
        const revisions: Revision[] = [];

        const initiativeRevs = res.initiatives;
        for(let i = 0; i < initiativeRevs.length; i++) {
            const author = initiativeRevs[i].revinfo?.author?.full_name ?? ''
            const date = new Date(initiativeRevs[i].revinfo.time)
            const initiative = initiativeRevs[i].initiative;
            const prevInitiative = i > 0 ? initiativeRevs[i - 1].initiative : {}
            const revId = initiativeRevs[i].revinfo.revId
            if(initiative.revision_type === 0) {
                continue;
            }
            if(initiative.owner_mod) {
                revisions.push({
                    type: 'Edit',
                    field:'Owner',
                    author: author,
                    nextValue: initiative.owner_id,
                    prevValue: prevInitiative.owner_id || '',
                    date,
                    id: revId,
                })
            } else if(initiative.priority_mod) {
                revisions.push({
                    type: 'Edit',
                    field:'Priority',
                    author: author,
                    nextValue: initiative.priority,
                    prevValue: prevInitiative.priority || '',
                    date,
                    id: revId,

                })
            } else if(initiative.problem_mod) {
                revisions.push({
                    type: 'Edit',
                    field:'Problem',
                    author: author,
                    nextValue: initiative.problem,
                    prevValue: prevInitiative.problem || '',
                    date,
                    id: revId,

                })
            } else if(initiative.release_date_mod) {
                revisions.push({
                    type: 'Edit',
                    field:'Live Date',
                    author: author,
                    nextValue: initiative.release_date ? new Date(initiative.release_date).toDateString() : '',
                    prevValue: prevInitiative.release_date ? new Date(prevInitiative.release_date).toDateString() : '',
                    date,
                    id: revId,

                })
            } else if(initiative.size_mod) {
                revisions.push({
                    type: 'Edit',
                    field:'Size',
                    author: author,
                    nextValue: initiative.size,
                    prevValue: prevInitiative.size || '',
                    date,
                    id: revId,

                })
            } else if(initiative.solution_mod) {
                revisions.push({
                    type: 'Edit',
                    field:'Solution',
                    author: author,
                    nextValue: initiative.solution,
                    prevValue: prevInitiative.solution || '',
                    date,
                    id: revId,

                })
            } else if(initiative.status_mod) {
                revisions.push({
                    type: 'Edit',
                    field:'Status',
                    author: author,
                    nextValue: initiative.status,
                    prevValue: prevInitiative.status || '',
                    date,
                    id: revId,

                })
            } else if(initiative.success_definition_mod) {
                revisions.push({
                    type: 'Edit',
                    field:'Success Definition',
                    author: author,
                    nextValue: initiative.success_definition,
                    prevValue: prevInitiative.success_definition || '',
                    date,
                    id: revId,

                })
            } else if(initiative.title_mod) {
                revisions.push({
                    type: 'Edit',
                    field:'Title',
                    author: author,
                    nextValue: initiative.title,
                    prevValue: prevInitiative.title || '',
                    date,
                    id: revId,

                })
            } else if(initiative.type_mod) {
                revisions.push({
                    type: 'Edit',
                    field:'Type',
                    author: author,
                    nextValue: initiative.type,
                    prevValue: prevInitiative.type || '',
                    date,
                    id: revId,

                })
            } else if(initiative.validation_mod) {
                revisions.push({
                    type: 'Edit',
                    field:'Validation',
                    author: author,
                    nextValue: initiative.validation,
                    prevValue: prevInitiative.validation || '',
                    date,
                    id: revId,

                })
            }

        }

        const storyRevs = res.stories//.sort((rev1: any, rev2: any) => rev1.revinfo.time > rev2.revinfo.time ? 1 : -1);
        for(let i = 0; i < storyRevs.length; i++) {

            const author = storyRevs[i].revinfo?.user?.full_name ?? ''
            const date = new Date(storyRevs[i].revinfo.time)
            const story = storyRevs[i].story;
            const revId = storyRevs[i].revinfo.revId

            const prevStory = i > 0 ? storyRevs[i - 1].story : {}
            if(story.revision_type === RevisionActions.create) {
                revisions.push({
                    type: 'Create Requirement',
                    author,
                    date,
                    field: 'Title',
                    nextValue: '',
                    prevValue: '',
                    id: revId,

                })
            } else if(story.revision_type === RevisionActions.edit) {
                if(story.title_mod) {
                    revisions.push({
                        type: 'Edit Requirement',
                        field:'Title',
                        author,
                        nextValue: story.title,
                        prevValue: prevStory.title || '',
                        date,
                        id: revId,

                    })
                } else if(story.description_mod) {
                    revisions.push({
                        type: 'Edit Requirement',
                        field: 'Description',
                        author,
                        nextValue: story.description,
                        prevValue: prevStory.description || '',
                        date,
                        id: revId,

                    })
                }
            }
        }

        setRevisions(groupChange(revisions).sort((r1, r2) => new Date(r2.date).getTime() - new Date(r1.date).getTime()));
    }

    useEffect(() => {
        if(!selectedFilter.length) {
            setData([...revisions])
        } else {
            setData(revisions.filter((rev) => {
                return (selectedFilter.some(f => f.title === rev.field) && !rev.type.includes('Requirement')) ||
                    (selectedFilter.some(f => f.id === 11) && rev.type.includes('Requirement'))


            }))
        }
    }, [revisions, selectedFilter])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={'Change Log'}
        >

            <Flexbox>
                <FilterButton
                    options={filterOptions}
                    value={selectedFilter}
                    onChange={(value) => setSelectedFilter(value)}
                    onFilterReset={() => setSelectedFilter([])}
                    multiple
                    label={'Fields'}
                    // className={classesInfo('filterButton')}
                />
            </Flexbox>
            {open ? (
                <Flexbox className={classes('tableContainer')}>
                    <Table
                        header={tableHeader}
                        stickyHeader
                        height={'400px'}
                        data={data.map(rev => {
                            const isRichText = rev.field === 'Description' || rev.field === 'Problem' ||
                            rev.field === 'Validation' || rev.field === 'Solution';
                            return { data: [
                                <Flexbox>{rev.id}</Flexbox>,
                                <Flexbox>{rev.type}</Flexbox>,
                                <Flexbox>{rev.field}</Flexbox>,
                                isRichText ? <Flexbox className={classes('valueField', 'description')} dangerouslySetInnerHTML={{ __html: rev.prevValue }}></Flexbox> : <Flexbox className={classes('valueField')}>{rev.prevValue}</Flexbox>,
                                isRichText ? <Flexbox className={classes('valueField', 'description')} dangerouslySetInnerHTML={{ __html: rev.nextValue }}></Flexbox> : <Flexbox className={classes('valueField')}>{rev.nextValue}</Flexbox>,
                                <Flexbox>{rev.author}</Flexbox>,
                                <Flexbox>{rev.date.toDateString() + ' ' + rev.date.toLocaleTimeString()}</Flexbox>,
                            ] }})}
                    />
                </Flexbox>

            ) : ''}
        </Dialog>
    )
}