import Flexbox from 'components/Flexbox'
import classNames from 'classnames/bind';
import styles from '../../assistant.module.scss'
import CustomTyphography from 'components/CustomTyphography';
import { Box, Link } from 'components';
import { FC } from 'react';
const classes = classNames.bind(styles)

interface WebPageReferencesPanelProps {
    title: string,
    data: {
        description: string,
        summarized_content: string,
        url: string
    }
}

const WebPageReferencesPanel: FC<WebPageReferencesPanelProps> = ({
    title,
    data,
}) => {
    return (
        <Flexbox vertical fullWidth className={classes('sourcesSidebarContainer', 'gap-6')}>
            <Flexbox fullWidth align justifyBetween className={classes('pb-4', 'sourcesSidebar-titleContainer')}>
                <CustomTyphography type='primary' className={classes('sourcesSidebar-title')}>{title}</CustomTyphography>
            </Flexbox>
            <Flexbox vertical fullWidth className={classes('gap-6', 'sourcesSidebar-mainContent')}>
                <Flexbox vertical className={classes('gap-6')}>
                    <Flexbox vertical fullWidth className={classes('gap-5', 'sourcesSidebar-group')}>
                        <Box className={classes('sourcesSidebar-item')} >
                            <Flexbox vertical className={classes('p-3', 'gap-1')}>
                                <CustomTyphography type='primary' className={classes('sourcesSidebar-key')}>Description:</CustomTyphography>
                                <Flexbox vertical fullWidth className={classes('sourcesSidebar-groupItem')}>
                                    {data.description}
                                </Flexbox>
                            </Flexbox>
                        </Box>
                        <Box className={classes('sourcesSidebar-item')} >
                            <Flexbox vertical className={classes('p-3', 'gap-1')}>
                                <CustomTyphography type='primary' className={classes('sourcesSidebar-key')}>Summarized Content:</CustomTyphography>
                                <Flexbox vertical fullWidth className={classes('sourcesSidebar-groupItem')}>
                                    {data.summarized_content}
                                </Flexbox>
                            </Flexbox>
                        </Box>
                        <Box className={classes('sourcesSidebar-item')} >
                            <Flexbox vertical className={classes('p-3', 'gap-1')}>
                                <CustomTyphography type='primary' className={classes('sourcesSidebar-key')}>URL:</CustomTyphography>
                                <Flexbox vertical fullWidth className={classes('sourcesSidebar-groupItem')}>
                                    <Link to={data.url} target='_blank' >
                                        {data.url}
                                    </Link>
                                </Flexbox>
                            </Flexbox>
                        </Box>
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    )
}

export default WebPageReferencesPanel