import store from 'store';
import { User } from './types';

export enum Permissions {
    ROLE_ADMIN = 'Admin',
    ROLE_EDITOR = 'Editor',
    ROLE_VIEWER = 'Viewer',
}

export enum Actions {
    create,
    edit,
    delete,
    view,
    adminPanel,
    createMetric,
}

const Abilities = {
    [Permissions.ROLE_ADMIN]: [Actions.create, Actions.delete, Actions.edit, Actions.view, Actions.adminPanel, Actions.createMetric],
    [Permissions.ROLE_EDITOR]: [Actions.create, Actions.view],
    [Permissions.ROLE_VIEWER]: [Actions.view]
}


export const getUserPermissionName = () => {
    const user = store.getState().user;
    if (user.roles.length) {
        const userRole = user.roles[0].name as unknown as keyof typeof Permissions;
        return Permissions[userRole]
    }
    return ''
}

export const hasPermission = (action: Actions, object: any = {}): boolean => {
    const user = store.getState().user;

    if (user.roles.length) {
        const userRole = user.roles[0].name as unknown as keyof typeof Permissions;

        if (Abilities[Permissions[userRole]].includes(action)) {
            return true;
        }
    }
    if (object.owner && object.owner.id) {
        return object.owner.id === user.id
    }
    if (object.owners && object.owners.length) {
        return object.owners.some((owner: User) => owner.id === user.id)
    }

    return false
}