import { Flexbox, Snackbar } from 'components';

interface JiraSnackbarProps{
    closeExportSuccess: () => void;
    showExportSuccess: boolean;
}

const JiraExportSnackbar = ({ closeExportSuccess, showExportSuccess }: JiraSnackbarProps) => {

    return(
        <Snackbar
            type='success'
            onClose={closeExportSuccess}
            open={showExportSuccess}
        >
            <Flexbox>Successfully exported</Flexbox>
        </Snackbar>
    )
}

export default JiraExportSnackbar