import { default as MTabs, TabsProps } from '@mui/material/Tabs';
import './tabs.scss'

const Tabs = ({ className, ...props }: TabsProps) => {
    return (
        <MTabs
            classes={{
                root: 'root',
                indicator: 'indicator',
            }}
            className={`tabsContainer ${className || ''}`}
            {...props}
        />
    )
}

export default Tabs;