import Flexbox from 'components/Flexbox'
import classNames from 'classnames/bind';
import styles from './horizontalSeparator.module.scss';
const classes = classNames.bind(styles);


const HorizontalSeparator = ({ className = '', dashed = false, }) => {
    return (
        <Flexbox fullWidth className={classes('horizontalSeparator', { dashed }, className)}/>
    )
}

export default HorizontalSeparator;