import { FC } from 'react';
import MentionEditor, { MentionEditorProps } from './MentionEditor';
import { MentionEditorTools, MentionEditorToolsProps } from './MentionEditorTools';
import { Stack } from 'components';
import styles from './mention-editor.module.scss';
import classNames from 'classnames/bind';
const classes = classNames.bind(styles);

interface MentionEditorWithToolsProps extends MentionEditorProps, MentionEditorToolsProps {}

export const MentionEditorWithTools: FC<MentionEditorWithToolsProps> = ({
    actions,
    ...editorProps
}) => {
    return (
        <Stack className={classes('with-tools')}>
            <MentionEditor {...editorProps} />
            <MentionEditorTools actions={actions} />
        </Stack>
    );
};
