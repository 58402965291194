
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { Button } from 'components';
import { useState, MouseEvent } from 'react';
import { PlusIcon } from 'components/icons';
import LinkPopover from './linkPopover';
import { Block, LinkMetadata } from 'utils/types';
const classes = classNames.bind(styles);

interface LinkAddProps {
    baseUrl: string;
    isEditable: boolean;
    editLinkAction: (data: any) => void;
    createLinkAction: (data: Block<LinkMetadata>) => void
}

const LinkAdd = ({ baseUrl, isEditable, editLinkAction, createLinkAction }: LinkAddProps) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const showPopover = (event: MouseEvent<HTMLElement | null>) => {
        setAnchorEl(event.currentTarget);
    }

    return isEditable ? (
        <>
            <Button className={classes('addNewButton')} startIcon={<PlusIcon />} onClick={showPopover}>
                Add new link
            </Button>
            <LinkPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} baseUrl={baseUrl} editLinkAction={editLinkAction} createLinkAction={createLinkAction} />
        </>)
        : null
}

export default LinkAdd