import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { User, UserStatuses } from 'utils/types'
import store, { RootState } from 'store';
import { usersApi } from './users-api';
import { SaveStatus } from 'common/savingStatus';

interface UsersState {
    users: User[],
    savingStatus: SaveStatus
}

const initialState: UsersState = {
    users: [],
    savingStatus: SaveStatus.UNSET
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsersData: (state, action: PayloadAction<User[]>) => {
            state.users = action.payload
        },
        updateUserSavingStatus: (state, action: PayloadAction<SaveStatus>) => {
            state.savingStatus = action.payload
        },
        updateUserStatus: (state, action: PayloadAction<{userId: number, status: UserStatuses}>) => {
            state.users = state.users.map(user => {
                if(user.id === action.payload.userId) {
                    return {
                        ...user,
                        status: action.payload.status
                    }
                } else {
                    return user
                }
            })
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            usersApi.endpoints.getUsers.matchFulfilled,
            (state, { payload }) => {
                state.users = payload
            },
        ),
        builder.addMatcher(
            usersApi.endpoints.editUser.matchFulfilled,
            (state, { payload }) => {
                state.savingStatus = SaveStatus.SAVED

                setTimeout(() => {
                    store.dispatch(updateUserSavingStatus(SaveStatus.UNSET));
                }, 2000);
            },
        ),
        builder.addMatcher(
            usersApi.endpoints.inviteUsers.matchFulfilled,
            (state, { payload }) => {
                state.users = [...state.users, ...payload]
            },
        )
    },
})

export const usersSelector = (store: RootState) => store.users.users;
export const usersSavingStatusSelector = (store: RootState) => store.users.savingStatus;
export const activeUsersSelector = createSelector(
    (store: RootState) => store.users.users as User[],
    (users: User[]) => users.filter((user: User) => user.status === UserStatuses.ACTIVE)
);

export const {
    setUsersData,
    updateUserSavingStatus,
    updateUserStatus,
} = usersSlice.actions;

export default usersSlice;
