import { TabPanel, Flexbox, Select } from 'components'
import styles from '../../products.module.scss';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useEffect } from 'react';
import { Owner, Product, Team } from 'utils/types';
import { activeUsersSelector } from 'store/users-slice';
import { teamsSelector } from 'store/teams-slice';
import { useEditProductMutation } from 'store/product-api';
import { productOwnerSelector, productParentProductSelector, productTeamsSelector, editProduct } from 'store/product-slice';
import { updateProductBody } from 'pages/Products/product';
import { useWorkspaceId } from 'utils/hooks';
import { getProducts } from 'pages/Products/products.api';
import { productsSelector } from 'store/products';
import { useLazyGetTeamsQuery } from 'store/teams-api';
const classes = classNames.bind(styles);

interface OverviewTabProps{
    active: boolean;
    productId: number;
    isEditable?: boolean
}

const OverviewTab = ({ active, productId, isEditable = true }: OverviewTabProps) => {
    const dispatch = useDispatch()
    const workspaceId = useWorkspaceId()

    const [editProductMutation] = useEditProductMutation();
    const [getTeams] = useLazyGetTeamsQuery()

    const users = useSelector(activeUsersSelector);
    const teams = useSelector(teamsSelector);
    const products = useSelector(productsSelector)

    const owner = useSelector(productOwnerSelector)
    const teamIds = useSelector(productTeamsSelector)?.map(team => team.id)
    const parentProductItem = useSelector(productParentProductSelector)

    useEffect(() => {
            getTeams({ workspaceId });
            dispatch(getProducts())
    },[])

    const onOwnerChange = (_e: ChangeEvent<{}>, value: Owner) => {
        dispatch(editProduct({ owner: value }));
        editProductMutation({ workspaceId, productId, data: updateProductBody() })
    }

    const onTeamsChange = (_e: ChangeEvent<{}>, value: Team[]) => {
        dispatch(editProduct({ teams: value }));
        editProductMutation({ workspaceId, productId, data: updateProductBody() })
    }

    const onParenProductChange = (_e: ChangeEvent<{}>, value: Product | null) => {
        dispatch(editProduct({ parentProduct: value?.id ? value : null }));
        editProductMutation({ workspaceId, productId, data: updateProductBody() })
    }

    const ownerUser = users.find(user => user.id === owner?.id);
    const parentProduct = products.find(product => product.id === parentProductItem?.id);

    return(
        <TabPanel className={classes('tabPanel')} active={active}>
            <Flexbox vertical className={classes('infoBox')}>
                <Flexbox className={classes('info')}>
                    <Select
                        onChange={onOwnerChange}
                        options={users}
                        disableClearable
                        value={ownerUser}
                        key={ownerUser?.id}
                        getOptionLabel={option => option.fullName}
                        placeholder='Owner'
                        label='Owner'
                        disabled={!isEditable}
                        getToolTipText={option => option.email}
                    />
                </Flexbox>
                <Flexbox className={classes('info')}>
                    <Select
                        onChange={onParenProductChange}
                        options={products.filter(pro => pro.id !== productId)}
                        value={parentProduct}
                        key={parentProduct?.id}
                        getOptionLabel={option => option.title}
                        placeholder='Parent Product'
                        label='Parent Product'
                        disabled={!isEditable}
                    />
                </Flexbox>
                <Flexbox className={classes('info')}>
                    <Select
                        onChange={onTeamsChange}
                        disableClearable
                        value={teams.filter(t => teamIds?.includes(t.id))}
                        options={teams}
                        multiple
                        getOptionLabel={option => option.name}
                        placeholder='Teams'
                        label='Teams'
                        disabled={!isEditable}
                    />
                </Flexbox>
            </Flexbox>
        </TabPanel>
    )
}

export default OverviewTab