const HandWaving = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_701_3147)">
                <path d="M9.37446 8.31398L7.34321 4.79575C7.13642 4.43689 7.08057 4.01061 7.18794 3.61059C7.2953 3.21058 7.55709 2.86955 7.91578 2.66246C8.27446 2.45538 8.70069 2.39917 9.1008 2.5062C9.50091 2.61323 9.84215 2.87474 10.0495 3.23325L13.1745 8.6459" stroke="#33CCB3" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.98062 10.4178L4.01187 5.27579C3.90907 5.09808 3.8423 4.90185 3.81538 4.69833C3.78845 4.4948 3.8019 4.28796 3.85495 4.08963C3.908 3.89131 3.99962 3.70538 4.12457 3.54248C4.24951 3.37958 4.40533 3.24289 4.58313 3.14024C4.76092 3.03759 4.95721 2.97099 5.16076 2.94423C5.36431 2.91748 5.57113 2.9311 5.76942 2.98432C5.9677 3.03754 6.15355 3.12931 6.31635 3.25439C6.47914 3.37947 6.6157 3.53541 6.7182 3.71329L9.37445 8.31405" stroke="#33CCB3" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.0307 12.9148C11.6163 12.197 11.504 11.344 11.7185 10.5435C11.933 9.74289 12.4568 9.06033 13.1745 8.64593L12.3933 7.29277C12.2905 7.11506 12.2237 6.91883 12.1968 6.71531C12.1699 6.51178 12.1833 6.30494 12.2364 6.10661C12.2894 5.90829 12.381 5.72236 12.506 5.55946C12.6309 5.39655 12.7868 5.25987 12.9645 5.15722C13.1423 5.05457 13.3386 4.98797 13.5422 4.96121C13.7457 4.93446 13.9526 4.94808 14.1508 5.0013C14.3491 5.05451 14.535 5.14629 14.6978 5.27137C14.8606 5.39645 14.9971 5.55239 15.0996 5.73027L16.6621 8.4366C17.0727 9.14741 17.3393 9.93214 17.4465 10.746C17.5538 11.5598 17.4997 12.3868 17.2874 13.1797C17.075 13.9727 16.7085 14.716 16.2088 15.3673C15.7091 16.0186 15.0861 16.565 14.3752 16.9755C13.6643 17.3859 12.8795 17.6523 12.0656 17.7594C11.2517 17.8665 10.4247 17.8122 9.63186 17.5997C8.83898 17.3871 8.09573 17.0205 7.44457 16.5206C6.7934 16.0208 6.24708 15.3976 5.8368 14.6866L2.86805 9.54457C2.66126 9.18571 2.60541 8.75943 2.71278 8.35942C2.82014 7.9594 3.08193 7.61837 3.44062 7.41129C3.7993 7.2042 4.22553 7.14799 4.62564 7.25502C5.02575 7.36205 5.36699 7.62356 5.57438 7.98207L6.98063 10.4178M6.33546 18.7502C5.27612 18.0152 4.39307 17.0543 3.74998 15.9368M13.75 2.42188C14.4631 2.42178 15.1636 2.60949 15.7811 2.96613C16.3986 3.32277 16.9113 3.83575 17.2676 4.45346" stroke="#33CCB3" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_701_3147">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default HandWaving