import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ConfirmationDialog, Flexbox, Loader, Snackbar } from 'components'
import classNames from 'classnames/bind';
import styles from './metrics.module.scss';
import { useWorkspaceId } from 'utils/hooks';
import { Metric, Preferences, PreferencesKeys, Team } from 'utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { uomList } from 'utils/constants';
import { SaveStatus } from 'common/savingStatus';
import { trimSpaces } from 'utils';
import AgGridTable, { AgColumn, ColumnTypes, CustomCellRendererParams, GridStatePreferences } from 'components/AgGridTable';
import { CellEditRequestEvent, CellEditingStoppedEvent, ColDef, GetRowIdFunc, GetRowIdParams, GridApi, GridReadyEvent, SortChangedEvent } from 'ag-grid-community';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import { useSearchParams } from 'react-router-dom';
import MetricsHeader from './MetricsHeader';
import { useCreateMetricMutation, useDeleteMetricMutation, useEditMetricMutation, useGetMetricsQuery, usePushMetricDataMutation } from 'store/metrics-api';
import { deleteMetricAction, metricsSelector, setMetricsData, updateMetricAction, updateMetricSavingStatus } from 'store/metrics-slice';

const classes = classNames.bind(styles);

type InputRowType = { add: boolean, uom: string, name: string, currentValue: string }

export enum FilterKeys {
    query = 'query',
    order = 'order',
    orderBy = 'orderBy',
    metricGridLayout = 'metricGridLayout'
}

interface IPreferencesData {
    query?: string,
    order?: 'asc' | 'desc',
    orderBy?: keyof Team,
    metricGridLayout?: any
}

interface IPreferencesParams extends Omit<IPreferencesData, 'metricGridLayout'> { }

let timeoutId: NodeJS.Timeout;

function isEmptyAddCell(params: { add: boolean, value?: any }) {
    return params?.add && !params.value
}

function onCellEditingStopped(params: CellEditingStoppedEvent) {
    if (params.data.add) {
        const oldCols = params.api.getColumnDefs();

        if (oldCols) {
            const newCols = oldCols.map((col: ColDef<any, any>) => {

                const oldData = params.data;
                const field = params.colDef.field;

                const newData = { ...oldData };
                newData[field!] = params.newValue;

                if (col.field === 'actions') {
                    return {
                        ...col,
                        cellRendererParams: {
                            isAddActive: !!newData.name && !!newData.uom,
                            newRow: newData
                        },
                    }
                } else {
                    return col
                }

            })

            params.api.setGridOption(
                'columnDefs',
                newCols
            );
        }
    }
}

const modifyUpdatedData = (event: CellEditRequestEvent) => {
    if (event.colDef.field === 'name') {
        return trimSpaces(event.newValue)
    } else {
        return event.newValue
    }
}

export default () => {
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();

    const preferencesRef = useRef<IPreferencesData>({});
    const [searchParams, setSearchParams] = useSearchParams();

    const { data: metricsData, isLoading: metricsLoading } = useGetMetricsQuery({ workspaceId });
    const [editMetric] = useEditMetricMutation();
    const [deleteMetric] = useDeleteMetricMutation();
    const [createMetric] = useCreateMetricMutation();
    const [pushMetricData] = usePushMetricDataMutation();

    const metrics = useSelector(metricsSelector);

    const [currentlyDeleting, setCurrentlyDeleting] = useState<number | null>(null);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [inputRow, setInputRow] = useState<InputRowType>({ add: true, uom: '', name: '', currentValue: '' });
    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [searchValue, setSearchValue] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState<null | string>(null);

    const colDefs: AgColumn[] = useMemo(() => [
        {
            headerName: 'Name',
            field: 'name',
            minWidth: 200,
            sortable: true,
            editable: true,
            valueFormatter: (params) =>
                isEmptyAddCell({ add: params.data?.add, value: params.value })
                    ? 'Name...'
                    : params.value,
            cellClass: params => (!params.value && params?.data?.add) ? 'ag-placeholder' : 'ag-custom-cell',
            wrapText: true,
            autoHeight: true,
            valueParser: params => params.newValue || ''
        },
        {
            colType: ColumnTypes.SimpleSelect,
            headerName: 'Unit',
            field: 'uom',
            minWidth: 200,
            sortable: true,
            editable: (params) => isEmptyAddCell({ add: params.data?.add }),
            cellEditorParams: {
                values: uomList
            },
            valueFormatter: (params) => {
                return isEmptyAddCell({ add: params?.data?.add, value: params.value })
                    ? 'Select Unit...'
                    : params.value
            },
            cellClass: params => (!params.value && params?.data?.add) ? 'ag-placeholder' : ''
        },
        {
            cellDataType: 'number',
            headerName: 'Current Value',
            field: 'currentValue',
            minWidth: 200,
            sortable: true,
            editable: true,
            valueFormatter: (params) =>
                isEmptyAddCell({ add: params?.data?.add, value: params.value })
                    ? 'Current Value...'
                    : params.value,
            cellClass: params => (!params.value && params?.data?.add) ? 'ag-placeholder' : ''
        },
        {
            headerName: 'Creator',
            field: 'creator',
            minWidth: 200,
            sortable: true,
            valueFormatter: (params) => {
                return params.value ? params.value?.fullName || '' : ''
            }
        },
        {
            colType: ColumnTypes.Action,
            field: 'actions',
            headerName: '',
            cellRendererParams: {
                isAddActive: false,
            },
            actions: params => {
                if (params?.node?.rowPinned !== 'top') {
                    return [{
                        action: () => showDeleteConfirmation(params.data?.id),
                        label: 'Delete',
                        type: 'red'
                    }]
                } else {
                    return []
                }
            },
        },
    ], [])

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.metricGridLayout))) as unknown as Preferences<FilterKeys>[];

        if (preferences && preferences.length) {
            const { metricGridLayout, order, orderBy, query } = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;

            preferencesRef.current = {
                query,
                order,
                orderBy,
                metricGridLayout
            }

            if (searchParams.toString().length === 0) {
                let queryFilters: IPreferencesParams = {}

                queryFilters = {
                    ...queryFilters,
                    ...(order && orderBy ? { order, orderBy } : {}),
                    ...(query ? { query } : {})
                };

                setSearchParams(queryFilters, { replace: true })
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await loadPreferences()
            setLoading(false)
        }
        setLoading(true);
        fetchData();
    }, [])

    useEffect(() => {
        if (metricsData) {
            const filteredData = metricsData.filter((metric: Metric) => metric.name?.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase()));
            dispatch(setMetricsData(filteredData));
        }
    }, [metricsData, debouncedQuery]);

    useEffect(() => {
        if (!loading) {
            const queryString = searchParams.get(FilterKeys.query);
            if (queryString) {
                setSearchValue(queryString);
            }
        }
    }, [loading])

    useEffect(() => {
        if (debouncedQuery !== null) {
            dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.metricGridLayout));
        }

        return () => clearTimeout(timeoutId);
    }, [debouncedQuery])

    const showDeleteConfirmation = (id: number) => {
        setCurrentlyDeleting(id);
        setOpenConfirmation(true)
    }

    const onCancelDelete = () => {
        setCurrentlyDeleting(null);
        setOpenConfirmation(false)
    }

    const onDeleteConfirmation = async () => {
        if (currentlyDeleting) {
            try {
                const res: any = await deleteMetric({ workspaceId, metricId: currentlyDeleting });
                if (res.error) {
                    setErrorMessage(res.error.data.errorMessage)
                } else {
                    dispatch(deleteMetricAction(currentlyDeleting))
                    onCancelDelete();
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const onUpdateMetric = async (metric: Metric & { add?: boolean }, updateState?: boolean, field?: string) => {
        if (!metric.add) {
            if (field === 'name') {
                const metricName = trimSpaces(metric.name);
                const alreadyExists = metrics.some(m => (trimSpaces(m.name) === metricName && m.id !== metric.id));

                if (!metric.name) {
                    setErrorMessage('Metric is required');
                } else if (alreadyExists) {
                    setErrorMessage('Metric name should be unique');
                    return false;
                } else {
                    dispatch(updateMetricSavingStatus(SaveStatus.SAVING));

                    const res: any = await editMetric({
                        workspaceId, metricId: metric.id, data: {
                            description: '',
                            formula: '',
                            level: 0,
                            name: metricName,
                            uom: metric.uom
                        }
                    })

                    if (res.error) {
                        setErrorMessage(res.error.data.errorMessage)
                    } else if (updateState) {
                        dispatch(updateMetricAction(metric))
                    }

                    return !res.error
                }
            } else if (field === 'currentValue') {
                dispatch(updateMetricSavingStatus(SaveStatus.SAVING));
                const res: any = await pushMetricData({ workspaceId, metricId: metric.id, value: metric.currentValue });

                if (res.error) {
                    setErrorMessage(res.error.data.errorMessage)
                } else if (updateState) {
                    dispatch(updateMetricAction(metric))
                }

                return !res.error
            }
        }
    }

    const onAddMetric = async (params: CustomCellRendererParams) => {
        const newRow = params.newRow

        if (!newRow.name) {
            setErrorMessage('Metric is required');
        } else if (metrics.some(metric => trimSpaces(metric.name) === trimSpaces(newRow.name))) {
            setErrorMessage('Metric name should be unique');
        } else if (!newRow.uom) {
            setErrorMessage('Unit is required')
        } else {
            const newMetric = {
                description: '',
                formula: '',
                level: 0,
                name: trimSpaces(newRow.name),
                uom: newRow.uom,
            }

            const res: any = await createMetric({ workspaceId, data: newMetric });

            setInputRow({ add: true, uom: '', name: '', currentValue: '' });

            if (res.error) {
                setErrorMessage(res.error.data.errorMessage)
            } else {
                if (newRow.currentValue) {
                    await pushMetricData({ workspaceId, metricId: res.data.id, value: Number(newRow.currentValue) });
                    dispatch(updateMetricAction({ ...res.data, currentValue: newRow.currentValue }));
                }

                const oldCols = params.api.getColumnDefs();

                if (oldCols) {
                    const newCols = oldCols.map((col: ColDef<any, any>) => {
                        if (col.field === 'actions') {
                            return {
                                ...col,
                                cellRendererParams: {
                                    isAddActive: false,
                                    newRow: { add: true, uom: '', name: '', currentValue: '' }
                                },
                            }
                        } else {
                            return col
                        }
                    })

                    params.api.setGridOption(
                        'columnDefs',
                        newCols
                    );
                }
            }
        }
    }

    const updateQueryPreference = useCallback((value: string) => {
        setSearchValue(value);
        preferencesRef.current.query = value;

        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setDebouncedQuery(value);
        }, 500);

    }, [preferencesRef])

    const onSortChanged = useCallback((e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        if (preferencesRef.current.order !== value?.sort || preferencesRef.current.orderBy !== value?.colId) {
            preferencesRef.current = { metricGridLayout: preferencesRef.current.metricGridLayout, ...modifiedSearchParamsObject }
            dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.metricGridLayout));
        }
    }, [preferencesRef, searchParams])

    const onGridStateChanged = useCallback((data: GridStatePreferences) => {
        preferencesRef.current.metricGridLayout = data;

        dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.metricGridLayout));
    }, [preferencesRef])

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    const getRowId = useMemo<GetRowIdFunc>(() => {
        return (params: GetRowIdParams) => params.data.id;
    }, []);

    return ((metricsLoading || loading) ? <Flexbox fullWidth align justify><Loader /></Flexbox> :
        <>
            <Flexbox vertical fullWidth className={classes('mainContainer')}>
                <MetricsHeader gridApi={gridApi} searchValue={searchValue} updateQueryPreference={updateQueryPreference} />
                <Flexbox className={classes('tableContainer')}>
                    <AgGridTable
                        data={metrics}
                        columns={colDefs}
                        pinnedTopRowData={[inputRow]}
                        onAddNewRow={onAddMetric}
                        onCellEditingStopped={onCellEditingStopped}
                        onGridReady={onGridReady}
                        order={preferencesRef.current.order}
                        orderBy={preferencesRef.current.orderBy}
                        gridStatePreferences={preferencesRef.current.metricGridLayout}
                        onGridStateChanged={onGridStateChanged}
                        onSortChanged={onSortChanged}
                        exportFileName='Metrics'
                        readOnlyEdit={true}
                        updateRowCallback={onUpdateMetric}
                        updateAddingRow={setInputRow}
                        modifyUpdatedData={modifyUpdatedData}
                        getRowId={getRowId}
                    />
                </Flexbox>
            </Flexbox>
            <ConfirmationDialog
                open={openConfirmation}
                onClose={onCancelDelete}
                onConfirm={onDeleteConfirmation}
                confirmButtonStyle='danger'
                title='Delete this Metric?'
            >
                <Flexbox vertical>
                    You are about to permanently delete this metric.
                    <br />
                    Doing so
                    <br />
                    <ul>
                        <li>Connected KRs will be deleted</li>
                        <li>Contributing initiatives will be disconnected</li>
                        <li>Historical data will be lost</li>
                    </ul>
                </Flexbox>
            </ConfirmationDialog>
            <Snackbar
                type='error'
                open={!!errorMessage}
                onClose={() => setErrorMessage(null)}
                autoHideDuration={3000}
            >
                <Flexbox>{errorMessage}</Flexbox>
            </Snackbar>
        </>
    )
}
