import { Dispatch, GetState } from 'store';
import { ACTION_ADD_USER_INTEGRATION, ACTION_ADD_WORKSPACE_INTEGRATION, ACTION_DELETE_WORKSPACE_INTEGRATIONS, ACTION_GET_WORKSPACE_INTEGRATIONS, ACTION_SET_AVAILABLE_INTEGRATIONS, ACTION_SET_WORKSPACE_INTEGRATIONS } from 'store/integrations';
import request from 'utils/request';
import { JiraMapping } from 'utils/types';


export const setIntegrationCode = (type: number, code: string) => async (dispatch: Dispatch, getState: GetState) => {
    try{
        const storeState = getState()
        const res = await request.post(`workspaces/${storeState.user.workspace.id}/integrations/${type}/token?code=${code}`);
        if(res.data) {
            dispatch({ type: ACTION_ADD_USER_INTEGRATION, payload: res.data })
        }
    } catch(err: any) {
        console.log(err);
        return { error: err.message }
    }


}

export const setWorkspaceIntegrationCode = (code: string, name: string) => async (dispatch: Dispatch, getState: GetState) => {
    try{
        const storeState = getState()
        const res = await request.post(`workspaces/${storeState.user.workspace.id}/integrations`, {
            name,
            code
        });
        if(res.data) {
            dispatch({ type: ACTION_ADD_WORKSPACE_INTEGRATION, payload: res.data })
        }
    } catch(err: any) {
        console.log(err);
        return { error: err.message }
    }


}

export const getAvailableIntegrations = () => async (dispatch: Dispatch, getState: GetState) => {
    try {
        const res = await request.get('/integrations');
        if(res.data) {
            dispatch({ type: ACTION_SET_AVAILABLE_INTEGRATIONS, payload: res.data });
        }
    } catch (err: any) {
        console.log(err);
        return { error: err.message }
    }
}

export const getWorkspaceIntegrations = () => async (dispatch: Dispatch, getState: GetState) => {

    const storeState = getState()

    dispatch({ type: ACTION_GET_WORKSPACE_INTEGRATIONS, payload: null })

    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/integrations`);
    if(res.data) {
        dispatch({ type: ACTION_SET_WORKSPACE_INTEGRATIONS, payload: res.data });
        return res.data
    }

}

export const disconnectIntegration = (integrationName: string) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    await request.delete(`/workspaces/${storeState.user.workspace.id}/integrations/${integrationName}`);
    dispatch({ type: ACTION_DELETE_WORKSPACE_INTEGRATIONS, payload: integrationName });
}

export const updateJiraMappings = (mappings: JiraMapping[]) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    await request.put(`/workspaces/${storeState.user.workspace.id}/integrations/JIRA`, {
        mappings
    });
}
