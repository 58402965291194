import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const usersApi = createApi({
    reducerPath: 'users.api',
    baseQuery: rtkBaseQueryWithReauth,
    tagTypes: ['users'],
    endpoints: builder => ({
        getUsers: builder.query({
            query: ({ workspaceId }) => `/workspaces/${workspaceId}/users`,
        }),
        inviteUsers: builder.mutation({
            query: ({ workspaceId, data }) => {
              return {
                url: `/workspaces/${workspaceId}/users/invite`,
                method: 'POST',
                body: data,
              };
            },
        }),
        resendInviteUsers: builder.mutation({
            query: ({ workspaceId, data }) => {
              return {
                url: `/workspaces/${workspaceId}/users/invite`,
                method: 'POST',
                body: data,
              };
            },
        }),
        deactivateUser: builder.mutation({
            query: ({ workspaceId, userId }) => {
              return {
                url: `/workspaces/${workspaceId}/users/${userId}/deactivate`,
                method: 'POST',
              };
            },
        }),
        editUser: builder.mutation({
            query: ({ workspaceId, userId, data }) => {
                return {
                    url: `/workspaces/${workspaceId}/users/${userId}`,
                    method: 'PUT',
                    body: data
                };
            },
        }),
    })
});

export const {
    useDeactivateUserMutation,
    useEditUserMutation,
    useGetUsersQuery,
    useInviteUsersMutation,
    useLazyGetUsersQuery,
    useResendInviteUsersMutation,
} = usersApi;