import { Box, Collapse, TableCell, TableRow } from '@mui/material';
import { Flexbox, Link } from 'components';
import { ArrowCollapsedIcon, ArrowExpendedIcon } from 'components/icons';
import React, { ReactNode, useEffect, useState } from 'react';

export interface RowProps {
    onClick?: (index: number) => void;
    data: any[];
    hasEditableField?: boolean;
    index: number;
    autoOpen?: boolean;
    collapsible?: boolean;
    tableCollapsible?: boolean;
    url?: string;
    openRenderer?: (data: any[], index: number) => ReactNode;
}


const Row = ({ onClick, data, hasEditableField, index, collapsible = true, tableCollapsible, autoOpen, openRenderer, url }: RowProps) => {
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if(autoOpen) {
            setIsOpen(true);
        }
    }, []);
    const toggleRow = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if(collapsible) {
            setIsOpen(oldValue => !oldValue)
        }
    }

    const tableRowClass = `tableRow${onClick || url ? ' clickable' : ''}${isOpen ? ' isOpen' : ''}${hasEditableField ? ' hasEditableField' : ''}${index % 2 ? ' even' : ' odd'}`

    return (
        <>
            <TableRow  onClick={() => onClick && onClick(index)} className={tableRowClass}>

                {tableCollapsible && (
                    <TableCell onClick={toggleRow}>
                        {
                            collapsible && (
                                <Flexbox justify className='arrowIcon'>
                                    {isOpen ? <ArrowExpendedIcon/> : <ArrowCollapsedIcon />}
                                </Flexbox>
                            )
                        }
                    </TableCell>
                )}
                {

                    data.map((cell, index) => {
                        return (

                            <TableCell
                                key={index}
                                classes={{
                                    root: 'cell'
                                }}
                                className={url ? '' : 'cellStandard'}
                            >
                                {url ? <Link className='cellLink' to={url}>{cell}</Link> : cell}
                            </TableCell>

                        )
                    })
                }

            </TableRow>
            {
                tableCollapsible && (
                    <TableRow>

                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={data.length + 1}>
                            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                                <Box margin={1}>
                                    {openRenderer ? openRenderer(data, index) : ''}
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )
            }
        </>
    )
}

export default Row;