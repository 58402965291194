import { CustomTyphography, Flexbox } from 'components'
import styles from './prdScorSidePanel.module.scss'
import classNames from 'classnames/bind';
import { Point } from 'utils/types';
import { FC } from 'react';

const classes = classNames.bind(styles);

interface InsightPointProps {
    point: Point
}

const InsightPoint: FC<InsightPointProps> = ({ point }) => {
    return (
        <Flexbox>
            <Flexbox vertical className={classes('pointsContainer', 'gap-3')}>
                <Flexbox vertical className={classes('gap-2')}>
                    <CustomTyphography type='primary' className={classes('feedbackTitle')}>{point.title}</CustomTyphography>
                    {point.content && <CustomTyphography className={classes('feedbackDescription')}>{point.content}</CustomTyphography>}
                </Flexbox>

                {point.recommendation && (
                    <Flexbox vertical className={classes('gradSuggestion', 'gap-2')}>
                        <CustomTyphography type='primary' className={classes('feedbackTitle')}>Suggestion</CustomTyphography>
                        <CustomTyphography className={classes('feedbackDescription')}>{point.recommendation}</CustomTyphography>
                    </Flexbox>
                )}
            </Flexbox>
        </Flexbox>
    )
}

export default InsightPoint