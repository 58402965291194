import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';


interface CustomProps {
    onChange?: (event: { target: { name: string; value: string } }) => void;
    name?: string;
    displayType?: 'text' | 'input';
    value?: string | number | null;
  }

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom({ onChange, value, name = '', displayType = 'input', ...props }, ref) {

        return (
            <NumericFormat
                getInputRef={ref}
                onValueChange={onChange ? (values) => {
                    onChange({
                        target: {
                            name: name,
                            value: values.value,
                        },
                    });
                } : undefined}
                value={value}
                valueIsNumericString
                thousandSeparator
                displayType={displayType}
                {...props}
            />
        );
    },
);

export default NumericFormatCustom