const PriorityLowIcon = () => {
    return (
        <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.232868 0.371915C0.591031 -0.0635683 1.2336 -0.125582 1.66809 0.233402L10.3219 7.38339L9.02486 8.96041L0.371065 1.81043C-0.0634222 1.45144 -0.125296 0.807398 0.232868 0.371915Z" fill="#0075FF"/>
            <path d="M17.7614 0.374427C18.1234 0.806761 18.0671 1.45132 17.6358 1.81409L9.09153 9L7.78083 7.43438L16.3251 0.248473C16.7564 -0.114298 17.3995 -0.0579069 17.7614 0.374427Z" fill="#0075FF"/>
        </svg>

    )
}

export default PriorityLowIcon;