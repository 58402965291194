import { Flexbox, Loader, TabPanel } from 'components'
import { FC, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from '../../../initiative.module.scss';
import InitiativeStates from './InitiativeStates';
import StoriesProgressTable from './StoriesProgressTable';
import InitiativeGrading from './InitiativeGrading';
import { useLazyGetInitiativeScoreQuery, useLazyGetInitiativeTrackingQuery } from 'store/initiativeGrading-api';
import { useWorkspaceId } from 'utils/hooks';
import { useParams } from 'react-router-dom';
import JiraStoriesProgressTable from './JiraStoriesProgressTable';

const classes = classNames.bind(styles);

interface ExecutionProps {
    active: boolean
}

const Execution: FC<ExecutionProps> = ({ active }) => {
    const workspaceId = useWorkspaceId();
    const params = useParams();

    const [getInitiativeTracking, { isLoading, data }] = useLazyGetInitiativeTrackingQuery()
    const [getInitiativeScore, { isLoading: isScoreLoading }] = useLazyGetInitiativeScoreQuery();

    useEffect(() => {
        if (active) {
            getInitiativeTracking({ workspaceId, id: params.id });
            getInitiativeScore({ workspaceId, id: params.id })
        }
    }, [active])

    return (
        <TabPanel vertical active={active} className={classes('gradingContainer')}>
            <Flexbox vertical fullWidth className={classes('tabContainer')} style={{ width: '100%' }}>
                {/* __________________________Tracking_______________________ */}
                {isLoading && <Flexbox fullWidth fullHeight align justify><Loader disableShrink /></Flexbox>}
                {
                    // Not Yet Synced, qualityScore == score
                    data && data.qualityScore !== null && (
                        <>
                            <InitiativeStates />
                            {/* Jira synced Tasks  */}
                            {!!data.jiraStories?.length && <JiraStoriesProgressTable />}
                            {/* Non synced tasks */}
                            {!!data.stories?.length && <StoriesProgressTable />}
                        </>
                    )
                }

                {/* _________________________AI Feedback____________________________ */}
                {!isScoreLoading && <InitiativeGrading />}
            </Flexbox>
        </TabPanel>
    )
}

export default Execution;