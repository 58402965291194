
import Flexbox, { FlexboxProps } from '../Flexbox'
import classNames from 'classnames/bind';

import styles from './tabPanel.module.scss';
import React from 'react';
const classes = classNames.bind(styles);

interface TabPanelOwnProps {
    active?: boolean;
}

export type TabPanelProps = TabPanelOwnProps & FlexboxProps

const TabPanel = React.forwardRef<HTMLDivElement, TabPanelProps>(({ className, active, children, ...props },ref) => {
    return (
        <Flexbox
            className={classes('tabPanelContainer', { hidden: !active }, className)}
            {...props}
            ref={ref}
        >{children}</Flexbox>
    )
})

export default TabPanel