import { useState, MouseEvent } from 'react';
import { Button, Checkbox, Flexbox, Menu, MenuItem, RadioButton } from 'components';

import styles from './groupFilter.module.scss';
const classes = classNames.bind(styles);
import classNames from 'classnames/bind';
import { TuneIcon } from 'components/icons';

export interface GroupFilterOption{
    id: number;
    title: string;
}

interface GroupFilterProps {
    label?: string;
    title: string;
    groups: FilterGroup[];
}

interface FilterGroupBase {
    title: string;
    options: GroupFilterOption[];
}

interface FilterGroupSingle extends FilterGroupBase {
    onChange: (option: GroupFilterOption) => void
    value:  GroupFilterOption;
    multiple: false
}
interface FilterGroupMulti extends FilterGroupBase {
    onChange: (option: GroupFilterOption[]) => void
    value:  GroupFilterOption[];
    multiple: true
}

type FilterGroup = FilterGroupSingle | FilterGroupMulti;

function isMultiple(props: FilterGroupSingle | FilterGroupMulti): props is FilterGroupMulti {
    return props.multiple;
}

export default ({ label, title, groups, ...props }: GroupFilterProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const onButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onMenuItemClick = (option: GroupFilterOption, group: FilterGroup) => {

        if(isMultiple(group)) {

            if(group.value.find(o => option.id === o.id)){
                group.onChange(group.value.filter(o => option.id !== o.id))
            }else{
                group.onChange([...group.value, option])
            }
        } else {
            group.onChange(option);
            handleClose
        }
    }

    const isSelectedOption = (option: GroupFilterOption, group: FilterGroup) => {
        if(isMultiple(group)) {
            return group.value.some(i => option.id === i.id)
        } else {
            return group.value.id === option.id
        }

    }

    return (
        <Flexbox className={classes('container')}>
            <Button
                className={classes('button', { active: open }, { 'iconButton': !label })}
                onClick={onButtonClick}
                variant="contained"
                startIcon={<TuneIcon />}
            >
                {label}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                classes={{
                    paper: classes('menu')
                }}
            >

                <Flexbox className={classes('mainTitle')}>{title}</Flexbox>
                {groups.map((group, index) => {
                    return (
                        <Flexbox className={classes('groupContainer')} vertical key={`${group.value}-${index}`}>
                            <Flexbox className={classes('groupTitle')}>{group.title}</Flexbox>
                            {group.options.map((option, index) => {
                                const isSelected = isSelectedOption(option, group);
                                return (
                                    <MenuItem
                                        disableGutters
                                        className={classes('menuItem')}
                                        key={index}
                                        selected={isSelected}
                                        onClick={() => onMenuItemClick(option, group)}
                                    >
                                        {group.multiple ?
                                            <Checkbox className={classes('checkbox')} size='small' checked={isSelected} />
                                            :
                                            <RadioButton className={classes('checkbox')} size='small' checked={isSelected} />}
                                        {option.title}
                                    </MenuItem>
                                )})}
                        </Flexbox>
                    )
                })}
            </Menu>
        </Flexbox>
    )
}