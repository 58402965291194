import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const productApi = createApi({
    reducerPath: 'product.api',
    baseQuery: rtkBaseQueryWithReauth,
    tagTypes: ['product'],
    endpoints: builder => ({
      getProduct: builder.query({
        query: ({ workspaceId, productId }) => `/workspaces/${workspaceId}/products/${productId}`,
        providesTags: ['product']
      }),
      editProduct: builder.mutation({
        query: ({ workspaceId, productId, data }) => {
          return {
            url: `/workspaces/${workspaceId}/products/${productId}`,
            method: 'PUT',
            body: data
          };
        },
      }),
      createProduct: builder.mutation({
        query: ({ workspaceId, product }) => {
          return {
            url: `/workspaces/${workspaceId}/products`,
            method: 'POST',
            body: product,
          };
        },
      }),
      getInitiatives: builder.query({
        query: ({ workspaceId, productId }) => `/workspaces/${workspaceId}/products/${productId}/initiatives`,
      }),
      deleteProduct: builder.mutation({
        query: ({ workspaceId, productId }) => {
          return {
            url: `/workspaces/${workspaceId}/products/${productId}`,
            method: 'DELETE',
          };
        },
      }),
    })
});

  export const {
    useGetProductQuery,
    useLazyGetProductQuery,
    useGetInitiativesQuery,
    useLazyGetInitiativesQuery,
    useEditProductMutation,
    useCreateProductMutation,
    useDeleteProductMutation
  } = productApi