import { DatePicker, Flexbox, Link, RichTextEditor, Select } from 'components';
import styles from '../dependency.module.scss';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, FC, useMemo } from 'react';
import {
    dependencyDescriptionSelector,
    dependencyDueDateSelector,
    dependencyInitiativeSelector,
    dependencyOwnerSelector,
    dependencyRequirementSelector,
    dependencyTeamSelector,
    dependencyTitleSelector,
    editDependency
} from 'store/dependency-slice';
import { Team } from 'utils/types';
import { useUpdateDependencyMutation } from 'store/dependency-api';
import { useWorkspaceId } from 'utils/hooks';
import { userSelector } from 'store/user';
import { teamsSelector } from 'store/teams-slice';
const classes = classNames.bind(styles);

interface DependencyLeftPanelProps {
    dependencyId: number,
}

const DependencyLeftPanel: FC<DependencyLeftPanelProps> = ({ dependencyId }) => {
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();

    const teams = useSelector(teamsSelector);
    const teamId = useSelector(dependencyTeamSelector);
    const dueDate = useSelector(dependencyDueDateSelector);
    const owner = useSelector(dependencyOwnerSelector);
    const initiative = useSelector(dependencyInitiativeSelector);
    const title = useSelector(dependencyTitleSelector);
    const requirement = useSelector(dependencyRequirementSelector);
    const description = useSelector(dependencyDescriptionSelector);
    const currentUser = useSelector(userSelector);

    const [updateDependencyMutation] = useUpdateDependencyMutation();

    const ownerUser = useMemo(() => {
        return currentUser.id === owner?.id;
    },[currentUser, owner])

    const saveDependency = async (name: string, value: any) => {
        try {
            await updateDependencyMutation({ workspaceId, dependencyId,  data: { [name]: value } })
        } catch (error) {
            console.error('Failed to update dependency', error);
        }
    }

    const onUpdateState = (name: string, value: any) => {
        dispatch(editDependency({ [name]: value }));
    }

    const onFieldChange = (name: string, value: any) => {
        saveDependency(name, value);
        onUpdateState(name, value)
    }

    const selectedTeam = useMemo(
        () => teams.find(t => t.id === teamId),
        [teams, teamId],
    );

    return (
        <Flexbox vertical className={classes('colLeft')}>
            <Flexbox vertical className={classes('fieldContainer', 'fieldMaxSize')}>
                     <Select
                        value={selectedTeam || {} as Team}
                        onChange={(_e: ChangeEvent<{}>, value: Team) => onFieldChange('teamId', value.id)}
                        options={teams}
                        getOptionLabel={option => option.name + ' / ' + (option.lead ? option.lead?.fullName : '-')}
                        label='Resolving Team/lead'
                        disableClearable
                        disabled={!ownerUser}
                    />
            </Flexbox>
            <Flexbox vertical className={classes('fieldContainer', 'fieldMaxSize')}>
                <DatePicker
                    selected={dueDate}
                    onChange={(date: Date | null) => onFieldChange('dueDate', date?.getTime())}
                    fullWidth
                    label='Due Date'
                    disabled={!ownerUser}
                />
            </Flexbox>
            <Flexbox vertical className={classes('fieldContainer', 'fieldMaxSize')}>
                <Select
                    value={owner?.fullName}
                    options={[]}
                    label='Requested By'
                    placeholder='Requested By'
                    disabled
                />
            </Flexbox>
            <Flexbox vertical className={classes('fieldContainer')}>
                <Flexbox className={classes('fieldLabel')}>
                    Dependant Initiative
                </Flexbox>
                <Flexbox className={classes('fieldTitle', 'fieldInitiativeLink')}>
                    {initiative.deleted ?
                        <Flexbox>
                            {initiative.title}
                            <Flexbox className={classes('deletedItemText')}>(Deleted)</Flexbox>
                        </Flexbox>
                        :
                        <Link to={`/initiatives/initiative/${initiative.id}`}>
                            {initiative.title}
                        </Link>
                    }
                </Flexbox>
            </Flexbox>
            <Flexbox vertical className={classes('fieldContainer')}>
                <Select
                    value={title}
                    options={[]}
                    label='Main Requirement'
                    placeholder='Title'
                    disabled
                    className={classes('requirementTitle')}
                />
                <Flexbox vertical className={classes('notEditable', 'requirementDescription')}>
                    <RichTextEditor
                        value={requirement}
                        onChange={() => {}}
                        placeholder="Description"
                        files={[]}
                        readOnly
                        disabled
                    />
                </Flexbox>
            </Flexbox>
            <Flexbox vertical className={classes('fieldContainer')}>
                <RichTextEditor
                    value={description}
                    onChange={(value) => onUpdateState('description', value)}
                    onBlur={() => saveDependency('description', description)}
                    placeholder='Description'
                    label='Description'
                    disabled={!ownerUser}
                    files={[]}
                />
            </Flexbox>
        </Flexbox>
    )
}

export default DependencyLeftPanel