import { Flexbox, Loader, Tabs, Tab } from 'components';
import { ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './okr.module.scss';
import { userSelector } from 'store/user';
import { InitiativesTab, KeyResultsTab, OverviewTab } from './components/tabs';
import { getQuarterEndDate, getQuarterStartDate } from 'utils/date';
import { useCreateOkrMutation, useLazyGetOkrByIdQuery } from 'store/okr-api';
import { useWorkspaceId } from 'utils/hooks';
import OkrHead from './components/OkrHead';
import store from 'store';
const classes = classNames.bind(styles);

const tabsList: string[] = ['overview', 'keyResults', 'initiatives']
const urlTabParamKey = 'tab';

export const defaultTitle = 'Untitled OKR';

export const updateOkrBody = () => {
    const currentOkr = store.getState().okr.currentOkr

    return {
        owner: currentOkr.owner,
        endDate: currentOkr.endDate?.getTime(),
        startDate: currentOkr.startDate?.getTime(),
        objective: currentOkr.title,
        parentOkr: currentOkr.parentOkr,
        teams: currentOkr.okrTeams
    }
}

const OkrForm = () => {
    const params = useParams();
    const okrId = params['id'] ? parseInt(params['id']) : undefined;

    const navigate = useNavigate();
    const workspaceId = useWorkspaceId();

    const [searchParams, setSearchParams] = useSearchParams();

    const [getOkrById, { data: currentOkr, isLoading }] = useLazyGetOkrByIdQuery()
    const [createOkr] = useCreateOkrMutation()

    const user = useSelector(userSelector);

    const onTabChange = (_event: ChangeEvent<{}>, value: number) => {
        setSearchParams(`${urlTabParamKey}=${tabsList[value]}`,{ replace: true })
    }

    const selectedTab = tabsList.indexOf(searchParams.get(urlTabParamKey) || '');

    useEffect(() => {
        if(!okrId) {
            createOkrHandler()
        } else {
            getOkrById({ okrId, workspaceId });
        }

        const tab = searchParams.get(urlTabParamKey);
        if(!tab || !tabsList.includes(tab)) {
            setSearchParams(`${urlTabParamKey}=${tabsList[1]}`,  { replace: true })
        }
    }, [okrId])

    const createOkrHandler = async () => {
        try {
            const res = await createOkr({
                workspaceId,
                model: {
                    objective: defaultTitle,
                    owner: user.id,
                    startDate: getQuarterStartDate(new Date()).getTime(),
                    endDate: getQuarterEndDate(new Date()).getTime()
                }
            }).unwrap()
            navigate(`/okrs/okr/${res.id}`, { replace: true })
        } catch (error) {
            console.error('Failed to create okr', error);
        }
    }

    return (
        isLoading ? (<Flexbox fullWidth align justify><Loader disableShrink/></Flexbox>) : (
            <Flexbox vertical fullWidth className={classes('mainContainer')}>
                {
                    okrId && currentOkr && (
                        <>
                            <OkrHead okrId={okrId} />
                            <Tabs
                                value={selectedTab !== -1 ? selectedTab : false}
                                onChange={onTabChange}
                                className={classes('tabs')}
                            >
                                <Tab label="Overview" id='0' />
                                <Tab label="Key Results" id='1' />
                                <Tab label="Initiatives" id='2' />
                            </Tabs>
                            <OverviewTab
                                active={selectedTab === 0}
                                okrId={okrId}
                            />
                            <KeyResultsTab
                                active={selectedTab === 1}
                                okrId={okrId}
                            />
                            <InitiativesTab
                                active={selectedTab === 2}
                                okrId={okrId}
                            />
                        </>
                    )
                }
            </Flexbox>
        )
    )
}

export default OkrForm;
