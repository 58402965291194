import { Box, Button, Flexbox, Typography } from 'components'
import { FC } from 'react';
import classNames from 'classnames/bind';
import styles from '../../../initiative.module.scss';
import { Point, Subsection } from 'utils/types';
import { feedbackPercentage } from './constants';
import PointItem from './PointItem';
import { useWorkspaceId } from 'utils/hooks';
import { useDeleteIrrelevantPointMutation, useLazyGetInitiativeScoreQuery } from 'store/initiativeGrading-api';
import { useDispatch, useSelector } from 'react-redux';
import { deleteIrrelevantPointAction, insightIdSelector } from 'store/initiativeGrading-slice';
import { useParams } from 'react-router-dom';

const classes = classNames.bind(styles);

interface GradingBoxProps {
    score?: number;
    title: string
    content?: string
    id?: number
    irrelevanceId?: number
    points?: Point[] | null
    subsections?: Subsection[] | null
}

const GradingBox: FC<GradingBoxProps> = ({ score, title, content, id, irrelevanceId, points, subsections }) => {
    const scoreBgColor = score ? feedbackPercentage.find(el => Math.abs(score) >= el.percentage[0] && Math.abs(score) <= el.percentage[1])?.bgColor : ''

    const workspaceId = useWorkspaceId();
    const params = useParams();
    const dispatch = useDispatch();

    const [deleteIrrelevantPoint, { isLoading: deleteIsLoading }] = useDeleteIrrelevantPointMutation();
    const [getInitiativeScore] = useLazyGetInitiativeScoreQuery()

    const insightId = useSelector(insightIdSelector);

    const handleDeleteIrrelevantPoint = async (pointId: number, irrelevanceId: number) => {
        try {
            await deleteIrrelevantPoint({ workspaceId, id: params.id, insightId, pointId, irrelevanceId })
            dispatch(deleteIrrelevantPointAction(irrelevanceId))
            await getInitiativeScore({ workspaceId, id: params.id })
        } catch (err) {
            console.log(err, 'err');
        }
    }

    return (
        <Flexbox className={classes('gradingBox')}>
            {!!score && (
                <Box className={classes('scoreBox', scoreBgColor)}>
                    -{score}%
                </Box>
            )}

            <Flexbox vertical className={classes('gradingContent')}>
                <Typography variant='h4' className={classes('insightTitle')}>{title}</Typography>
                {/* ________________________________IRELEVANT______________________________ */}
                {
                    irrelevanceId && id ? (
                        <Flexbox className={classes('spaceBetween')}>
                            <Typography className={classes('feedbackDescription')}>{content}</Typography>
                            <Button
                                variant='contained'
                                disabled={deleteIsLoading}
                                onClick={() => handleDeleteIrrelevantPoint(id, irrelevanceId)}
                                className={classes('deleteIrelevantBtn')}
                            >
                                Delete
                            </Button>
                        </Flexbox>
                    ) :
                        <>
                            {/* ________________________________POINTS______________________________ */}
                            {
                                points && (
                                    <>
                                        {points.filter(el => el.isRelevant).map(point => <PointItem key={point.id} point={point} />)}
                                    </>
                                )
                            }

                            {/* ________________________________SUBSECTIONS______________________________ */}

                            {
                                subsections && (
                                    <Flexbox vertical className={classes('subsectionsContainer')}>
                                        {subsections.map((subsection, sIndex) => (
                                            <Flexbox vertical key={`${subsection.title}_${sIndex}`} className={classes('subsectionsBox')}>
                                                <Typography variant='h5' className={classes('subsectionTitle')}>{subsection.title}</Typography>
                                                {
                                                    subsection.points.filter(el => el.isRelevant).map(point => <PointItem key={point.id} point={point} />)
                                                }
                                            </Flexbox>
                                        ))}
                                    </Flexbox>
                                )
                            }
                        </>
                }
            </Flexbox>
        </Flexbox>
    )
}

export default GradingBox;