const Fire = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_701_5514)">
                <path d="M14.0147 11.5625C13.8818 12.4138 13.4819 13.2009 12.8726 13.8101C12.2634 14.4194 11.4763 14.8193 10.625 14.9522M5.62781 5.10607C4.4217 6.92429 3.4375 9.00121 3.4375 10.9375C3.4375 12.678 4.1289 14.3472 5.35961 15.5779C6.59032 16.8086 8.25952 17.5 10 17.5C11.7405 17.5 13.4097 16.8086 14.6404 15.5779C15.8711 14.3472 16.5625 12.678 16.5625 10.9375C16.5625 6.87503 13.75 3.75003 11.4706 1.47437L11.4705 1.47443L8.75 7.18753L5.62789 5.10612L5.62781 5.10607Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_701_5514">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Fire