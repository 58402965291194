import { Editor } from 'tinymce';
import { useEffect } from 'react';
import {
    confirmNewCommentSegment,
    createCustomEvent,
    highLightCommentSegment,
    highLightEditor,
    removeNewCommentSegment,
    resolveCommentSegment,
} from 'utils/comments';
import {
    CommentEventCreateSuccessDetail,
    CommentEventCurrentHighLightDetail,
    CommentEventDeleteDetail,
    CommentEventHighLightEditorDetail,
    CommentEventResolveDetail,
    CommentExtended,
    CommentFieldKey,
} from 'utils/types';

interface UseCommentEffectsOptions {
    editor: Editor | null;
    comments: CommentExtended[];
    onValueChange: (newValue: string) => void;
    onSuccess?: () => void;
    fieldKey?: CommentFieldKey;
    objectId?: number
}

export const useCommentEffects = ({ editor, comments, fieldKey, objectId, onValueChange, onSuccess }: UseCommentEffectsOptions) => {
    useEffect(() => {
        if (editor) {
            const syncEditorState = () => {
                const content = editor.getContent();
                onValueChange(content);
            };

            const commentCreateCancelHandler = (e: CustomEvent) => {
                removeNewCommentSegment(editor, e.detail.temporaryId);
                syncEditorState();
            };
            document.addEventListener('comment-create-cancel', commentCreateCancelHandler);

            const commentDeleteHandler = (e: CustomEvent<CommentEventDeleteDetail>) => {
                removeNewCommentSegment(editor, e.detail.commentId);
                syncEditorState();
            };
            document.addEventListener('comment-delete', commentDeleteHandler);

            const commentCurrentHighLightHandler = (e: CustomEvent<CommentEventCurrentHighLightDetail>) => {
                highLightCommentSegment(editor, e.detail.commentId, e.detail.remove)
            };
            document.addEventListener('comment-current-highlight', commentCurrentHighLightHandler);

            const editorHighLightHandler = (e: CustomEvent<CommentEventHighLightEditorDetail>) => {
                if (fieldKey) { highLightEditor(editor, fieldKey, e.detail.comment, objectId) }
            };
            document.addEventListener('highlight-editor', editorHighLightHandler);

            const commentCreateSuccessHandler = (
                e: CustomEvent<CommentEventCreateSuccessDetail>
            ) => {
                const didConfirmComment = confirmNewCommentSegment(editor, e.detail.temporaryId, e.detail.commentId);
                if (didConfirmComment) {
                    syncEditorState();
                    onSuccess?.();
                }
            };
            document.addEventListener('comment-create-success', commentCreateSuccessHandler);

            const commentResolveHandler = (e: CustomEvent<CommentEventResolveDetail>) => {
                resolveCommentSegment(editor, e.detail.commentId, e.detail.resolved);
                syncEditorState();
            };
            document.addEventListener('comment-resolve', commentResolveHandler);

            /** EDITOR LISTENERS */
            const editorDocument = editor.getDoc();
            const commentedElements = editorDocument.querySelectorAll('span[data-id]');
            const commentedElementMouseEnterHandlers = new Map<Element, () => void>();
            const commentedElementMouseClickHandlers = new Map<Element, (e: Event) => void>();
            const commentedElementMouseLeaveHandler = () => {
                document.dispatchEvent(createCustomEvent('comment-highlight-clear', {}));
            };
            commentedElements.forEach(element => {
                const id = element.getAttribute('data-id');
                const mouseEnterHandler = () => {
                    if (id) {
                        document.dispatchEvent(
                            createCustomEvent('comment-highlight', {
                                detail: { commentId: parseInt(id) },
                            })
                        );
                    }
                };
                element.addEventListener('mouseenter', mouseEnterHandler);
                commentedElementMouseEnterHandlers.set(element, mouseEnterHandler);
                const mouseClickHandler = (e: Event) => {
                    e.stopPropagation();
                    if (id) {
                        document.dispatchEvent(
                            createCustomEvent('comment-highlight', {
                                detail: { commentId: parseInt(id), scroll: true },
                            })
                        );
                    }
                };
                element.addEventListener('click', mouseClickHandler);
                commentedElementMouseClickHandlers.set(element, mouseClickHandler);
                element.addEventListener('mouseleave', commentedElementMouseLeaveHandler);
            });

            return () => {
                document.removeEventListener('comment-create-success', commentCreateSuccessHandler);
                document.removeEventListener('comment-create-cancel', commentCreateCancelHandler);
                document.removeEventListener('comment-delete', commentDeleteHandler);
                document.removeEventListener('comment-resolve', commentResolveHandler);
                document.removeEventListener('comment-current-highlight', commentCurrentHighLightHandler);
                document.addEventListener('highlight-editor', editorHighLightHandler);
                commentedElements.forEach(element => {
                    const mouseEnterHandler = commentedElementMouseEnterHandlers.get(element);
                    if (mouseEnterHandler) {
                        element.removeEventListener('mouseenter', mouseEnterHandler);
                    }
                    const mouseClickHandler = commentedElementMouseClickHandlers.get(element);
                    if (mouseClickHandler) {
                        element.removeEventListener('click', mouseClickHandler);
                    }
                    element.removeEventListener('mouseleave', commentedElementMouseLeaveHandler);
                });
            };
        }
    }, [editor, comments]);
};
