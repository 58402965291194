
import classNames from 'classnames/bind';
import { AddButton, Dialog, Flexbox, IconButton, Input, Select } from 'components';
import Table, { TableHeader } from 'components/Table';
import { ChangeEvent, useState } from 'react';
import { emailValidation } from 'utils/validations';
import { Permissions } from 'utils/permissions';
import styles from './usersInviteDialog.module.scss';
import { CloseIcon } from 'components/icons';
import { getEnumKeyByEnumValue } from 'utils';
import { useInviteUsersMutation } from 'store/users-api';
import { useWorkspaceId } from 'utils/hooks';
const classes = classNames.bind(styles);


interface UsersInviteDialogProps{
    inviteDialogOpen: boolean;
    setInviteDialogOpen: (setDialog: boolean) => void
}

const inviteHeader: TableHeader<InviteModel>[] = [
    {
        id: 'email',
        text: 'E-mail',
        width: '240px',
    },
    {
        id: 'role',
        text: 'Role',
        width: '120px',
    },
    {
        id: 'id',
        text: '',
        width: '50px'
    }
]

export interface InviteModel{
    email: string,
    role: keyof typeof Permissions,
    id?: string,
}

type PermissionOption = { id: keyof typeof Permissions; value: Permissions };

const permissionOptions = Object.keys(Permissions).map(key => ({
    id: key as keyof typeof Permissions,
    value: Permissions[key as keyof typeof Permissions]
}));

const UsersInviteDialog = ({ inviteDialogOpen, setInviteDialogOpen } : UsersInviteDialogProps) => {
    const workspaceId = useWorkspaceId();

    const [inviteUsers] = useInviteUsersMutation();

    const [inviteTableData, setInviteTableData] = useState<InviteModel[]>([])
    const [inviteEmail, setInviteEmail] = useState('')
    const [inviteRole, setInviteRole] = useState<PermissionOption | null>(null)

    const onInviteEmailHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setInviteEmail(e.target.value)
    }

    const onInviteRoleHandler = (_e: ChangeEvent<{}>, value: PermissionOption | null) => {
        setInviteRole(value)
    }

    const addInviteUser = () => {
        if(emailValidation(inviteEmail.trim()) && inviteRole){
            const role = getEnumKeyByEnumValue(Permissions, inviteRole.value);
            if(role) {
                setInviteTableData([
                    {
                        email: inviteEmail,
                        role
                    },
                    ...inviteTableData
                ])
                setInviteEmail('')
                setInviteRole(null)
            }
        }
    }

    const removeInviteUser = (index: number) => {
        const inviteData = [...inviteTableData]
        inviteData.splice(index, 1)
        setInviteTableData(inviteData)
    }

    const closeInviteDialog = () => {
        setInviteDialogOpen(false);
        setInviteEmail('');
        setInviteRole(null)
        setInviteTableData([]);
    }

    const onInvite = async () => {
        if(inviteTableData.length){
            inviteUsers({ workspaceId, data: inviteTableData }).finally(closeInviteDialog)
        }
    }

    const onUserRoleChange = (index: number, newValue: keyof typeof Permissions) => {
        const inviteTable = [...inviteTableData]
        inviteTable[index].role = newValue

        setInviteTableData(inviteTable)
    }

    const inviteTable = inviteTableData.map((user, index) => {
        return {
            data: [
                <Flexbox>
                    {user.email}
                </Flexbox>,
                <Select
                    value={permissionOptions.find(option => option.id === user.role)}
                    onChange={(_e: ChangeEvent<{}>, value: PermissionOption) => {
                        onUserRoleChange(index, value.id)
                    }}
                    options={permissionOptions}
                    disableClearable
                    getOptionLabel={option => option.value}
                    variant='filled'
                />,
                <Flexbox justifyEnd>
                    <IconButton
                        className={classes('iconButton')}
                        disableRipple
                        size="small"
                        onClick={() => removeInviteUser(index)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Flexbox>
            ]
        }
    });

    return(
        <Dialog
            className={classes('inviteDialogContainer')}
            open={inviteDialogOpen}
            title='User E-mail'
            onClose={closeInviteDialog}
            confirmButton
            confirmButtonLabel='Invite'
            onConfirm={onInvite}
            cancelButton
        >
            <Flexbox className={classes('invitePopupMainContainer')}>
                <Table
                    stickyHeader={false}
                    header={inviteHeader}
                    data={[{
                        data: [
                            <Input
                                value={inviteEmail}
                                onChange={onInviteEmailHandler}
                                placeholder='Add e-mail'
                                variant='filled'
                            />,
                            <Select
                                onChange={onInviteRoleHandler}
                                options={permissionOptions}
                                value={inviteRole}
                                getOptionLabel={option => option.value}
                                variant='filled'
                                placeholder='Select role'
                            />,
                            <Flexbox justifyEnd>
                                <AddButton
                                    onClick={addInviteUser}
                                    active={!!inviteEmail && !!inviteRole}
                                />
                            </Flexbox>,
                        ],
                        hasEditableField: true
                    },
                    ...inviteTable]
                    }
                />
            </Flexbox>
        </Dialog>
    )
}

export default UsersInviteDialog