import { FC } from 'react';
import { getInitialsFromName } from 'utils';
import styles from './commentAvatar.module.scss';
import classNames from 'classnames/bind';
import { Avatar } from 'components';
const classes = classNames.bind(styles);

interface CommentAvatarProps {
    className?: string;
    filled?: boolean;
    initials?: boolean;
    children: string;
}

export const CommentAvatar: FC<CommentAvatarProps> = ({
    className,
    children,
    initials = false,
    filled = false,
}) => {
    return (
        <Avatar
            className={classes('comment-avatar', className, {
                filled,
            })}
        >
            {initials ? getInitialsFromName(children) : children}
        </Avatar>
    );
};
