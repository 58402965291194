export const getEnumKeyByEnumValue = <T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null => {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
}

export const getInitialsFromName = (fullName: string) => {
    const nameParts = fullName.split(' ');
    return `${nameParts[0][0]}${nameParts[1] ? nameParts[1][0] : ''}`;
}

export const calculateProgress = (start: number, target: number, current: number) => {
    if (target - start === 0) {
        return 0;
    }
    return Math.min(100 * (current - start) / (target - start), 100);
}

export const toPrecision = (value: number, format = 1) => {
    const multiplier = 10 ** format;

    return Math.floor(value * multiplier) / multiplier;
}

export const trimSpaces = (text: string) => {
    return text.trim().replace(/\s+/g, ' ');
}

export const calculateOkrProgress = (values: number[]) => {
    let progress = 0;

    if (values.length) {
        const allProgress = values.reduce((prevValue: number, current: number) => {
            return prevValue + current
        }, 0)

        progress = allProgress / values.length;
    }

    return progress
}

export function isValidHttpsUrl(url: string) {
    const pattern = /^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?$/;
    return pattern.test(url);
}
