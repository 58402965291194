import { LinearProgress, Select } from 'components';
import Flexbox from 'components/Flexbox'
import Table, { TableHeader } from 'components/Table';
import { ReportOKR, ReportOKRKeyResult, ReportVariantTypes } from 'utils/types';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import { calculateOkrProgress } from 'utils';
import { useDispatch } from 'react-redux';
import { updateReport } from 'pages/Reports/reports.api';
const classes = classNames.bind(styles);


const okrHeaders: TableHeader<ReportOKR>[] = [
    {
        id: 'objective',
        text: 'Objective',
        sortable: true,
    },
    {
        id: 'owner',
        text: 'Owner',
        sortable: true,
    },
    {
        id: 'startDate',
        text: 'Start Date',
        sortable: true,
    },
    {
        id: 'endDate',
        text: 'End Date',
        sortable: true,
    },
    {
        id: 'progress',
        text: 'Progress',
        sortable: true,
    },
];

interface KeyResultTableHeader {
    metric: string;
    current: number;
    target: number;
    confidence: number
}

const krHeaders: TableHeader<KeyResultTableHeader>[] = [
    {
        id: 'metric',
        text: 'Metric',
    },
    {
        id: 'current',
        text: 'Current value',
    },
    {
        id: 'target',
        text: 'Target value',
    },
    {
        id: 'confidence',
        text: 'Confidence',
    },
]

interface OkrReportTableProps{
    okrs: ReportOKR[],
    reportId: number,
    templateId: number,
    teamId: number,
    reportType: ReportVariantTypes;
}

const confidenceValues = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']



const OkrReportTable = ({ okrs, reportId, templateId, teamId, reportType }: OkrReportTableProps) => {

    const dispatch = useDispatch();

    const [orderBy, setOrderBy] = useState<keyof ReportOKR | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc' | undefined>();

    const [data, setData] = useState<ReportOKR[]>([])

    useEffect(() => {
        setData(data)
    }, [okrs])

    useEffect(() => {
        let data = [...okrs];

        if(order && orderBy) {
            data.sort((d1, d2) => {
                const value1 = d1[orderBy];
                const value2 = d2[orderBy]
                if(!!value1  && !!value2 && value1 > value2) {
                    return order === 'asc' ? 1 : -1
                } else {
                    return order === 'asc' ? -1 : 1
                }
            })
        }
        setData(data);

    }, [order, orderBy, okrs])

    const sortHandler = (header: keyof ReportOKR) => {
        setOrderBy(header);
        setOrder(oldOrder => {
            return oldOrder === 'asc' ? 'desc' : 'asc'
        })
    }

    const onConfidenceChange = async (newValue: string, currentOkr: ReportOKR, kr: ReportOKRKeyResult) => {

        const getKr = data.find(okr => okr.id === currentOkr.id)?.keyResults.find(keyResult => keyResult.id === kr.id);

        if(getKr){
            getKr.confidence = +newValue
            setData([...data])

            const model = {
                metricId: kr.id,
                okrId: currentOkr.id,
                confidence: +newValue,
                teamId: teamId
            }
            await dispatch(updateReport(templateId, reportId, model))
        }
    }

    const krsTableRenderer = (_d: string[], index: number) => {

        const currentOkr = data[index]

        return (
            <Table
                header={krHeaders}
                stickyHeader={false}
                nested
                data={currentOkr?.keyResults.map(kr => {

                    return {
                        data: [
                            kr.name,
                            kr.value,
                            kr.targetValue,
                            <Flexbox>
                                <Select
                                    onChange={(_e: ChangeEvent<{}>, value: string) => {onConfidenceChange(value, currentOkr, kr)}}
                                    value={confidenceValues.find(confidenceValue => confidenceValue === (kr.confidence + ''))}
                                    options={confidenceValues}
                                    variant='filled'
                                    placeholder='1-10'
                                    disableClearable
                                />
                            </Flexbox>
                        ]
                    }
                })}
            />
        )
    }

    return(
        <Flexbox vertical className={classes('tableContainer')}>
            <Flexbox className={classes('title')}>
                {reportType === ReportVariantTypes['By Okr'] ? 'OKR' : 'OKRs'}
            </Flexbox>
            <Table
                header={okrHeaders}
                stickyHeader={false}
                data={data.map(okr => {

                    const startDate = okr.startDate ? new Date(okr.startDate) : null;
                    const endDate = okr.endDate ? new Date(okr.endDate) : null;

                    okr.allKrProgress = calculateOkrProgress(okr.keyResults?.map(el => el.progress))

                    return {
                        openRenderer: krsTableRenderer,
                        collapsible: okr.keyResults?.length > 0,
                        url: `/okrs/okr/${okr.id}`,
                        data: [
                            okr.objective,
                            okr.owner,
                            startDate?.toLocaleDateString(),
                            endDate?.toLocaleDateString(),
                            <Flexbox align>
                                <Flexbox className={classes('progressContainer')}>
                                    <LinearProgress value={okr.allKrProgress || 0} variant="determinate" />
                                </Flexbox>
                                <Flexbox>{Math.round(okr.allKrProgress || 0)}% </Flexbox>
                            </Flexbox>,
                        ]
                    }
                })}
                collapsible
                orderBy={orderBy}
                order={order}
                sortHandler={sortHandler}
                emptyText={data.length === 0 ? 'There are no OKRs yet' : undefined}
            />
        </Flexbox>
    )
}

export default OkrReportTable
